







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LogwoodService from '@/logwood/services/logwood-service'
import LogwoodHistoryFilter from '@/logwood/models/logwood-history-filter'
import LogwoodHistory from '@/logwood/models/logwood-history'
import { LogwoodPackageState } from '@/logwood/models/logwood-package-state'
import { HistoryPeriodType } from '@/logwood/models/history-period-type'
import { sumBy } from 'lodash'

@Component
export default class LogwoodHistoryInventory extends Vue {
  @Prop()
  private state!: LogwoodPackageState

  @Prop()
  private title!: string

  private periodTypes = [
    {
      text: 'shifts',
      value: HistoryPeriodType.Date,
      icon: 'calendar_view_day',
    },
    {
      text: 'weeks',
      value: HistoryPeriodType.Week,
      icon: 'calendar_view_week',
    },
    {
      text: 'months',
      value: HistoryPeriodType.Month,
      icon: 'calendar_view_month',
    },
  ]

  private loading = false
  private filter = new LogwoodHistoryFilter()
  private shifts: LogwoodHistory[] = []

  private get isPeriodTypeDate() {
    return this.filter.periodType === HistoryPeriodType.Date
  }

  private get isPeriodTypeWeek() {
    return this.filter.periodType === HistoryPeriodType.Week
  }

  private get isPeriodTypeMonth() {
    return this.filter.periodType === HistoryPeriodType.Month
  }

  private get inputType() {
    return this.isPeriodTypeMonth ? 'month' : 'date'
  }

  private get type() {
    if (this.isPeriodTypeMonth) {
      return 'month'
    }
    if (this.isPeriodTypeWeek) {
      return 'week'
    }
    return 'day'
  }

  @Watch('filter', { deep: true })
  private async filterChanged() {
    await this.loadShiftsAsync()
  }

  private get volumeSum() {
    return sumBy(this.shifts, (i) => i.volume)
  }

  private async loadShiftsAsync() {
    this.loading = true
    this.shifts = await LogwoodService.loadShiftsFilteredAsync(this.filter)
    this.loading = false
  }

  private print() {
    window.print()
    // window.close()
  }

  private async mounted() {
    await this.loadShiftsAsync()
  }
}

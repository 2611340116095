import axios from '@/app/axios'
import bus from '@/app/bus'
import routing from '@/app/config/routing'
import router from '@/app/router'
import store from '@/app/store'
import Responses from '@/app/utils/responses'
import AuthRequest from '@/users/requests/auth-request'
import AuthResponse from '@/users/responses/auth-response'
import AuthModule from '@/users/store/auth-module'
import { getModule } from 'vuex-module-decorators'
import { AxiosResponse } from 'axios'
import i18n from '@/app/i18n'

class AuthService {
  public async loginAsync(request: AuthRequest) {
    const res = await axios.post<AuthResponse>('auth/login', request)
    return this.handleAuthResponse(res)
  }

  public async impersonate(request: AuthRequest) {
    const res = await axios.post<AuthResponse>('auth/impersonate', request)
    return this.handleAuthResponse(res)
  }

  public async resetPasswordAsync(
    email: string,
    token: string,
    password: string
  ) {
    const res = await axios.post('auth/password/reset', {
      email,
      token,
      password,
    })
    return [res.data.success, res.data.message]
  }

  public async sendPasswordResetLinkAsync(email: string) {
    await axios.post('auth/sendPasswordResetLink', { email })
  }

  public async changePasswordAsync(
    password: string,
    passwordConfirmation: string
  ) {
    const res = await axios.post('auth/password/change', {
      password,
      passwordConfirmation,
    })
    return [res.data.success, res.data.message]
  }

  public async logoutAsync() {
    const authModule = getModule(AuthModule, store)
    await axios.post('auth/logout')
    authModule.logout()
    // Browser History cannot be flushed.
    router.push({
      name: routing.logoutRedirect,
      params: { lang: i18n.locale },
    })
  }

  public authenticated() {
    const authModule = getModule(AuthModule, store)
    return authModule.authenticated
  }

  public isInRole(role: string) {
    const authModule = getModule(AuthModule, store)
    return authModule.roles.some((r) => r === role)
  }

  public isInAnyRole(roles: string[]) {
    return roles.some(this.isInRole)
  }

  public user() {
    const authModule = getModule(AuthModule, store)
    return authModule.user
  }

  public tenant() {
    const authModule = getModule(AuthModule, store)
    return authModule.tenant
  }

  public displayName() {
    const authModule = getModule(AuthModule, store)
    return authModule.displayName
  }

  private redirect() {
    const authModule = getModule(AuthModule, store)
    const role = authModule.roles.length > 0 ? authModule.roles[0] : 'DEFAULT'

    const redirectUrl = router.currentRoute.params.redirectUrl
    if (redirectUrl != null) {
      router.push({ name: redirectUrl })
      return
    }

    const routes = routing.loginDefaultRedirect as { [s: string]: string }
    if (routes[role]) {
      router.push({
        name: routes[role],
        params: { lang: i18n.locale },
      })
    } else {
      router.push({
        name: routes['DEFAULT'],
        params: { lang: i18n.locale },
      })
    }
  }

  private handleAuthResponse(res: AxiosResponse<AuthResponse>) {
    const authModule = getModule(AuthModule, store)
    const response = Responses.obj(res)
    if (response != null) {
      if (response.authenticated && response.token !== null) {
        authModule.login(response)
        bus.$emit('auth.logged-in')
        this.redirect()
      }
      return response
    }
    return null
  }
}

export default new AuthService()

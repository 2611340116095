








































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class DateInput extends Vue {
  @Ref()
  private pickerMenu!: any

  @Prop({ required: true, default: null })
  private value!: moment.MomentInput

  @Prop({ default: 'date' })
  private type!: string

  @Prop({ default: 'DD.MM.YYYY' })
  private displayFormat!: string

  @Prop({ default: 'YYYY-MM-DD' })
  private modelFormat!: string

  @Prop({ default: '' })
  private name!: string

  @Prop({ default: '' })
  private rules!: string

  @Prop({ default: () => [] })
  private errors!: any[]

  @Prop({ default: '100%' })
  private width!: string

  private menu = false
  private model = this.formatDate(this.value)
  private displayModel = this.formatDisplayDate(this.value)

  @Watch('value')
  private valueChanged(val: moment.MomentInput) {
    this.model = this.formatDate(val)
    this.displayModel = this.formatDisplayDate(val)
  }

  private dateSelected() {
    this.pickerMenu.save(this.model)
    this.$emit('input', this.model)
  }

  private monthSelected() {
    const date = `${this.model}-01`
    this.pickerMenu.save(date)
    this.$emit('input', date)
  }

  private formatDate(val: moment.MomentInput) {
    if (this.value) {
      return moment(val, this.modelFormat).format(this.modelFormat)
    }
    return ''
  }

  private formatDisplayDate(val: moment.MomentInput) {
    if (this.value) {
      return moment(val, this.modelFormat).format(this.displayFormat)
    }
    return ''
  }
}

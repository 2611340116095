var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "elevation-0",
    attrs: {
      loading: _vm.loading,
      headers: _vm.headers,
      items: _vm.stores,
      options: _vm.pPagination,
      "server-items-length": _vm.pPagination.totalItems,
      "footer-props": { itemsPerPageText: _vm.$t("items_per_page") },
      "no-data-text": _vm.$t("no_data_available")
    },
    on: {
      "update:options": function($event) {
        _vm.pPagination = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item.name",
          fn: function(ref) {
            var item = ref.item
            return [
              _vm._v(" " + _vm._s(item.name) + " "),
              _c("tenancy-chip", { attrs: { "tenant-id": item.tenantId } })
            ]
          }
        },
        {
          key: "item.project",
          fn: function(ref) {
            var item = ref.item
            return [
              item.projectId
                ? [
                    _c("span", [
                      _vm._v(
                        _vm._s(item.projectNumber) +
                          " " +
                          _vm._s(item.projectName)
                      )
                    ])
                  ]
                : _c("em", { staticClass: "text--disabled" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "_stores._stores_table.missing_warehouse_project"
                          )
                        )
                      )
                    ])
                  ])
            ]
          }
        },
        {
          key: "item.division",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "v-chip",
                { attrs: { small: "", color: _vm.divisionColor(item) } },
                [_c("span", [_vm._v(_vm._s(_vm.divisionName(item)))])]
              )
            ]
          }
        },
        {
          key: "item.primary",
          fn: function(ref) {
            var item = ref.item
            return [item.isPrimary ? _c("v-icon", [_vm._v("done")]) : _vm._e()]
          }
        },
        {
          key: "item.action",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "div",
                { staticClass: "text-no-wrap" },
                [
                  item.projectId
                    ? [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-1",
                            attrs: { icon: "", title: _vm.$t("parts_list") },
                            on: {
                              click: function($event) {
                                return _vm.newPartsList(item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("list")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-1",
                            attrs: { icon: "", title: _vm.$t("orders") },
                            on: {
                              click: function($event) {
                                return _vm.orders(item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("shopping_cart")])],
                          1
                        )
                      ]
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", title: _vm.$t("edit") },
                      on: {
                        click: function($event) {
                          return _vm.$emit("edit", item)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("edit")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", title: _vm.$t("delete") },
                      on: {
                        click: function($event) {
                          return _vm.$emit("remove", item)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  )
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RoleBadge extends Vue {
  @Prop({ required: true })
  private role!: string

  get roleName() {
    return this.$tc(`_users._roles.${this.role}`)
  }
}

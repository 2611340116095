import { render, staticRenderFns } from "./side-menu-group.vue?vue&type=template&id=6873bad6&"
import script from "./side-menu-group.vue?vue&type=script&lang=ts&"
export * from "./side-menu-group.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VListGroup,VListItemAction,VListItemContent,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6873bad6')) {
      api.createRecord('6873bad6', component.options)
    } else {
      api.reload('6873bad6', component.options)
    }
    module.hot.accept("./side-menu-group.vue?vue&type=template&id=6873bad6&", function () {
      api.rerender('6873bad6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/components/side-menu-group.vue"
export default component.exports
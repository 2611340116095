



















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ProjectItemGroup from '@/projects/entities/project-item-group'
import ProjectItem from '@/projects/entities/project-item'
import ProductOrderItem from '@/orders/entities/product-order-item'

@Component
export default class ProjectItemsSelect extends Vue {
  @Prop()
  private value!: ProjectItem[]

  @Prop()
  private groups!: ProjectItemGroup[]

  @Prop({ type: String, default: 'Keine Auswahl möglich.' })
  private emptyMessage!: string

  @Prop({ default: () => [] })
  private orderStates!: ProductOrderItem[]

  private selection: ProjectItem[] = this.value
  private open: ProjectItemGroup[] = []

  @Watch('value')
  private valueChanged(val: ProjectItem[], old: ProjectItem[]) {
    if (val !== old) {
      this.selection = val
    }
  }

  @Watch('selection')
  private selectionChanged(val: ProjectItem[], old: ProjectItem[]) {
    if (val !== old) {
      val.forEach((i) => {
        this.groups.forEach((g) => {
          if (g.items.includes(i)) {
            i.groupName = g.name
          }
        })
      })
      this.$emit('input', val)
    }
  }

  private expandAll() {
    this.open = this.groups
  }

  private collapseAll() {
    this.open = []
  }

  private selectAll() {
    this.selection = this.groups.flatMap((g) => g.items)
  }

  private unselectAll() {
    this.selection = []
  }
}

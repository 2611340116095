var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logwood-tag" }, [
    _c("div", { staticClass: "logwood-tag__table" }, [
      _c("div", { staticClass: "logwood-tag__table__row" }, [
        _vm._m(0),
        _c("div", { staticClass: "logwood-tag__table__col two-third" }, [
          _c("svg", { attrs: { id: "barcode" } })
        ])
      ]),
      _c("div", { staticClass: "logwood-tag__table__row" }, [
        _c("div", { staticClass: "logwood-tag__table__col one-third" }, [
          _c("span", { staticClass: "logwood-tag__h1" }, [
            _vm._v(_vm._s(_vm.$t("package_nr")))
          ])
        ]),
        _c("div", { staticClass: "logwood-tag__table__col two-third" }, [
          _c("span", { staticClass: "logwood-tag__h1" }, [
            _vm._v(_vm._s(_vm._f("logwoodPackageNr")(_vm.pack)))
          ])
        ])
      ]),
      _c("div", { staticClass: "logwood-tag__table__row" }, [
        _c("div", { staticClass: "logwood-tag__table__col one-third" }, [
          _c("div", { staticClass: "logwood-tag__h3" }),
          _c("div", { staticClass: "logwood-tag__h1" }, [
            _vm._v(_vm._s(_vm.$t("wood_type")))
          ])
        ]),
        _c("div", { staticClass: "logwood-tag__table__col one-half" }, [
          _c("div", { staticClass: "logwood-tag__h3" }),
          _c("div", { staticClass: "logwood-tag__h4" }, [
            _vm._v(_vm._s(_vm.pack.targetProductName))
          ])
        ]),
        _c("div", { staticClass: "logwood-tag__table__col one-sixth" }, [
          _c("div", { staticClass: "logwood-tag__h3" }, [
            _vm._v(_vm._s(_vm.$t("thickness")))
          ]),
          _c("div", { staticClass: "logwood-tag__h1" }, [
            _vm._v(_vm._s(_vm._f("num")(_vm.pack.height, 0)))
          ])
        ])
      ]),
      _c("div", { staticClass: "logwood-tag__table__row" }, [
        _c("div", { staticClass: "logwood-tag__table__col one-third" }, [
          _c("div", { staticClass: "logwood-tag__h3" }, [
            _vm._v(_vm._s(_vm.$t("length")))
          ]),
          _c("div", { staticClass: "logwood-tag__h2" }, [
            _vm._v(_vm._s(_vm._f("num")(_vm.pack.length, 0)) + " mm")
          ])
        ]),
        _c("div", { staticClass: "logwood-tag__table__col one-third" }, [
          _c("div", { staticClass: "logwood-tag__h3" }, [
            _vm._v(_vm._s(_vm.$t("quantity")) + " m³")
          ]),
          _c("div", { staticClass: "logwood-tag__h2" }, [
            _vm._v(_vm._s(_vm._f("num")(_vm.pack.volume, 3)) + " m³")
          ])
        ]),
        _c("div", { staticClass: "logwood-tag__table__col one-third" }, [
          _c("div", { staticClass: "logwood-tag__h3" }, [
            _vm._v(_vm._s(_vm.$t("quantity")) + " m²")
          ]),
          _c("div", { staticClass: "logwood-tag__h2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("num")(_vm.pack.volume / (_vm.pack.height / 1000), 2)
                ) +
                " m² "
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "logwood-tag__table__row" }, [
        _c("div", { staticClass: "logwood-tag__table__col one-third" }, [
          _c("div", { staticClass: "logwood-tag__h3" }, [
            _vm._v(_vm._s(_vm.$t("date")))
          ]),
          _c("div", { staticClass: "logwood-tag__h2" }, [
            _vm._v(_vm._s(_vm._f("isoDate")(_vm.pack.outerBearingOn)))
          ])
        ]),
        _c("div", { staticClass: "logwood-tag__table__col two-third" }, [
          _c("div", { staticClass: "logwood-tag__h3" }, [
            _vm._v(_vm._s(_vm.$t("processor")))
          ]),
          _c("div", { staticClass: "logwood-tag__h2" }, [
            _vm._v(_vm._s(_vm.pack.createdByName))
          ])
        ])
      ]),
      _c("div", { staticClass: "logwood-tag__table__row" }, [
        _c("div", { staticClass: "logwood-tag__table__col" }, [
          _c("div", { staticClass: "logwood-tag__h3" }, [
            _vm._v(_vm._s(_vm.$t("info")))
          ]),
          _c("div", { staticClass: "logwood-tag__info" })
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logwood-tag__table__col one-third" }, [
      _c("img", { attrs: { src: "/img/logo_short.png", height: "80" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c("h1", { staticClass: "mb-5" }, [
        _vm._v(_vm._s(_vm.$t("_users._register_form.register")))
      ]),
      _c("p", [_vm._v(_vm._s(_vm.$t("_users._register_form.subtitle")))]),
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var valid = ref.valid
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("validation-provider", {
                          attrs: {
                            rules: "required|email",
                            name: _vm.$t("email")
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("email"),
                                        outlined: "",
                                        dense: "",
                                        "error-messages": errors,
                                        "hide-details": errors.length === 0
                                      },
                                      model: {
                                        value: _vm.request.email,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.request,
                                            "email",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "request.email"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("salutation-select", {
                          attrs: {
                            label: _vm.$t("salutation"),
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.request.salutation,
                            callback: function($$v) {
                              _vm.$set(_vm.request, "salutation", $$v)
                            },
                            expression: "request.salutation"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("validation-provider", {
                          attrs: {
                            rules: "required",
                            name: _vm.$t("firstName")
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("firstName"),
                                        outlined: "",
                                        dense: "",
                                        "error-messages": errors,
                                        "hide-details": errors.length === 0
                                      },
                                      model: {
                                        value: _vm.request.firstName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.request,
                                            "firstName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "request.firstName"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("validation-provider", {
                          attrs: {
                            rules: "required",
                            name: _vm.$t("lastName")
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("lastName"),
                                        outlined: "",
                                        dense: "",
                                        "error-messages": errors,
                                        "hide-details": errors.length === 0
                                      },
                                      model: {
                                        value: _vm.request.lastName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.request,
                                            "lastName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "request.lastName"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("validation-provider", {
                          attrs: { rules: "required", name: _vm.$t("address") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("address-input", {
                                      ref: "addressInput",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        "error-messages": errors,
                                        "hide-details": errors.length === 0
                                      },
                                      model: {
                                        value: _vm.request.address,
                                        callback: function($$v) {
                                          _vm.$set(_vm.request, "address", $$v)
                                        },
                                        expression: "request.address"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-end mt-3" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.loading,
                          disabled: !valid,
                          depressed: "",
                          color: "primary"
                        },
                        on: { click: _vm.register }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("_users._register_form.register")) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from '@/app/axios'
import Responses from '@/app/utils/responses'

@Component
export default class Tags extends Vue {
  @Prop({ required: true })
  private value!: string[]

  @Prop({ required: true })
  private url!: string

  @Prop({ default: 'Tags' })
  private label!: string

  @Prop({ type: Boolean, default: false })
  private readonly!: boolean

  private items: string[] = []
  private selection: string[] = this.value
  private text = ''
  private loading: boolean = false

  @Watch('value', { deep: true })
  private valueChanged(val: string[], old: string[]) {
    if (val !== old) {
      this.selection = val
    }
  }

  private async getKeywords(text: string | null) {
    this.loading = true
    const res = await axios.get<string[]>(`${this.url}?text=${text || ''}`)
    this.items = Responses.list(res)
    this.loading = false
  }

  private onChange() {
    this.$emit('input', this.selection)
  }

  private highlight(v: string) {
    const regex = new RegExp(`(${this.text})`, 'ig')
    return v.replace(regex, '<strong>$1</strong>')
  }

  private async created() {}

  private mounted() {}
}

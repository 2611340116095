var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "container-toolbar", attrs: { flat: "" } },
        [
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("open"), "hide-details": "" },
            on: { click: _vm.fetchAll },
            model: {
              value: _vm.isOpen,
              callback: function($$v) {
                _vm.isOpen = $$v
              },
              expression: "isOpen"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: {
              label: _vm.$t("in_production_planning"),
              "hide-details": ""
            },
            on: { click: _vm.fetchAll },
            model: {
              value: _vm.isInPreparation,
              callback: function($$v) {
                _vm.isInPreparation = $$v
              },
              expression: "isInPreparation"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("in_production"), "hide-details": "" },
            on: { click: _vm.fetchAll },
            model: {
              value: _vm.isInProduction,
              callback: function($$v) {
                _vm.isInProduction = $$v
              },
              expression: "isInProduction"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("in_delivery"), "hide-details": "" },
            on: { click: _vm.fetchAll },
            model: {
              value: _vm.isInDelivery,
              callback: function($$v) {
                _vm.isInDelivery = $$v
              },
              expression: "isInDelivery"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("closed"), "hide-details": "" },
            on: { click: _vm.fetchAll },
            model: {
              value: _vm.isClosed,
              callback: function($$v) {
                _vm.isClosed = $$v
              },
              expression: "isClosed"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("cancelled"), "hide-details": "" },
            on: { click: _vm.fetchAll },
            model: {
              value: _vm.isCancelled,
              callback: function($$v) {
                _vm.isCancelled = $$v
              },
              expression: "isCancelled"
            }
          })
        ],
        1
      ),
      _c(
        "v-toolbar",
        { staticClass: "container-toolbar", attrs: { flat: "" } },
        [
          _c("v-text-field", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce:input",
                value: 500,
                expression: "500",
                arg: "input"
              }
            ],
            staticClass: "mr-2",
            attrs: {
              label: _vm.$t("number") + "/" + _vm.$t("name"),
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: "",
              "append-icon": "search"
            },
            on: { input: _vm.fetchAll },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c("company-select", {
            staticClass: "mr-2",
            attrs: {
              type: "customer",
              label: _vm.$t("customer"),
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            on: { input: _vm.fetchAll },
            model: {
              value: _vm.customerId,
              callback: function($$v) {
                _vm.customerId = $$v
              },
              expression: "customerId"
            }
          }),
          _c("user-autocomplete", {
            attrs: {
              label: _vm.$t("leader"),
              outlined: "",
              dense: "",
              "hide-details": "",
              "highlight-current-user": ""
            },
            on: { input: _vm.fetchAll },
            model: {
              value: _vm.leadId,
              callback: function($$v) {
                _vm.leadId = $$v
              },
              expression: "leadId"
            }
          })
        ],
        1
      ),
      _c("projects-table", {
        attrs: {
          loading: _vm.loading,
          projects: _vm.projects,
          pagination: _vm.pagination
        },
        on: {
          "update:pagination": function($event) {
            _vm.pagination = $event
          },
          edit: _vm.openEdit,
          delete: _vm.remove
        }
      }),
      _c(
        "v-speed-dial",
        {
          staticClass: "mb-8",
          attrs: {
            fixed: "",
            bottom: "",
            right: "",
            direction: "top",
            transition: "slide-y-reverse-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", fab: "" },
                      model: {
                        value: _vm.fab,
                        callback: function($$v) {
                          _vm.fab = $$v
                        },
                        expression: "fab"
                      }
                    },
                    [
                      _vm.fab
                        ? _c("v-icon", [_vm._v("close")])
                        : _c("v-icon", [_vm._v("build")])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.fab,
            callback: function($$v) {
              _vm.fab = $$v
            },
            expression: "fab"
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { title: _vm.$t("_projects.add_project"), fab: "" },
              on: { click: _vm.openNew }
            },
            [_c("v-icon", [_vm._v("add")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { title: _vm.$t("_projects.import_project"), fab: "" },
              on: { click: _vm.importProject }
            },
            [_c("v-icon", [_vm._v("get_app")])],
            1
          )
        ],
        1
      ),
      _c("project-dialog", {
        ref: "projectDialog",
        on: { create: _vm.create, update: _vm.update }
      }),
      _c("project-import-dialog", {
        ref: "projectImportDialog",
        on: { imported: _vm.imported }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
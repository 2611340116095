var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "x-number-input",
    _vm._g(
      _vm._b(
        {
          attrs: {
            prefix: _vm.currency,
            "fractional-digits": _vm.fractionalDigits
          },
          model: {
            value: _vm.amount,
            callback: function($$v) {
              _vm.amount = $$v
            },
            expression: "amount"
          }
        },
        "x-number-input",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
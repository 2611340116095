var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "offset-y": "",
        "max-width": 150
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "div",
                _vm._g(
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    staticClass:
                      "\n        cursor-pointer\n        text-caption text-center text-no-wrap text--secondary\n      "
                  },
                  on
                ),
                [
                  _vm.oversize
                    ? _c("span", [
                        _vm.oversize > 0 ? _c("span", [_vm._v("+")]) : _vm._e(),
                        _vm._v(_vm._s(_vm.oversize) + " mm ")
                      ])
                    : _c("span", [_vm._v(_vm._s(_vm.$t("oversize")))])
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "pa-3" },
            [
              _c("x-number-input", {
                attrs: {
                  placeholder: _vm.$t("oversize"),
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  "fractional-digits": 1,
                  suffix: "mm"
                },
                model: {
                  value: _vm.oversize,
                  callback: function($$v) {
                    _vm.oversize = $$v
                  },
                  expression: "oversize"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-group",
    {
      staticClass: "side-menu-group",
      attrs: { value: "true" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function() {
            return [
              _c(
                "v-list-item-action",
                [
                  _vm.icon
                    ? _c("v-icon", [_vm._v(_vm._s(_vm.icon))])
                    : _vm._e(),
                  _vm.iconClass
                    ? _c("i", {
                        class: _vm.iconClass,
                        style: "font-size:" + _vm.size + "px;"
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(_vm.title))])],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }











import { Component, Prop, Vue } from 'vue-property-decorator'
import LogwoodPackage from '@/logwood/models/logwood-package'

@Component
export default class LogwoodPackageId extends Vue {
  @Prop()
  private pack!: LogwoodPackage
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "700px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    attrs: { "min-height": "100%" }
                  },
                  [
                    _c("v-card-title", { staticStyle: { height: "64px" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("sync_prices")) + " ")
                    ]),
                    _c(
                      "v-card-text",
                      { staticClass: "flex-grow-1" },
                      [
                        _c("v-alert", { attrs: { type: "warning" } }, [
                          _vm._v(_vm._s(_vm.$t("hint_csv_format")))
                        ]),
                        _c(
                          "v-form",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required",
                                            name: _vm.$t("file")
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("v-file-input", {
                                                      attrs: {
                                                        label: _vm.$t("file"),
                                                        accept: ".csv",
                                                        "show-size": "",
                                                        outlined: "",
                                                        dense: "",
                                                        disabled: _vm.loading,
                                                        "error-messages": errors,
                                                        "hide-details":
                                                          errors.length === 0
                                                      },
                                                      model: {
                                                        value: _vm.file,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.file = $$v
                                                        },
                                                        expression: "file"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "pb-0" },
                                      [
                                        _c("excel-column-select", {
                                          attrs: {
                                            file: _vm.file,
                                            label: _vm.$t(
                                              "column_product_number"
                                            ),
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            disabled: !_vm.file
                                          },
                                          model: {
                                            value: _vm.articleColumnIndex,
                                            callback: function($$v) {
                                              _vm.articleColumnIndex = $$v
                                            },
                                            expression: "articleColumnIndex"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "pb-0" },
                                      [
                                        _c("excel-column-select", {
                                          attrs: {
                                            file: _vm.file,
                                            label: _vm.$t("column_price"),
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            disabled: !_vm.file
                                          },
                                          model: {
                                            value: _vm.priceColumnIndex,
                                            callback: function($$v) {
                                              _vm.priceColumnIndex = $$v
                                            },
                                            expression: "priceColumnIndex"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "pb-0" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules:
                                              "required|integer|min_value:1",
                                            name: _vm.$t("price_divisor")
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "price_divisor"
                                                        ),
                                                        outlined: "",
                                                        dense: "",
                                                        "error-messages": errors,
                                                        "hide-details": "auto",
                                                        disabled: !_vm.file
                                                      },
                                                      model: {
                                                        value: _vm.priceDivisor,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.priceDivisor = $$v
                                                        },
                                                        expression:
                                                          "priceDivisor"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.warnings && _vm.warnings.length > 0
                          ? _c(
                              "v-alert",
                              {
                                staticClass: "mx-3 mt-5",
                                attrs: {
                                  border: "bottom",
                                  "colored-border": "",
                                  type: "warning",
                                  elevation: "2",
                                  dismissible: ""
                                }
                              },
                              [
                                _c(
                                  "ul",
                                  _vm._l(_vm.warnings, function(warning, idx) {
                                    return _c("li", { key: idx }, [
                                      _vm._v(" " + _vm._s(warning) + " ")
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-7 pb-5" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text",
                            attrs: { text: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: _vm.loading,
                              disabled: invalid,
                              depressed: "",
                              color: "primary"
                            },
                            on: { click: _vm.confirm }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("import")) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "tenants fill-width" },
      [
        _c("tenant-dialog", {
          ref: "dialog",
          on: { create: _vm.create, update: _vm.update }
        }),
        _c("tenants-table", {
          attrs: {
            loading: _vm.loading,
            tenants: _vm.tenants,
            pagination: _vm.pagination
          },
          on: {
            "update:pagination": function($event) {
              _vm.pagination = $event
            },
            edit: _vm.edit
          }
        }),
        _vm.$auth.isInAnyRole(["ADMIN"])
          ? _c(
              "v-btn",
              {
                staticClass: "mb-8",
                attrs: {
                  fixed: "",
                  fab: "",
                  bottom: "",
                  right: "",
                  color: "primary"
                },
                on: { click: _vm.openNew }
              },
              [_c("v-icon", [_vm._v("add")])],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
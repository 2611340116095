







































import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import Product from '@/products/entities/product'
import ProductsService from '@/products/services/products-service'
import ProductPicker from '@/products/components/product-picker.vue'
import isString from 'lodash/isString'
import { ProductCategory } from '@/products/entities/product-category'
import ProductFilter from '@/products/entities/product-filter'

@Component
export default class SimpleProductSelect extends Vue {
  @Prop({ required: true })
  private value!: Product | string | null

  @Prop({ default: () => [] })
  private categories!: ProductCategory[]

  @Prop({ type: Boolean, default: false })
  private pruned!: boolean

  @Prop({ type: Boolean, default: false })
  private returnObject!: boolean

  @Ref()
  private picker!: ProductPicker

  private filter = new ProductFilter()
  private loading = false

  private items: Product[] = []
  private item: Product | null = null

  @Watch('value')
  private async valueChanged(val: Product | string | null) {
    await this.prefetchAsync(val)
  }

  @Watch('categories', { deep: true })
  private async categoriesChangedAsync() {
    this.filter.categories = this.categories
  }

  @Watch('filter.text')
  private async searchChanged(val: string, old: string) {
    if (val && val !== old) {
      this.filter.text = val
      await this.loadAsync()
    }
  }

  private async loadAsync() {
    this.loading = true
    this.items = await ProductsService.findAsync(this.filter)
    this.loading = false
  }

  private async prefetchAsync(val: Product | string | null) {
    if (val == null) {
      this.item = null
      return
    }

    let item: Product | null = null

    if (isString(val)) {
      item = await this.loadOneAsync(val)
    } else {
      item = val
    }

    this.pick(item)
  }

  private async loadOneAsync(val: string) {
    const pre = this.items.find((i) => i.id === val)
    return pre || (await ProductsService.findOneAsync(val))
  }

  private pick(item: Product | null) {
    this.addToItems(item)
    this.item = item
    this.emitChange()
  }

  private addToItems(item: Product | null) {
    if (item && this.items.every((p) => p.id !== item.id)) {
      this.items.push(item)
    }
  }

  private emitChange() {
    this.$emit('input', this.returnObject ? this.item : this.item?.id)
    this.$emit('input:id', this.item?.id)
    this.$emit('input:object', this.item)
  }

  private async mounted() {
    this.filter.categories = this.categories

    await this.prefetchAsync(this.value)
  }
}

import Country from '@/locations/models/country'

class CountriesService {

  public findAllAsync(): Country[] {
    return [
      { code: 'AT', name: 'Österreich' },
      { code: 'DE', name: 'Deutschland' },
      { code: 'DK', name: 'Dänemark' },
      { code: 'SF', name: 'Finnland' },
      { code: 'EE', name: 'Estland' },
      { code: 'IT', name: 'Italien' },
      { code: 'LV', name: 'Lettland' },
      { code: 'LT', name: 'Litauen' },
      { code: 'LI', name: 'Liechtenstein' },
      { code: 'NO', name: 'Norwegen' },
      { code: 'SE', name: 'Schweden' },
      { code: 'CH', name: 'Schweiz' },
      { code: 'SK', name: 'Slowakei' },
      { code: 'SI', name: 'Slowenien' },
      { code: 'CZ', name: 'Tschechien' },
      { code: 'HU', name: 'Ungarn' },
    ]
  }
}

export default new CountriesService()

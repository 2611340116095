























































import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator'
import ProjectItemGroup from '@/projects/entities/project-item-group'

@Component
export default class PartsListGroupHeader extends Vue {
  @Prop()
  private group!: ProjectItemGroup

  @Prop()
  private index!: number
}

import Vue from 'vue'
import numeral from 'numeral'

Vue.filter('num', (v: number, fractionalDigits: number = 0) => {
  if (v == null) {
    return ''
  }
  const fraction = '0'.repeat(fractionalDigits)
  return numeral(v).format(`0,0.${fraction}`)
})

Vue.filter('snum', (v: number, fractionalDigits: number = 0) => {
  if (v == null) {
    return ''
  }
  const fraction = '0'.repeat(fractionalDigits)
  let n = numeral(v).format(`0,0.${fraction}`)
  if (v > 0) {
    n = '+' + n
  }
  return n
})
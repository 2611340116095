





































































































































































































































import Tenant from '@/tenants/entities/tenant'
import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import EditDialogMixin from '@/common/dialogs/mixins/edit-dialog-mixin'
import AddressInput from '@/locations/components/address-input/address-input.vue'
import axios from '@/app/axios'
import ImageInput from '@/common/components/image-input.vue'

@Component
export default class TenantDialog extends Mixins<EditDialogMixin<Tenant>>(
  EditDialogMixin
) {
  @Ref()
  private addressInput!: AddressInput

  @Ref()
  private imageUpload!: ImageInput

  model = new Tenant()

  private async uploadImage(image: File) {
    const formData = new FormData()
    formData.append('image', image, image.name)
    const res = await axios.post<string>(
      `tenantlogos/${this.model.id}/image`,
      formData
    )
    this.imageUpload.reload()
  }
}

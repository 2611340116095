









































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import projectsService from '@/projects/services/projects-service'
import axios from '@/app/axios'
import Project from '@/projects/entities/project'
import ProjectItemGroup from '../entities/project-item-group'
import moment from 'moment'

@Component
export default class Print extends Vue {
  @Prop()
  private projectId!: string

  @Prop()
  private itemIds!: string

  private project: Project = new Project()
  private groups: ProjectItemGroup[] = []

  private getDateTime() {
    return moment().format('DD.MM.YYYY HH:mm')
  }

  private async mounted() {
    this.project =
      (await projectsService.findOneAsync(this.projectId)) || new Project()

    const res = await axios.post<ProjectItemGroup[]>(`print`, {
      itemIds: JSON.parse(this.itemIds),
    })
    this.groups = res.data

    this.$nextTick(() => window.print())
  }
}

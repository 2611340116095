var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1500px",
        fullscreen: _vm.$vuetify.breakpoint.mdAndDown
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "min-height": "100%" } },
        [
          _c("v-card-title", { staticStyle: { height: "64px" } }, [
            _vm._v(" " + _vm._s(_vm.$t("_orders.details")) + " ")
          ]),
          _c(
            "v-card-text",
            { staticClass: "flex-grow-1" },
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "3" } },
                            [
                              _c("business-division-select", {
                                staticClass: "mb-2",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  hint: _vm.$t("required_field"),
                                  "persistent-hint": "",
                                  "hide-details": "auto",
                                  disabled:
                                    _vm.disabledForCurrentUser || _vm.readonly
                                },
                                model: {
                                  value: _vm.order.businessUnit,
                                  callback: function($$v) {
                                    _vm.$set(_vm.order, "businessUnit", $$v)
                                  },
                                  expression: "order.businessUnit"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("group_s"),
                                  disabled:
                                    _vm.disabledForCurrentUser || _vm.readonly,
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.order.groupName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.order, "groupName", $$v)
                                  },
                                  expression: "order.groupName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: _vm.$t("status"),
                                  items: _vm.statusList,
                                  "item-text": "text",
                                  "item-value": "value",
                                  disabled: _vm.readonly,
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.order.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.order, "status", $$v)
                                  },
                                  expression: "order.status"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("company-select", {
                                attrs: {
                                  label: _vm.$t("supplier"),
                                  type: "supplier",
                                  outlined: "",
                                  dense: "",
                                  hint: _vm.$t("required_field"),
                                  "persistent-hint": "",
                                  "hide-details": "auto",
                                  disabled:
                                    _vm.disabledForCurrentUser || _vm.readonly,
                                  ellipsis: 50
                                },
                                on: { input: _vm.supplierChangedAsync },
                                model: {
                                  value: _vm.order.supplierId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.order, "supplierId", $$v)
                                  },
                                  expression: "order.supplierId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: _vm.$t("contact_at_supplier"),
                                  items: _vm.supplierContactPersons,
                                  "item-text": "lastName",
                                  "item-value": "id",
                                  disabled:
                                    _vm.disabledForCurrentUser || _vm.readonly,
                                  outlined: "",
                                  dense: "",
                                  hint: _vm.$t("required_field"),
                                  "persistent-hint": "",
                                  "hide-details": "auto"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          _vm._s(item.firstName) +
                                            " " +
                                            _vm._s(item.lastName)
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          _vm._s(item.firstName) +
                                            " " +
                                            _vm._s(item.lastName)
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.order.supplierContactPersonId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.order,
                                      "supplierContactPersonId",
                                      $$v
                                    )
                                  },
                                  expression: "order.supplierContactPersonId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("date-input", {
                                attrs: {
                                  label: _vm.$t("expected_delivery_date"),
                                  disabled:
                                    _vm.disabledForCurrentUser || _vm.readonly,
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.order.expectedDeliveryDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.order,
                                      "expectedDeliveryDate",
                                      $$v
                                    )
                                  },
                                  expression: "order.expectedDeliveryDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v(_vm._s(_vm.$t("position")))]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("number")))]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("name")))]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("quantity")))]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("quantity_ordered")))
                            ]),
                            _vm.enabledForCurrentUser
                              ? _c("th", [_vm._v(_vm._s(_vm.$t("price")))])
                              : _vm._e(),
                            _c("th", [_vm._v(_vm._s(_vm.$t("comments")))])
                          ])
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.order.items, function(item) {
                            return _c("tr", { key: item.id }, [
                              _c("td", [_vm._v(_vm._s(item.pos))]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        to: {
                                          path:
                                            "/products/" +
                                            encodeURIComponent(
                                              item.productNumber
                                            )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item.productNumber))]
                                  )
                                ],
                                1
                              ),
                              _c("td", [_vm._v(_vm._s(item.productName))]),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("num")(item.quantity, 2)) +
                                    " " +
                                    _vm._s(_vm.unitName(item.unit)) +
                                    " "
                                )
                              ]),
                              _c(
                                "td",
                                { staticStyle: { width: "150px" } },
                                [
                                  _c("x-number-input", {
                                    attrs: {
                                      "fractional-digits": _vm.allowedFractionalDigits(
                                        item.purchaseUnit
                                      ),
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      suffix: _vm.unitName(item.purchaseUnit),
                                      disabled:
                                        _vm.disabledForCurrentUser ||
                                        _vm.readonly
                                    },
                                    model: {
                                      value: item.purchaseQuantity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "purchaseQuantity",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "item.purchaseQuantity"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.enabledForCurrentUser
                                ? _c(
                                    "td",
                                    { staticStyle: { width: "150px" } },
                                    [
                                      _c("x-money-input", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                          disabled:
                                            _vm.disabledForCurrentUser ||
                                            _vm.readonly
                                        },
                                        model: {
                                          value: item.price,
                                          callback: function($$v) {
                                            _vm.$set(item, "price", $$v)
                                          },
                                          expression: "item.price"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "td",
                                { staticStyle: { width: "40%" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      disabled: _vm.readonly
                                    },
                                    model: {
                                      value: item.notes,
                                      callback: function($$v) {
                                        _vm.$set(item, "notes", $$v)
                                      },
                                      expression: "item.notes"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              !_vm.readonly
                ? _c("files-list", {
                    attrs: {
                      files: _vm.attachments,
                      title: _vm.$t("attachments")
                    },
                    on: {
                      uploadFiles: _vm.filesSelectedAsync,
                      downloadFile: _vm.download,
                      deleteFile: _vm.deleteAttachmentAsync
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "align-end px-7 pb-7" },
            [
              _c(
                "div",
                [
                  !_vm.readonly
                    ? _c("v-checkbox", {
                        staticClass: "mt-0 pt-0",
                        attrs: {
                          label: _vm.$t("send_order_directly"),
                          "hide-details": "",
                          dense: ""
                        },
                        on: { change: _vm.updateUserPreferences },
                        model: {
                          value: _vm.currentUser.sendOrdersDirectly,
                          callback: function($$v) {
                            _vm.$set(_vm.currentUser, "sendOrdersDirectly", $$v)
                          },
                          expression: "currentUser.sendOrdersDirectly"
                        }
                      })
                    : _vm._e(),
                  !_vm.readonly
                    ? _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("notify_if_orderes_delivered"),
                          "hide-details": "",
                          dense: ""
                        },
                        on: { change: _vm.updateUserPreferences },
                        model: {
                          value: _vm.currentUser.notifyIfOrdersDelivered,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.currentUser,
                              "notifyIfOrdersDelivered",
                              $$v
                            )
                          },
                          expression: "currentUser.notifyIfOrdersDelivered"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { text: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              !_vm.readonly
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        disabled: !_vm.isValid,
                        title: _vm.$t("save"),
                        depressed: "",
                        color: "primary"
                      },
                      on: { click: _vm.saveAsync }
                    },
                    [_c("v-icon", [_vm._v("save")])],
                    1
                  )
                : _vm._e(),
              !_vm.readonly
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        disabled: !_vm.isValid || _vm.disabledForCurrentUser,
                        title: _vm.$t("send"),
                        depressed: "",
                        color: "primary"
                      },
                      on: { click: _vm.sendAsync }
                    },
                    [_c("v-icon", [_vm._v("send")])],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
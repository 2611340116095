import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import ProjectItemGroup from '@/projects/entities/project-item-group'

class ProjectGroupsService {

  public async loadAsync(projectId: string) {
    const res = await axios.get<ProjectItemGroup[]>(
      `projectgroups/${projectId}/load`
    )
    return Responses.list(res)
  }

  public async saveAsync(projectId: string, item: ProjectItemGroup) {
    await axios.put(`projectgroups/${projectId}/save`, item)
  }

  public async swapAsync(id1: string, id2: string) {
    const res = await axios.put<any>(`projectgroups/swap`, { id1, id2 })
    return res.status === 200
  }

  public async duplicateAsync(item: ProjectItemGroup) {
    await axios.post(`projectgroups/${item.id}/duplicate`)
  }

  public async removeAsync(item: ProjectItemGroup) {
    await axios.delete(`projectgroups/${item.id}`)
  }
}

export default new ProjectGroupsService()

import { render, staticRenderFns } from "./parts-list-group-items.vue?vue&type=template&id=29c244ed&"
import script from "./parts-list-group-items.vue?vue&type=script&lang=ts&"
export * from "./parts-list-group-items.vue?vue&type=script&lang=ts&"
import style0 from "./parts-list-group-items.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('29c244ed')) {
      api.createRecord('29c244ed', component.options)
    } else {
      api.reload('29c244ed', component.options)
    }
    module.hot.accept("./parts-list-group-items.vue?vue&type=template&id=29c244ed&", function () {
      api.rerender('29c244ed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/projects/components/parts-list-group-items.vue"
export default component.exports
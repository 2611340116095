






































































































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import EditDialogMixin from '@/common/dialogs/mixins/edit-dialog-mixin'
import Contact from '../entities/contact'

@Component
export default class ContactDialog extends Mixins<EditDialogMixin<Contact>>(EditDialogMixin) {

}

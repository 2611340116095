var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stores fill-width" },
    [
      _c("store-dialog", {
        ref: "dialog",
        on: { create: _vm.create, update: _vm.update }
      }),
      _c("stores-table", {
        attrs: {
          loading: _vm.loading,
          stores: _vm.stores,
          pagination: _vm.filter.paging
        },
        on: {
          "update:pagination": function($event) {
            return _vm.$set(_vm.filter, "paging", $event)
          },
          edit: _vm.openEdit,
          remove: _vm.remove
        }
      }),
      _c(
        "v-btn",
        {
          staticClass: "mb-8",
          attrs: {
            fixed: "",
            fab: "",
            bottom: "",
            right: "",
            color: "primary"
          },
          on: { click: _vm.openNew }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import axios from '@/app/axios'
import CrudService from '@/common/services/crud-service'
import Tenant from '@/tenants/entities/tenant'
import Responses from '@/app/utils/responses'
import store from '@/app/store'
import TenantModule from '@/tenants/store/tenant-module'
import { getModule } from 'vuex-module-decorators'

class TenantsService extends CrudService<Tenant> {
  public async loadAllAsync() {
    const res = await axios.get<Tenant[]>(`${this.resource}/all`)
    return Responses.list(res)
  }

  public async getCurrent() {
    const module = getModule(TenantModule, store)
    const res = await axios.get<Tenant>(`tenants/current`)
    const data = Responses.obj(res)
    module.setTenantData(data)
    return data
  }
}

export default new TenantsService('tenants')

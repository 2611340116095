var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "50vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "min-height": "100%" } },
        [
          _c("v-card-title", { staticStyle: { height: "64px" } }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("price_history")))])
          ]),
          _c("v-card-text", { staticClass: "flex-grow-1" }, [
            _c(
              "div",
              { staticClass: "w-chart px-4 pt-2 pb-4" },
              [
                _c(
                  "v-toolbar",
                  {
                    staticClass: "container-toolbar",
                    attrs: { flat: "", color: "transparent" }
                  },
                  [
                    _c("period-input", {
                      attrs: {
                        outlined: "",
                        dense: "",
                        "hide-details": "auto",
                        "no-time": ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.fetchAllAsync()
                        }
                      },
                      model: {
                        value: _vm.filter.period,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "period", $$v)
                        },
                        expression: "filter.period"
                      }
                    }),
                    _c("v-spacer"),
                    _c(
                      "v-btn-toggle",
                      { attrs: { mandatory: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                _vm.tab = 0
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("show_chart")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                _vm.tab = 1
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("reorder")])],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-window",
                  {
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c(
                      "v-window-item",
                      [
                        _c("x-chart", {
                          staticClass: "mt-2",
                          attrs: {
                            config: _vm.chartConfig,
                            labels: _vm.chartLabels,
                            data: _vm.chartData
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-window-item",
                      [
                        _c("v-data-table", {
                          staticClass: "transparent",
                          attrs: {
                            loading: _vm.loading,
                            headers: _vm.headers,
                            items: _vm.history,
                            "no-data-text": _vm.$t(
                              "_products._price-history-dialog.empty"
                            ),
                            "hide-default-footer": "",
                            "item-key": "start"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item.priceDate",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dateTime")(item.priceDate)
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.shift",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("logwood-history-date", {
                                      attrs: {
                                        type: _vm.filter.periodType,
                                        shift: item
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.netPurchasePrice",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("num")(
                                            item.netPurchasePrice,
                                            4
                                          )
                                        ) +
                                          " " +
                                          _vm._s(_vm.currency)
                                      )
                                    ])
                                  ]
                                }
                              },
                              {
                                key: "item.change",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("change-display", {
                                      attrs: {
                                        "current-value": item.netPurchasePrice,
                                        "previous-value": item.previousPrice,
                                        unit: _vm.currency,
                                        "number-of-fraction-digits": 4,
                                        "hide-flat-change": ""
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "px-6 pb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { text: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
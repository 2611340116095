import Guid from '@/app/utils/guid'

export default class AuthResponse {
  public loaded: boolean = false
  public authenticated: boolean = false
  public isNotConfirmed: boolean = false
  public isDisabled: boolean = false
  public isLockedOut: boolean = false
  public userId: string = Guid.empty()
  public tenantId: string | null = null
  public email: string = ''
  public roles: string[] = []
  public token: string | null = null
  public refreshToken: string | null = null
}




















































































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import axios from '@/app/axios'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'

@Component
export default class ProductsImportDialog extends Vue {
  @Ref()
  private validator!: ValidationObserver

  private dialog = false
  private loading = false

  private directory: DirectoryTreeItem = new DirectoryTreeItem()
  private file = new File([''], '')
  private warnings: string[] = []

  public open(directory: DirectoryTreeItem) {
    this.directory = directory
    this.dialog = true
  }

  private async confirm() {
    if (await this.validator.validate()) {
      this.loading = true
      await this.importCsv()
      this.$emit('upload:complete')
      this.loading = false
      if (this.warnings.length === 0) {
        this.reset()
        this.cancel()
      }
    }
  }

  private async importCsv() {
    const formData = new FormData()
    formData.append('file', this.file, this.file.name)
    const res = await axios.post<string[]>(
      `productsimport/${this.directory.id}`,
      formData
    )
    this.warnings = res.data
  }

  private reset() {
    if (this.validator) {
      this.validator.reset()
    }
  }

  private cancel() {
    this.dialog = false
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("profiles-picker", {
        ref: "picker",
        attrs: { value: _vm.value },
        on: { confirm: _vm.confirm }
      }),
      _c(
        "div",
        { staticClass: "profiles-input d-flex" },
        [
          _c("profile-graph", { attrs: { value: _vm.item } }),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-space-between align-center flex-grow-1"
            },
            [
              _c(
                "div",
                { staticClass: "font-size-75 text-no-wrap" },
                [
                  _vm.item.frontEdgingProduct
                    ? _c(
                        "product-order-indicator",
                        {
                          attrs: {
                            "order-states": _vm.orderStates,
                            product: _vm.item.frontEdgingProduct,
                            "project-item-id": _vm.item.id
                          }
                        },
                        [
                          _c("span", { staticClass: "profile-name" }, [
                            _c("span", { staticClass: "profile-heading" }, [
                              _vm._v("V")
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.item.frontEdgingProduct.name))
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.item.backEdgingProduct
                    ? _c(
                        "product-order-indicator",
                        {
                          attrs: {
                            "order-states": _vm.orderStates,
                            product: _vm.item.backEdgingProduct,
                            "project-item-id": _vm.item.id
                          }
                        },
                        [
                          _c("span", { staticClass: "profile-name" }, [
                            _c("span", { staticClass: "profile-heading" }, [
                              _vm._v("H")
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.item.backEdgingProduct.name))
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.item.leftEdgingProduct
                    ? _c(
                        "product-order-indicator",
                        {
                          attrs: {
                            "order-states": _vm.orderStates,
                            product: _vm.item.leftEdgingProduct,
                            "project-item-id": _vm.item.id
                          }
                        },
                        [
                          _c("span", { staticClass: "profile-name" }, [
                            _c("span", { staticClass: "profile-heading" }, [
                              _vm._v("L")
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.item.leftEdgingProduct.name))
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.item.rightEdgingProduct
                    ? _c(
                        "product-order-indicator",
                        {
                          attrs: {
                            "order-states": _vm.orderStates,
                            product: _vm.item.rightEdgingProduct,
                            "project-item-id": _vm.item.id
                          }
                        },
                        [
                          _c("span", { staticClass: "profile-name" }, [
                            _c("span", { staticClass: "profile-heading" }, [
                              _vm._v("R")
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.item.rightEdgingProduct.name))
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.item.customEdgingProduct
                    ? _c(
                        "product-order-indicator",
                        {
                          attrs: {
                            "order-states": _vm.orderStates,
                            product: _vm.item.customEdgingProduct,
                            "project-item-id": _vm.item.id
                          }
                        },
                        [
                          _c("span", { staticClass: "profile-name" }, [
                            _c("span", { staticClass: "profile-heading" }, [
                              _vm._v("S")
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.item.customEdgingProduct.name))
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.readonly === false
                ? _c(
                    "v-btn",
                    {
                      attrs: { title: "Artikel auswählen", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.picker.open()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("search")])],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
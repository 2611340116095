var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var valid = ref.valid
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    attrs: { "min-height": "100%" }
                  },
                  [
                    _c("v-card-title", { staticStyle: { height: "64px" } }, [
                      _vm.editMode
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("edit_contact")))])
                        : _c("span", [_vm._v(_vm._s(_vm.$t("add_contact")))])
                    ]),
                    _c(
                      "v-card-text",
                      { staticClass: "flex-grow-1" },
                      [
                        _c(
                          "v-form",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required|email",
                                            name: "email"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: _vm.$t("email"),
                                                        outlined: "",
                                                        dense: "",
                                                        "error-messages": errors,
                                                        "hide-details":
                                                          errors.length === 0
                                                      },
                                                      model: {
                                                        value: _vm.model.email,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "email",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.email"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required",
                                            name: "salutation"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "salutation"
                                                        ),
                                                        outlined: "",
                                                        dense: "",
                                                        "error-messages": errors,
                                                        "hide-details":
                                                          errors.length === 0
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model.salutation,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "salutation",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.salutation"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required",
                                            name: "firstName"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "firstName"
                                                        ),
                                                        outlined: "",
                                                        dense: "",
                                                        "error-messages": errors,
                                                        "hide-details":
                                                          errors.length === 0
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model.firstName,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "firstName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.firstName"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required",
                                            name: "lastName"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "lastName"
                                                        ),
                                                        outlined: "",
                                                        dense: "",
                                                        "error-messages": errors,
                                                        "hide-details":
                                                          errors.length === 0
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model.lastName,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "lastName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.lastName"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.$t("search_terms"),
                                            outlined: "",
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.model.searchTerms,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "searchTerms",
                                                $$v
                                              )
                                            },
                                            expression: "model.searchTerms"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-7 pb-5" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text",
                            attrs: { text: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(_vm._s(_vm.$t("cancel")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: !valid,
                              loading: _vm.loading,
                              depressed: "",
                              color: "primary"
                            },
                            on: { click: _vm.confirm }
                          },
                          [
                            _vm.editMode
                              ? _c("span", [_vm._v(_vm._s(_vm.$t("save")))])
                              : _c("span", [_vm._v(_vm._s(_vm.$t("add")))])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
            ? _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "v-card",
                    { attrs: { tile: "" } },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("_home.past_due_projects")))
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _vm.lateProjects.length == 0
                            ? _c("em", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("_home.all_is_well")) +
                                    " "
                                )
                              ])
                            : _c(
                                "v-simple-table",
                                {
                                  staticClass: "max-height-60vh",
                                  attrs: { "fixed-header": "" }
                                },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("name")))
                                      ]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("customer")))
                                      ]),
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("production_planning_lead")
                                          )
                                        )
                                      ]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("due_date")))
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.lateProjects, function(
                                      project,
                                      i
                                    ) {
                                      return _c(
                                        "tr",
                                        {
                                          key: i,
                                          staticClass: "cursor-pointer",
                                          on: {
                                            click: function($event) {
                                              return _vm.gotoProject(project.id)
                                            }
                                          }
                                        },
                                        [
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text--secondary font-size-75"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(project.number) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c("div", [
                                              _vm._v(_vm._s(project.name))
                                            ])
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(project.companyName))
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                project.productionPlanningLeadShortCode
                                              )
                                            )
                                          ]),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "td-w-shrink text-right"
                                            },
                                            [
                                              project.dueDate
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        color: "red",
                                                        small: "",
                                                        outlined: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("date")(
                                                              project.dueDate
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(_vm._s(_vm.$t("_home.past_due_orders")))
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _vm.lateOrders.length == 0
                        ? _c("em", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("_home.all_is_well")) + " "
                            )
                          ])
                        : _c(
                            "v-simple-table",
                            {
                              staticClass: "max-height-60vh",
                              attrs: { "fixed-header": "" }
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { staticClass: "w-120px" }, [
                                    _vm._v(_vm._s(_vm.$t("name")))
                                  ]),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("supplier")))
                                  ]),
                                  _c("th", [_vm._v(_vm._s(_vm.$t("buyer")))]),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("delivery_date")))
                                  ])
                                ])
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.lateOrders, function(order, i) {
                                  return _c(
                                    "tr",
                                    {
                                      key: i,
                                      staticClass: "cursor-pointer",
                                      on: {
                                        click: function($event) {
                                          return _vm.gotoOrder(order.id)
                                        }
                                      }
                                    },
                                    [
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text--secondary font-size-75"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(order.projectNumber) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(_vm._s(order.number))
                                        ])
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(order.supplierName))
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(order.buyerShortCode))
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "td-w-shrink text-right"
                                        },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color: "red",
                                                small: "",
                                                outlined: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      order.expectedDeliveryDate
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "v-card",
                    { attrs: { tile: "" } },
                    [
                      _c("v-card-title", [
                        _vm._v(
                          _vm._s(_vm.$t("_home.recently_changed_projects"))
                        )
                      ]),
                      _c(
                        "v-list",
                        _vm._l(_vm.recentlyChangedProjects, function(
                          project,
                          i
                        ) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              on: {
                                click: function($event) {
                                  return _vm.gotoProject(project.id)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("ruler")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(project.number) +
                                        " - " +
                                        _vm._s(project.name) +
                                        " "
                                    )
                                  ]),
                                  _c("v-list-item-subtitle", {
                                    domProps: {
                                      textContent: _vm._s(project.companyName)
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "v-card",
                    { attrs: { tile: "" } },
                    [
                      _c("v-card-title", [
                        _vm._v(
                          _vm._s(_vm.$t("_home.recently_changed_companies"))
                        )
                      ]),
                      _c(
                        "v-list",
                        _vm._l(_vm.recentlyChangedCompanies, function(
                          company,
                          i
                        ) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              on: {
                                click: function($event) {
                                  return _vm.gotoCompany(company.id)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("domain")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(" " + _vm._s(company.name) + " ")
                                  ]),
                                  company.address
                                    ? _c(
                                        "v-list-item-subtitle",
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                company.address.addressLine
                                              )
                                            )
                                          ]),
                                          company.address.city
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "mx-1" },
                                                  [_vm._v("·")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      company.address.countryId
                                                    )
                                                  )
                                                ]),
                                                company.address.postalCode
                                                  ? _c("span", [_vm._v("-")])
                                                  : _vm._e(),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      company.address.postalCode
                                                    )
                                                  )
                                                ]),
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        company.address.city
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("order-details-dialog", {
        ref: "orderDetailsDialog",
        on: { confirm: _vm.loadLateOrdersAsync }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }


























































import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import Product from '@/products/entities/product'
import productsService from '@/products/services/products-service'
import ProductPicker from '@/products/components/product-picker.vue'
import { ProductCategory } from '@/products/entities/product-category'
import ProductFilter from '@/products/entities/product-filter'

@Component
export default class ProductSelect extends Vue {
  @Ref()
  private picker!: ProductPicker

  @Prop({ required: true })
  private value!: Product | null

  @Prop({ default: () => [] })
  private categories!: ProductCategory[]

  @Prop({ type: Boolean, default: false })
  private pruned!: boolean

  @Prop({ default: '' })
  private group!: string

  private filter = new ProductFilter()
  private products: Product[] = []
  private items: any[] = []
  private product: Product | null = this.value
  private isProductOrdered = false

  @Watch('value')
  private valueChanged(val: Product | null, old: Product | null) {
    if (val == null || old == null || val.id !== old.id) {
      this.assignProduct(val)
      this.addToProducts(this.product)
    }
  }

  @Watch('categories', { deep: true })
  private async categoriesChangedAsync() {
    this.filter.categories = this.categories
  }

  @Watch('filter.text')
  private async searchUpdated(val: string, old: string) {
    if (val !== old && val && val.length >= 3) {
      this.products = await productsService.findAsync(this.filter)
      this.updateItems()
    }
  }

  private assignProduct(val: Product | null) {
    if (val) {
      this.product = this.products.find((p) => p.id === val.id) || val
    }
  }

  private addToProducts(val: Product | null) {
    if (val && this.products.every((p) => p.id !== val.id)) {
      this.products.push(val)
      this.updateItems()
    }
  }

  private pickProduct(product: Product) {
    this.addToProducts(product)
    this.product = product
    this.emitChange(product)
  }

  private emitChange(product: Product) {
    this.rememberPick(product)
    this.$emit('input', product)
  }

  private rememberPick(product: Product | undefined) {
    if (product && this.group.length > 0) {
      this.addItemToRemember(this.group, product)
    }
  }

  private addItemToRemember(subId: string, product: Product) {
    const persisted = localStorage.getItem(`${process.env.VUE_APP_ID}:ph`)
    const lists = JSON.parse(persisted || '{}')

    if (lists[subId] === undefined) {
      lists[subId] = []
    }

    const list: Product[] = lists[subId]
    const idx = list.findIndex((i) => i.id === product.id)

    // Remove the element from the list.
    if (idx > -1) {
      list.splice(idx, 1)
    }

    // Add item at the top.
    list.unshift(product)
    // Remove item at the bottom, if too long.
    if (list.length > 5) {
      list.pop()
    }

    localStorage.setItem(`${process.env.VUE_APP_ID}:ph`, JSON.stringify(lists))
  }

  private getRememberedItems(subId: string) {
    const persisted = localStorage.getItem(`${process.env.VUE_APP_ID}:ph`)
    if (persisted) {
      const lists = JSON.parse(persisted)
      const list: Product[] = lists[subId]
      return list || []
    }
    return []
  }

  private updateItems() {
    const list = []
    if (this.products.length > 0) {
      list.push({ header: 'Treffer' })
      list.push(...this.products)
    }
    if (this.group.length > 0) {
      const remembered = this.getRememberedItems(this.group)
      if (remembered.length > 0) {
        list.push({ header: 'Zuletzt verwendet' })
        list.push(...remembered)
      }
    }
    this.items = list
  }

  private mounted() {
    this.filter.categories = this.categories
    this.addToProducts(this.product)
  }
}
























































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import Company from '@/companies/entities/company'
import PaginationRequest from '@/common/pagination/entities/pagination-request'
import companiesService from '@/companies/services/companies-service'
import CompanyDialog from '@/companies/components/company-dialog.vue'
import ContactsDialog from '@/companies/components/contacts-dialog.vue'
import CorrectionListImportDialog from '@/companies/components/correction-list-import-dialog.vue'
import PriceListImportDialog from '@/companies/components/price-list-import-dialog copy.vue'
import PriceSyncDialog from '@/companies/components/price-sync-dialog copy.vue'

@Component
export default class Companies extends Vue {
  @Prop({ default: null })
  private companyId!: string | null

  @Ref()
  private companyDialog!: CompanyDialog

  @Ref()
  private contactsDialog!: ContactsDialog

  @Ref()
  private correctionListImportDialog!: CorrectionListImportDialog

  @Ref()
  private priceListImportDialog!: PriceListImportDialog

  @Ref()
  private priceSyncDialog!: PriceSyncDialog

  private showManufacturers: boolean = true
  private showSuppliers: boolean = true
  private showWarehouses: boolean = true
  private showCustomers: boolean = true
  private search: string = ''
  private pagination = new PaginationRequest()

  private loading: boolean = false
  private companies: Company[] = []
  private selectedCompanyId = ''

  public async resetPageAndFetchAll() {
    this.pagination.page = 1
    await this.fetchAll()
  }

  public async fetchAll() {
    this.loading = true
    const response = await companiesService.findAllFilteredAsync({
      search: this.search,
      paging: this.pagination,
      isCustomer: this.showCustomers,
      isManufacturer: this.showManufacturers,
      isSupplier: this.showSuppliers,
      isWarehouse: this.showWarehouses,
      isEnabled: null,
    })
    this.companies = response.items
    this.pagination.totalItems = response.totalItems
    this.loading = false
  }

  public openNew() {
    this.companyDialog.openNew(new Company())
  }

  public openEdit(company: Company) {
    this.companyDialog.openEdit(company)
  }

  public async create(company: Company) {
    await companiesService.createAsync(company)
    await this.fetchAll()
  }

  public async update(company: Company) {
    await companiesService.updateAsync(company)
    await this.fetchAll()
  }

  private async remove(company: Company) {
    await companiesService.removeAsync(company.id)
    await this.fetchAll()
  }

  private editContacts(company: Company) {
    this.selectedCompanyId = company.id
    this.contactsDialog.open()
  }

  @Watch('pagination', { deep: true })
  private async paginationChanged(
    newVal: PaginationRequest,
    oldVal: PaginationRequest
  ) {
    if (newVal !== oldVal) {
      await this.fetchAll()
    }
  }

  async mounted() {
    if (this.companyId) {
      let company = await companiesService.findOneAsync(this.companyId)
      if (company) {
        this.openEdit(company)
      }
    }
  }
}

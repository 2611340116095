var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-text-field",
        _vm._b(
          {
            staticStyle: { width: "180px" },
            attrs: {
              "append-icon": "edit",
              label: _vm.$t("current_stock"),
              outlined: "",
              dense: "",
              readonly: ""
            },
            on: {
              "click:append": function($event) {
                return _vm.editAsync()
              }
            },
            model: {
              value: _vm.formattedCurrentUnit,
              callback: function($$v) {
                _vm.formattedCurrentUnit = $$v
              },
              expression: "formattedCurrentUnit"
            }
          },
          "v-text-field",
          _vm.$attrs,
          false
        )
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "900" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(" " + _vm._s(_vm.$t("current_stock")) + " ")
              ]),
              _c("v-card-text", { staticClass: "flex-grow-1" }, [
                _c("div", { staticClass: "d-flex mt-3" }, [
                  _c(
                    "div",
                    { staticClass: "flex-grow-1 mr-3" },
                    [
                      _c("x-number-input", {
                        attrs: {
                          "fractional-digits": 2,
                          suffix: _vm.unit,
                          label: _vm.$t("_products._stock_input.delta"),
                          outlined: "",
                          dense: "",
                          hint: _vm.$t("_products._stock_input.hint"),
                          "persistent-hint": ""
                        },
                        model: {
                          value: _vm.change,
                          callback: function($$v) {
                            _vm.change = $$v
                          },
                          expression: "change"
                        }
                      }),
                      _c("v-textarea", {
                        attrs: {
                          label: _vm.$t("_products._stock_input.comment"),
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          rows: "1",
                          "auto-grow": ""
                        },
                        model: {
                          value: _vm.comment,
                          callback: function($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "" },
                          on: { click: _vm.addAsync }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "w-chart pa-4 mt-3" },
                  [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "container-toolbar",
                        attrs: { flat: "", dense: "", color: "transparent" }
                      },
                      [
                        _c("period-input", {
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "auto",
                            "no-time": ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.loadAsync()
                            }
                          },
                          model: {
                            value: _vm.filter.period,
                            callback: function($$v) {
                              _vm.$set(_vm.filter, "period", $$v)
                            },
                            expression: "filter.period"
                          }
                        }),
                        _c("v-spacer"),
                        _c(
                          "v-btn-toggle",
                          { attrs: { mandatory: "" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    _vm.tab = 0
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("show_chart")])],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    _vm.tab = 1
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("reorder")])],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-window",
                      {
                        model: {
                          value: _vm.tab,
                          callback: function($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab"
                        }
                      },
                      [
                        _c(
                          "v-window-item",
                          [
                            _c("x-chart", {
                              staticClass: "mt-2",
                              attrs: {
                                config: _vm.chartConfig,
                                labels: _vm.chartLabels,
                                data: _vm.chartData
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-window-item",
                          [
                            _c("v-data-table", {
                              staticClass: "transparent",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.history,
                                "no-data-text": _vm.$t("no_data_available"),
                                "disable-pagination": "",
                                "hide-default-footer": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.current",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("num")(item.current, 2)
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.change",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "span",
                                          {
                                            class: {
                                              "pos-num": item.change > 0,
                                              "neg-num": item.change < 0
                                            }
                                          },
                                          [
                                            item.change > 0
                                              ? _c("span", [_vm._v("+")])
                                              : _vm._e(),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("num")(item.change, 2)
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.createdOn",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateTime")(item.createdOn)
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.createdBy",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " + _vm._s(item.createdBy) + " "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.orderNumber",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        item.orderNumber
                                          ? _c(
                                              "v-chip",
                                              {
                                                attrs: { small: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.ordersDialog.openAsync(
                                                      item.orderNumber
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.orderNumber) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-card-actions",
                { staticClass: "px-6 pb-5 pt-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("order-details-dialog", {
        ref: "ordersDialog",
        attrs: { readonly: true }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
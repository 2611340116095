var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-header" }, [
      _c("img", {
        staticStyle: { float: "right", "max-width": "250px" },
        attrs: { src: "/img/logo.png" }
      }),
      _c("h1", [
        _vm._v(
          " " + _vm._s(_vm.project.number) + " | " + _vm._s(_vm.project.name)
        ),
        _c("br"),
        _vm._v(_vm._s(_vm.project.companyName) + " ")
      ])
    ]),
    _c("div", { staticClass: "page-footer" }, [
      _c("span", [
        _vm._v(
          " " +
            _vm._s(_vm.project.number) +
            " | " +
            _vm._s(_vm.project.name) +
            " "
        )
      ]),
      _c("span", [
        _vm._v(" " + _vm._s(_vm.project.productionPlanningLeadShortCode) + " ")
      ]),
      _c("span", [_vm._v(" " + _vm._s(_vm.getDateTime()) + " ")])
    ]),
    _c("table", [
      _vm._m(0),
      _c(
        "tbody",
        [
          _c("print-view", {
            attrs: { project: _vm.project, groups: _vm.groups }
          })
        ],
        1
      ),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("td", [_c("div", { staticClass: "page-header-space" })])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tfoot", [
      _c("tr", [_c("td", [_c("div", { staticClass: "page-footer-space" })])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
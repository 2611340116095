













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LogwoodService from '@/logwood/services/logwood-service'
import LogwoodPackage from '@/logwood/models/logwood-package'
import LogwoodShiftPackageFilter from '@/logwood/models/logwood-shift-package-filter'
import isEqual from 'lodash/isEqual'

@Component
export default class LogwoodShiftPackagesTable extends Vue {
  @Prop({ default: '' })
  private title!: string

  private packs: LogwoodPackage[] = []
  private filter = new LogwoodShiftPackageFilter()
  private loading = false

  get headers() {
    return [
      {
        text: this.$tc('_logwood._logwood-packages-table.outerBearingOn'),
        value: 'outerBearingOn',
        sortable: false,
      },
      {
        text: this.$tc('number_short'),
        value: 'type',
        sortable: false,
      },
      {
        text: this.$tc('volume'),
        value: 'volume',
        sortable: false,
      },
      {
        text: '',
        value: 'action',
        sortable: false,
        class: 'td-w-shrink',
        align: 'end',
      },
    ]
  }

  @Watch('filter', { deep: true })
  private async filterChanged() {
    await this.loadPackagesAsync()
  }

  public async refreshAsync() {
    await this.loadPackagesAsync()
  }

  private async loadPackagesAsync() {
    this.loading = true
    const res = await LogwoodService.loadShiftPackagesAsync(this.filter)
    this.packs = res.items
    this.filter.paging.totalItems = res.totalItems
    this.loading = false
  }

  private async mounted() {
    this.filter.paging.itemsPerPage = 5

    await this.loadPackagesAsync()
  }
}

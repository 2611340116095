var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "8" } },
            [
              _c("logwood-packages-table", {
                staticClass: "mb-3",
                attrs: {
                  title: _vm.$t(
                    "_logwood._logwood-packages-table.dryBearing_title"
                  ),
                  state: _vm.dryBearingState,
                  "show-inventory-button": ""
                },
                on: {
                  print: _vm.printAsync,
                  "print:inventory": _vm.printPackageInventoryAsync
                }
              }),
              _c("logwood-packages-table", {
                staticClass: "mb-3",
                attrs: {
                  title: _vm.$t(
                    "_logwood._logwood-packages-table.dryingChamber_title"
                  ),
                  "sub-title": _vm.$t(
                    "_logwood._logwood-packages-table.dryingChamber_subtitle"
                  ),
                  state: _vm.dryingChamberState,
                  "num-of-locations": _vm.$tenant.data.numberOfDryingChambers,
                  "show-inventory-button": ""
                },
                on: {
                  print: _vm.printAsync,
                  "print:inventory": _vm.printPackageInventoryAsync
                }
              }),
              _c("logwood-packages-table", {
                staticClass: "mb-3",
                attrs: {
                  title: _vm.$t(
                    "_logwood._logwood-packages-table.outerBearing_title"
                  ),
                  state: _vm.outerBearingState,
                  "show-inventory-button": ""
                },
                on: {
                  print: _vm.printAsync,
                  "print:inventory": _vm.printPackageInventoryAsync
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c("logwood-table", { staticClass: "mb-3" }),
              _c("logwood-history", {
                staticClass: "mb-3",
                attrs: {
                  title: _vm.$t("logwood_sawmill"),
                  state: _vm.outerBearingState
                },
                on: { "print:inventory": _vm.printShiftInventoryAsync }
              }),
              _c("logwood-history", {
                attrs: {
                  title: _vm.$t("logwood_cut"),
                  state: _vm.consumedState
                },
                on: { "print:inventory": _vm.printShiftInventoryAsync }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
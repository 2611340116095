














































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import productDirectoriesService from '@/products/services/product-directories-service'
import ProductDirectoryFilter from '@/products/entities/product-directory-filter'

@Component
export default class DirectoryPicker extends Vue {
  @Prop({ default: 'Element wählen' })
  private title!: string

  @Prop({ default: () => [] })
  private excluded!: string[]

  @Prop({ type: Boolean, default: false })
  private onlyFolders!: boolean

  @Prop({ type: Boolean, default: false })
  private multiselect!: boolean

  private dialog = false
  private loading = false

  private items: DirectoryTreeItem[] = []
  private open: DirectoryTreeItem[] = []
  private active: DirectoryTreeItem[] = []
  private selection: DirectoryTreeItem[] = []

  private filter = new ProductDirectoryFilter()

  @Watch('filter', { deep: true })
  private filterChanged() {
    this.loadAll()
  }

  public async openDialog() {
    await this.loadAll()
    this.dialog = true
  }

  private selectFolder(item: DirectoryTreeItem | null) {
    if (item) {
      if (this.multiselect && !this.selection.includes(item)) {
        this.selection.push(item)
      } else {
        this.selection = [item]
      }
    }
  }

  private confirm() {
    this.dialog = false
    this.$emit('confirm', this.multiselect ? this.selection : this.selection[0])
  }

  private selectFolderAndConfirm(item: DirectoryTreeItem | null) {
    this.selectFolder(item)
    this.confirm()
  }

  private cancel() {
    this.dialog = false
    this.$emit('cancel')
  }

  private async loadAll() {
    this.items = await productDirectoriesService.findAllAsync(this.filter)
  }

  private filterExcluded(folders: DirectoryTreeItem[]) {
    return folders.filter((f) => this.excluded.includes(f.id) === false)
  }

  private mounted() {
    this.filter.onlyFolders = this.onlyFolders
  }
}






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class XIcon extends Vue {

  @Prop({ default: '' })
  public name!: string
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-autocomplete",
        _vm._b(
          {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce:update:search-input",
                value: 500,
                expression: "500",
                arg: "update:search-input"
              }
            ],
            attrs: {
              items: _vm.items,
              loading: _vm.loading,
              "search-input": _vm.filter.text,
              "return-object": "",
              "hide-no-data": "",
              "hide-selected": "",
              "no-filter": "",
              "menu-props": { "min-width": 450 },
              "append-outer-icon": "search"
            },
            on: {
              change: _vm.emitChange,
              "update:searchInput": function($event) {
                return _vm.$set(_vm.filter, "text", $event)
              },
              "update:search-input": function($event) {
                return _vm.$set(_vm.filter, "text", $event)
              },
              "click:append-outer": function($event) {
                return _vm.picker.open(_vm.item ? _vm.item.number : null)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", { staticClass: "text-no-wrap" }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [_vm._v(_vm._s(item.name))]),
                        _c("v-list-item-subtitle", [
                          _vm._v(_vm._s(item.number))
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.item,
              callback: function($$v) {
                _vm.item = $$v
              },
              expression: "item"
            }
          },
          "v-autocomplete",
          _vm.$attrs,
          false
        )
      ),
      _c("product-picker", {
        ref: "picker",
        attrs: { categories: _vm.categories, pruned: _vm.pruned },
        on: { confirm: _vm.pick }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
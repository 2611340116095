




import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import { Chart } from 'chart.js'
import isEqual from 'lodash/isEqual'

@Component
export default class XChart extends Vue {
  @Prop()
  private config!: any

  @Prop({ default: () => [] })
  private labels!: string[]

  @Prop({ default: () => [] })
  private data!: any[][]

  @Prop({ default: false })
  private loading!: boolean

  @Ref()
  private canvas!: HTMLCanvasElement

  private chart: Chart | null = null

  @Watch('config', { deep: true })
  private configChanged(val: any, old: any) {
    if (!isEqual(val, old)) {
      this.init()
    }
  }

  @Watch('labels', { deep: true })
  private labelsChanged(val: any, old: any) {
    if (!isEqual(val, old)) {
      this.init()
    }
  }

  @Watch('data', { deep: true })
  private dataChanged(val: any, old: any) {
    if (!isEqual(val, old)) {
      this.init()
    }
  }

  private init() {
    if (this.chart != null) {
      this.chart.destroy()
      this.chart = null
    }

    this.chart = new Chart(this.canvas, this.config)

    this.chart.data.labels = this.labels

    const datasets = this.chart.data.datasets
    const minLength = Math.min(datasets.length, this.data.length)
    for (let i = 0; i < minLength; i++) {
      datasets[i].data = this.data[i]
    }

    this.chart.update()
  }

  private mounted() {
    this.init()
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-layout" },
    [
      _c("side-menu", {
        attrs: { width: 300 },
        model: {
          value: _vm.showSideMenu,
          callback: function($$v) {
            _vm.showSideMenu = $$v
          },
          expression: "showSideMenu"
        }
      }),
      _vm.showHeader
        ? _c(
            "v-app-bar",
            { attrs: { id: "header", app: "", flat: "", color: "white" } },
            [
              !_vm.showSideMenu
                ? _c("v-app-bar-nav-icon", {
                    on: { click: _vm.toggleSideMenu }
                  })
                : _vm._e(),
              _c(
                _vm.header,
                _vm._b({ tag: "component" }, "component", _vm.$props, false)
              ),
              _c("language-selection", {
                staticClass: "mr-3",
                attrs: { dark: "" }
              }),
              _c("user-widget", { staticClass: "mr-2" })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            {
              staticClass: "align-start align-content-start",
              class: {
                "with-header": _vm.showHeader && _vm.$vuetify.breakpoint.mdAndUp
              },
              attrs: { "fill-height": "", fluid: "" }
            },
            [_vm._t("default")],
            2
          ),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c("v-footer", { staticClass: "py-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-space-between align-center fill-width px-3"
                  },
                  [
                    _c("div", { staticClass: "text-caption text--disabled" }, [
                      _c("span", [_vm._v("© " + _vm._s(_vm.coyrightYear))]),
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$env.VUE_APP_COPYRIGHT_HOLDER) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm.$vuetify.breakpoint.mdAndUp
                      ? _c(
                          "span",
                          { staticClass: "text-caption text--disabled" },
                          [
                            _vm._v(
                              " Server: " +
                                _vm._s(_vm.serverVersion) +
                                " | Client: " +
                                _vm._s(_vm.clientVersion) +
                                " | Environment: " +
                                _vm._s(_vm.environment) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }


















































import { Component, Prop, Vue } from 'vue-property-decorator'
import LogwoodService from '@/logwood/services/logwood-service'
import { LogwoodPackageState } from '@/logwood/models/logwood-package-state'
import LogwoodPackageInventory from '@/logwood/models/logwood-package-inventory'
import { sumBy } from 'lodash'

@Component
export default class LogwoodInventory extends Vue {
  @Prop()
  private state!: LogwoodPackageState

  @Prop()
  private title!: string

  private loading = false
  private inventory: LogwoodPackageInventory[] = []

  private get priceSum() {
    return sumBy(this.inventory, (i) => i.price * i.stock)
  }

  private get stockSum() {
    return sumBy(this.inventory, (i) => i.stock)
  }

  private async loadPackAsync() {
    const inventory = await LogwoodService.getPackageInventoryAsync(this.state)
    this.inventory = inventory || []
  }
  private async mounted() {
    await this.loadPackAsync()

    // window.print()
    // window.close()
  }
}

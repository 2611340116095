
























































import store from '@/app/store'
import authService from '@/users/services/auth-service'
import AccountModule from '@/users/store/account-module'
import { getModule } from 'vuex-module-decorators'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class UserWidget extends Vue {
  private menu: boolean = false

  get displayName() {
    const accountModule = getModule(AccountModule, store)
    const data = accountModule.userData
    const firstName = data.firstName || ' '
    const lastName = data.lastName || ' '

    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
  }

  private goToSettings() {
    this.$router.push({
      name: 'settings',
      params: { lang: this.$i18n.locale },
    })
    this.menu = false
  }

  private async logout() {
    this.menu = false
    await authService.logoutAsync()
  }
}

export default class Stock {
  id = 0
  storeId = ''
  productId = ''
  current = 0
  change = 0
  comment = ''
  orderNumber = ''
  createdOn = new Date()
  createdBy = ''
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        attrs: {
          items: _vm.companies,
          loading: _vm.loading,
          "search-input": _vm.search,
          "return-object": "",
          "no-filter": "",
          "hide-no-data": "",
          "hide-selected": "",
          clearable: ""
        },
        on: {
          change: _vm.emitChange,
          "update:searchInput": function($event) {
            _vm.search = $event
          },
          "update:search-input": function($event) {
            _vm.search = $event
          },
          "click:clear": function($event) {
            return _vm.emitClear()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", { attrs: { title: item.name } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("ellipsis")(item.name, _vm.ellipsis)) +
                      " "
                  )
                ])
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(" " + _vm._s(item.name) + " ")
                    ]),
                    _c(
                      "v-list-item-subtitle",
                      [
                        _c("address-line", { attrs: { address: item.address } })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.company,
          callback: function($$v) {
            _vm.company = $$v
          },
          expression: "company"
        }
      },
      "v-autocomplete",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
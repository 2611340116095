












import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ProjectStatus } from '@/projects/entities/project'
import Project from '@/projects/entities/project'

@Component
export default class ProjectStatusSelect extends Vue {
  @Prop({ default: ProjectStatus.Open })
  public value!: ProjectStatus

  @Watch('value')
  private valueChanged(val: moment.MomentInput) {
    this.model = this.value
  }

  private items: SelectListItem[] = []
  private model: ProjectStatus = this.value

  selectionChanged() {
    this.$emit('input', this.model)
  }

  created() {
    for (const s of Project.statusList) {
      const item = new SelectListItem(s.value, this.$tc(s.text))
      this.items.push(item)
    }
  }
}

class SelectListItem {
  public id: ProjectStatus
  public name: String

  constructor(id: ProjectStatus, name: string) {
    this.id = id
    this.name = name
  }
}


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LogwoodShift from '@/logwood/models/logwood-history'
import LogwoodService from '@/logwood/services/logwood-service'

@Component
export default class LogwoodShiftsTable extends Vue {
  @Prop({ default: 1 })
  private numOfShifts!: number

  private shifts: LogwoodShift[] = []

  public async refreshAsync() {
    await this.loadShiftsAsnyc()
  }

  private async loadShiftsAsnyc() {
    this.shifts = await LogwoodService.loadShiftsAsync(this.numOfShifts)
  }

  private async mounted() {
    await this.loadShiftsAsnyc()
  }
}

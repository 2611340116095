









import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  LogwoodPackageKey,
  LogwoodPackageState,
} from '@/logwood/models/logwood-package-state'

@Component
export default class LogwoodPackageStateSelect extends Vue {
  @Prop({ required: true })
  private value!: LogwoodPackageKey

  private item = this.value

  private get items() {
    const list = []

    list.push({
      value: { state: LogwoodPackageState.OuterBearing, location: 1 },
      text: this.$tc('_logwood._logwood-package-state-select.outerBearing'),
    })
    for (
      let location = 1;
      location <= this.$tenant.data.numberOfDryingChambers;
      location++
    ) {
      list.push({
        value: { state: LogwoodPackageState.DryingChamber, location: location },
        text: `${this.$tc(
          '_logwood._logwood-package-state-select.dryingChamber'
        )} ${location}`,
      })
    }

    list.push({
      value: { state: LogwoodPackageState.DryBearing, location: 1 },
      text: this.$tc('_logwood._logwood-package-state-select.dryingBearing'),
    })

    return list
  }

  @Watch('value')
  private valueChanged(val: LogwoodPackageKey, old: LogwoodPackageKey) {
    if (val.state !== old.state || val.location !== old.location) {
      this.item = val
    }
  }

  @Watch('item')
  private itemChanged(key: LogwoodPackageKey) {
    this.$emit('input', key)
  }
}

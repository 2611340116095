var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-avatar",
    { attrs: { size: _vm.size, color: "white" } },
    [
      _vm.imagePath
        ? _c("img", {
            style: "height: " + _vm.zoom + "px; width: " + _vm.zoom + "px",
            attrs: { src: _vm.fullIagePath }
          })
        : _c("v-icon", [_vm._v("text_snippet")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
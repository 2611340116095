import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import ProductDirectoryFilter from '@/products/entities/product-directory-filter'

class ProductDirectoriesService {
  public async createFolderAsync(dir: DirectoryTreeItem) {
    const res = await axios.post<any>(`productdirectories`, dir)
    return Responses.obj(res)
  }

  public async updateFolderAsync(dir: DirectoryTreeItem) {
    const res = await axios.put<string>(`productdirectories`, dir)
    return Responses.obj(res)
  }

  public async moveItemsAsync(srcItemIds: string[], dstFolderId: string | null) {
    await axios.post(`productdirectories/move`, {
      srcItemIds,
      dstFolderId,
    })
  }

  public async hideFolderAsync(folderId: string) {
    await axios.get(`productdirectories/${folderId}/hide`)
  }

  public async unhideFolderAsync(folderId: string) {
    await axios.get(`productdirectories/${folderId}/unhide`)
  }

  public async excludeFolderAsync(folderId: string) {
    await axios.get(`productdirectories/${folderId}/exclude`)
  }

  public async includeFolderAsync(folderId: string) {
    await axios.get(`productdirectories/${folderId}/include`)
  }

  public async applySupplierMultiplierAsync(folderId: string, multiplier: number | null) {
    await axios.post(`productdirectories/${folderId}/multiplier/apply`, {
      folderId,
      multiplier
    })
  }

  public async removeFolderAsync(folderId: string) {
    await axios.delete(`productdirectories/${folderId}`)
  }

  public async findAllAsync(request: ProductDirectoryFilter) {
    const res = await axios.post<DirectoryTreeItem[]>(
      'productdirectories/all',
      request
    )
    const roots = Responses.list(res)
    return roots
  }
}

export default new ProductDirectoriesService()























































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import productDirectoriesService from '@/products/services/product-directories-service'
import DirectoryPicker from '@/products/components/directory-picker.vue'

@Component
export default class MoveDirectoryDialog extends Vue {
  @Ref()
  private srcPicker!: DirectoryPicker
  @Ref()
  private dstPicker!: DirectoryPicker

  private dialog = false
  private loading = false

  private srcItems: DirectoryTreeItem[] = []
  private dstFolder: DirectoryTreeItem | null = null

  get scrItemsName() {
    if (this.srcItems.length === 0) {
      return this.$t('select_elements_to_move') + '...'
    } else if (this.srcItems.length === 1) {
      return this.srcItems[0].name
    }
    return `${this.srcItems.length} ${this.$t('elements_selected')}`
  }

  get dstFolderName() {
    if (this.dstFolder) {
      return this.dstFolder.name
    }
    return this.$t('pick_target_folder') + '...'
  }

  get excludedItems() {
    return [this.srcItems.map((i) => i.id)]
  }

  public open() {
    this.dialog = true
  }

  private selectSrcItems(items: DirectoryTreeItem[]) {
    this.srcItems = items
  }

  private selectDstFolder(folder: DirectoryTreeItem) {
    this.dstFolder = folder
  }

  private async confirm() {
    if (this.srcItems.length > 0) {
      await productDirectoriesService.moveItemsAsync(
        this.srcItems.map((i) => i.id),
        this.dstFolder?.id || null
      )
      this.dialog = false
      this.$emit('confirm')
    }
  }

  private cancel() {
    this.dialog = false
    this.$emit('cancel')
  }
}

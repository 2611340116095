






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class TenancyChip extends Vue {
  @Prop()
  private tenantId!: string | null
}













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { UnitType } from '@/products/entities/unit-type'
import { purchaseUnits } from '@/products/utils/units'

@Component
export default class UnitSelect extends Vue {
  @Prop()
  private value!: UnitType

  @Prop()
  private unit!: UnitType

  private purchaseUnit = this.value
  private items = purchaseUnits[this.unit]

  @Watch('value')
  private valueChanged(val: UnitType) {
    this.purchaseUnit = val
  }

  @Watch('unit')
  private unitChanged(val: UnitType) {
    this.setItems(val)
  }

  @Watch('purchaseUnit')
  private purchaseUnitChanged(val: UnitType) {
    this.$emit('input', val)
  }

  private setItems(val: UnitType) {
    this.items = purchaseUnits[val]
    if (this.items.length === 1) {
      this.purchaseUnit = this.items[0].value
    }
  }
}

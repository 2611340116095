import store from '@/app/store'
import AuthResponse from '@/users/responses/auth-response'
import RefreshResponse from '@/users/responses/refresh-response'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ store, name: 'AuthModule' })
export default class AuthModule extends VuexModule {
  public auth: AuthResponse = new AuthResponse()

  get authenticated(): boolean {
    return this.auth.authenticated
  }

  get token(): string {
    return this.auth.token || ''
  }

  get refreshToken(): string {
    return this.auth.refreshToken || ''
  }

  get user(): string {
    return this.auth.userId || ''
  }

  get tenant(): string {
    return this.auth.tenantId || ''
  }

  get roles(): string[] {
    return this.auth.roles
  }

  get displayName(): string {
    return this.auth.email
  }

  @Mutation
  public login(auth: AuthResponse) {
    this.auth = auth
  }

  @Mutation
  public refresh(response: RefreshResponse) {
    this.auth.token = response.token
    this.auth.refreshToken = response.refreshToken
  }

  @Mutation
  public setTenantId(tenantId: string | null) {
    this.auth.tenantId = tenantId
  }

  @Mutation
  public logout() {
    this.auth = new AuthResponse()
    localStorage.removeItem(process.env.VUE_APP_ID)
  }
}

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { LogwoodPackageState } from '@/logwood/models/logwood-package-state'
import LogwoodPackage from '@/logwood/models/logwood-package'

@Component
export default class LogwoodPackagesTable extends Vue {
  @Prop({ default: '' })
  private title!: string

  @Prop({ default: '' })
  private subTitle!: string

  @Prop({ default: LogwoodPackageState.OuterBearing })
  private state!: LogwoodPackageState

  @Prop({ type: Boolean, default: false })
  private showInventoryButton!: boolean

  @Prop({ type: Number, default: 1 })
  private numOfLocations!: number

  public print(item: LogwoodPackage) {
    this.$emit('print', item)
  }
}

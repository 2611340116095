var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-inventory-list" }, [
    _c("div", { staticClass: "product-inventory-list__header" }, [
      _c("img", {
        staticClass: "product-inventory-list__logo",
        attrs: { src: "/img/logo_print.png" }
      }),
      _c("h1", [
        _vm._v(_vm._s(_vm.$t("_products._product-inventory-list.title")))
      ])
    ]),
    _c("table", { staticClass: "product-inventory-list__table" }, [
      _c("thead", { staticClass: "product-inventory-list__table__header" }, [
        _c("tr", [
          _c("th", { staticClass: "pt-12 pl-3 text-left" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("product_number_short")) +
                " / " +
                _vm._s(_vm.$t("name")) +
                " "
            )
          ]),
          _c("th", { staticClass: "pt-12 text-left" }, [
            _vm._v(_vm._s(_vm.$t("manufacturer")))
          ]),
          _c("th", { staticClass: "pl-3 pt-12 text-left" }, [
            _vm._v(_vm._s(_vm.$t("suppliers")))
          ]),
          _c("th", { staticClass: "pt-12 text-right" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("_products._product-inventory-list.price_per_unit")
                ) +
                " "
            )
          ]),
          _c("th", { staticClass: "pt-12 text-right" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("_products._product-inventory-list.stock")) +
                " "
            )
          ]),
          _c("th", { staticClass: "pt-12 pr-3 text-right" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("_products._product-inventory-list.total_value_stock")
                ) +
                " "
            )
          ])
        ])
      ]),
      _c(
        "tbody",
        [
          _vm._l(_vm.inventory, function(item, idx) {
            return _c(
              "tr",
              { key: item.id, class: { striped: idx % 2 === 0 } },
              [
                _c("td", { staticClass: "pl-3" }, [
                  _c("div", { staticClass: "font-size-85 text--secondary" }, [
                    _vm._v(_vm._s(item.number))
                  ]),
                  _c(
                    "div",
                    [
                      _vm.editable
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: _vm.detailsLink(item),
                                target: "_blank"
                              }
                            },
                            [_c("span", [_vm._v(_vm._s(item.name))])]
                          )
                        : _c("span", [_vm._v(_vm._s(item.name))])
                    ],
                    1
                  )
                ]),
                _c("td", [_vm._v(_vm._s(item.manufacturerName))]),
                _c("td", { staticClass: "pl-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.supplierNames ? item.supplierNames.join(", ") : ""
                      ) +
                      " "
                  )
                ]),
                _c("td", { staticClass: "text-right text-no-wrap pl-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("num")(item.price, 2)) +
                      " €/" +
                      _vm._s(_vm.unitName(item.unit)) +
                      " "
                  )
                ]),
                _c("td", { staticClass: "pl-2" }, [
                  item.isBulkGood
                    ? _c(
                        "div",
                        _vm._l(item.stocks, function(stock, idx) {
                          return _c(
                            "div",
                            {
                              key: item.id + "-" + stock.storeId,
                              staticClass:
                                "d-flex flex-column justify-center align-end my-1"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-end align-center mb-2",
                                  class: { "mt-3": idx > 0 }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-size-75 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(stock.storeName) + " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm.editable
                                ? _c("x-number-input", {
                                    staticClass: "x-dense",
                                    staticStyle: { "max-width": "150px" },
                                    attrs: {
                                      "fractional-digits": 2,
                                      outlined: "",
                                      dense: "",
                                      success: stock._success,
                                      "append-icon": stock._success
                                        ? "done"
                                        : "",
                                      "hide-details": "auto",
                                      suffix: item._success
                                        ? ""
                                        : _vm.unitName(item.unit)
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.$emit("changed:stock", stock)
                                      }
                                    },
                                    model: {
                                      value: stock.current,
                                      callback: function($$v) {
                                        _vm.$set(stock, "current", $$v)
                                      },
                                      expression: "stock.current"
                                    }
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-end align-center",
                                      staticStyle: {
                                        height: "32px",
                                        "min-width": "100px",
                                        "max-width": "300px"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-no-wrap" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("num")(stock.current, 2)
                                              ) +
                                              " " +
                                              _vm._s(_vm.unitName(item.unit)) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ]),
                _c(
                  "td",
                  { staticClass: "pr-3 text-right" },
                  _vm._l(item.stocks, function(stock) {
                    return _c(
                      "div",
                      {
                        key: item.id + "-" + stock.storeId,
                        staticClass:
                          "d-flex flex-column justify-center align-end my-1"
                      },
                      [
                        _c("div", {
                          staticClass: "d-flex justify-end align-center mb-2",
                          class: { "mt-3": idx > 0 },
                          staticStyle: { width: "100%", height: "8px" }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "text-no-wrap",
                            staticStyle: { height: "32px" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("num")(item.price * stock.current, 2)
                                ) +
                                " € "
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          }),
          _c("tr", [
            _c("td", { staticClass: "pl-3", attrs: { colspan: "4" } }, [
              _c("strong", [_vm._v(_vm._s(_vm.$t("total")))])
            ]),
            _c(
              "td",
              { staticClass: "pr-3 text-right text-no-wrap" },
              _vm._l(_vm.stockSums, function(stockSum, idx) {
                return _c(
                  "div",
                  { key: idx, staticClass: "font-weight-bold" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("num")(stockSum.amount, 2)) +
                        " " +
                        _vm._s(_vm.unitName(stockSum.unit)) +
                        " "
                    )
                  ]
                )
              }),
              0
            ),
            _c("td", { staticClass: "pr-3 text-right text-no-wrap" }, [
              _c("strong", [
                _vm._v(_vm._s(_vm._f("num")(_vm.priceSum, 2)) + " €")
              ])
            ])
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
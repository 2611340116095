






















































import { Component, Vue } from 'vue-property-decorator'
import { LogwoodPackageState } from '@/logwood/models/logwood-package-state'
import LogwoodPackage from '@/logwood/models/logwood-package'

@Component
export default class LogwoodHome extends Vue {
  private outerBearingState = LogwoodPackageState.OuterBearing
  private dryingChamberState = LogwoodPackageState.DryingChamber
  private dryBearingState = LogwoodPackageState.DryBearing
  private consumedState = LogwoodPackageState.Consumed

  private async printAsync(pack: LogwoodPackage) {
    const route = this.$router.resolve({
      name: 'logwood-tag',
      params: { lang: this.$i18n.locale, num: pack.number },
    })
    window.open(route.href)
  }

  private async printPackageInventoryAsync(
    state: LogwoodPackageState,
    title: string
  ) {
    const route = this.$router.resolve({
      name: 'logwood-package-inventory',
      params: {
        lang: this.$i18n.locale,
        state: state.toString(),
        title: title,
      },
    })
    window.open(route.href)
  }

  private async printShiftInventoryAsync(
    state: LogwoodPackageState,
    title: string
  ) {
    const route = this.$router.resolve({
      name: 'logwood-shift-inventory',
      params: {
        lang: this.$i18n.locale,
        state: state.toString(),
        title: title,
      },
    })
    window.open(route.href)
  }
}

import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import PaginationRequest from '@/common/pagination/entities/pagination-request'
import PaginationResponse from '@/common/pagination/entities/pagination-response'
import Entity from '@/common/models/entity'

export default abstract class CrudService<E extends Entity> {

  protected resource: string

  /**
   * Creates a new CRUD service for a resource endpoint.
   *
   * @param resource API resource endpoint name providing the CRUD services.
   */
  constructor(resource: string) {
    this.resource = resource
  }

  public async findOneAsync(id: string | null) {
    if (!id) {
      return null
    }
    const res = await axios.get<E>(`${this.resource}?id=${id}`)
    return Responses.obj(res)
  }

  public async findAllAsync(paging: PaginationRequest) {
    const res = await axios.post<PaginationResponse<E>>(this.resource, paging)
    return Responses.pagedList(res)
  }

  public async createAsync(entity: E) {
    const res = await axios.post<E>(`${this.resource}/create`, entity)
    return Responses.obj(res)
  }

  public async updateAsync(entity: E) {
    const res = await axios.put<E>(`${this.resource}/${entity.id}/update`, entity)
    return Responses.obj(res)
  }

  public async removeAsync(id: string) {
    await axios.post(`${this.resource}/${id}/delete`)
  }

  public async sendAsync(entity: E) {
    const res = await axios.get<E>(`${this.resource}/${entity.id}/send`)
    return Responses.obj(res)
  }
}

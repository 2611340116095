import Address from '@/locations/models/address'
import Entity from '@/common/models/entity'

export default class Tenant extends Entity {

  public firstName: string = ''
  public lastName: string = ''
  public company: string = ''
  public vat: string = ''
  public email: string = ''
  public isSmallBusiness: boolean = false
  public address = new Address()
  public enabledTomifaSolidWood = false
  public solidWoodOffcutFactor = 0
  public numberOfDryingChambers = 1
}

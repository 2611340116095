





















































































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import directoriesService from '@/projects/services/project-group-template-directories-service'
import ProjectGroupTemplateDirectoryFilter from '@/projects/entities/project-group-template-directory-filter'
import MoveTemplateDirectoryDialog from '@/projects/components/move-template-directory-dialog.vue'
import cloneDeep from 'lodash/cloneDeep'

@Component
export default class ProjectGroupTemplateDirectoryTree extends Vue {
  @Prop({ type: Boolean, default: false })
  private editDisabled!: boolean

  @Prop({ type: Array, default: () => [] })
  private lastSelectedNumbers!: string[]

  @Ref()
  private treeView: any

  @Ref()
  private moveDirectoryDialog!: MoveTemplateDirectoryDialog

  private loading: boolean = false
  private items: DirectoryTreeItem[] = []
  private open: string[] = []
  private all: string[] = []
  private active: string[] = []
  private tree = []

  private search = ''
  private showSearch = false

  private menuVisible = false
  private articleMenuVisible = false
  private mouseX = 0
  private mouseY = 0
  private selectedFolder: DirectoryTreeItem | null = null
  private selectedItem: DirectoryTreeItem | null = null
  private filter = new ProjectGroupTemplateDirectoryFilter()

  @Watch('active')
  private activeChanged(val: any[]) {
    this.select()
  }

  @Watch('filter', { deep: true })
  private async filterChangedAsync() {
    await this.reloadAsync()
  }

  @Watch('search')
  private searchChanged(val: string) {
    if (!val || val.length < 3) {
      this.clearSearch()
    } else {
      this.open = cloneDeep(this.all)
    }
  }

  private clearSearch() {
    this.open = []
  }

  private async loadAllAsync() {
    this.loading = true
    const request = new ProjectGroupTemplateDirectoryFilter()
    this.items = await directoriesService.findAllAsync(this.filter)
    this.all = this.allSubItemsList(this.items)
    this.loading = false
  }

  private customTreeFilter(
    item: DirectoryTreeItem,
    queryText: string,
    itemText: string
  ) {
    return (
      (item.name &&
        item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1) ||
      (item.number &&
        item.number.toLowerCase().indexOf(queryText.toLowerCase()) > -1)
    )
  }

  private allSubItems(item: DirectoryTreeItem): string[] {
    if (item.isFolder) {
      const lst = this.allSubItemsList(item.children)
      lst.push(item.id)
      return lst
    }
    return []
  }

  private allSubItemsList(items: DirectoryTreeItem[]): string[] {
    if (items.length === 0) return []
    return items.flatMap(this.allSubItems)
  }

  public async reloadAsync() {
    await this.loadAllAsync()
  }

  private select() {
    if (this.active.length > 0) {
      this.$emit('select', this.active[0])
    }
  }

  private onDoubleClick(item: DirectoryTreeItem) {
    if (!item.isFolder) {
      this.$emit('select:dblclick', item)
    }
  }

  private showContextMenu(e: MouseEvent, item: DirectoryTreeItem) {
    if (this.editDisabled) {
      return
    }
    if (this.$auth.isInAnyRole(['ADMIN', 'TENANT', 'USER'])) {
      this.mouseX = e.clientX
      this.mouseY = e.clientY
      this.selectedItem = item
      if (item == null || !item.isFolder) {
        this.articleMenuVisible = true
      }
      if (item == null || item.isFolder) {
        this.selectedFolder = item
        this.menuVisible = true
      }
    }
  }

  private newFolder() {
    const folder = new DirectoryTreeItem()
    folder.name = this.$tc('new_folder')
    folder.isEditing = true
    folder.isFolder = true
    return folder
  }

  private async createRootFolderAsync() {
    const folder = this.newFolder()
    await this.createFolderInternalAsync(folder)
    this.items.push(folder)
    this.focusFolderNameInput(folder.id)
  }

  private async createFolderAsync() {
    const folder = this.newFolder()
    if (this.selectedFolder) {
      folder.parentId = this.selectedFolder.id
      await this.openFolder(this.selectedFolder)
      await this.createFolderInternalAsync(folder)
      this.selectedFolder.children.splice(0, 0, folder)
      this.focusFolderNameInput(folder.id)
    }
  }

  private async createFolderInternalAsync(folder: DirectoryTreeItem) {
    const res = await directoriesService.createFolderAsync(folder)
    if (res) {
      folder.id = res.id
      folder.tenantId = res.tenantId
    }
  }

  private focusFolderNameInput(id: string) {
    this.$nextTick(() => {
      const elem = document.getElementById(id)
      if (elem instanceof HTMLInputElement) {
        elem.focus()
        elem.select()
        elem.setSelectionRange(0, elem.value.length, 'forward')
      }
    })
  }

  private openFolder(folder: DirectoryTreeItem) {
    if (this.open.includes(folder.id) === false) {
      this.open.push(folder.id)
    }
  }

  private editFolder() {
    if (this.selectedFolder) {
      this.renameFolder(this.selectedFolder)
    }
  }

  private renameFolder(item: DirectoryTreeItem) {
    item.isEditing = true
    this.focusFolderNameInput(item.id)
  }

  private async updateFolderAsync(folder: DirectoryTreeItem) {
    if (folder.name == null || folder.name === '') {
      folder.name = 'Neuer Ordner'
    }

    await directoriesService.updateFolderAsync(folder)

    folder.isEditing = false
  }

  private async removeFolderAsync() {
    if (confirm('Ordner und alle enthaltenen Daten löschen?')) {
      if (this.selectedFolder) {
        await directoriesService.removeFolderAsync(this.selectedFolder.id)
        await this.reloadAsync()
      }
    }
  }

  private async hideFolderAsync() {
    if (this.selectedFolder) {
      await directoriesService.hideFolderAsync(this.selectedFolder.id)
      await this.reloadAsync()
    }
  }

  private async unhideFolderAsync() {
    if (this.selectedFolder) {
      await directoriesService.unhideFolderAsync(this.selectedFolder.id)
      await this.reloadAsync()
    }
  }

  private async mounted() {
    await this.loadAllAsync()
  }
}

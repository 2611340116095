var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-header" }, [
      _c("img", {
        staticStyle: { float: "right", "max-width": "250px" },
        attrs: { src: "/img/logo_print.png" }
      }),
      _c("h1", [
        _vm._v(
          " " + _vm._s(_vm.project.number) + " | " + _vm._s(_vm.project.name)
        ),
        _c("br"),
        _vm._v(_vm._s(_vm.project.companyName) + " ")
      ])
    ]),
    _c("div", { staticClass: "page-footer" }, [
      _c("span", [
        _vm._v(
          " " +
            _vm._s(_vm.project.number) +
            " | " +
            _vm._s(_vm.project.name) +
            " "
        )
      ]),
      _c("span", [
        _vm._v(" " + _vm._s(_vm.project.productionPlanningLeadShortCode) + " ")
      ]),
      _c("span", [_vm._v(" " + _vm._s(_vm.getDateTime()) + " ")])
    ]),
    _c("table", [
      _vm._m(0),
      _c("tbody", [
        _c(
          "table",
          [
            _vm._l(_vm.categories, function(cat) {
              return _c("tbody", { key: cat }, [
                _c("tr", [
                  _c("td", { attrs: { colspan: "7" } }, [
                    _c("h2", [_vm._v(_vm._s(cat))])
                  ])
                ]),
                _c("tr", [
                  _c(
                    "td",
                    { attrs: { colspan: "7" } },
                    [
                      _c("v-simple-table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("product_number_short")))
                            ]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("name")))]),
                            _c(
                              "th",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleCategorySort(
                                          cat,
                                          "quantity"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("quantity")) +
                                        " (" +
                                        _vm._s(_vm.$t("parts_list")) +
                                        ")"
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleCategorySort(
                                          cat,
                                          "purchaseQuantity"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("quantity")) +
                                        " (" +
                                        _vm._s(_vm.$t("order")) +
                                        ")"
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleCategorySort(
                                          cat,
                                          "price"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("price")) +
                                        " (" +
                                        _vm._s(_vm.$t("parts_list")) +
                                        ")"
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleCategorySort(
                                          cat,
                                          "purchasePrice"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("price")) +
                                        " (" +
                                        _vm._s(_vm.$t("order")) +
                                        ")"
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleCategorySort(
                                          cat,
                                          "purchaseSinglePrice"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("price")) +
                                        "/" +
                                        _vm._s(_vm.$t("piece")) +
                                        " (" +
                                        _vm._s(_vm.$t("order")) +
                                        ")"
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.categoryItems(cat), function(item) {
                            return _c("tr", { key: item.productNumber }, [
                              _c("td", [_vm._v(_vm._s(item.productNumber))]),
                              _c("td", [_vm._v(_vm._s(item.productName))]),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    width: "120px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("num")(item.quantity, 2)) +
                                      " " +
                                      _vm._s(item.unit) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    width: "120px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("num")(item.purchaseQuantity, 2)
                                      ) +
                                      " " +
                                      _vm._s(item.purchaseUnit) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    width: "120px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("money")(item.price)) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    width: "120px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("money")(item.purchasePrice)
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    width: "120px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("money")(
                                          item.purchasePrice /
                                            item.purchaseQuantity
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          }),
                          0
                        ),
                        _c("tfoot", [
                          _c("tr", [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c("strong", [_vm._v(_vm._s(_vm.$t("sum")))])
                            ]),
                            _c(
                              "td",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("num")(
                                        _vm.quantityCategorySum(cat),
                                        2
                                      )
                                    )
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("num")(
                                          _vm.purchaseQuantityCategorySum(cat),
                                          2
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("money")(_vm.priceCategorySum(cat))
                                    )
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("money")(
                                          _vm.purchasePriceCategorySum(cat)
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            ),
                            _c("td")
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ])
              ])
            }),
            _c("tfoot", [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: { padding: "3px 15px" },
                    attrs: { colspan: "2" }
                  },
                  [_c("strong", [_vm._v(_vm._s(_vm.$t("total")))])]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "text-align": "right",
                      padding: "3px",
                      width: "120px"
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm._f("num")(_vm.quantitySum, 2)))
                    ])
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "text-align": "right",
                      padding: "3px",
                      width: "120px"
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm._f("num")(_vm.purchaseQuantitySum, 2)))
                    ])
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "text-align": "right",
                      padding: "3px",
                      width: "120px"
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm._f("money")(_vm.priceSum)))
                    ])
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "text-align": "right",
                      padding: "3px 15px",
                      width: "130px"
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm._f("money")(_vm.purchasePriceSum)))
                    ])
                  ]
                ),
                _c("td", {
                  staticStyle: { padding: "3px 15px", width: "115px" }
                })
              ])
            ])
          ],
          2
        )
      ]),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("td", [_c("div", { staticClass: "page-header-space" })])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tfoot", [
      _c("tr", [_c("td", [_c("div", { staticClass: "page-footer-space" })])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
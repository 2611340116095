import Address from '@/locations/models/address'

export default class RegistrationRequest {

    public email = ''
    public role = ''
    public salutation = 'Mrs'
    public title = 'Mag'
    public firstName = ''
    public lastName = ''
    public address = new Address()
    public code = ''
    public password = ''
    public confirmPassword = ''
}

import PaginationRequest from '@/common/pagination/entities/pagination-request'

export default class UserFilter {
  public search: string = ''
  public tenantId: string | null = null
  public paging: PaginationRequest = new PaginationRequest()
  public isAdmin = true
  public isTenant = true
  public isUser = true
  public isRestricted = true
  public isExternal = false
}

import { render, staticRenderFns } from "./store-select.vue?vue&type=template&id=10019b06&"
import script from "./store-select.vue?vue&type=script&lang=ts&"
export * from "./store-select.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VListItemContent,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10019b06')) {
      api.createRecord('10019b06', component.options)
    } else {
      api.reload('10019b06', component.options)
    }
    module.hot.accept("./store-select.vue?vue&type=template&id=10019b06&", function () {
      api.rerender('10019b06', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/stores/components/store-select.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            id: "header",
            app: "",
            flat: "",
            "hide-on-scroll": "",
            dark: "",
            color: "primary"
          }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "app-bar-btn",
              attrs: {
                to: { name: "home", params: { lang: _vm.$i18n.locale } },
                text: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("home")) + " ")]
          ),
          _c("language-selection", {
            staticClass: "ml-1",
            attrs: { dark: "" }
          }),
          _c("v-spacer"),
          _c("user-widget")
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }















































































































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import axios from '@/app/axios'
import Company from '@/companies/entities/company'
import snacks from '@/app/services/snacks'

@Component
export default class JoinCompaniesDialog extends Vue {
  @Ref()
  private validator!: ValidationObserver

  private dialog = false
  private loading = false
  private showError = false

  private srcCompany: Company = new Company()
  private dstCompany: Company | null = null

  public open(srcCompany: Company) {
    this.srcCompany = srcCompany
    this.dialog = true
    this.loading = false
    this.showError = false
  }

  private async confirm() {
    if (await this.validator.validate()) {
      this.loading = true
      if (await this.join()) {
        this.$emit('confirm')
        this.loading = false
        this.reset()
        this.cancel()
      } else {
        this.showError = true
      }
    }
  }

  private async join() {
    if (this.dstCompany) {
      let res = await axios.post(`joincompanies`, {
        srcId: this.srcCompany.id,
        dstId: this.dstCompany.id,
      })

      if (res.status == 200) {
        snacks.success('Firmen erfolgreich zusammengeführt.')
        return true
      }
    }
    return false
  }

  private swap() {
    if (this.dstCompany) {
      const tmp = this.srcCompany
      this.srcCompany = this.dstCompany
      this.dstCompany = tmp
    }
  }

  private reset() {
    if (this.validator) {
      this.validator.reset()
    }
  }

  private cancel() {
    this.dialog = false
  }
}

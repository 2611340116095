var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logwood-shift-inventory" }, [
    _c("div", { staticClass: "logwood-shift-inventory__header" }, [
      _c("img", {
        staticClass: "logwood-shift-inventory__logo",
        attrs: { src: "/img/logo_print.png" }
      }),
      _c("h1", [
        _vm.isPeriodTypeDate
          ? _c("span", [_vm._v(_vm._s(_vm.$t("logwood_sum_shift")))])
          : _vm.isPeriodTypeWeek
          ? _c("span", [_vm._v(_vm._s(_vm.$t("logwood_sum_weekly")))])
          : _c("span", [_vm._v(_vm._s(_vm.$t("logwood_sum_monthly")))]),
        _c("span", { staticClass: "mx-1" }, [_vm._v("-")]),
        _c("span", [_vm._v(_vm._s(_vm.title))])
      ])
    ]),
    _c("div", { staticClass: "no-print" }, [
      _c(
        "div",
        { staticClass: "d-flex mb-4" },
        [
          _c("logwood-history-filter-input", {
            staticClass: "mr-2",
            model: {
              value: _vm.filter,
              callback: function($$v) {
                _vm.filter = $$v
              },
              expression: "filter"
            }
          }),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { depressed: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.print()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("print")) + " ")]
          )
        ],
        1
      )
    ]),
    _c("table", { staticClass: "logwood-shift-inventory__table" }, [
      _c("thead", { staticClass: "logwood-shift-inventory__table__header" }, [
        _c("tr", [
          _c("th", { staticClass: "text-left" }, [
            _vm._v(_vm._s(_vm.$t("period")))
          ]),
          _c("th", { staticClass: "text-right" }, [
            _vm._v(_vm._s(_vm.$t("volume")))
          ])
        ])
      ]),
      _c(
        "tbody",
        [
          _vm._l(_vm.shifts, function(item, idx) {
            return [
              _c("tr", { key: idx }, [
                _c(
                  "td",
                  { staticClass: "bordered" },
                  [
                    _c("logwood-history-date", {
                      attrs: { type: _vm.filter.periodType, shift: item }
                    })
                  ],
                  1
                ),
                _c("td", { staticClass: "bordered text-right" }, [
                  _vm._v(_vm._s(_vm._f("num")(item.volume, 3)) + " m³")
                ])
              ]),
              _vm._l(item.products, function(product, pid) {
                return _c("tr", { key: idx + "-" + pid }, [
                  _c("td", { staticClass: "pl-6 py-1" }, [
                    _c("span", { staticClass: "font-size-85" }, [
                      _vm._v(_vm._s(product.productName))
                    ])
                  ]),
                  _c("td", { staticClass: "py-1 text-right" }, [
                    _c("span", { staticClass: "font-size-85" }, [
                      _vm._v(_vm._s(_vm._f("num")(product.volume, 3)) + " m³")
                    ])
                  ])
                ])
              })
            ]
          })
        ],
        2
      ),
      _c("tfoot", { staticClass: "logwood-shift-inventory__table__footer" }, [
        _c("tr", [
          _c("td", { attrs: { colspan: "1" } }, [
            _c("strong", [_vm._v(_vm._s(_vm.$t("total")))])
          ]),
          _c("td", { staticClass: "text-right" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm._f("num")(_vm.volumeSum, 2)) + " m³")
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
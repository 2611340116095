var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "elevation-0",
    attrs: {
      loading: _vm.loading,
      headers: _vm.headers,
      items: _vm.projects,
      options: _vm.pPagination,
      "server-items-length": _vm.pPagination.totalItems,
      "footer-props": { itemsPerPageText: _vm.$t("items_per_page") },
      "no-data-text": _vm.$t("no_data_available")
    },
    on: {
      "update:options": function($event) {
        _vm.pPagination = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item.number",
          fn: function(ref) {
            var item = ref.item
            return [_vm._v(_vm._s(item.number))]
          }
        },
        {
          key: "item.name",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("span", [_vm._v(_vm._s(item.name))]),
              item.storeIds.length > 0
                ? _c(
                    "v-chip",
                    { staticClass: "ma-2 px-1", attrs: { "x-small": "" } },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("inventory")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c("tenancy-chip", { attrs: { "tenant-id": item.tenantId } })
            ]
          }
        },
        {
          key: "item.companyName",
          fn: function(ref) {
            var item = ref.item
            return [_vm._v(_vm._s(item.companyName))]
          }
        },
        {
          key: "item.dueDate",
          fn: function(ref) {
            var item = ref.item
            return [_vm._v(_vm._s(_vm._f("date")(item.dueDate)))]
          }
        },
        {
          key: "item.status",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("project-status-display", {
                model: {
                  value: item.status,
                  callback: function($$v) {
                    _vm.$set(item, "status", $$v)
                  },
                  expression: "item.status"
                }
              })
            ]
          }
        },
        {
          key: "item.action",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "div",
                { staticClass: "text-no-wrap" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { icon: "", title: _vm.$t("details") },
                      on: {
                        click: function($event) {
                          return _vm.details(item)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("search")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { icon: "", title: _vm.$t("parts_list") },
                      on: {
                        click: function($event) {
                          return _vm.newPartsList(item)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("list")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { icon: "", title: _vm.$t("orders") },
                      on: {
                        click: function($event) {
                          return _vm.orders(item)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("shopping_cart")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { icon: "", title: _vm.$t("edit") },
                      on: {
                        click: function($event) {
                          return _vm.edit(item)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("edit")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { icon: "", title: _vm.$t("delete") },
                      on: {
                        click: function($event) {
                          return _vm.deleteProject(item)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { UnitType } from '@/products/entities/unit-type'

const unitM = { value: UnitType.M, text: 'lfm' }
const unitM2 = { value: UnitType.M2, text: 'm²' }
const unitM3 = { value: UnitType.M3, text: 'm³' }
const unitKG = { value: UnitType.KG, text: 'kg' }
const unitSTK = { value: UnitType.STK, text: 'Stk' }
const unitGT = { value: UnitType.GT, text: 'Gt' }
const unitL = { value: UnitType.L, text: 'L' }
const unitPKG = { value: UnitType.PKG, text: 'Pkg' }

export const units = [
  unitM,
  unitM2,
  unitM3,
  unitKG,
  unitSTK,
  unitGT,
  unitL,
  unitPKG,
]

export const purchaseUnits = [
  [unitM],
  [unitM2, unitSTK],
  [unitM3],
  [unitKG],
  [unitSTK],
  [unitGT],
  [unitL],
  [unitPKG],
]

export function unitName(unit: UnitType) {
  return units.find((u) => u.value === unit)?.text || ''
}

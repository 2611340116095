



















































































































import Project from '@/projects/entities/project'
import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import projectsService from '@/projects/services/projects-service'
import ordersService from '@/orders/services/orders-service'
import Order, { OrderStatus } from '@/orders/entities/order'
import OrderDetailsDialog from '@/orders/components/order-details-dialog.vue'

@Component
export default class ProjectOrders extends Vue {
  @Ref()
  private orderDetailsDialog!: OrderDetailsDialog

  @Prop()
  projectId!: string

  isOpen = true
  isSent = true
  isReceived = false
  isClosed = false
  groupMode = 0
  search = ''
  project: Project = new Project()
  orders: Order[] = []

  get bySupplierHeaders() {
    return [
      {
        text: this.$tc('number_short'),
        align: 'start',
        value: 'number',
      },
      {
        text: this.$tc('created'),
        value: 'orderDate',
        align: 'start',
        sortable: false,
      },
      {
        text: this.$tc('expected'),
        value: 'expectedDeliveryDate',
        align: 'start',
        sortable: false,
      },
      {
        text: this.$tc('group'),
        value: 'groupName',
        align: 'start',
      },
      {
        text: this.$tc('buyer'),
        value: 'buyerShortCode',
        align: 'start',
        sortable: false,
      },
      {
        text: this.$tc('status'),
        value: 'status',
        align: 'start',
      },
      {
        text: this.$tc('cost_center_short'),
        align: 'start',
        value: 'businessUnit',
      },
      {
        text: '',
        value: 'action',
        sortable: false,
        class: 'td-w-shrink',
        align: 'end',
      },
    ]
  }

  async loadAsync() {
    this.orders = await ordersService.loadByProjectAsync(this.projectId)
  }

  async loadProjectAsync() {
    this.project =
      (await projectsService.findOneAsync(this.projectId)) || new Project()

    const ordersFor = this.$tc('_orders._project_orders.orders_for')
    const projNumber = this.project.number
    const projName = this.project.name
    const compName = this.project.companyName

    this.$bus.$emit(
      'app.simple-header.title',
      `${ordersFor} ${projNumber} - ${projName} - ${compName}`
    )
  }

  get suppliers() {
    return this.orders
      .map((o) =>
        o.supplierName ? `${o.supplierName} - ${o.supplierNumber ?? ''}` : ''
      )
      .filter((v, i, a) => a.indexOf(v) === i)
  }

  supplierOrders(supplierNumber: string) {
    return this.orders.filter(
      (o) =>
        (o.supplierName
          ? `${o.supplierName} - ${o.supplierNumber ?? ''}`
          : '') == supplierNumber
    )
  }

  supplierOrdersByStatus(supplierNumber: string, status: OrderStatus) {
    return this.orders
      .filter((o) => o.status == status)
      .filter(
        (o) =>
          (o.supplierName
            ? `${o.supplierName} - ${o.supplierNumber ?? ''}`
            : '') == supplierNumber
      )
  }

  async details(order: Order) {
    await this.orderDetailsDialog.openAsync(order.id)
  }

  async deleteAsync(order: Order) {
    if (confirm(this.$tc('are_you_sure'))) {
      await ordersService.removeAsync(order.id)
      await this.loadAsync()
    }
  }

  async mounted() {
    await this.loadProjectAsync()
    await this.loadAsync()
  }
}






















































import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import productsService from '@/products/services/products-service'
import Product from '@/products/entities/product'
import Guid from '@/app/utils/guid'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import { ProductCategory } from '@/products/entities/product-category'

@Component
export default class ProductPicker extends Vue {
  @Prop({ default: () => [] })
  private categories!: ProductCategory[]

  @Prop({ type: Boolean, default: false })
  private pruned!: boolean

  private dialog = false
  private loading = false

  private product: Product | null = null
  private lastSelectedNumbers: string[] = []

  public open(id: string) {
    this.dialog = true
    if (id) {
      this.loadProduct(id)
    }
  }

  public confirm() {
    this.dialog = false
    this.$emit('confirm', this.product)
  }

  public cancel() {
    this.dialog = false
    this.$emit('cancel')
  }

  private async quickConfirm(item: DirectoryTreeItem) {
    await this.loadProduct(item.id)
    this.confirm()
  }

  private async loadProduct(id: string) {
    if (id !== Guid.empty()) {
      this.product = await productsService.findOneAsync(id)
    }
  }
}

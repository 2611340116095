export default class ProductSupplier {
  public productId: string = ''
  public supplierId: string = ''
  public supplierProductId: string = ''
  public netPurchasePrice = 0
  public priceDate: string | null = null
  public netSalesPriceMultiplier: number | null = null
  public isWarehouse = false
  public isPrimary = false
}










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class DateNav extends Vue {
  @Prop({ required: true })
  private value!: string

  @Prop({ default: 'day' })
  private type!: 'day' | 'week' | 'month'

  private end = this.value

  @Watch('value')
  private valueChanged(val: string) {
    this.end = val
  }

  private previous() {
    const d = moment(this.end).subtract(1, this.type).format('YYYY-MM-DD')
    this.$emit('input', d)
    this.$emit('previous', d)
  }

  private next() {
    const d = moment(this.end).add(1, this.type).format('YYYY-MM-DD')
    this.$emit('input', d)
    this.$emit('next', d)
  }

  private pick(val: string) {
    this.$emit('pick', val)
    this.$emit('input', val)
  }
}

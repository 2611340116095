










































import { Component, Vue, Ref } from 'vue-property-decorator'
import RegistrationRequest from '@/users/requests/registration-request'
import accountService from '@/users/services/account-service'
import RegisterForm from '@/users/components/register-form.vue'

@Component
export default class Register extends Vue {
  @Ref()
  private registerForm!: RegisterForm

  private registered = false
  private success = false

  private async register(request: RegistrationRequest) {
    const response = await accountService.registerAsync(request)
    if (response && response.success) {
      this.registered = true
      this.success = response.success
    } else {
      this.registerForm.reset()
    }
  }

  private created() {
    // Redirect to 404 if user registration is disabled.
    if (this.$env.VUE_APP_ALLOW_REGISTRATION === 'no') {
      this.$router.push({ name: 'error', params: { status: '404' } })
    }
  }
}

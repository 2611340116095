


























































import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import ProjectItemGroup from '@/projects/entities/project-item-group'
import ProjectItem from '@/projects/entities/project-item'
import { ProductCategory } from '@/products/entities/product-category'
import cloneDeep from 'lodash/cloneDeep'
import ProductOrderItem from '@/orders/entities/product-order-item'

@Component
export default class ProjectItemsDialog extends Vue {
  @Ref()
  private validator!: ValidationObserver

  @Prop({ type: String })
  private title!: string

  @Prop()
  private selectedCategories!: ProductCategory[]

  @Prop({ type: Boolean, default: false })
  private hideCategories!: boolean

  @Prop({ type: String, default: 'Keine Auswahl möglich.' })
  private emptyMessage!: string

  @Prop({ default: () => [] })
  private orderStates!: ProductOrderItem[]

  private dialog = false
  private loading = false

  private categories: ProductCategory[] = this.selectedCategories
  private groups: ProjectItemGroup[] = []
  private selection: ProjectItem[] = []

  private get filteredGroups() {
    const clonedGroups = cloneDeep(this.groups)
    for (const group of clonedGroups) {
      group.items = group.items.filter((i) => {
        if (i.product) {
          return this.categories.includes(i.product.category)
        }
        return false
      })
    }
    return clonedGroups.filter((g) => g.items.length > 0)
  }

  public open(groups: ProjectItemGroup[]) {
    this.groups = cloneDeep(groups)
    this.dialog = true
  }

  private async confirm() {
    if (await this.validator.validate()) {
      this.loading = true
      this.$emit('confirm', this.selection)
      this.loading = false
      this.reset()
      this.cancel()
    }
  }

  private reset() {
    if (this.validator) {
      this.validator.reset()
    }
  }

  private cancel() {
    this.dialog = false
  }
}
































































































































































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import projectGroupTemplatesService from '@/projects/services/project-group-templates-service'
import ProjectGroupTemplate from '@/projects/entities/project-group-template'
import ProjectGroupTemplateDirectoryTree from '@/projects/components/project-group-template-directory-tree.vue'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import ProjectItem from '@/projects/entities/project-item'

@Component
export default class ProjectGroupTemplatesDialog extends Vue {
  private dialog = false
  private loading = false
  private template: ProjectGroupTemplate | null = null
  private items: ProjectItem[] = []

  @Ref()
  private directoryTree!: ProjectGroupTemplateDirectoryTree

  public async open() {
    this.template = null
    this.$nextTick(async () => {
      if (this.directoryTree) {
        await this.directoryTree.reloadAsync()
      }
    })
    this.dialog = true
  }

  private close() {
    this.dialog = false
  }

  private confirm() {
    if (this.template) {
      this.$emit('templateClicked', this.template)
      this.close()
    }
  }

  private async quickConfirm(item: DirectoryTreeItem) {
    await this.loadTemplate(item.id)
    this.confirm()
  }

  private async loadTemplate(id: string) {
    this.template = await projectGroupTemplatesService.findOneAsync(id)
    if (this.template) {
      this.items = JSON.parse(this.template.content)
    }
  }

  private async deleteTemplate(template: ProjectGroupTemplate) {
    if (confirm(this.$t('are_you_sure').toString())) {
      await projectGroupTemplatesService.deleteAsync(template.id)
      await this.directoryTree.reloadAsync()
      this.template = null
    }
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "700px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "min-height": "100%" } },
        [
          _c("v-card-title", { staticStyle: { height: "64px" } }, [
            _c("span", [_vm._v(_vm._s(_vm.title))])
          ]),
          _c(
            "v-card-text",
            { staticClass: "flex-grow-1" },
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", dense: "" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: _vm.$t("show_hidden_folders"),
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.filter.includeHidden,
                      callback: function($$v) {
                        _vm.$set(_vm.filter, "includeHidden", $$v)
                      },
                      expression: "filter.includeHidden"
                    }
                  }),
                  _c("v-spacer")
                ],
                1
              ),
              _c("v-treeview", {
                staticClass: "fill-height",
                attrs: {
                  open: _vm.open,
                  active: _vm.active,
                  items: _vm.items,
                  activatable: "",
                  selectable: _vm.multiselect,
                  "selection-type": "independent",
                  "item-key": "id",
                  dense: "",
                  transition: "",
                  "return-object": ""
                },
                on: {
                  "update:open": function($event) {
                    _vm.open = $event
                  },
                  "update:active": function($event) {
                    _vm.active = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function(ref) {
                      var item = ref.item
                      var open = ref.open
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "clickable",
                            class: { hidden: item.isHidden },
                            on: {
                              click: function($event) {
                                return _vm.selectFolder(item)
                              },
                              dblclick: function($event) {
                                return _vm.selectFolderAndConfirm(item)
                              }
                            }
                          },
                          [
                            item.isFolder
                              ? _c("v-icon", [
                                  _vm._v(
                                    " " +
                                      _vm._s(open ? "folder_open" : "folder") +
                                      " "
                                  )
                                ])
                              : _c("v-icon", [_vm._v(" text_snippet ")])
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "label",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "clickable",
                            class: { hidden: item.isHidden },
                            on: {
                              click: function($event) {
                                return _vm.selectFolder(item)
                              },
                              dblclick: function($event) {
                                return _vm.selectFolderAndConfirm(item)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selection,
                  callback: function($$v) {
                    _vm.selection = $$v
                  },
                  expression: "selection"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-7 pb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { text: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    depressed: "",
                    color: "primary"
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("select")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-5" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("product-category-select", {
                attrs: { outlined: "", dense: "", "hide-details": "" },
                model: {
                  value: _vm.categoryId,
                  callback: function($$v) {
                    _vm.categoryId = $$v
                  },
                  expression: "categoryId"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.categoryId, loading: _vm.isLoading },
                  on: { click: _vm.syncCategory }
                },
                [_vm._v("Jetzt synchronisieren")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }









import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class SalutationSelect extends Vue {

  @Prop({ required: true })
  private value!: string

  private salutations = [
    { text: 'Frau', value: 'Mrs' },
    { text: 'Herr', value: 'Mr' },
    { text: 'Firma', value: 'Company' },
  ]

  private salutation: string = this.value

  @Watch('value')
  private valueChanged(val: string, old: string) {
    if (val !== old) {
      this.salutation = val
    }
  }

  @Watch('salutation')
  private salutationChanged(val: string, old: string) {
    this.$emit('input', val)
  }
}

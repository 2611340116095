var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { tile: "" } },
    [
      _c("v-card-title", [
        _vm._v(_vm._s(_vm.$t("_logwood._logwood-table.title")))
      ]),
      _c(
        "v-card-text",
        [
          _vm.inventory.length == 0
            ? _c("em", [
                _vm._v(_vm._s(_vm.$t("_logwood._logwood-table.empty")))
              ])
            : _c(
                "v-simple-table",
                {
                  staticClass: "max-height-60vh",
                  attrs: { "fixed-header": "" }
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "td-w-shrink" }),
                      _c("th", [_vm._v(" " + _vm._s(_vm.$t("name")) + " ")]),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(" " + _vm._s(_vm.$t("current_stock")) + " ")
                      ])
                    ])
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.inventory, function(logwood, idx) {
                      return _c("tr", { key: idx }, [
                        _c(
                          "td",
                          { staticClass: "pr-0" },
                          [
                            _c("product-avatar", {
                              attrs: {
                                "image-path": logwood.imagePath,
                                zoom: "76"
                              }
                            })
                          ],
                          1
                        ),
                        _c("td", [
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    target: "_blank",
                                    to: {
                                      path:
                                        "/products/" +
                                        encodeURIComponent(logwood.number)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(logwood.name) + " ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "text--secondary font-size-75" },
                            [_vm._v(" " + _vm._s(logwood.number) + " ")]
                          )
                        ]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(_vm._f("num")(logwood.stock, 2)) + " m³"
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
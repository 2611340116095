import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import LogwoodInventory from '@/logwood/models/logwood-inventory'
import LogwoodPackage from '@/logwood/models/logwood-package'
import LogwoodHistory from '@/logwood/models/logwood-history'
import Product from '@/products/entities/product'
import LogwoodPackageFilter from '@/logwood/models/logwood-package-filter'
import { LogwoodPackageKey, LogwoodPackageState } from '@/logwood/models/logwood-package-state'
import PaginationResponse from '@/common/pagination/entities/pagination-response'
import LogwoodShiftPackageFilter from '@/logwood/models/logwood-shift-package-filter'
import LogwoodPackageInventory from '@/logwood/models/logwood-package-inventory'
import LogwoodShiftsFilter from '@/logwood/models/logwood-history-filter'

class LogwoodService {

  public async findPackageAsync(num: string) {
    const res = await axios.get<LogwoodPackage>(`logwood/packages?num=${num}`)
    return Responses.obj(res)
  }

  public async createPackageAsync(pack: LogwoodPackage) {
    const res = await axios.post<string>(`logwood/packages`, pack)
    return Responses.obj(res)
  }

  public removePackageAsync(pack: LogwoodPackage) {
    return axios.delete(`logwood/packages/${pack.id}`)
  }

  public async updatePackageStateAsync(pack: LogwoodPackage, key: LogwoodPackageKey) {
    const res = await axios.put<any>(`logwood/packages/${pack.id}/state/${key.state}/location/${key.location}`)
    return Responses.obj(res)
  }

  public async loadShiftsAsync(n: number = 1) {
    const res = await axios.get<LogwoodHistory[]>(`logwood/shifts?n=${n}`)
    return Responses.list(res)
  }

  public async loadShiftsFilteredAsync(filter: LogwoodShiftsFilter) {
    const res = await axios.post<LogwoodHistory[]>(`logwood/shifts/filter`, filter)
    return res.data
    // return Responses.pagedList(res)
  }

  public async loadProductsAsync() {
    const res = await axios.get<Product[]>(`logwood/products`)
    return Responses.list(res)
  }

  public async loadInventoryAsync() {
    const res = await axios.get<LogwoodInventory[]>(`logwood/inventory`)
    return Responses.list(res)
  }

  public async getPackageInventoryAsync(state: LogwoodPackageState) {
    const res = await axios.get<LogwoodPackageInventory[]>(`logwood/packages/inventory?state=${state}`)
    return Responses.obj(res)
  }

  public async loadPackagesAsync(filter: LogwoodPackageFilter) {
    const res = await axios.post<PaginationResponse<LogwoodPackage>>(`logwood/packages/filter`, filter)
    return Responses.pagedList(res)
  }

  public async loadShiftPackagesAsync(filter: LogwoodShiftPackageFilter) {
    const res = await axios.post<PaginationResponse<LogwoodPackage>>(`logwood/packages/shift/filter`, filter)
    return Responses.pagedList(res)
  }
}

export default new LogwoodService()






















































import { Component, Vue, Ref } from 'vue-property-decorator'

@Component
export default class Login extends Vue {
  private showResetForm = false
}


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LogwoodHistoryFilter from '@/logwood/models/logwood-history-filter'
import { HistoryPeriodType } from '@/logwood/models/history-period-type'
import isEqual from 'lodash/isEqual'

@Component
export default class LogwoodHistoryFilterInput extends Vue {
  @Prop({ required: true })
  private value!: LogwoodHistoryFilter

  @Prop({ type: Boolean, default: false })
  private hideDateInputs!: boolean

  private filter = this.value

  private periodTypes = [
    {
      text: 'shifts',
      value: HistoryPeriodType.Date,
      icon: 'calendar_view_day',
    },
    {
      text: 'weeks',
      value: HistoryPeriodType.Week,
      icon: 'calendar_view_week',
    },
    {
      text: 'months',
      value: HistoryPeriodType.Month,
      icon: 'calendar_view_month',
    },
  ]

  @Watch('value', { deep: true })
  private valueChanged(val: LogwoodHistoryFilter, old: LogwoodHistoryFilter) {
    if (!isEqual(val, old)) {
      this.filter = val
    }
  }

  private emit() {
    this.$emit('input', this.filter)
  }
}

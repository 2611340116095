


























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ProjectItemGroup from '@/projects/entities/project-item-group'
import ProjectItem from '@/projects/entities/project-item'
import { ProductCategory } from '@/products/entities/product-category'
import ProductOrderItem from '@/orders/entities/product-order-item'
import Product from '@/products/entities/product'

@Component
export default class PartsListGroupItems extends Vue {
  @Prop()
  private group!: ProjectItemGroup

  @Prop()
  private categories!: ProductCategory[]

  @Prop({ default: () => [] })
  private orderStates!: ProductOrderItem[]

  @Prop()
  private isWarehouseProject!: boolean

  private itemsFilter(items: ProjectItem[]) {
    return items.filter(this.itemFilter).sort((a, b) => a.sorting - b.sorting)
  }

  private itemFilter(item: ProjectItem) {
    return (
      item.product === null || this.categories.includes(item.product.category)
    )
  }
}

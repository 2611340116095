var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("v-file-input", {
                attrs: {
                  label: _vm.$t("_equipment.upload") + " (.xlsx)",
                  outlined: "",
                  dense: "",
                  accept: ".xlsx",
                  "show-size": "",
                  loading: _vm.uploading,
                  "success-messages": _vm.successMessage,
                  "hide-details": "auto"
                },
                on: {
                  change: function($event) {
                    return _vm.upload()
                  }
                },
                model: {
                  value: _vm.file,
                  callback: function($$v) {
                    _vm.file = $$v
                  },
                  expression: "file"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", md: "4" } }, [
            _c(
              "div",
              {
                staticClass: "d-flex align-center ml-8 py-2 cursor-pointer",
                on: {
                  click: function($event) {
                    return _vm.download()
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    staticStyle: { fill: "#000000" },
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      x: "0px",
                      y: "0px",
                      width: "32",
                      height: "32",
                      viewBox: "0 0 48 48"
                    }
                  },
                  [
                    _c("rect", {
                      attrs: {
                        width: "16",
                        height: "9",
                        x: "28",
                        y: "15",
                        fill: "#21a366"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        fill: "#185c37",
                        d:
                          "M44,24H12v16c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V24z"
                      }
                    }),
                    _c("rect", {
                      attrs: {
                        width: "16",
                        height: "9",
                        x: "28",
                        y: "24",
                        fill: "#107c42"
                      }
                    }),
                    _c("rect", {
                      attrs: {
                        width: "16",
                        height: "9",
                        x: "12",
                        y: "15",
                        fill: "#3fa071"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        fill: "#33c481",
                        d: "M42,6H28v9h16V8C44,6.895,43.105,6,42,6z"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        fill: "#21a366",
                        d: "M14,6h14v9H12V8C12,6.895,12.895,6,14,6z"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M22.319,13H12v24h10.319C24.352,37,26,35.352,26,33.319V16.681C26,14.648,24.352,13,22.319,13z",
                        opacity: ".05"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M22.213,36H12V13.333h10.213c1.724,0,3.121,1.397,3.121,3.121v16.425\tC25.333,34.603,23.936,36,22.213,36z",
                        opacity: ".07"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M22.106,35H12V13.667h10.106c1.414,0,2.56,1.146,2.56,2.56V32.44C24.667,33.854,23.52,35,22.106,35z",
                        opacity: ".09"
                      }
                    }),
                    _c(
                      "linearGradient",
                      {
                        attrs: {
                          id: "flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1",
                          x1: "4.725",
                          x2: "23.055",
                          y1: "14.725",
                          y2: "33.055",
                          gradientUnits: "userSpaceOnUse"
                        }
                      },
                      [
                        _c("stop", {
                          attrs: { offset: "0", "stop-color": "#18884f" }
                        }),
                        _c("stop", {
                          attrs: { offset: "1", "stop-color": "#0b6731" }
                        })
                      ],
                      1
                    ),
                    _c("path", {
                      attrs: {
                        fill: "url(#flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1)",
                        d:
                          "M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16\tC24,33.105,23.105,34,22,34z"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        fill: "#fff",
                        d:
                          "M9.807,19h2.386l1.936,3.754L16.175,19h2.229l-3.071,5l3.141,5h-2.351l-2.11-3.93L11.912,29H9.526\tl3.193-5.018L9.807,19z"
                      }
                    })
                  ],
                  1
                ),
                _c("span", { staticClass: "ml-2" }, [
                  _vm._v(" " + _vm._s(_vm.$t("_equipment.download")) + " ")
                ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          staticClass: "container-toolbar mb-1 mr-6",
          attrs: { flat: "", dense: "", color: "transparent" }
        },
        [
          _c("v-text-field", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce:input",
                value: 500,
                expression: "500",
                arg: "input"
              }
            ],
            staticClass: "mx-2",
            attrs: {
              label: _vm.$t("search"),
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: "",
              "append-icon": "search"
            },
            on: { "click:clear": _vm.clearSearch },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
                        ? _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    title: _vm.$t("more") + " ...",
                                    icon: "",
                                    small: ""
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("more_vert")])],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _vm.$auth.isInAnyRole(["ADMIN", "TENANT"])
                    ? [
                        _vm.editDisabled === false
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.createRootFolderAsync()
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("create_new_folder")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("create_new_base_dir")) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.moveDirectoryDialog.open()
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("format_line_spacing")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("move_elements")) + "... "
                              )
                            ])
                          ],
                          1
                        ),
                        _c("v-divider")
                      ]
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0",
                            attrs: {
                              label: _vm.$t("show_hidden_folders"),
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.filter.includeHidden,
                              callback: function($$v) {
                                _vm.$set(_vm.filter, "includeHidden", $$v)
                              },
                              expression: "filter.includeHidden"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.loading
        ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
        : _vm._e(),
      _c("v-treeview", {
        ref: "treeView",
        attrs: {
          open: _vm.open,
          active: _vm.active,
          items: _vm.items,
          search: _vm.search,
          filter: _vm.customTreeFilter,
          "open-on-click": "",
          activatable: "",
          "item-key": "id",
          dense: "",
          transition: ""
        },
        on: {
          "update:open": function($event) {
            _vm.open = $event
          },
          "update:active": function($event) {
            _vm.active = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "prepend",
            fn: function(ref) {
              var item = ref.item
              var open = ref.open
              return [
                _c(
                  "div",
                  {
                    staticClass: "mr-1",
                    class: { hidden: item.isHidden, excluded: item.isExcluded },
                    on: {
                      dblclick: function($event) {
                        return _vm.onDoubleClick(item)
                      },
                      contextmenu: function($event) {
                        return _vm.showContextMenu($event, item)
                      }
                    }
                  },
                  [
                    item.isFolder
                      ? _c(
                          "v-avatar",
                          { attrs: { size: "38", color: "white" } },
                          [
                            _c("v-icon", [
                              _vm._v(_vm._s(open ? "folder_open" : "folder"))
                            ])
                          ],
                          1
                        )
                      : _c(
                          "v-badge",
                          {
                            staticClass: "inventory-article-badge",
                            attrs: {
                              value: item.isWarehouseProduct,
                              icon: "inventory",
                              overlap: "",
                              left: ""
                            }
                          },
                          [
                            _c("product-avatar", {
                              attrs: { "image-path": item.imagePath }
                            })
                          ],
                          1
                        )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "label",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    class: { hidden: item.isHidden, excluded: item.isExcluded },
                    on: {
                      dblclick: function($event) {
                        return _vm.onDoubleClick(item)
                      },
                      contextmenu: function($event) {
                        return _vm.showContextMenu($event, item)
                      }
                    }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.isEditing,
                          expression: "item.isEditing"
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.name,
                          expression: "item.name"
                        }
                      ],
                      staticClass: "folder-name-input",
                      attrs: { id: item.id, placeholder: _vm.$t("new_folder") },
                      domProps: { value: item.name },
                      on: {
                        blur: function($event) {
                          return _vm.updateFolderAsync(item)
                        },
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.updateFolderAsync(item)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(item, "name", $event.target.value)
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !item.isEditing,
                            expression: "!item.isEditing"
                          }
                        ],
                        staticClass: "py-1"
                      },
                      [
                        item.number
                          ? _c(
                              "div",
                              { staticClass: "text-caption text--disabled" },
                              [_vm._v(" " + _vm._s(item.number) + " ")]
                            )
                          : _vm._e(),
                        _c("div", { staticStyle: { "line-height": "1" } }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        !item._isNew
                          ? _c("tenancy-chip", {
                              attrs: { "tenant-id": item.tenantId }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.tree,
          callback: function($$v) {
            _vm.tree = $$v
          },
          expression: "tree"
        }
      }),
      _c(
        "v-menu",
        {
          attrs: {
            "position-x": _vm.mouseX,
            "position-y": _vm.mouseY,
            absolute: "",
            "offset-y": ""
          },
          model: {
            value: _vm.menuVisible,
            callback: function($$v) {
              _vm.menuVisible = $$v
            },
            expression: "menuVisible"
          }
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _vm.$auth.isInAnyRole(["ADMIN", "TENANT"])
                ? [
                    _c(
                      "v-list-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.createProduct()
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-avatar",
                          [_c("v-icon", [_vm._v("text_snippet")])],
                          1
                        ),
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("add_product")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.createFolderAsync()
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-avatar",
                          [_c("v-icon", [_vm._v("create_new_folder")])],
                          1
                        ),
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("add_subfolder")))
                        ])
                      ],
                      1
                    ),
                    _c("v-divider")
                  ]
                : _vm._e(),
              _vm.selectedFolder
                ? [
                    _vm.$auth.isInAnyRole(["ADMIN", "TENANT"])
                      ? [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.editFolder()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                [_c("v-icon", [_vm._v("title")])],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("rename_folder")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.importProducts()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                [_c("v-icon", [_vm._v("cloud_upload")])],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("import_products")) +
                                    "... "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.applySupplierMultiplier()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                [_c("v-icon", [_vm._v("call_split")])],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "_products._apply_supplier_multiplier_dialog.apply"
                                      )
                                    ) +
                                    "... "
                                )
                              ])
                            ],
                            1
                          ),
                          _c("v-divider")
                        ]
                      : _vm._e(),
                    _vm.selectedFolder.isHidden
                      ? _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.unhideFolderAsync()
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("visibility")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(_vm.$t("show")))
                            ])
                          ],
                          1
                        )
                      : _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.hideFolderAsync()
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("visibility_off")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(_vm.$t("hide")))
                            ])
                          ],
                          1
                        ),
                    _vm.selectedFolder.isExcluded
                      ? _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.includeFolderAsync()
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("search")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(_vm.$t("include_from_search")))
                            ])
                          ],
                          1
                        )
                      : _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.excludeFolderAsync()
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("search_off")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(_vm.$t("exclude_from_search")))
                            ])
                          ],
                          1
                        ),
                    _vm.$auth.isInAnyRole(["ADMIN"])
                      ? [
                          _c("v-divider"),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.removeFolderAsync()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                [_c("v-icon", [_vm._v("delete")])],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("delete")))
                              ])
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: {
            "position-x": _vm.mouseX,
            "position-y": _vm.mouseY,
            absolute: "",
            "offset-y": ""
          },
          model: {
            value: _vm.articleMenuVisible,
            callback: function($$v) {
              _vm.articleMenuVisible = $$v
            },
            expression: "articleMenuVisible"
          }
        },
        [_c("v-list", { attrs: { dense: "" } })],
        1
      ),
      _c("products-import-dialog", {
        ref: "productsImportDialog",
        on: { "upload:complete": _vm.onImportComplete }
      }),
      _c("move-directory-dialog", {
        ref: "moveDirectoryDialog",
        on: {
          confirm: function($event) {
            return _vm.reloadAsync()
          }
        }
      }),
      _c("apply-supplier-multiplier-dialog", {
        ref: "applySupplierMultiplierDialog",
        on: {
          confirm: function($event) {
            return _vm.reloadAsync()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
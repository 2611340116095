var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "", outlined: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-1" },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", height: "28px" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    title: "Alle ausklappen",
                    small: "",
                    icon: "",
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.expandAll()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("unfold_more")])],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    title: "Alle ausklappen",
                    small: "",
                    icon: "",
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.collapseAll()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("unfold_less")])],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    title: "Alle Positionen auswählen",
                    "x-small": "",
                    text: "",
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectAll()
                    }
                  }
                },
                [_vm._v(" Alle ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    title: "Alle Positionen abwählen",
                    "x-small": "",
                    text: "",
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.unselectAll()
                    }
                  }
                },
                [_vm._v(" Keine ")]
              )
            ],
            1
          ),
          _vm.groups && _vm.groups.length > 0
            ? _c("v-treeview", {
                staticStyle: { "max-height": "500px", "overflow-y": "auto" },
                attrs: {
                  open: _vm.open,
                  items: _vm.groups,
                  "item-children": "items",
                  "selection-type": "leaf",
                  selectable: "",
                  "return-object": "",
                  "open-on-click": ""
                },
                on: {
                  "update:open": function($event) {
                    _vm.open = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function(ref) {
                        var item = ref.item
                        var leaf = ref.leaf
                        return [
                          leaf
                            ? _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("div", { staticClass: "mr-2" }, [
                                    _vm._v(" " + _vm._s(item.sorting) + " ")
                                  ]),
                                  _c(
                                    "product-order-indicator",
                                    {
                                      attrs: {
                                        "order-states": _vm.orderStates,
                                        product: item.product,
                                        "project-item-id": item.id
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "font-weight-bold mr-2"
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "text--secondary" },
                                        [_vm._v(_vm._s(item.product.name))]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold mr-2" },
                                  [_vm._v(_vm._s(item.number))]
                                ),
                                _c("span", [_vm._v(_vm._s(item.name))])
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4208808177
                ),
                model: {
                  value: _vm.selection,
                  callback: function($$v) {
                    _vm.selection = $$v
                  },
                  expression: "selection"
                }
              })
            : _c(
                "v-alert",
                { staticClass: "mt-3", attrs: { text: "", color: "info" } },
                [_vm._v(_vm._s(_vm.emptyMessage))]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    attrs: { "min-height": "100%" }
                  },
                  [
                    _c(
                      "v-card-title",
                      [
                        _c("validation-provider", {
                          attrs: { rules: "required", name: _vm.$t("group") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      staticClass: "mr-2",
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        label: _vm.$t("group"),
                                        filled: "",
                                        flat: "",
                                        dense: "",
                                        "hide-details": "",
                                        "error-messages": errors,
                                        title: errors
                                      },
                                      on: {
                                        focus: function($event) {
                                          _vm.readonly = true
                                        },
                                        blur: function($event) {
                                          _vm.readonly = false
                                        }
                                      },
                                      model: {
                                        value: _vm.group.number,
                                        callback: function($$v) {
                                          _vm.$set(_vm.group, "number", $$v)
                                        },
                                        expression: "group.number"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("validation-provider", {
                          attrs: { rules: "required", name: _vm.$t("name") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      staticStyle: { width: "350px" },
                                      attrs: {
                                        label: _vm.$t("name"),
                                        filled: "",
                                        flat: "",
                                        dense: "",
                                        "hide-details": "",
                                        "error-messages": errors,
                                        title: errors
                                      },
                                      on: {
                                        focus: function($event) {
                                          _vm.readonly = true
                                        },
                                        blur: function($event) {
                                          _vm.readonly = false
                                        }
                                      },
                                      model: {
                                        value: _vm.group.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.group, "name", $$v)
                                        },
                                        expression: "group.name"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "flex-grow-1" },
                      [
                        _c(
                          "v-simple-table",
                          {
                            staticClass: "partslist-group-dialog__table",
                            attrs: { dense: "" }
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "w-reorder" }),
                                _c("th", { staticClass: "w-nr" }, [
                                  _vm._v(_vm._s(_vm.$t("number_short")))
                                ]),
                                _c("th", { staticClass: "w-count" }, [
                                  _vm._v(_vm._s(_vm.$t("quantity")))
                                ]),
                                _c("th", { staticClass: "w-size" }, [
                                  _vm._v(_vm._s(_vm.$t("length")))
                                ]),
                                _c("th", { staticClass: "w-size" }, [
                                  _vm._v(_vm._s(_vm.$t("width")))
                                ]),
                                _c("th", { staticClass: "w-size" }, [
                                  _vm._v(_vm._s(_vm.$t("length2")))
                                ]),
                                _c("th", { staticClass: "w-size" }, [
                                  _vm._v(_vm._s(_vm.$t("width2")))
                                ]),
                                _c("th", { staticClass: "w-material" }, [
                                  _vm._v(_vm._s(_vm.$t("product")))
                                ]),
                                _c("th", { staticClass: "w-kanten" }, [
                                  _vm._v(_vm._s(_vm.$t("profiles")))
                                ]),
                                _c("th", { staticClass: "w-material" }, [
                                  _vm._v(_vm._s(_vm.$t("fu_upper")))
                                ]),
                                _c("th", { staticClass: "w-material" }, [
                                  _vm._v(_vm._s(_vm.$t("fu_lower")))
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("title")))]),
                                _c("th", { staticClass: "w-position" }, [
                                  _vm._v(_vm._s(_vm.$t("position")))
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("misc")))]),
                                _c("th", { staticClass: "w-actions" })
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.group.items, function(item, index) {
                                return _c("tr", { key: item.id || index }, [
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple"
                                              }
                                            ],
                                            on: {
                                              click: function($event) {
                                                return _vm.up(index)
                                              }
                                            }
                                          },
                                          [_vm._v(" keyboard_arrow_up ")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple"
                                              }
                                            ],
                                            on: {
                                              click: function($event) {
                                                return _vm.down(index)
                                              }
                                            }
                                          },
                                          [_vm._v(" keyboard_arrow_down ")]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "85%" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.group.number) +
                                            "_" +
                                            _vm._s(item.sorting)
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          rules:
                                            "required|decimal|min_value:0.01",
                                          name: _vm.$t("quantity")
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("x-number-input", {
                                                    staticClass: "text-center",
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "quantity"
                                                      ),
                                                      solo: "",
                                                      flat: "",
                                                      "hide-details": "",
                                                      "fractional-digits": 2,
                                                      "error-messages": errors,
                                                      title: errors
                                                    },
                                                    model: {
                                                      value: item.quantity,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "quantity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.quantity"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          rules: "required|decimal|min_value:0",
                                          name: _vm.$t("length")
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("x-number-input", {
                                                    staticClass: "text-center",
                                                    attrs: {
                                                      readonly:
                                                        item.length2 > 0,
                                                      placeholder: _vm.$t(
                                                        "length"
                                                      ),
                                                      solo: "",
                                                      flat: "",
                                                      "hide-details": "",
                                                      "fractional-digits": 1,
                                                      "error-messages": errors,
                                                      title: errors
                                                    },
                                                    model: {
                                                      value: item.length,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "length",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.length"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          rules: "required|decimal|min_value:0",
                                          name: _vm.$t("width")
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("x-number-input", {
                                                    staticClass: "text-center",
                                                    attrs: {
                                                      readonly: item.width2 > 0,
                                                      placeholder: _vm.$t(
                                                        "width"
                                                      ),
                                                      solo: "",
                                                      flat: "",
                                                      "hide-details": "",
                                                      "fractional-digits": 1,
                                                      "error-messages": errors,
                                                      title: errors
                                                    },
                                                    model: {
                                                      value: item.width,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "width",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.width"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "w-oversize-td" },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          rules: "decimal|min_value:0",
                                          name: _vm.$t("length2")
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("x-number-input", {
                                                    staticClass: "text-center",
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "length2"
                                                      ),
                                                      solo: "",
                                                      flat: "",
                                                      "hide-details": "",
                                                      "fractional-digits": 1,
                                                      "error-messages": errors,
                                                      title: errors
                                                    },
                                                    model: {
                                                      value: item.length2,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "length2",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.length2"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("oversize-menu", {
                                        attrs: {
                                          "finished-size": item.length2,
                                          size: item.length
                                        },
                                        on: {
                                          "update:size": function($event) {
                                            return _vm.$set(
                                              item,
                                              "length",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "w-oversize-td" },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          rules: "decimal|min_value:0",
                                          name: _vm.$t("width2")
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("x-number-input", {
                                                    staticClass: "text-center",
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "width2"
                                                      ),
                                                      solo: "",
                                                      flat: "",
                                                      "hide-details": "",
                                                      "fractional-digits": 1,
                                                      "error-messages": errors,
                                                      title: errors
                                                    },
                                                    model: {
                                                      value: item.width2,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "width2",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.width2"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("oversize-menu", {
                                        attrs: {
                                          "finished-size": item.width2,
                                          size: item.width
                                        },
                                        on: {
                                          "update:size": function($event) {
                                            return _vm.$set(
                                              item,
                                              "width",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "product-order-indicator",
                                        {
                                          attrs: {
                                            "order-states": _vm.orderStates,
                                            product: item.product,
                                            "project-item-id": item.id
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              slim: "",
                                              rules: "required",
                                              name: _vm.$t("product")
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("product-select", {
                                                        attrs: {
                                                          group: "article",
                                                          placeholder: _vm.$t(
                                                            "product"
                                                          ),
                                                          solo: "",
                                                          flat: "",
                                                          "hide-details": "",
                                                          "error-messages": errors,
                                                          title: errors
                                                        },
                                                        model: {
                                                          value: item.product,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "product",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.product"
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c("profiles-input", {
                                        attrs: { value: item }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c("product-select", {
                                        attrs: {
                                          group: "fup",
                                          placeholder: _vm.$t("product"),
                                          categories: _vm.fupCategory,
                                          pruned: "",
                                          solo: "",
                                          flat: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: item.frontFup,
                                          callback: function($$v) {
                                            _vm.$set(item, "frontFup", $$v)
                                          },
                                          expression: "item.frontFup"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c("product-select", {
                                        attrs: {
                                          group: "fup",
                                          placeholder: _vm.$t("product"),
                                          categories: _vm.fupCategory,
                                          pruned: "",
                                          solo: "",
                                          flat: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: item.backFup,
                                          callback: function($$v) {
                                            _vm.$set(item, "backFup", $$v)
                                          },
                                          expression: "item.backFup"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          placeholder: _vm.$t("name"),
                                          solo: "",
                                          flat: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: item.name,
                                          callback: function($$v) {
                                            _vm.$set(item, "name", $$v)
                                          },
                                          expression: "item.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          placeholder: _vm.$t("position_long"),
                                          solo: "",
                                          flat: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: item.posName,
                                          callback: function($$v) {
                                            _vm.$set(item, "posName", $$v)
                                          },
                                          expression: "item.posName"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          placeholder: _vm.$t("misc"),
                                          solo: "",
                                          flat: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: item.remarks,
                                          callback: function($$v) {
                                            _vm.$set(item, "remarks", $$v)
                                          },
                                          expression: "item.remarks"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            title: _vm.$t("duplicate"),
                                            tabindex: "-1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.duplicate(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [_vm._v("content_copy")])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            title: _vm.$t("delete"),
                                            tabindex: "-1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.remove(item)
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("delete")])],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mt-2",
                            attrs: { small: "", block: "", depressed: "" },
                            on: { click: _vm.add }
                          },
                          [
                            _c("v-icon", [_vm._v("add")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("_projects._parts_list.add_line")
                                ) +
                                " "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-7 pb-5" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text",
                            attrs: { text: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: _vm.loading,
                              disabled: invalid,
                              depressed: "",
                              color: "primary"
                            },
                            on: { click: _vm.confirm }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("save")))])]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
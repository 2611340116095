var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "330px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    attrs: { "min-height": "100%" }
                  },
                  [
                    _c("v-card-title", { staticStyle: { height: "64px" } }, [
                      _vm._v(_vm._s(_vm.$t("export_correction_list")))
                    ]),
                    _c(
                      "v-card-text",
                      { staticClass: "flex-grow-1" },
                      [
                        _c(
                          "v-form",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("company")) + " "
                                      ),
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.company.name))
                                      ])
                                    ])
                                  ],
                                  1
                                ),
                                _vm.company.isManufacturer &&
                                _vm.company.isSupplier
                                  ? _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-radio-group",
                                              {
                                                staticClass: "mt-0",
                                                model: {
                                                  value: _vm.type,
                                                  callback: function($$v) {
                                                    _vm.type = $$v
                                                  },
                                                  expression: "type"
                                                }
                                              },
                                              [
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "_companies.manufacturer_correction_list"
                                                    ),
                                                    value: "manufacturer"
                                                  }
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "_companies.supplier_correction_list"
                                                    ),
                                                    value: "supplier"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-7 pb-5" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text",
                            attrs: { text: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(_vm._s(_vm.$t("cancel")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: _vm.loading,
                              disabled: invalid,
                              depressed: "",
                              color: "primary"
                            },
                            on: { click: _vm.confirm }
                          },
                          [_vm._v(_vm._s(_vm.$t("export")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }



























































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import PagedMixin from '@/common/pagination/mixins/paged-mixin'
import User from '@/users/entities/user'

@Component
export default class UsersTable extends Mixins(PagedMixin) {
  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ default: () => [] })
  public users!: User[]

  get headers() {
    return [
      { text: this.$tc('name'), value: 'name', sortable: false },
      { text: this.$tc('email'), value: 'email', sortable: false },
      { text: this.$tc('address'), value: 'address', sortable: false },
      { text: this.$tc('_users.user_role'), value: 'role', sortable: false },
      { text: this.$tc('status'), value: 'status', sortable: false },
      {
        text: '',
        value: 'action',
        sortable: false,
        class: 'td-w-shrink',
        align: 'end',
      },
    ]
  }

  private edit(user: User) {
    this.$emit('edit', user)
  }

  private impersonate(user: User) {
    this.$emit('impersonate', user)
  }

  private enable(user: User) {
    this.$emit('enable', user)
  }

  private disable(user: User) {
    this.$emit('disable', user)
  }

  private deleteUser(user: User) {
    if (confirm(this.$tc('_users.confirm_user_delete'))) {
      this.$emit('delete', user)
    }
  }
}













import Address from '@/locations/models/address'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AddressLine extends Vue {
  @Prop({ required: true })
  private address!: Address
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "700px",
        "min-height": "400px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    attrs: { "min-height": "450px" }
                  },
                  [
                    _c("v-card-title", { staticStyle: { height: "64px" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.title))])
                    ]),
                    _c(
                      "v-card-text",
                      { staticClass: "flex-grow-1" },
                      [
                        !_vm.hideCategories
                          ? _c("product-category-select", {
                              attrs: {
                                multiple: "",
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                close: "",
                                limit: 3,
                                clearable: ""
                              },
                              model: {
                                value: _vm.categories,
                                callback: function($$v) {
                                  _vm.categories = $$v
                                },
                                expression: "categories"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c("project-items-select", {
                              attrs: {
                                groups: _vm.filteredGroups,
                                "empty-message": _vm.emptyMessage,
                                "order-states": _vm.orderStates
                              },
                              model: {
                                value: _vm.selection,
                                callback: function($$v) {
                                  _vm.selection = $$v
                                },
                                expression: "selection"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-7 pb-5" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text",
                            attrs: { text: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(_vm._s(_vm.$t("cancel")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: _vm.loading,
                              disabled: invalid || _vm.selection.length === 0,
                              depressed: "",
                              color: "primary"
                            },
                            on: { click: _vm.confirm }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("create")))])]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
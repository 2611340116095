




























































import { Component, Vue } from 'vue-property-decorator'
import productsService from '@/products/services/products-service'
import ProductInventoryFilter from '@/products/entities/product-inventory-filter'
import ProductInventoryItem from '@/products/entities/product-inventory-item'
import Stock from '@/stores/models/stock'
import storesService from '@/stores/services/stores-service'
import Guid from '@/app/utils/guid'

@Component
export default class ProductInventory extends Vue {
  private filter = new ProductInventoryFilter()
  private inventory: ProductInventoryItem[] = []
  private loading = false

  private async loadAsync() {
    this.loading = true
    this.inventory = await productsService.findInventoryAsync(this.filter)
    this.loading = false
  }

  private print() {
    // TODO: Statt einer eigenen Seite wäre hier einfach ein Ausblenden via css-media möglich oder?
    const route = this.$router.resolve({
      name: 'product-inventory-print',
      params: {
        lang: this.$i18n.locale,
        category: this.filter.category.toString(),
        manufacturerId: this.filter.manufacturerId || Guid.empty(),
        supplierId: this.filter.supplierId || Guid.empty(),
        bulkGoodsOnly: `${this.filter.bulkGoodsOnly}`,
      },
    })
    window.open(route.href)
  }

  private async updateStockAsync(stock: Stock) {
    await storesService.setStockAsync(stock)
    Vue.set(stock, '_success', true)
    setTimeout(() => Vue.set(stock, '_success', false), 3000)
  }

  private async mounted() {
    await this.loadAsync()
  }
}











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ImageInput extends Vue {
  @Prop({ type: String })
  private src!: string | null

  @Prop({ type: String })
  private fallbackSrc!: string | null

  private showImage = true
  private currentSrc = this.src

  private loadFallback() {
    this.currentSrc = this.fallbackSrc
  }
}































































































import { Component, Vue } from 'vue-property-decorator'
import axios from '@/app/axios'
import downloadAsync from '@/common/files/download'

@Component
export default class EquipmentHome extends Vue {
  private file = new File([''], '')
  private uploading = false
  private successMessage = ''

  private async download() {
    await downloadAsync(`equipment`, `equipment.xlsx`)
  }

  private async upload() {
    try {
      this.uploading = true
      const formData = new FormData()
      formData.append('file', this.file, this.file.name)
      await axios.post(`equipment`, formData)
      this.successMessage = this.$tc('_equipment.upload_success')
      setTimeout(() => (this.successMessage = ''), 3000)
    } finally {
      this.file = new File([''], '')
      this.uploading = false
    }
  }
}

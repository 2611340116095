






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import User from '@/users/entities/user'
import usersService from '@/users/services/users-service'
import PaginationRequest from '@/common/pagination/entities/pagination-request'

@Component
export default class UserAutocomplete extends Vue {
  @Prop({ default: null })
  private value!: string | null

  @Prop({ type: Boolean, default: false })
  private highlightCurrentUser!: boolean

  private isLoading = false
  private pagination = new PaginationRequest()
  private user: User | null = null
  private users: User[] = []

  @Watch('value')
  private valueChanged(val: string | null, old: string | null) {
    if (val !== old) {
      this.setUser(val)
    }
  }

  private async loadAsync() {
    this.isLoading = true
    this.users = await usersService.findAllForRolesAsync()

    if (this.highlightCurrentUser) {
      const currentUser = this.users.find((u) => u.id === this.$user.data.id)
      if (currentUser) {
        this.users.unshift(currentUser)
      }
    }

    this.setUser(this.value)
    this.isLoading = false
  }

  private setUser(userId: string | null) {
    if (userId) {
      this.user = this.users.find((u) => u.id === userId) || null
    } else {
      this.user = null
    }
  }

  private filter(item: User, queryText: string) {
    const firstname = item.firstName?.toLocaleLowerCase()
    const lastname = item.lastName?.toLocaleLowerCase()
    const shortcode = item.shortCode?.toLocaleLowerCase()

    for (const part of queryText.trim().split(/\s+/)) {
      const lowpart = part.toLocaleLowerCase()
      if (
        firstname.indexOf(lowpart) === -1 &&
        lastname.indexOf(lowpart) === -1 &&
        shortcode !== lowpart
      ) {
        return false
      }
    }
    return true
  }

  private change(u: User) {
    this.$emit('input', u.id)
  }

  private async mounted() {
    await this.loadAsync()
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { tile: "" } },
    [
      _c(
        "v-card-title",
        [
          _c("span", { staticClass: "mr-1" }, [
            _vm.isPeriodTypeDate
              ? _c("span", [_vm._v(_vm._s(_vm.$t("logwood_sum_shift")))])
              : _vm.isPeriodTypeWeek
              ? _c("span", [_vm._v(_vm._s(_vm.$t("logwood_sum_weekly")))])
              : _c("span", [_vm._v(_vm._s(_vm.$t("logwood_sum_monthly")))]),
            _c("span", { staticClass: "mx-1" }, [_vm._v("-")]),
            _c("span", [_vm._v(_vm._s(_vm.title))])
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mt-2",
              attrs: { depressed: "", small: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("print:inventory", _vm.state, _vm.title)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("print_view")) + " ")]
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "container-toolbar",
              attrs: { dense: "", flat: "" }
            },
            [
              _c("logwood-history-filter-input", {
                attrs: { "hide-date-inputs": "" },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn-toggle",
                { attrs: { mandatory: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function($event) {
                          _vm.tab = 0
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("show_chart")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function($event) {
                          _vm.tab = 1
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("reorder")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-window",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-window-item",
                [
                  _c("x-chart", {
                    staticClass: "my-2",
                    attrs: {
                      config: _vm.chartConfig,
                      labels: _vm.chartLabels,
                      data: _vm.chartData
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-0",
                    attrs: {
                      loading: _vm.loading,
                      headers: _vm.headers,
                      items: _vm.shifts,
                      "no-data-text": _vm.$t(
                        "_logwood._logwood-shifts-table.empty"
                      ),
                      "show-expand": "",
                      "single-expand": "",
                      "hide-default-footer": "",
                      "item-key": "start"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.shift",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("logwood-history-date", {
                                attrs: {
                                  type: _vm.filter.periodType,
                                  shift: item
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "item.volume",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm._f("num")(item.volume, 3)) + " m³"
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "expanded-item",
                          fn: function(ref) {
                            var headers = ref.headers
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                {
                                  staticClass: "px-6 pb-1",
                                  attrs: { colspan: headers.length }
                                },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "logwood-shift-history__products"
                                    },
                                    [
                                      _c(
                                        "tbody",
                                        _vm._l(item.products, function(
                                          product
                                        ) {
                                          return _c(
                                            "tr",
                                            { key: product.productName },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "pt-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(product.productName)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-right pt-1"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("num")(
                                                          product.volume,
                                                          3
                                                        )
                                                      ) +
                                                      " m³ "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("date-nav", {
            attrs: { type: _vm.type },
            on: { pick: _vm.pick, previous: _vm.pick, next: _vm.pick },
            model: {
              value: _vm.filter.end,
              callback: function($$v) {
                _vm.$set(_vm.filter, "end", $$v)
              },
              expression: "filter.end"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "485px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "min-height": "100%" } },
        [
          _c("v-card-title", { staticStyle: { height: "64px" } }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("move_elements")))])
          ]),
          _c("v-card-text", { staticClass: "flex-grow-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column justify-space-around align-center",
                staticStyle: { "min-height": "140px" }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-1",
                    attrs: { small: "", depressed: "" },
                    on: {
                      click: function($event) {
                        return _vm.srcPicker.openDialog()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.scrItemsName) + " ")]
                ),
                _c("directory-picker", {
                  ref: "srcPicker",
                  attrs: {
                    title: _vm.$t("select_elements_to_move"),
                    excluded: _vm.excludedItems,
                    multiselect: ""
                  },
                  on: { confirm: _vm.selectSrcItems }
                }),
                _vm._v(" " + _vm._s(_vm.$t("move_to")) + " "),
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-1",
                    attrs: { small: "", depressed: "" },
                    on: {
                      click: function($event) {
                        return _vm.dstPicker.openDialog()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.dstFolderName) + " ")]
                ),
                _c("directory-picker", {
                  ref: "dstPicker",
                  attrs: {
                    title: _vm.$t("pick_target_folder"),
                    excluded: _vm.excludedItems,
                    "only-folders": ""
                  },
                  on: { confirm: _vm.selectDstFolder }
                })
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "px-7 pb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { text: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    depressed: "",
                    color: "primary"
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("move_elements")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Home from '@/app/views/home.vue'
import AGB from '@/app/views/agb.vue'
import DSGVO from '@/app/views/dsgvo.vue'
import FAQ from '@/app/views/faq.vue'
import About from '@/app/views/about.vue'
import Impressum from '@/app/views/impressum.vue'
import Tenants from '@/tenants/views/tenants.vue'
import EmailConfirm from '@/users/views/email-confirm.vue'
import Login from '@/users/views/login.vue'
import Permissions from '@/users/views/permissions.vue'
import Register from '@/users/views/register.vue'
import SetPassword from '@/users/views/set-password.vue'
import ResetPassword from '@/users/views/reset-password.vue'
import Settings from '@/users/views/settings.vue'
import Users from '@/users/views/users.vue'
import Error from '@/app/views/error.vue'
import Products from '@/products/views/products.vue'
import ProductInventory from '@/products/views/product-inventory.vue'
import ProductInventoryPrint from '@/products/views/product-inventory-print.vue'
import PriceHistories from '@/products/views/price-histories.vue'
import PartsList from '@/projects/views/parts-list.vue'
import Companies from '@/companies/views/companies.vue'
import Print from '@/projects/views/print.vue'
import PrintSums from '@/projects/views/print-sums.vue'
import Projects from '@/projects/views/projects.vue'
import ProjectDetails from '@/projects/views/project-details.vue'
import Orders from '@/orders/views/orders.vue'
import ProjectOrders from '@/orders/views/project-orders.vue'
import LogwoodHome from '@/logwood/views/logwood-home.vue'
import LogwoodInventory from '@/logwood/views/logwood-inventory.vue'
import LogwoodRegistration from '@/logwood/views/logwood-registration.vue'
import LogwoodScan from '@/logwood/views/logwood-scan.vue'
import LogwoodTag from '@/logwood/views/logwood-tag.vue'
import LogwoodHistoryInventory from '@/logwood/views/logwood-history-inventory.vue'
import Management from '@/admin/views/management.vue'
import Stores from '@/stores/views/stores.vue'
import Equipment from '@/equipment/views/equipment.vue'

export default {
  // Default route the user will be redirected to if none is specified in the
  // request params.
  loginDefaultRedirect: {
    ADMIN: 'home',
    TENANT: 'home',
    USER: 'home',
    DEFAULT: 'home',
  },

  // Route to redirect to after a successful logout.
  logoutRedirect: 'login',

  routes: [
    {
      path: '/:lang?',
      name: 'default',
      component: Home,
      meta: {
        layout: 'sidebar-layout',
        title: 'home',
        requiresAuth: true,
      },
    },
    {
      path: '/:lang?/home',
      name: 'home',
      component: Home,
      meta: {
        layout: 'sidebar-layout',
        title: 'home',
        requiresAuth: true,
      },
    },
    {
      path: '/:lang?/agb',
      name: 'agb',
      component: AGB,
      meta: {
        layout: 'sidebar-layout',
      },
    },
    {
      path: '/:lang?/faq',
      name: 'faq',
      component: FAQ,
      meta: {
        layout: 'sidebar-layout',
      },
    },
    {
      path: '/:lang?/about',
      name: 'about',
      component: About,
      meta: {
        layout: 'sidebar-layout',
      },
    },
    {
      path: '/:lang?/dsgvo',
      name: 'dsvgo',
      component: DSGVO,
      meta: {
        layout: 'sidebar-layout',
      },
    },
    {
      path: '/:lang?/impressum',
      name: 'impressum',
      component: Impressum,
      meta: {
        layout: 'sidebar-layout',
      },
    },
    {
      path: '/:lang?/:user/email-confirm/:token',
      name: 'email-confirm',
      component: EmailConfirm,
      props: true,
      meta: {
        layout: 'empty-layout',
      },
    },
    {
      path: '/:lang?/login/:redirectUrl?',
      name: 'login',
      component: Login,
      meta: {
        layout: 'header-layout',
      },
    },
    {
      path: '/:lang?/register',
      name: 'register',
      component: Register,
      meta: {
        layout: 'header-layout',
      },
    },
    {
      path: '/:lang?/:email/set-password/:token',
      name: 'set-password',
      component: SetPassword,
      props: true,
      meta: {
        layout: 'empty-layout',
      },
    },
    {
      path: '/:lang?/:email/reset-password/:token',
      name: 'reset-password',
      component: ResetPassword,
      props: true,
      meta: {
        layout: 'empty-layout',
      },
    },
    {
      path: '/:lang?/settings',
      name: 'settings',
      component: Settings,
      meta: {
        layout: 'sidebar-layout',
        title: 'Einstellungen',
        requiresAuth: true,
      },
    },
    {
      path: '/:lang?/users',
      name: 'users',
      component: Users,
      meta: {
        layout: 'sidebar-layout',
        title: 'users',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT'],
      },
    },
    {
      path: '/:lang?/tenants',
      name: 'tenants',
      component: Tenants,
      meta: {
        layout: 'sidebar-layout',
        title: 'tenants',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT'],
      },
    },
    {
      path: '/:lang?/management',
      name: 'management',
      component: Management,
      meta: {
        layout: 'sidebar-layout',
        title: 'management',
        requiresAuth: true,
        roles: ['ADMIN'],
      },
    },

    {
      path: '/:lang?/equipment',
      name: 'equipment',
      component: Equipment,
      meta: {
        layout: 'sidebar-layout',
        title: 'equipment',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT'],
      },
    },

    // {
    //   path: '/:lang?/permissions',
    //   name: 'permissions',
    //   component: Permissions,
    //   meta: {
    //     layout: 'sidebar-layout',
    //     title: 'Berechtigungen',
    //     requiresAuth: true,
    //     roles: ['ADMIN'],
    //   },
    // },
    {
      path: '/:lang?/products/:productIdOrNumber?',
      name: 'products',
      component: Products,
      props: true,
      meta: {
        layout: 'sidebar-layout',
        title: 'products',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER', 'RESTRICTED'],
      },
    },
    {
      path: '/:lang?/product-inventory',
      name: 'product-inventory',
      component: ProductInventory,
      props: true,
      meta: {
        layout: 'sidebar-layout',
        title: '_products._product-inventory.menu',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER', 'RESTRICTED'],
      },
    },
    {
      path: '/:lang?/price-histories',
      name: 'price-histories',
      component: PriceHistories,
      props: true,
      meta: {
        layout: 'sidebar-layout',
        title: '_products._price-histories.menu',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT'],
      },
    },
    {
      path:
        '/:lang?/product-inventory/print/:category/:manufacturerId/:supplierId/:bulkGoodsOnly',
      name: 'product-inventory-print',
      component: ProductInventoryPrint,
      props: true,
      meta: {
        layout: 'empty-layout',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER', 'RESTRICTED'],
      },
    },
    {
      path: '/:lang?/stores',
      name: 'stores',
      component: Stores,
      props: true,
      meta: {
        layout: 'sidebar-layout',
        title: 'stores',
        requiresAuth: true,
        roles: ['ADMIN'],
      },
    },
    {
      path: '/:lang?/companies/:companyId?',
      name: 'companies',
      component: Companies,
      props: true,
      meta: {
        layout: 'sidebar-layout',
        title: 'companies',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER'],
      },
    },
    {
      path: '/:lang?/print/:projectId/:itemIds',
      name: 'print',
      component: Print,
      props: true,
      meta: {
        layout: 'empty-layout',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER'],
      },
    },
    {
      path: '/:lang?/print-sums/:projectId/:itemIds',
      name: 'print-sums',
      component: PrintSums,
      props: true,
      meta: {
        layout: 'empty-layout',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER'],
      },
    },
    {
      path: '/:lang?/projects',
      name: 'projects',
      component: Projects,
      meta: {
        layout: 'sidebar-layout',
        title: 'projects',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER'],
      },
    },
    {
      path: '/:lang?/project-details/:projectId',
      name: 'project-details',
      component: ProjectDetails,
      props: true,
      meta: {
        layout: 'sidebar-layout',
        title: 'Projektdetails',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER'],
      },
    },
    {
      path: '/:lang?/project/:projectId/parts',
      name: 'parts-list',
      props: true,
      component: PartsList,
      meta: {
        layout: 'sidebar-layout',
        title: 'Stückliste',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER'],
      },
    },

    {
      path: '/:lang?/orders/',
      name: 'orders',
      component: Orders,
      meta: {
        layout: 'sidebar-layout',
        title: 'orders',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER', 'RESTRICTED'],
      },
    },
    {
      path: '/:lang?/project-orders/:projectId',
      name: 'project-orders',
      props: true,
      component: ProjectOrders,
      meta: {
        layout: 'sidebar-layout',
        title: 'Bestellungen zum Projekt',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER', 'RESTRICTED'],
      },
    },

    {
      path: '/:lang?/logwood/home',
      name: 'logwood-home',
      props: true,
      component: LogwoodHome,
      meta: {
        layout: 'sidebar-layout',
        title: '_logwood._logwood-home.title',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER', 'RESTRICTED'],
      },
    },
    {
      path: '/:lang?/logwood/package/inventory/:state/:title',
      name: 'logwood-package-inventory',
      props: true,
      component: LogwoodInventory,
      meta: {
        layout: 'empty-layout',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER', 'RESTRICTED'],
      },
    },
    {
      path: '/:lang?/logwood/registration',
      name: 'logwood-registration',
      props: true,
      component: LogwoodRegistration,
      meta: {
        layout: 'sidebar-layout',
        title: '_logwood._logwood-registration.title',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER', 'RESTRICTED'],
      },
    },
    {
      path: '/:lang?/logwood/scan',
      name: 'logwood-scan',
      props: true,
      component: LogwoodScan,
      meta: {
        layout: 'sidebar-layout',
        title: '_logwood._logwood-scan.title',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER', 'RESTRICTED'],
      },
    },
    {
      path: '/:lang?/logwood/tag/:num',
      name: 'logwood-tag',
      props: true,
      component: LogwoodTag,
      meta: {
        layout: 'empty-layout',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'RESTRICTED'],
      },
    },
    {
      path: '/:lang?/logwood/shift/inventory/:state/:title',
      name: 'logwood-shift-inventory',
      props: true,
      component: LogwoodHistoryInventory,
      meta: {
        layout: 'empty-layout',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT', 'USER', 'RESTRICTED'],
      },
    },

    // Error page.
    {
      path: '/error/:status',
      name: 'error',
      component: Error,
      meta: {
        layout: 'empty-layout',
      },
    },

    // Fallback redirect.
    { path: '*', redirect: '/error/404' },
  ],
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showImage
    ? _c(
        "v-img",
        _vm._b(
          { attrs: { src: _vm.currentSrc }, on: { error: _vm.loadFallback } },
          "v-img",
          _vm.$attrs,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
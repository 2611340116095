var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-5", attrs: { flat: "" } },
    [
      _c("v-card-title", { staticClass: "pb-0" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "v-card-text",
        _vm._l(_vm.files, function(file, i) {
          return _c(
            "v-list-item",
            { key: i },
            [
              _c("v-list-item-content", [
                _vm._v(" " + _vm._s(file.fileName) + " ")
              ]),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { title: _vm.$t("download") },
                      on: {
                        click: function($event) {
                          return _vm.downloadFile(file)
                        }
                      }
                    },
                    [_vm._v("get_app")]
                  )
                ],
                1
              ),
              _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
                ? _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { title: _vm.$t("delete") },
                          on: {
                            click: function($event) {
                              return _vm.deleteFile(file)
                            }
                          }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        1
      ),
      _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
        ? _c(
            "v-card-actions",
            [
              _c("v-file-input", {
                attrs: {
                  label: _vm.$t("upload"),
                  outlined: "",
                  dense: "",
                  multiple: ""
                },
                on: { change: _vm.uploadFiles },
                model: {
                  value: _vm.file,
                  callback: function($$v) {
                    _vm.file = $$v
                  },
                  expression: "file"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }




































































































































































































































import { Component, Ref, Vue, Prop } from 'vue-property-decorator'
import ProjectItemGroup from '@/projects/entities/project-item-group'
import projectsService from '@/projects/services/projects-service'
import projectGroupsService from '@/projects/services/project-groups-service'
import Project, { ProjectStatus } from '@/projects/entities/project'
import { ProductCategory } from '@/products/entities/product-category'
import { productCategories } from '@/products/utils/product-categories'
import snacks from '@/app/services/snacks'
import PartsListGroupDialog from '../components/parts-list-group-dialog.vue'
import PartlistUploadDialog from '@/projects/components/partlist-upload-dialog.vue'
import ProjectItemsDialog from '@/projects/components/project-items-dialog.vue'
import ProjectItem from '@/projects/entities/project-item'
import ordersService from '@/orders/services/orders-service'
import axios from '@/app/axios'
import ProjectGroupTemplatesDialog from '@/projects/components/project-group-templates-dialog.vue'
import CreateTemplateDialog from '@/projects/components/create-template-dialog.vue'
import Guid from '@/app/utils/guid'
import ProjectGroupTemplate from '@/projects/entities/project-group-template'
import projectGroupTemplatesService from '@/projects/services/project-group-templates-service'
import ProductOrderItem from '@/orders/entities/product-order-item'

@Component
export default class PartList extends Vue {
  @Prop()
  private projectId!: string

  @Ref()
  private groupDialog!: PartsListGroupDialog

  @Ref()
  private partlistUploadDialog!: PartlistUploadDialog
  @Ref()
  private machineListDialog!: ProjectItemsDialog
  @Ref()
  private printableListDialog!: ProjectItemsDialog
  @Ref()
  private printableSumsDialog!: ProjectItemsDialog
  @Ref()
  private orderListDialog!: ProjectItemsDialog
  @Ref()
  private projectGroupTemplatesDialog!: ProjectGroupTemplatesDialog
  @Ref()
  private createTemplateDialog!: CreateTemplateDialog

  private project: Project = new Project()
  private groups: ProjectItemGroup[] = []
  private categories: ProductCategory[] = productCategories.map((c) => c.value)
  private orderStates: ProductOrderItem[] = []

  private panels: number[] = []
  private loading = false
  private fab = false

  private get machineCategoryIds() {
    return [ProductCategory.MaterialPlatten]
  }

  private get allCategoryIds() {
    return productCategories.map((c) => c.value)
  }

  private get canOrder() {
    return (
      this.project.status === ProjectStatus.InPreparation ||
      this.project.status === ProjectStatus.InProduction
    )
  }

  private async loadProjectAsync() {
    const proj = await projectsService.findOneAsync(this.projectId)
    this.project = proj || new Project()

    const num = this.project.number
    const name = this.project.name
    const company = this.project.companyName
    const title = `${num} - ${name} - ${company}`
    this.$bus.$emit('app.simple-header.title', title)
  }

  private async loadAsync() {
    this.loading = true
    this.groups = await projectGroupsService.loadAsync(this.projectId)
    this.loading = false
  }

  private async saveAsync(group: ProjectItemGroup) {
    this.loading = true

    for (const item of group.items) {
      item.productId = item.product?.id || Guid.empty()
      item.frontEdgingProductId = item.frontEdgingProduct?.id || null
      item.backEdgingProductId = item.backEdgingProduct?.id || null
      item.leftEdgingProductId = item.leftEdgingProduct?.id || null
      item.rightEdgingProductId = item.rightEdgingProduct?.id || null
      item.customEdgingProductId = item.customEdgingProduct?.id || null
      item.frontFupId = item.frontFup?.id || null
      item.backFupId = item.backFup?.id || null

      item.product = null
      item.frontEdgingProduct = null
      item.backEdgingProduct = null
      item.leftEdgingProduct = null
      item.rightEdgingProduct = null
      item.customEdgingProduct = null
      item.frontFup = null
      item.backFup = null
    }

    try {
      await projectGroupsService.saveAsync(this.projectId, group)
      await this.loadAsync()
    } finally {
      this.loading = false
    }
  }

  private expandAll() {
    this.panels = this.groups.map((g) => g.sorting)
  }

  private collapseAll() {
    this.panels = []
  }

  private async up(index: number) {
    if (index > 0) {
      const sel = this.groups[index]
      const adj = this.groups[index - 1]
      await this.swap(sel.id, adj.id)
    }
  }

  private async down(index: number) {
    if (index < this.groups.length - 1) {
      const sel = this.groups[index]
      const adj = this.groups[index + 1]
      await this.swap(sel.id, adj.id)
    }
  }

  private async swap(id1: string, id2: string) {
    await projectGroupsService.swapAsync(id1, id2)
    await this.loadAsync()
  }

  public addGroup() {
    const newGroup = new ProjectItemGroup()
    newGroup.projectId = this.projectId
    newGroup.sorting = -1
    //newGroup.sorting = this.groups.length + 1
    newGroup.items.push(new ProjectItem())
    this.groups.push(newGroup)
    this.groupDialog.open(newGroup)
  }

  private async duplicateGroup(group: ProjectItemGroup) {
    await projectGroupsService.duplicateAsync(group)
    await this.loadAsync()
  }

  private async removeGroup(group: ProjectItemGroup) {
    if (confirm(this.$tc('_projects._parts_list.confirm_remove_group'))) {
      await projectGroupsService.removeAsync(group)
      await this.loadAsync()
    }
  }

  private cleanupGroup(group: ProjectItemGroup) {
    const last = this.groups[this.groups.length - 1]
    if (last.id === Guid.empty()) {
      this.groups.splice(this.groups.length - 1, 1)
    }
  }

  private openMachineListDialog() {
    this.machineListDialog.open(this.groups)
  }

  private async createMachineListAsync(items: ProjectItem[]) {
    await this.exportCsvAsync('optiplanning', items)
    await this.exportCsvAsync('nesting', items)
  }

  private async exportCsvAsync(type: string, items: ProjectItem[]) {
    const name = `${this.project.number}.${type}`
    const data = {
      projectId: this.project.id,
      itemIds: items.map((i) => i.id),
    }
    const response = await axios.post(`csv/${type}`, data, {
      responseType: 'blob',
    })
    this.downloadFile(name, response.data)
  }

  private downloadFile(name: string, data: any) {
    const blob = new Blob([data], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `${name}.csv`
    link.click()
    URL.revokeObjectURL(link.href)
  }

  private openPrintableListDialog() {
    this.printableListDialog.open(this.groups)
  }

  private async createPrintableListAsync(items: ProjectItem[]) {
    const route = this.$router.resolve({
      name: 'print',
      params: {
        lang: this.$i18n.locale,
        projectId: this.projectId,
        itemIds: JSON.stringify(items.map((i) => i.id)),
      },
    })
    window.open(route.href, '_blank')
  }

  private openPrintableSumsDialog() {
    this.printableSumsDialog.open(this.groups)
  }

  private async createPrintableSumsAsync(items: ProjectItem[]) {
    const route = this.$router.resolve({
      name: 'print-sums',
      params: {
        lang: this.$i18n.locale,
        projectId: this.projectId,
        itemIds: JSON.stringify(items.map((i) => i.id)),
      },
    })
    window.open(route.href, '_blank')
  }

  private openOrderListDialog() {
    this.orderListDialog.open(this.groups)
  }

  private async createOrderListAsync(items: ProjectItem[]) {
    let order = await ordersService.createOrdersAsync(
      this.projectId,
      items.map((i) => i.id)
    )
    this.$router.push({
      name: 'project-orders',
      params: {
        lang: this.$i18n.locale,
        projectId: this.projectId,
      },
    })
  }

  private openPartlistUploadDialog() {
    this.partlistUploadDialog.open(this.project)
  }

  private projectGroupTemplateSelected(template: ProjectGroupTemplate) {
    var newItems = <ProjectItem[]>JSON.parse(template.content)
    const newGroup = new ProjectItemGroup()
    newGroup.projectId = this.projectId
    newGroup.sorting = this.groups.length + 1
    newItems.forEach((i) => {
      i.id = Guid.empty()
      newGroup.items.push(i)
    })
    this.groups.push(newGroup)
    this.groupDialog.open(newGroup)
  }

  private async createTemplateAsync(template: ProjectGroupTemplate) {
    await projectGroupTemplatesService.createAsync(template)
    snacks.success(this.$tc('_projects._partlist_edit.template_added'))
  }

  private async loadOrderStatesAsync() {
    this.orderStates = await ordersService.findAllOrderedProductNumbersAsync(
      this.projectId
    )
  }

  private async mounted() {
    await this.loadProjectAsync()
    await this.loadAsync()
    this.expandAll()
    await this.loadOrderStatesAsync()
  }
}






























































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import Project from '@/projects/entities/project'
import projectsService from '@/projects/services/projects-service'

@Component
export default class PartlistUploadDialog extends Vue {
  @Ref()
  private validator!: ValidationObserver

  private dialog = false
  private loading = false

  private project: Project | null = null
  private file = new File([''], '')
  private warnings: string = ''

  public open(project: Project) {
    this.project = project
    this.dialog = true
  }

  private async confirm() {
    if (await this.validator.validate()) {
      this.loading = true
      await this.uploadFile(this.file)
      this.$emit('imported')
      this.loading = false
      if (this.warnings.length === 0) {
        this.reset()
        this.cancel()
      }
    }
  }

  public async uploadFile(file: File) {
    if (file.size > 0 && this.project) {
      const result = await projectsService.importImosAsync(
        this.project.id,
        file
      )
      this.warnings = result
    }
  }

  private reset() {
    if (this.validator) {
      this.validator.reset()
    }
  }

  private cancel() {
    this.dialog = false
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              return [
                _c("v-text-field", {
                  directives: [
                    {
                      name: "debounce",
                      rawName: "v-debounce:input",
                      value: 500,
                      expression: "500",
                      arg: "input"
                    }
                  ],
                  attrs: {
                    placeholder: _vm.$t(
                      "_logwood._logwood-scan.scan_placeholder"
                    ),
                    outlined: "",
                    autofocus: ""
                  },
                  on: { input: _vm.scanAsync },
                  model: {
                    value: _vm.text,
                    callback: function($$v) {
                      _vm.text = $$v
                    },
                    expression: "text"
                  }
                }),
                _vm.hasOuterBearingStatePacks
                  ? _c("logwood-scan-table", {
                      staticClass: "mb-8",
                      attrs: { value: _vm.packs, state: _vm.outerBearingState },
                      on: {
                        "change:state": _vm.changeStateAsync,
                        remove: _vm.removeAsync
                      }
                    })
                  : _vm._e(),
                _vm.hasDryingChamberStatePacks
                  ? _c("logwood-scan-table", {
                      staticClass: "mb-8",
                      attrs: {
                        value: _vm.packs,
                        state: _vm.dryingChamberState
                      },
                      on: {
                        "change:state": _vm.changeStateAsync,
                        remove: _vm.removeAsync
                      }
                    })
                  : _vm._e(),
                _vm.hasDryBearingStatePacks
                  ? _c("logwood-scan-table", {
                      staticClass: "mb-8",
                      attrs: { value: _vm.packs, state: _vm.dryBearingState },
                      on: {
                        "change:state": _vm.changeStateAsync,
                        remove: _vm.removeAsync
                      }
                    })
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-simple-table",
    { staticClass: "parts-list-group-items__table", attrs: { dense: "" } },
    [
      _c("thead", [
        _c("tr", [
          _c("th", { staticClass: "w-nr" }, [
            _vm._v(_vm._s(_vm.$t("number_short")))
          ]),
          _c("th", { staticClass: "w-count" }, [
            _vm._v(_vm._s(_vm.$t("quantity")))
          ]),
          _c("th", { staticClass: "w-size" }, [
            _vm._v(_vm._s(_vm.$t("length")))
          ]),
          _c("th", { staticClass: "w-size" }, [
            _vm._v(_vm._s(_vm.$t("width")))
          ]),
          _c("th", { staticClass: "w-size" }, [
            _vm._v(_vm._s(_vm.$t("length2")))
          ]),
          _c("th", { staticClass: "w-size" }, [
            _vm._v(_vm._s(_vm.$t("width2")))
          ]),
          _c("th", { staticClass: "w-material" }, [
            _vm._v(_vm._s(_vm.$t("product")))
          ]),
          _c("th", { staticClass: "w-kanten" }, [
            _vm._v(_vm._s(_vm.$t("profiles")))
          ]),
          _c("th", { staticClass: "w-material" }, [
            _vm._v(_vm._s(_vm.$t("fu_upper")))
          ]),
          _c("th", { staticClass: "w-material" }, [
            _vm._v(_vm._s(_vm.$t("fu_lower")))
          ]),
          _c("th", [_vm._v(_vm._s(_vm.$t("title")))]),
          _c("th", { staticClass: "w-position" }, [
            _vm._v(_vm._s(_vm.$t("position")))
          ]),
          _c("th", [_vm._v(_vm._s(_vm.$t("misc")))]),
          _vm.isWarehouseProject
            ? _c("th", [_vm._v(_vm._s(_vm.$t("business_unit_short")))])
            : _vm._e()
        ])
      ]),
      _c(
        "tbody",
        _vm._l(_vm.itemsFilter(_vm.group.items), function(item, index) {
          return _c("tr", { key: index }, [
            _c("td", { staticClass: "text-center" }, [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(_vm.group.number) + "_" + _vm._s(item.sorting))
              ])
            ]),
            _c("td", { staticClass: "text-center" }, [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(item.quantity))
              ])
            ]),
            _c("td", { staticClass: "text-center" }, [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(item.length))
              ])
            ]),
            _c("td", { staticClass: "text-center" }, [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(item.width))
              ])
            ]),
            _c("td", { staticClass: "text-center" }, [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(item.length2))
              ])
            ]),
            _c("td", { staticClass: "text-center" }, [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(item.width2))
              ])
            ]),
            _c(
              "td",
              [
                _c(
                  "product-order-indicator",
                  {
                    attrs: {
                      "order-states": _vm.orderStates,
                      product: item.product,
                      "project-item-id": item.id
                    }
                  },
                  [
                    _c("product-avatar", {
                      staticClass: "mr-1",
                      attrs: {
                        "image-path": item.product.imagePath,
                        size: "24",
                        zoom: "24"
                      }
                    }),
                    _c("span", { staticClass: "font-size-85" }, [
                      _vm._v(_vm._s(item.product ? item.product.name : ""))
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "td",
              [_c("profiles-input", { attrs: { value: item, readonly: "" } })],
              1
            ),
            _c("td", [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(item.frontFup ? item.frontFup.name : ""))
              ])
            ]),
            _c("td", [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(item.backFup ? item.backFup.name : ""))
              ])
            ]),
            _c("td", [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(item.name))
              ])
            ]),
            _c("td", [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(item.posName))
              ])
            ]),
            _c("td", [
              _c("span", { staticClass: "font-size-85" }, [
                _vm._v(_vm._s(item.remarks))
              ])
            ]),
            _vm.isWarehouseProject
              ? _c("td", [
                  _c("span", { staticClass: "font-size-85" }, [
                    _vm._v(_vm._s(item.businessUnit))
                  ])
                ])
              : _vm._e()
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Guid from '@/app/utils/guid'
import ProjectItem from '@/projects/entities/project-item'

export default class ProjectItemGroup {
  public id: string = Guid.empty()
  public projectId: string | null = null
  public number: string = ''
  public name: string = ''
  public items: ProjectItem[] = []
  public sorting: number = 1
}

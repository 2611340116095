












































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import PaginationRequest from '@/common/pagination/entities/pagination-request'
import UserDialog from '@/users/components/user-dialog.vue'
import User from '@/users/entities/user'
import accountService from '@/users/services/account-service'
import usersService from '@/users/services/users-service'
import authService from '@/users/services/auth-service'
import snacks from '@/app/services/snacks'
import UserFilter from '@/users/entities/user-filter'
import isEqual from 'lodash/isEqual'

@Component
export default class Users extends Vue {
  @Ref()
  private dialog!: UserDialog

  private loading: boolean = false
  private users: User[] = []
  private filter = new UserFilter()

  @Watch('filter.paging')
  private async filterPagingChanged(
    val: PaginationRequest,
    old: PaginationRequest
  ) {
    if (isEqual(val, old) === false) {
      await this.fetchAll()
    }
  }

  public async fetchAll(resetPage = false) {
    this.loading = true
    if (resetPage) {
      this.filter.paging.page = 1
    }
    const response = await usersService.findAllFilteredAsync(this.filter)
    this.users = response.items
    this.filter.paging.totalItems = response.totalItems
    this.loading = false
  }

  public openNew() {
    this.dialog.openNew(new User())
  }

  public openEdit(user: User) {
    this.dialog.openEdit(user)
  }

  public async impersonate(user: User) {
    const response = await authService.impersonate({
      email: user.email,
      password: '',
      rememberMe: false,
    })

    if (response) {
      if (response.isNotConfirmed) {
        snacks.warning(this.$tc('_users._impersonate.email_not_confirmed'))
      } else if (response.isDisabled || response.isLockedOut) {
        snacks.warning(this.$tc('_users._impersonate.account_disabled'))
      } else if (response.authenticated === false) {
        snacks.warning(this.$tc('_users._impersonate.cannot_log_in'))
      }
    }
  }

  public async create(user: User) {
    await accountService.createAsync(user)
    await this.fetchAll()
  }

  public async update(user: User) {
    await accountService.updateAsync(user)
    await this.fetchAll()
  }

  private async enable(user: User) {
    await accountService.enableAsync(user)
    await this.fetchAll()
  }

  private async disable(user: User) {
    await accountService.disableAsync(user)
    await this.fetchAll()
  }

  private async remove(user: User) {
    await accountService.removeAsync(user.id)
    await this.fetchAll()
  }
}

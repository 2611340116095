var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.groups, function(group) {
      return _c(
        "div",
        { key: group.id, staticClass: "mt-5" },
        [
          _c("h2", [_vm._v(_vm._s(group.number) + " - " + _vm._s(group.name))]),
          _c(
            "v-simple-table",
            { staticClass: "print-view__table", attrs: { dense: "" } },
            [
              _c("thead", [
                _c("tr", [
                  _c("th", { staticClass: "w-nr" }, [
                    _vm._v(_vm._s(_vm.$t("number_short")))
                  ]),
                  _c("th", { staticClass: "w-count" }, [
                    _vm._v(_vm._s(_vm.$t("pieces")))
                  ]),
                  _c("th", { staticClass: "w-size" }, [
                    _vm._v(_vm._s(_vm.$t("length")))
                  ]),
                  _c("th", { staticClass: "w-size" }, [
                    _vm._v(_vm._s(_vm.$t("width")))
                  ]),
                  _c("th", { staticClass: "w-size" }, [
                    _vm._v(_vm._s(_vm.$t("length2")))
                  ]),
                  _c("th", { staticClass: "w-size" }, [
                    _vm._v(_vm._s(_vm.$t("width2")))
                  ]),
                  _c("th", { staticClass: "w-material" }, [
                    _vm._v(_vm._s(_vm.$t("product")))
                  ]),
                  _c("th", { staticClass: "w-kanten" }, [
                    _vm._v(_vm._s(_vm.$t("profiles")))
                  ]),
                  _c("th", { staticClass: "w-material" }, [
                    _vm._v(_vm._s(_vm.$t("fu_upper")))
                  ]),
                  _c("th", { staticClass: "w-material" }, [
                    _vm._v(_vm._s(_vm.$t("fu_lower")))
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("title")))]),
                  _c("th", { staticClass: "w-position" }, [
                    _vm._v(_vm._s(_vm.$t("position")))
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("misc")))])
                ])
              ]),
              _c(
                "tbody",
                _vm._l(group.items, function(item) {
                  return _c("tr", { key: item.id }, [
                    _c("td", [
                      _vm._v(_vm._s(group.number) + "_" + _vm._s(item.sorting))
                    ]),
                    _c("td", [_vm._v(_vm._s(item.quantity))]),
                    _c("td", [_vm._v(_vm._s(_vm._f("num")(item.length, 1)))]),
                    _c("td", [_vm._v(_vm._s(_vm._f("num")(item.width, 1)))]),
                    _c("td", [_vm._v(_vm._s(_vm._f("num")(item.length2, 1)))]),
                    _c("td", [_vm._v(_vm._s(_vm._f("num")(item.width2, 1)))]),
                    _c("td", { staticClass: "text-left" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("product-avatar", {
                            staticClass: "ml-2 mr-2",
                            attrs: {
                              "image-path": item.product.imagePath,
                              size: "48",
                              zoom: "48"
                            }
                          }),
                          _c("div", [
                            item.product.number != item.product.name
                              ? _c("div", { staticClass: "text--disabled" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("get")(item, "product.number")
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e(),
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm._f("get")(item, "product.name"))
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ]),
                    _c("td", { staticClass: "text-left" }, [
                      _c("div", [
                        _c("strong", { staticClass: "mr-2" }, [_vm._v("V")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("get")(item, "frontEdgingProduct.name")
                            ) +
                            " "
                        )
                      ]),
                      _c("div", [
                        _c("strong", { staticClass: "mr-2" }, [_vm._v("H")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("get")(item, "backEdgingProduct.name")
                            ) +
                            " "
                        )
                      ]),
                      _c("div", [
                        _c("strong", { staticClass: "mr-2" }, [_vm._v("L")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("get")(item, "leftEdgingProduct.name")
                            ) +
                            " "
                        )
                      ]),
                      _c("div", [
                        _c("strong", { staticClass: "mr-2" }, [_vm._v("R")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("get")(item, "rightEdgingProduct.name")
                            ) +
                            " "
                        )
                      ]),
                      _c("div", [
                        _c("strong", { staticClass: "mr-2" }, [_vm._v("S")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("get")(item, "customEdgingProduct.name")
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(_vm._f("get")(item, "frontFup.name")))
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(_vm._f("get")(item, "backFup.name")))
                    ]),
                    _c("td", [_vm._v(_vm._s(item.name))]),
                    _c("td", [_vm._v(_vm._s(item.posName))]),
                    _c("td", [_vm._v(_vm._s(item.remarks))])
                  ])
                }),
                0
              )
            ]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
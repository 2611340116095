


























import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import Product from '@/products/entities/product'
import LogwoodService from '@/logwood/services/logwood-service'
import isString from 'lodash/isString'

@Component
export default class LogwoodSelect extends Vue {
  @Prop({ required: true })
  private value!: Product | string | null

  @Prop({ type: Boolean, default: false })
  private returnObject!: boolean

  private items: Product[] = []
  private product: Product | null = null

  @Watch('value')
  private valueChanged(
    val: Product | string | null,
    old: Product | string | null
  ) {
    this.product = this.loadProduct(val)
  }

  private loadProduct(val: Product | string | null) {
    if (val == null) {
      return null
    }

    if (isString(val)) {
      return this.items.find((i) => i.id === val) || null
    }

    return val
  }

  private filter(item: Product, queryText: string) {
    const itemText = item.name.toLowerCase() + ' ' + item.number
    const searchText = queryText.toLowerCase()
    return itemText.indexOf(searchText) > -1
  }

  private emitChange() {
    const val = this.returnObject ? this.product : this.product?.id || null
    this.$emit('input', val)
  }

  private async mounted() {
    this.items = await LogwoodService.loadProductsAsync()
    this.product = this.loadProduct(this.value)
  }
}

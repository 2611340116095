var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "485px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "min-height": "100%" } },
        [
          _c("v-card-title", { staticStyle: { height: "64px" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("_projects._create_templates_dialog.title")) +
                " "
            )
          ]),
          _c(
            "v-card-text",
            { staticClass: "flex-grow-1" },
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  rules: "required",
                                  name: _vm.$t("name")
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.$t("name"),
                                            outlined: "",
                                            dense: "",
                                            "error-messages": errors,
                                            "hide-details": "auto",
                                            hint: _vm.$t("required_field"),
                                            "persistent-hint": ""
                                          },
                                          model: {
                                            value: _vm.name,
                                            callback: function($$v) {
                                              _vm.name = $$v
                                            },
                                            expression: "name"
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-1",
                                  attrs: { small: "", depressed: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.dstPicker.openDialog()
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.dstFolderName) + " ")]
                              ),
                              _c("template-directory-picker", {
                                ref: "dstPicker",
                                attrs: {
                                  title: _vm.$t("pick_target_folder"),
                                  "only-folders": ""
                                },
                                on: { confirm: _vm.selectDstFolder }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-7 pb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { text: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    disabled: !_vm.valid,
                    depressed: "",
                    color: "primary"
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }




























import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator'
import Store from '@/stores/models/store'
import StoresService from '@/stores/services/stores-service'

@Component
export default class StoreSelect extends Vue {
  @Prop()
  private value!: Store | string | null

  @Prop({ default: () => [] })
  private excluded!: (Store | string)[]

  @Prop({ type: Boolean, default: false })
  private returnObject!: boolean

  @Prop({ default: 25 })
  private ellipsis!: number

  @Ref()
  private validator!: ValidationObserver

  private search: string = ''
  private loading: boolean = false
  private stores: Store[] = []
  private store: Store | null = null

  @Watch('value')
  private async valueChanged(val: Store | string) {
    await this.prefetchAsync(val)
  }

  @Watch('search')
  private async searchChanged(val: string, old: string) {
    if (val && val !== old) {
      await this.loadAsync()
    }
  }

  private async prefetchAsync(value: Store | string) {
    if (value == null) {
      this.store = null
      return
    }
    const store =
      typeof value === 'string' || value instanceof String
        ? await StoresService.findOneAsync(value as string)
        : value

    if (store) {
      if (this.stores.findIndex((c) => c.id === store.id) === -1) {
        this.stores.push(store)
      }
      this.store = store
      this.emitChange()
    }
  }

  private async loadAsync() {
    this.loading = true
    const excluded = this.excluded.map((c) => (c instanceof Store ? c.id : c))
    const stores = await StoresService.findAllAsync(this.search)
    this.stores = stores.filter((c) => excluded.includes(c.id) === false)
    this.loading = false
  }

  private async emitChange() {
    this.$emit('input', this.returnObject ? this.store : this.store?.id)
    this.$emit('input:object', this.store)
  }

  private emitClear() {
    this.$emit('input', null)
    this.$emit('input:object', null)
  }

  private async mounted() {
    await this.loadAsync()
    if (this.value) {
      await this.prefetchAsync(this.value)
    }
  }
}

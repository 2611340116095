export function extractHeader(csv: string | null | undefined) {

  if (!csv) {
    return []
  }

  const lines = csv.split('\n', 1)

  if (lines.length === 0) {
    return []
  }

  const header = lines[0]
  const columns = header.split(';')

  if (columns.length === 0) {
    return []
  }

  return columns.map(c => c.trim())
}
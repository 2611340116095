










































import { Component, Ref, Vue } from 'vue-property-decorator'
import LogwoodService from '@/logwood/services/logwood-service'
import LogwoodPackage from '@/logwood/models/logwood-package'
import {
  LogwoodPackageKey,
  LogwoodPackageState,
} from '@/logwood/models/logwood-package-state'
import snacks from '@/app/services/snacks'

@Component
export default class LogwoodScan extends Vue {
  @Ref()
  private validator!: ValidationObserver

  private loading = false
  private text = ''
  private packs: LogwoodPackage[] = []

  private outerBearingState = LogwoodPackageState.OuterBearing
  private dryingChamberState = LogwoodPackageState.DryingChamber
  private dryBearingState = LogwoodPackageState.DryBearing

  private get hasOuterBearingStatePacks() {
    return this.packs.some((p) => p.state == this.outerBearingState)
  }

  private get hasDryingChamberStatePacks() {
    return this.packs.some((p) => p.state == this.dryingChamberState)
  }

  private get hasDryBearingStatePacks() {
    return this.packs.some((p) => p.state == this.dryBearingState)
  }

  private async scanAsync(text: string) {
    const pack = await LogwoodService.findPackageAsync(text)

    if (pack) {
      if (this.packs.every((p) => p.number !== pack.number)) {
        this.packs.push(pack)
        this.text = ''
      }
    }
  }

  private async changeStateAsync(pack: LogwoodPackage, key: LogwoodPackageKey) {
    const res = await LogwoodService.updatePackageStateAsync(pack, key)

    if (res) {
      pack.state = key.state
      pack.location = key.location
    }

    if (key.state === LogwoodPackageState.Consumed) {
      snacks.success(this.$tc('_logwood._logwood-scan.consumed'))
    } else {
      Vue.set(pack, '_state', res.success)
      setTimeout(() => Vue.set(pack, '_state', null), 2000)
    }
  }

  private async removeAsync(pack: LogwoodPackage) {
    await LogwoodService.removePackageAsync(pack)
    snacks.success(this.$tc('_logwood._logwood-scan.removed'))
    this.packs = this.packs.filter((p) => p.id !== pack.id)
  }
}

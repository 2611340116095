var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "min-height": "100%" } },
        [
          _c("v-card-title", { staticStyle: { height: "64px" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("_projects._project_group_templates_dialog.title")
                ) +
                " "
            )
          ]),
          _c(
            "v-card-text",
            { staticClass: "flex-grow-1" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0 pl-0", attrs: { cols: "auto" } },
                        [
                          _c("project-group-template-directory-tree", {
                            ref: "directoryTree",
                            staticClass: "fill-height",
                            on: {
                              select: _vm.loadTemplate,
                              "select:dblclick": _vm.quickConfirm
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c("v-col", { staticClass: "pa-0" }, [
                        _vm.template
                          ? _c(
                              "div",
                              { staticClass: "fill-height pl-4" },
                              [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "container-toolbar",
                                    attrs: { flat: "" }
                                  },
                                  [
                                    _c("h2", [
                                      _vm._v(_vm._s(_vm.template.name))
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          icon: "",
                                          title: _vm.$t("delete"),
                                          tabindex: "-1"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.deleteTemplate(
                                              _vm.template
                                            )
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v("delete")])],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-simple-table",
                                  {
                                    staticClass: "template-preview-table",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { staticClass: "w-count" }, [
                                          _vm._v(_vm._s(_vm.$t("pieces")))
                                        ]),
                                        _c("th", { staticClass: "w-size" }, [
                                          _vm._v(_vm._s(_vm.$t("length")))
                                        ]),
                                        _c("th", { staticClass: "w-size" }, [
                                          _vm._v(_vm._s(_vm.$t("width")))
                                        ]),
                                        _c("th", { staticClass: "w-size" }, [
                                          _vm._v(_vm._s(_vm.$t("length2")))
                                        ]),
                                        _c("th", { staticClass: "w-size" }, [
                                          _vm._v(_vm._s(_vm.$t("width2")))
                                        ]),
                                        _c(
                                          "th",
                                          { staticClass: "w-material" },
                                          [_vm._v(_vm._s(_vm.$t("product")))]
                                        ),
                                        _c("th", { staticClass: "w-kanten" }, [
                                          _vm._v(_vm._s(_vm.$t("profiles")))
                                        ]),
                                        _c(
                                          "th",
                                          { staticClass: "w-material" },
                                          [_vm._v(_vm._s(_vm.$t("fu_upper")))]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "w-material" },
                                          [_vm._v(_vm._s(_vm.$t("fu_lower")))]
                                        ),
                                        _c("th", [
                                          _vm._v(_vm._s(_vm.$t("title")))
                                        ]),
                                        _c(
                                          "th",
                                          { staticClass: "w-position" },
                                          [_vm._v(_vm._s(_vm.$t("position")))]
                                        ),
                                        _c("th", [
                                          _vm._v(_vm._s(_vm.$t("misc")))
                                        ])
                                      ])
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.items, function(item) {
                                        return _c("tr", { key: item.id }, [
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.quantity) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.length) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.width) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.length2) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.width2) + " "
                                              )
                                            ]
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.product
                                                    ? item.product.name
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-no-wrap",
                                                staticStyle: {
                                                  "font-size": "75%"
                                                }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "profile-heading"
                                                    },
                                                    [_vm._v("V")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.frontEdgingProduct
                                                          ? item
                                                              .frontEdgingProduct
                                                              .name
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "profile-heading"
                                                    },
                                                    [_vm._v("H")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.backEdgingProduct
                                                          ? item
                                                              .backEdgingProduct
                                                              .name
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "profile-heading"
                                                    },
                                                    [_vm._v("L")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.leftEdgingProduct
                                                          ? item
                                                              .leftEdgingProduct
                                                              .name
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "profile-heading"
                                                    },
                                                    [_vm._v("R")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.rightEdgingProduct
                                                          ? item
                                                              .rightEdgingProduct
                                                              .name
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "profile-heading"
                                                    },
                                                    [_vm._v("S")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.customEdgingProduct
                                                          ? item
                                                              .customEdgingProduct
                                                              .name
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ]
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.frontFup
                                                    ? item.frontFup.name
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.backFup
                                                    ? item.backFup.name
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " + _vm._s(item.posName) + " "
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " + _vm._s(item.remarks) + " "
                                            )
                                          ])
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-7 pb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { text: "" },
                  on: { click: _vm.close }
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    depressed: "",
                    color: "primary"
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("select")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
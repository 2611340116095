var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "" } },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-layout",
            {
              attrs: {
                "align-center": "",
                "justify-center": "",
                "fill-height": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs11: "", md6: "", lg4: "" } },
                [
                  _vm.showResetForm
                    ? _c("reset-form", {
                        staticClass: "elevation-10 pa-5 mx-sm-10",
                        on: {
                          reset: function($event) {
                            _vm.showResetForm = false
                          },
                          cancel: function($event) {
                            _vm.showResetForm = false
                          }
                        }
                      })
                    : [
                        _c("login-form", {
                          staticClass: "elevation-10 pa-5 mx-sm-10"
                        }),
                        _c(
                          "v-row",
                          { staticClass: "mx-sm-10 mt-5" },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      type: "button",
                                      text: "",
                                      block: "",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.showResetForm = true
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("_account.forgot_password")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              [
                                _vm.$env.VUE_APP_ALLOW_REGISTRATION !== "no"
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          to: {
                                            name: "register",
                                            params: { lang: _vm.$i18n.locale }
                                          },
                                          type: "button",
                                          text: "",
                                          block: "",
                                          small: ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("_account.register")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
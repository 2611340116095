










import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import moment, { Moment } from 'moment'

@Component
export default class XMoneyInput extends Vue {
  @Prop()
  private value!: number | null

  @Prop({ type: String, default: '€' })
  private currency!: string

  @Prop({ type: Number, default: 2 })
  private fractionalDigits!: number

  private amount = this.value

  @Watch('value')
  private valueChanged(val: number, old: number) {
    if (val !== old) {
      this.amount = val
    }
  }

  @Watch('amount')
  private amountChanged(val: number, old: number) {
    if (val !== old) {
      this.$emit('input', val)
    }
  }
}
































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LogwoodPackage from '@/logwood/models/logwood-package'
import {
  LogwoodPackageKey,
  LogwoodPackageState,
} from '@/logwood/models/logwood-package-state'

@Component
export default class LogwoodScanTable extends Vue {
  @Prop({ default: () => [] })
  private value!: LogwoodPackage[]

  @Prop({ default: () => LogwoodPackageState.OuterBearing })
  private state!: LogwoodPackageState

  private packs: LogwoodPackage[] = this.value

  private get filteredPacks() {
    return this.packs.filter((p) => this.state === p.state)
  }

  @Watch('value', { deep: true })
  private valueChanged(val: LogwoodPackage[]) {
    this.packs = val
  }

  private successMessage(pack: any) {
    return pack._state === true
      ? this.$tc('_logwood._logwood-scan-table.success')
      : ''
  }

  private errorMessage(pack: any) {
    return pack._state === false
      ? this.$tc('_logwood._logwood-scan-table.error')
      : ''
  }

  private isDryBearingState(pack: LogwoodPackage) {
    return (
      pack.state === LogwoodPackageState.DryBearing &&
      pack.dryingBearingOn !== null
    )
  }

  private emitStateChange(pack: LogwoodPackage, key: LogwoodPackageKey) {
    this.$emit('change:state', pack, key)
  }

  private emitConsume(pack: LogwoodPackage) {
    if (confirm(this.$tc('_logwood._logwood-scan-table.confirm_consume'))) {
      this.emitStateChange(pack, {
        state: LogwoodPackageState.Consumed,
        location: pack.location,
      })
    }
  }

  private emitRemove(pack: LogwoodPackage) {
    if (confirm(this.$tc('_logwood._logwood-scan-table.confirm_remove'))) {
      this.$emit('remove', pack)
    }
  }
}

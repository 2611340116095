











import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class XNumberInput extends Vue {
  @Prop()
  private value!: number | null

  @Prop({ type: Number, default: 0 })
  private fractionalDigits!: number

  private focus = false
  private display = this.displayValue(this.value)

  @Watch('value')
  private valueChanged(val: number, old: number) {
    if (!this.focus && val !== old) {
      this.display = this.displayValue(val)
    }
  }

  @Watch('display')
  private displayChanged(val: string, old: string) {
    if (val !== old) {
      this.$emit('input', this.$fmt.parseNum(val))
    }
  }

  private onFocus() {
    this.focus = true
    this.display = this.inputValue(this.value)
    this.$nextTick(this.selectText)
  }

  private selectText() {
    const textField = this.$refs['input'] as Vue
    const textElem = textField.$el
    const inputs = textElem.getElementsByTagName('input')

    if (inputs.length > 0) {
      inputs[0].select()
    }
  }

  private onBlur() {
    this.focus = false
    this.display = this.displayValue(this.value)
  }

  private displayValue(value: number | null) {
    return this.$fmt.num(value, this.fractionalDigits)
  }

  private inputValue(value: number | null) {
    return this.$fmt.inputNum(value, this.fractionalDigits)
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          attrs: { items: _vm.items },
          model: {
            value: _vm.item,
            callback: function($$v) {
              _vm.item = $$v
            },
            expression: "item"
          }
        },
        "v-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
















































































import { Component, Ref, Vue, Watch, Prop } from 'vue-property-decorator'
import productsService from '@/products/services/products-service'
import Product from '@/products/entities/product'
import Guid from '@/app/utils/guid'
import snacks from '@/app/services/snacks'
import moment from 'moment'
import ProductDirectoryTree from '@/products/components/product-directory-tree.vue'

@Component
export default class Products extends Vue {
  @Prop()
  private productIdOrNumber!: string

  private product: Product | null = null
  private fab = false

  @Ref()
  private productDirectoryTree!: ProductDirectoryTree

  private createProduct(folderId: string) {
    this.product = new Product()
    this.product.directoryId = folderId
    this.activateProduct()
  }

  private async loadProductAsync(id: string) {
    if (id !== Guid.empty()) {
      this.product = await productsService.findOneAsync(id)
    }
  }

  private async saveAsync() {
    if (this.product) {
      if (this.product.id === Guid.empty()) {
        this.product = await productsService.createAsync(this.product)
        await this.productDirectoryTree.reloadAsync()
        this.activateProduct()
        snacks.success(this.$tc('product_created'))
      } else {
        this.product = await productsService.updateAsync(this.product)
        snacks.success(this.$tc('saved_changed'))
      }
    }
  }

  private activateProduct() {
    if (this.product?.id) {
      this.productDirectoryTree.activate(this.product.id)
    }
  }

  private async duplicateAsync() {
    if (this.product && confirm(this.$tc('ask_product_duplicated'))) {
      let newProduct = <Product | null>JSON.parse(JSON.stringify(this.product))
      if (newProduct) {
        newProduct.id = Guid.empty()
        newProduct.name = `${newProduct.name}_${moment().format('X')}`
        newProduct.number = `${newProduct.number}_${moment().format('X')}`
        newProduct.imagePath = null
        newProduct = await productsService.createAsync(newProduct)
        await this.productDirectoryTree.reloadAsync()
      }
    }
  }

  private async deleteAsync() {
    if (this.product && confirm(this.$tc('ask_product_deleted'))) {
      await productsService.deleteAsync(this.product.id)
      await this.productDirectoryTree.reloadAsync()
      this.product = null
    }
  }

  private async mounted() {
    if (this.productIdOrNumber) {
      await this.loadProductAsync(this.productIdOrNumber)
    }
  }
}

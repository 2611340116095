



















import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import PaginationRequest from '@/common/pagination/entities/pagination-request'
import Store from '@/stores/models/store'
import StoresService from '@/stores/services/stores-service'
import StoreFilter from '@/stores/models/store-filter'
import snacks from '@/app/services/snacks'
import isEqual from 'lodash/isEqual'
import StoreDialog from '@/stores/components/store-dialog.vue'

@Component
export default class Users extends Vue {
  @Ref()
  private dialog!: StoreDialog

  private loading: boolean = false
  private stores: Store[] = []
  private filter = new StoreFilter()

  @Watch('filter.paging')
  private async filterPagingChanged(
    val: PaginationRequest,
    old: PaginationRequest
  ) {
    if (isEqual(val, old) === false) {
      await this.fetchAll()
    }
  }

  private async fetchAll(resetPage = false) {
    this.loading = true
    if (resetPage) {
      this.filter.paging.page = 1
    }
    const response = await StoresService.findAllFilteredAsync(this.filter)
    this.stores = response.items
    this.filter.paging.totalItems = response.totalItems
    this.loading = false
  }

  private openNew() {
    this.dialog.openNew(new Store())
  }

  private openEdit(store: Store) {
    this.dialog.openEdit(store)
  }

  private async create(store: Store) {
    await StoresService.saveAsync(store)
    await this.fetchAll()
  }

  private async update(store: Store) {
    await StoresService.saveAsync(store)
    await this.fetchAll()
  }

  private async remove(store: Store) {
    if (confirm(this.$tc('_stores.confirm_store_delete'))) {
      await StoresService.removeAsync(store)
      await this.fetchAll()
    }
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.sendResetLink($event)
        }
      }
    },
    [
      _c("h3", { staticClass: "mb-5" }, [
        _vm._v(_vm._s(_vm.$t("_account.forgot_password")))
      ]),
      _c("p", [_vm._v(_vm._s(_vm.$t("_users._reset_form.subtitle")))]),
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("validation-provider", {
                          attrs: {
                            rules: "required|email",
                            name: _vm.$t("email")
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("email"),
                                        outlined: "",
                                        "error-messages": errors,
                                        "hide-details": errors.length === 0,
                                        autocomplete: "off",
                                        autocorrect: "off",
                                        autocapitalize: "none",
                                        spellcheck: "false"
                                      },
                                      model: {
                                        value: _vm.email,
                                        callback: function($$v) {
                                          _vm.email =
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                        },
                                        expression: "email"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { type: "button", text: "", block: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              type: "submit",
                              loading: _vm.loading,
                              disabled: invalid,
                              depressed: "",
                              block: "",
                              color: "primary"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("_users._reset_form.send_reset_link")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
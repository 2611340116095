export enum ProductCategory {
  Sonstiges = 0,
  MaterialPlatten = 1,
  MaterialKanten = 2,
  Massivholz = 3,
  FurnierBelegung = 4,
  Anleimer = 5,
  Beschlaege = 6,
  ZukaufWerkstatt = 7,
  ZukaufHandel = 8,
  Lagerartikel = 9,
  WerkstattArtikel = 10,
  Montagematerial = 11,
  Werkzeug = 12,
  Buerobedarf = 13,
  Lebensmittel = 14,
  Rundholz = 15,
  Schaerfen = 16,
  Bekleidung = 17,
  Oberflaeche = 18,
  Furnierkante = 19,
  Verpackung = 20,
  InstandhaltungReparatur = 21,
  Moebelfertigteile = 22,
}

import Address from '@/locations/models/address'
import Entity from '@/common/models/entity'

export default class User extends Entity {
  public userName = ''
  public email = ''
  public tenantId: string | null = null
  public role = 'USER'
  public salutation = 'Mrs'
  public title = 'Mag'
  public firstName = ''
  public lastName = ''
  public shortCode = ''
  public address = new Address()
  public isDeleted = false
  public remarks = ''
  public password = ''
  public sendOrdersDirectly = false
  public notifyIfOrdersDelivered = false
}

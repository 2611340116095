import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import FileEntry from '@/common/models/fileEntry'
import PaginationResponse from '@/common/pagination/entities/pagination-response'
import CrudService from '@/common/services/crud-service'
import Order from '../entities/order'
import OrderFilter from '../entities/order-filter'
import ProductOrderItem from '../entities/product-order-item'

class OrdersService extends CrudService<Order> {
  public async findAllFilteredAsync(request: OrderFilter) {
    const res = await axios.post<PaginationResponse<Order>>(
      `orders/filter`,
      request
    )
    return Responses.pagedList(res)
  }

  public async findTotalAsync(request: OrderFilter) {
    const res = await axios.post<number>(
      `orders/total`,
      request
    )
    return Responses.obj(res)
  }

  public async findAllLateAsync() {
    const res = await axios.get<Order[]>(`orders/late`)
    return Responses.list(res)
  }

  public async findAllOrderedProductNumbersAsync(projectId: string) {
    const res = await axios.get<ProductOrderItem[]>(`orders/orderedProductNumbers?projectId=${projectId}`)
    return Responses.list(res)
  }

  public async createOrdersAsync(projectId: string, projectItemIds: string[]) {
    const res = await axios.post<Order[]>(`orders/createFromProject`, {
      projectId: projectId,
      projectItemIds: projectItemIds,
    })
    return Responses.list(res)
  }

  public async loadByProjectAsync(projectId: string) {
    const res = await axios.get<Order[]>(`orders/byProject?projectId=${projectId}`)
    return Responses.list(res)
  }

  public async uploadAsync(orderId: string, file: File) {
    const formData = new FormData()
    formData.append('id', orderId)
    formData.append('file', file, file.name)
    await axios.post(`orders/${orderId}/attachment`, formData)
  }

  public async findAllAttachmentsAsync(orderId: string) {
    const res = await axios.get<FileEntry[]>(`orders/${orderId}/attachments`)
    return Responses.list(res)
  }

  public async deleteAttachmentAsync(orderId: string, fileName: string) {
    const res = await axios.post(`orders/${orderId}/deleteAttachment`, {
      id: orderId,
      fileName: fileName,
    })
  }
}

export default new OrdersService('orders')

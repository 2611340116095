






import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { OrderStatus } from '@/orders/entities/order'
import Order from '@/orders/entities/order'

@Component
export default class OrderStatusDisplay extends Vue {
  @Prop()
  private value!: OrderStatus

  get statusList() {
      return Order.statusList
  }

  statusColor(status: OrderStatus) {
    return Order.statusColor(status)
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "container-toolbar", attrs: { flat: "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                to: {
                  name: "parts-list",
                  params: { projectId: _vm.project.id, lang: _vm.$i18n.locale }
                },
                depressed: "",
                small: ""
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("_projects._partlist_edit.goto")) + " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.suppliers.length == 0
                ? _c(
                    "em",
                    [
                      _vm._v(" Noch keine Bestellungen vorhanden. Über "),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "parts-list",
                              params: {
                                projectId: _vm.project.id,
                                lang: _vm.$i18n.locale
                              }
                            }
                          }
                        },
                        [_vm._v(" die Stückliste ")]
                      ),
                      _vm._v(" können Bestellungen generiert werden. ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.groupMode == 0
                ? _c(
                    "v-expansion-panels",
                    { key: "1", attrs: { multiple: "" } },
                    _vm._l(_vm.suppliers, function(supplierNumber) {
                      return _c(
                        "v-expansion-panel",
                        { key: supplierNumber },
                        [
                          _c("v-expansion-panel-header", [
                            supplierNumber
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(" " + _vm._s(supplierNumber) + " "),
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ml-2",
                                        class: {
                                          error:
                                            _vm.supplierOrdersByStatus(
                                              supplierNumber,
                                              0
                                            ).length > 0
                                        },
                                        attrs: { small: "" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.supplierOrders(supplierNumber)
                                                .length
                                            ) +
                                            " gesamt, " +
                                            _vm._s(
                                              _vm.supplierOrdersByStatus(
                                                supplierNumber,
                                                0
                                              ).length
                                            ) +
                                            " offen "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !supplierNumber
                              ? _c(
                                  "span",
                                  [
                                    _c("em", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "_orders._project_orders.missing_supplier"
                                          )
                                        )
                                      )
                                    ]),
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ml-2",
                                        class: {
                                          error:
                                            _vm.supplierOrdersByStatus(
                                              supplierNumber,
                                              0
                                            ).length > 0
                                        },
                                        attrs: { small: "" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.supplierOrders(supplierNumber)
                                                .length
                                            ) +
                                            " gesamt, " +
                                            _vm._s(
                                              _vm.supplierOrdersByStatus(
                                                supplierNumber,
                                                0
                                              ).length
                                            ) +
                                            " offen "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("v-data-table", {
                                attrs: {
                                  headers: _vm.bySupplierHeaders,
                                  items: _vm.supplierOrders(supplierNumber),
                                  "no-data-text": _vm.$t("no_data_available"),
                                  "disable-pagination": "",
                                  "hide-default-footer": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.orderDate",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("dateTime")(
                                                  item.orderDate
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.expectedDeliveryDate",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("order-delivery-date-display", {
                                            model: {
                                              value: item.expectedDeliveryDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "expectedDeliveryDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.expectedDeliveryDate"
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.status",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("order-status-display", {
                                            model: {
                                              value: item.status,
                                              callback: function($$v) {
                                                _vm.$set(item, "status", $$v)
                                              },
                                              expression: "item.status"
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.action",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "text-no-wrap" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    icon: "",
                                                    title: "Details"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.details(item)
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [_vm._v("edit")])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    icon: "",
                                                    title: "Löschen"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteAsync(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("delete")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("order-details-dialog", {
        ref: "orderDetailsDialog",
        on: { confirm: _vm.loadAsync }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("order-details-dialog", {
        ref: "orderDetailsDialog",
        on: { confirm: _vm.fetchAllAsync }
      }),
      _c(
        "v-toolbar",
        { staticClass: "container-toolbar", attrs: { flat: "" } },
        [
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("open"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAllAsync },
            model: {
              value: _vm.isOpen,
              callback: function($$v) {
                _vm.isOpen = $$v
              },
              expression: "isOpen"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("ordered"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAllAsync },
            model: {
              value: _vm.isOrdered,
              callback: function($$v) {
                _vm.isOrdered = $$v
              },
              expression: "isOrdered"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("ordered_online"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAllAsync },
            model: {
              value: _vm.isOrderedOnline,
              callback: function($$v) {
                _vm.isOrderedOnline = $$v
              },
              expression: "isOrderedOnline"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("in_stock"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAllAsync },
            model: {
              value: _vm.isInStock,
              callback: function($$v) {
                _vm.isInStock = $$v
              },
              expression: "isInStock"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("delivered"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAllAsync },
            model: {
              value: _vm.isDelivered,
              callback: function($$v) {
                _vm.isDelivered = $$v
              },
              expression: "isDelivered"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("partially_delivered"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAllAsync },
            model: {
              value: _vm.isPartiallyDelivered,
              callback: function($$v) {
                _vm.isPartiallyDelivered = $$v
              },
              expression: "isPartiallyDelivered"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("closed"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAllAsync },
            model: {
              value: _vm.isClosed,
              callback: function($$v) {
                _vm.isClosed = $$v
              },
              expression: "isClosed"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("cancelled"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAllAsync },
            model: {
              value: _vm.isCancelled,
              callback: function($$v) {
                _vm.isCancelled = $$v
              },
              expression: "isCancelled"
            }
          })
        ],
        1
      ),
      _c(
        "v-toolbar",
        { staticClass: "container-toolbar", attrs: { flat: "" } },
        [
          _c("period-input", {
            staticClass: "mr-2",
            attrs: { outlined: "", dense: "", "hide-details": "auto" },
            on: {
              input: function($event) {
                return _vm.fetchAllAsync()
              }
            },
            model: {
              value: _vm.period,
              callback: function($$v) {
                _vm.period = $$v
              },
              expression: "period"
            }
          }),
          _c("v-text-field", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce:input",
                value: 500,
                expression: "500",
                arg: "input"
              }
            ],
            attrs: {
              label: _vm.$t("search") + " (" + _vm.$t("search_example") + ")",
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: "",
              "append-icon": "search"
            },
            on: { input: _vm.resetPageAndFetchAllAsync },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-data-table", {
                attrs: {
                  loading: _vm.loading,
                  headers: _vm.headers,
                  items: _vm.orders,
                  options: _vm.pagination,
                  "server-items-length": _vm.pagination.totalItems,
                  "footer-props": {
                    itemsPerPageText: _vm.$t("items_per_page")
                  },
                  "no-data-text": _vm.$t("no_data_available")
                },
                on: {
                  "update:options": [
                    function($event) {
                      _vm.pagination = $event
                    },
                    function($event) {
                      return _vm.fetchAllAsync()
                    }
                  ]
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.orderDate",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("dateTime")(item.orderDate)) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.expectedDeliveryDate",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("order-delivery-date-display", {
                            model: {
                              value: item.expectedDeliveryDate,
                              callback: function($$v) {
                                _vm.$set(item, "expectedDeliveryDate", $$v)
                              },
                              expression: "item.expectedDeliveryDate"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.status",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("order-status-display", {
                            model: {
                              value: item.status,
                              callback: function($$v) {
                                _vm.$set(item, "status", $$v)
                              },
                              expression: "item.status"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.price",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("money")(item.price)) + " "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.action",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "text-no-wrap" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-1",
                                  attrs: { icon: "", title: "Details" },
                                  on: {
                                    click: function($event) {
                                      return _vm.detailsAsync(item)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("edit")])],
                                1
                              ),
                              _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        icon: "",
                                        title: _vm.$t("delete")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteAsync(item)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("delete")])],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "body.append",
                      fn: function() {
                        return [
                          _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
                            ? _c("tr", [
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td"),
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-right font-size-85 font-weight-bold py-2"
                                    },
                                    [
                                      _c("span", { staticClass: "mr-3" }, [
                                        _vm._v(_vm._s(_vm.$t("total")))
                                      ]),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm._f("money")(_vm.sum)))
                                      ])
                                    ]
                                  )
                                ]),
                                _c("td")
                              ])
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.role
    ? _c("v-chip", { attrs: { small: "", outlined: "" } }, [
        _vm._v(_vm._s(_vm.roleName))
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Dates from '@/app/utils/dates'
import moment from 'moment'
import Vue from 'vue'

Vue.filter('moment', (v: moment.MomentInput) => {
  return moment(v)
})

Vue.filter('weekday', (v: moment.MomentInput) => {
  return v ? Dates.getWeekdayName(v) : ''
})

Vue.filter('day', (v: moment.MomentInput) => {
  return v ? Dates.day(v) : ''
})

Vue.filter('date', (v: moment.MomentInput) => {
  return v ? Dates.date(v) : ''
})

Vue.filter('isoDate', (v: moment.MomentInput) => {
  return v ? Dates.isoDate(v) : ''
})

Vue.filter('dateTime', (v: moment.MomentInput) => {
  return v ? Dates.dateTime(v) : ''
})

Vue.filter('time', (v: moment.MomentInput, seconds: boolean = false) => {
  return v ? Dates.time(v, seconds) : ''
})

Vue.filter('calendar', (v: moment.MomentInput) => {
  return v ? moment(v).calendar() : ''
})

Vue.filter('week', (v: moment.MomentInput) => {
  return v ? moment(v).isoWeek() : ''
})

Vue.filter('month', (v: moment.MomentInput) => {
  return v ? Dates.month(v) : ''
})

Vue.filter('year', (v: moment.MomentInput) => {
  return v ? Dates.year(v) : ''
})
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("correction-list-export-dialog", {
        ref: "correctionListExportDialog"
      }),
      _c("join-companies-dialog", {
        ref: "joinCompaniesDialog",
        on: {
          confirm: function($event) {
            return _vm.$emit("refresh")
          }
        }
      }),
      _c("v-data-table", {
        staticClass: "elevation-0",
        attrs: {
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.companies,
          options: _vm.pPagination,
          "server-items-length": _vm.pPagination.totalItems,
          "footer-props": { itemsPerPageText: _vm.$t("items_per_page") },
          "no-data-text": _vm.$t("no_data_available")
        },
        on: {
          "update:options": function($event) {
            _vm.pPagination = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(item.name) + " ")]),
                  item.isWarehouse
                    ? _c(
                        "v-chip",
                        { staticClass: "ma-2 px-1", attrs: { "x-small": "" } },
                        [
                          _c("v-icon", { attrs: { "x-small": "" } }, [
                            _vm._v("inventory")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("tenancy-chip", { attrs: { "tenant-id": item.tenantId } })
                ]
              }
            },
            {
              key: "item.address",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("address-line", { attrs: { address: item.address } })
                ]
              }
            },
            {
              key: "item.type",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.isManufacturer
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "mr-1",
                          attrs: { small: "", outlined: "" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("manufacturer")) + " ")]
                      )
                    : _vm._e(),
                  item.isSupplier
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "mr-1",
                          attrs: { small: "", outlined: "" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("supplier")) + " ")]
                      )
                    : _vm._e(),
                  item.isWarehouse
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "mr-1",
                          attrs: { small: "", outlined: "" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("warehouse")) + " ")]
                      )
                    : _vm._e(),
                  item.isCustomer
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "mr-1",
                          attrs: { small: "", outlined: "" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("customer")) + " ")]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.status",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.isEnabled
                    ? _c(
                        "v-chip",
                        { attrs: { small: "", outlined: "", color: "green" } },
                        [_vm._v(_vm._s(_vm.$t("active")))]
                      )
                    : _vm._e(),
                  !item.isEnabled
                    ? _c(
                        "v-chip",
                        { attrs: { small: "", outlined: "", color: "red" } },
                        [_vm._v(_vm._s(_vm.$t("inactive")))]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    { staticClass: "text-no-wrap" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { icon: "", title: _vm.$t("contact_persons") },
                          on: {
                            click: function($event) {
                              return _vm.editContacts(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("supervisor_account")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { icon: "", title: _vm.$t("edit") },
                          on: {
                            click: function($event) {
                              return _vm.edit(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("edit")])],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              title: _vm.$t("more"),
                                              icon: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("more_vert")])],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              item.isManufacturer || item.isSupplier
                                ? [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.correctionListExportDialog.open(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          [
                                            _c("v-icon", [
                                              _vm._v("cleaning_services")
                                            ])
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("export_correction_list")
                                              ) +
                                              "... "
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    item.isSupplier
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.exportPriceList(item)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-avatar",
                                              [
                                                _c("v-icon", [
                                                  _vm._v("price_check")
                                                ])
                                              ],
                                              1
                                            ),
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("export_price_list")
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("v-divider")
                                  ]
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.joinCompaniesDialog.open(item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [_c("v-icon", [_vm._v("call_merge")])],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("merge_companies")) +
                                        "... "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteCompany(item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [_c("v-icon", [_vm._v("delete")])],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("delete_company")) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
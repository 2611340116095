import { render, staticRenderFns } from "./academic-title-select.vue?vue&type=template&id=023476c4&"
import script from "./academic-title-select.vue?vue&type=script&lang=ts&"
export * from "./academic-title-select.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('023476c4')) {
      api.createRecord('023476c4', component.options)
    } else {
      api.reload('023476c4', component.options)
    }
    module.hot.accept("./academic-title-select.vue?vue&type=template&id=023476c4&", function () {
      api.rerender('023476c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/users/components/academic-title-select.vue"
export default component.exports
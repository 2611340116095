import Roles from '@/users/models/roles'
import authService from '@/users/services/auth-service'
import Vue from 'vue'
import { PluginObject, VueConstructor } from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $auth: Permissions
  }
}

class Permissions {
  get authenticated() {
    return authService.authenticated()
  }

  get user() {
    return authService.user()
  }

  get tenant() {
    return authService.tenant()
  }

  get isAdmin() {
    return this.isInRole(Roles.Admin)
  }

  get isTenant() {
    return this.isInRole(Roles.Tenant)
  }

  public isInRole(role: string) {
    return authService.isInRole(role)
  }

  public isNotInRole(role: string) {
    return this.isInRole(role) === false
  }

  public isInAnyRole(roles: string[]) {
    return authService.isInAnyRole(roles)
  }

  public isInNeitherRole(roles: string[]) {
    return this.isInAnyRole(roles) === false
  }
}

// tslint:disable-next-line: max-classes-per-file
class VuePermissionsPlugin implements PluginObject<any> {
  public install(vue: VueConstructor, options?: any): void {
    vue.prototype.$auth = new Permissions()
  }
}

Vue.use(new VuePermissionsPlugin())























































































































































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import productDirectoriesService from '@/products/services/product-directories-service'
import ProductsImportDialog from '@/products/components/products-import-dialog.vue'
import MoveDirectoryDialog from '@/products/components/move-directory-dialog.vue'
import ProductDirectoryFilter from '@/products/entities/product-directory-filter'
import cloneDeep from 'lodash/cloneDeep'
import { getModule } from 'vuex-module-decorators'
import AuthModule from '@/users/store/auth-module'
import { ProductCategory } from '@/products/entities/product-category'
import ApplySupplierMultiplierDialog from '@/products/components/apply-supplier-multiplier-dialog.vue'

@Component
export default class ProductDirectoryTree extends Vue {
  @Prop({ type: Boolean, default: false })
  private editDisabled!: boolean

  @Prop({ type: Array, default: () => [] })
  private lastSelectedNumbers!: string[]

  @Prop({ default: () => [] })
  private categories!: ProductCategory[]

  @Prop({ type: Boolean, default: false })
  private pruned!: boolean

  @Ref()
  private treeView: any

  @Ref()
  private productsImportDialog!: ProductsImportDialog

  @Ref()
  private moveDirectoryDialog!: MoveDirectoryDialog

  @Ref()
  private applySupplierMultiplierDialog!: ApplySupplierMultiplierDialog

  private loading: boolean = false
  private items: DirectoryTreeItem[] = []
  private open: string[] = []
  private all: string[] = []
  private active: string[] = []
  private tree = []

  private search = ''
  private showSearch = false

  private menuVisible = false
  private articleMenuVisible = false
  private mouseX = 0
  private mouseY = 0
  private selectedFolder: DirectoryTreeItem | null = null
  private selectedItem: DirectoryTreeItem | null = null
  private filter = new ProductDirectoryFilter()

  private isDeleting = false

  @Watch('active')
  private activeChanged(val: any[]) {
    this.select()
  }

  @Watch('filter', { deep: true })
  private async filterChangedAsync() {
    await this.reloadAsync()
  }

  @Watch('search')
  private searchChanged(val: string) {
    if (!val || val.length < 3) {
      this.clearSearch()
    } else {
      this.open = cloneDeep(this.all)
    }
  }

  @Watch('categories', { deep: true })
  private async categoriesChangedAsync() {
    this.filter.categories = this.categories
  }

  @Watch('pruned', { deep: true })
  private async prunedChangedAsync() {
    this.filter.prunedTree = this.pruned
  }

  private get tenantId() {
    const authModule = getModule(AuthModule, this.$store)
    return this.$user.data.tenantId ?? authModule.tenant
  }

  private get imosImportUrl() {
    const url = this.$env.VUE_APP_IMOS_API_URL
    return `${url}/import/${this.tenantId}/all`
  }

  private clearSearch() {
    this.open = []
  }

  private async loadAllAsync() {
    this.loading = true
    this.items = await productDirectoriesService.findAllAsync(this.filter)
    this.all = this.allSubItemsList(this.items)
    this.loading = false
  }

  private customTreeFilter(
    item: DirectoryTreeItem,
    queryText: string,
    itemText: string
  ) {
    const included = item.isExcluded === false
    const matchesName = this.contains(item.name, queryText)
    const matchesNumber = this.contains(item.number, queryText)
    const matchesManufacturerId = this.contains(
      item.manufacturerProductId,
      queryText
    )

    let matchesAnySupplerId = false
    for (const supplierId of item.supplierIds) {
      matchesAnySupplerId =
        matchesAnySupplerId || this.contains(supplierId, queryText)
    }

    const matches =
      matchesName ||
      matchesNumber ||
      matchesManufacturerId ||
      matchesAnySupplerId

    return included && matches
  }

  private contains(value: string, pattern: string) {
    return (
      value != null &&
      value !== '' &&
      value.toLowerCase().includes(pattern.toLowerCase())
    )
  }

  private allSubItems(item: DirectoryTreeItem): string[] {
    if (item.isFolder) {
      const lst = this.allSubItemsList(item.children)
      lst.push(item.id)
      return lst
    }
    return []
  }

  private allSubItemsList(items: DirectoryTreeItem[]): string[] {
    if (items.length === 0) return []
    return items.flatMap(this.allSubItems)
  }

  public async reloadAsync() {
    await this.loadAllAsync()
  }

  public activate(id: string) {
    if (id) {
      this.active = [id]
    }
  }

  private select() {
    if (this.active.length > 0) {
      this.$emit('select', this.active[0])
    }
  }

  private onDoubleClick(item: DirectoryTreeItem) {
    if (!item.isFolder) {
      this.$emit('select:dblclick', item)
    }
  }

  private showContextMenu(e: MouseEvent, item: DirectoryTreeItem) {
    if (this.editDisabled) {
      return
    }
    if (this.$auth.isInAnyRole(['ADMIN', 'TENANT', 'USER'])) {
      this.mouseX = e.clientX
      this.mouseY = e.clientY
      this.selectedItem = item
      if (item == null || !item.isFolder) {
        this.articleMenuVisible = true
      }
      if (item == null || item.isFolder) {
        this.selectedFolder = item
        this.menuVisible = true
      }
    }
  }

  private newFolder() {
    const folder = new DirectoryTreeItem()
    folder.name = this.$tc('new_folder')
    folder.isEditing = true
    folder.isFolder = true
    return folder
  }

  private async createRootFolderAsync() {
    const folder = this.newFolder()
    await this.createFolderInternalAsync(folder)
    this.items.push(folder)
    this.focusFolderNameInput(folder.id)
  }

  private async createFolderAsync() {
    const folder = this.newFolder()
    if (this.selectedFolder) {
      folder.parentId = this.selectedFolder.id
      await this.openFolder(this.selectedFolder)
      await this.createFolderInternalAsync(folder)
      this.selectedFolder.children.splice(0, 0, folder)
      this.focusFolderNameInput(folder.id)
    }
  }

  private async createFolderInternalAsync(folder: DirectoryTreeItem) {
    const res = await productDirectoriesService.createFolderAsync(folder)
    if (res) {
      folder.id = res.id
      folder.tenantId = res.tenantId
    }
  }

  private focusFolderNameInput(id: string) {
    this.$nextTick(() => {
      const elem = document.getElementById(id)
      if (elem instanceof HTMLInputElement) {
        elem.focus()
        elem.select()
        elem.setSelectionRange(0, elem.value.length, 'forward')
      }
    })
  }

  private openFolder(folder: DirectoryTreeItem) {
    if (this.open.includes(folder.id) === false) {
      this.open.push(folder.id)
    }
  }

  private editFolder() {
    if (this.selectedFolder) {
      this.renameFolder(this.selectedFolder)
    }
  }

  private renameFolder(item: DirectoryTreeItem) {
    item.isEditing = true
    this.focusFolderNameInput(item.id)
  }

  private async updateFolderAsync(folder: DirectoryTreeItem) {
    if (folder.name == null || folder.name === '') {
      folder.name = 'Neuer Ordner'
    }

    await productDirectoriesService.updateFolderAsync(folder)

    folder.isEditing = false
  }

  private async removeFolderAsync() {
    if (confirm('Ordner und alle enthaltenen Daten löschen?')) {
      if (this.selectedFolder) {
        this.isDeleting = true
        try {
          await productDirectoriesService.removeFolderAsync(
            this.selectedFolder.id
          )
          await this.reloadAsync()
        } finally {
          this.isDeleting = false
        }
      }
    }
  }

  private async hideFolderAsync() {
    if (this.selectedFolder) {
      await productDirectoriesService.hideFolderAsync(this.selectedFolder.id)
      await this.reloadAsync()
    }
  }

  private async unhideFolderAsync() {
    if (this.selectedFolder) {
      await productDirectoriesService.unhideFolderAsync(this.selectedFolder.id)
      await this.reloadAsync()
    }
  }

  private async excludeFolderAsync() {
    if (this.selectedFolder) {
      await productDirectoriesService.excludeFolderAsync(this.selectedFolder.id)
      await this.reloadAsync()
    }
  }

  private async includeFolderAsync() {
    if (this.selectedFolder) {
      await productDirectoriesService.includeFolderAsync(this.selectedFolder.id)
      await this.reloadAsync()
    }
  }

  private newFile() {
    const file = new DirectoryTreeItem()
    file.name = 'Neuer Artikel'
    file._isNew = true
    return file
  }

  private createProduct() {
    if (this.selectedFolder) {
      const file = this.newFile()
      this.selectedFolder.children.push(file)
      this.open.push(this.selectedFolder.id)
      this.$emit('create', this.selectedFolder.id)
    }
  }

  private importProducts() {
    if (this.selectedFolder) {
      this.productsImportDialog.open(this.selectedFolder)
    }
  }

  private async onImportComplete() {
    if (this.selectedFolder) {
      await this.reloadAsync()
      this.openFolder(this.selectedFolder)
    }
  }

  private applySupplierMultiplier() {
    if (this.selectedFolder) {
      this.applySupplierMultiplierDialog.open(this.selectedFolder)
    }
  }

  private async mounted() {
    this.filter.categories = this.categories
    this.filter.prunedTree = this.pruned
    await this.loadAllAsync()
  }
}









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ProductCategory } from '@/products/entities/product-category'
import { productCategories } from '@/products/utils/product-categories'

@Component
export default class ProductCategorySelect extends Vue {
  @Prop()
  private value!: ProductCategory | ProductCategory[]

  @Prop({ type: Boolean, default: false })
  private close!: boolean

  @Prop({ type: Number, default: 0 })
  private limit!: number

  private item = this.value
  private items = this.sort(productCategories)

  get valueIsArray() {
    return Array.isArray(this.value)
  }

  @Watch('value')
  private valueChanged(val: ProductCategory | ProductCategory[]) {
    this.item = val
  }

  @Watch('item')
  private itemChanged(val: ProductCategory | ProductCategory[]) {
    this.$emit('input', val)
  }

  private selectAll() {
    if (Array.isArray(this.value)) {
      this.item = productCategories.map((c) => c.value)
    }
  }

  private sort(items: any[]) {
    return items.sort((a, b) => {
      const ta = this.$tc(`_product_categories.${a.text}`)
      const tb = this.$tc(`_product_categories.${b.text}`)
      if (ta < tb) return -1
      if (ta > tb) return 1
      return 0
    })
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$vuetify.breakpoint.mdAndUp
    ? _c(
        "div",
        [
          _vm.filteredPacks && _vm.filteredPacks.length > 0
            ? _c("v-simple-table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("number_short")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("volume")))]),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "_logwood._logwood-packages-table.outerBearingOn"
                          )
                        )
                      )
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "_logwood._logwood-packages-table.dryingChamberOn"
                          )
                        )
                      )
                    ]),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "_logwood._logwood-packages-table.dryingBearingOn"
                          )
                        )
                      )
                    ]),
                    _c("th", { staticClass: "actions" }, [
                      _vm._v(_vm._s(_vm.$t("current_store")))
                    ]),
                    _c("th", { staticClass: "remove-actions" })
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.filteredPacks, function(pack) {
                    return _c("tr", { key: pack.id }, [
                      _c(
                        "td",
                        [_c("logwood-package-id", { attrs: { pack: pack } })],
                        1
                      ),
                      _c("td", [
                        _c("div", [
                          _vm._v(_vm._s(_vm._f("num")(pack.volume, 3)) + " m³")
                        ]),
                        _c(
                          "div",
                          { staticClass: "text--secondary font-size-75" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(pack.length) +
                                " mm × " +
                                _vm._s(pack.height) +
                                " mm "
                            )
                          ]
                        )
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("date")(pack.outerBearingOn)))
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("date")(pack.dryingChamberOn)))
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("date")(pack.dryingBearingOn)))
                      ]),
                      _c(
                        "td",
                        { staticClass: "actions" },
                        [
                          _c("logwood-package-state-select", {
                            attrs: {
                              value: {
                                state: pack.state,
                                location: pack.location
                              },
                              success: pack._state === true,
                              "success-messages": _vm.successMessage(pack),
                              error: pack._state === false,
                              "error-messages": _vm.errorMessage(pack),
                              outlined: "",
                              dense: "",
                              "hide-details": "auto"
                            },
                            on: {
                              change: function($event) {
                                var i = arguments.length,
                                  argsArray = Array(i)
                                while (i--) argsArray[i] = arguments[i]
                                return _vm.emitStateChange.apply(
                                  void 0,
                                  [pack].concat(argsArray)
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", { staticClass: "remove-actions" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end align-center" },
                          [
                            _vm.isDryBearingState(pack)
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      title: _vm.$t(
                                        "_logwood._logwood-scan-table.consume"
                                      ),
                                      icon: "",
                                      color: "error"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.emitConsume(pack)
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("add_task")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.$auth.isInAnyRole(["ADMIN", "TENANT"])
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.emitRemove(pack)
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("delete")])],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e()
        ],
        1
      )
    : _c(
        "div",
        _vm._l(_vm.filteredPacks, function(pack) {
          return _c(
            "v-card",
            { key: pack.id, staticClass: "mb-3" },
            [
              _c("v-card-title", [
                _vm._v(" " + _vm._s(_vm._f("logwoodPackageNr")(pack)) + " ")
              ]),
              _c("v-card-subtitle", { staticClass: "font-size-85" }, [
                _vm._v(
                  " " +
                    _vm._s(pack.productName || _vm.$t("additional_purchases")) +
                    " → " +
                    _vm._s(pack.targetProductName) +
                    " "
                )
              ]),
              _c(
                "v-card-text",
                { staticClass: "d-flex justify-space-between align-center" },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("num")(pack.volume, 3)) + " m³")
                  ]),
                  _c("span", { staticClass: "text--secondary font-size-75" }, [
                    _vm._v(
                      " " +
                        _vm._s(pack.length) +
                        " mm × " +
                        _vm._s(pack.height) +
                        " mm "
                    )
                  ])
                ]
              ),
              _c(
                "v-card-actions",
                { staticClass: "flex-column px-4 pb-4" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("logwood-package-state-select", {
                        attrs: {
                          value: { state: pack.state, location: pack.location },
                          success: pack._state === true,
                          "success-messages": _vm.successMessage(pack),
                          error: pack._state === false,
                          "error-messages": _vm.errorMessage(pack),
                          outlined: "",
                          dense: "",
                          "hide-details": "auto"
                        },
                        on: {
                          change: function($event) {
                            var i = arguments.length,
                              argsArray = Array(i)
                            while (i--) argsArray[i] = arguments[i]
                            return _vm.emitStateChange.apply(
                              void 0,
                              [pack].concat(argsArray)
                            )
                          }
                        }
                      }),
                      _vm.$auth.isInAnyRole(["ADMIN", "TENANT"])
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.emitRemove(pack)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("delete")])],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.isDryBearingState(pack)
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            depressed: "",
                            block: "",
                            large: "",
                            color: "error"
                          },
                          on: {
                            click: function($event) {
                              return _vm.emitConsume(pack)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("_logwood._logwood-scan-table.consume")
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import store from '@/app/store'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import Tenant from '@/tenants/entities/tenant'

@Module({ store, name: 'TenantModule' })
export default class TenantModule extends VuexModule {

  public data: Tenant | null = null

  get tenantData(): Tenant {
    return this.data || new Tenant()
  }

  @Mutation
  public setTenantData(data: Tenant | null) {
    this.data = data
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "elevation-0",
    attrs: {
      loading: _vm.loading,
      headers: _vm.headers,
      items: _vm.users,
      options: _vm.pPagination,
      "server-items-length": _vm.pPagination.totalItems,
      "no-data-text": _vm.$t("no_data_available")
    },
    on: {
      "update:options": function($event) {
        _vm.pPagination = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item.name",
          fn: function(ref) {
            var item = ref.item
            return [
              _vm._v(
                " " + _vm._s(item.firstName) + " " + _vm._s(item.lastName) + " "
              )
            ]
          }
        },
        {
          key: "item.address",
          fn: function(ref) {
            var item = ref.item
            return [_c("address-line", { attrs: { address: item.address } })]
          }
        },
        {
          key: "item.role",
          fn: function(ref) {
            var item = ref.item
            return [_c("role-badge", { attrs: { role: item.role } })]
          }
        },
        {
          key: "item.status",
          fn: function(ref) {
            var item = ref.item
            return [
              item.emailConfirmed === false
                ? _c("v-chip", { attrs: { small: "", outlined: "" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("_users.not_confirmed")) + " ")
                  ])
                : _vm._e(),
              item.emailConfirmed && item.isEnabled === false
                ? _c("v-chip", { attrs: { small: "", outlined: "" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("_users.disabled")) + " ")
                  ])
                : _vm._e(),
              item.isEnabled && item.emailConfirmed
                ? _c("v-chip", { attrs: { small: "", outlined: "" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("active")) + " ")
                  ])
                : _vm._e()
            ]
          }
        },
        {
          key: "item.action",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "div",
                { staticClass: "text-no-wrap" },
                [
                  item.id !== _vm.$auth.user
                    ? [
                        item.isEnabled
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  icon: "",
                                  title: _vm.$t("_users.disable_user")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.disable(item)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("block")])],
                              1
                            )
                          : _c(
                              "v-btn",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  icon: "",
                                  title: _vm.$t("_users.enable_user")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.enable(item)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("check")])],
                              1
                            )
                      ]
                    : _vm._e(),
                  _vm.$auth.isInRole("ADMIN") && item.id !== _vm.$auth.user
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            icon: "",
                            title: _vm.$t("_users.impersonate_user")
                          },
                          on: {
                            click: function($event) {
                              return _vm.impersonate(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("supervisor_account")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { icon: "", title: _vm.$t("edit") },
                      on: {
                        click: function($event) {
                          return _vm.edit(item)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("edit")])],
                    1
                  ),
                  item.id !== _vm.$auth.user
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { icon: "", title: _vm.$t("delete") },
                          on: {
                            click: function($event) {
                              return _vm.deleteUser(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
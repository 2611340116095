


































import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator'
import Company from '@/companies/entities/company'
import companiesServce from '@/companies/services/companies-service'

@Component
export default class CompanySelect extends Vue {
  @Prop()
  private value!: Company | string | null

  @Prop({ default: 'supplier' })
  private type!: CompanyType

  @Prop({ default: () => [] })
  private excluded!: (Company | string)[]

  @Prop({ type: Boolean, default: false })
  private returnObject!: boolean

  @Prop({ default: 25 })
  private ellipsis!: number

  @Ref()
  private validator!: ValidationObserver

  private search: string = ''
  private loading: boolean = false
  private companies: Company[] = []
  private company: Company | null = null

  @Watch('value')
  private async valueChanged(val: Company | string) {
    await this.prefetchAsync(val)
  }

  @Watch('search')
  private async searchChanged(val: string, old: string) {
    if (val && val !== old) {
      await this.loadAsync()
    }
  }

  private async prefetchAsync(value: Company | string) {
    if (value == null) {
      this.company = null
      return
    }
    const company =
      typeof value === 'string' || value instanceof String
        ? await companiesServce.findOneAsync(value as string)
        : value

    if (company) {
      if (this.companies.findIndex((c) => c.id === company.id) === -1) {
        this.companies.push(company)
      }
      this.company = company
      this.emitChange()
    }
  }

  private async loadAsync() {
    this.loading = true
    switch (this.type) {
      case 'supplier':
        this.companies = await companiesServce.findAllSuppliersAsync(
          this.search
        )
        break
      case 'manufacturer':
        this.companies = await companiesServce.findAllManufacturersAsync(
          this.search
        )
        break
      case 'customer':
        this.companies = await companiesServce.findAllCustomersAsync(
          this.search
        )
        break
      case 'noncustomer':
        this.companies = await companiesServce.findAllNonCustomersAsync(
          this.search
        )
        break
      case 'all':
        this.companies = await companiesServce.findAllCompaniesAsync(
          this.search
        )
        break
    }
    const excluded = this.excluded.map((c) => (c instanceof Company ? c.id : c))
    this.companies = this.companies.filter(
      (c) => excluded.includes(c.id) === false
    )
    this.loading = false
  }

  private async emitChange() {
    this.$emit('input', this.returnObject ? this.company : this.company?.id)
    this.$emit('input:object', this.company)
  }

  private emitClear() {
    this.$emit('input', null)
    this.$emit('input:object', null)
  }

  private async mounted() {
    if (this.value) {
      await this.prefetchAsync(this.value)
    }
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._b(
      {
        attrs: { items: _vm.salutations },
        model: {
          value: _vm.salutation,
          callback: function($$v) {
            _vm.salutation = $$v
          },
          expression: "salutation"
        }
      },
      "v-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
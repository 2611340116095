var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": "400px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "px-5 pt-5 primary" }, [
            _c("span", { staticClass: "white--text" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ]),
          _c(
            "v-card-text",
            { staticClass: "pa-5" },
            [_vm._t("default", null, { context: _vm.context })],
            2
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "px-5 pb-5 pt-3" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { depressed: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.confirm()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.confirmButtonText) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
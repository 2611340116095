var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "700px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var valid = ref.valid
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    attrs: { "min-height": "100%" }
                  },
                  [
                    _c("v-card-title", { staticStyle: { height: "64px" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("merge_companies")) + " ")
                    ]),
                    _c(
                      "v-card-text",
                      { staticClass: "flex-grow-1" },
                      [
                        _vm.showError
                          ? _c("v-alert", { attrs: { type: "error" } }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("unexpected_error")) + " "
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "v-form",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-alert",
                                          { attrs: { type: "warning" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "_companies._join_companies_dialog.deleted_company_hint"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.srcCompany.name) +
                                                " (" +
                                                _vm._s(_vm.srcCompany.number) +
                                                ")"
                                            )
                                          ])
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.srcCompany.address.addressLine
                                            )
                                          )
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.srcCompany.address.countryId
                                              ) +
                                              "-" +
                                              _vm._s(
                                                _vm.srcCompany.address
                                                  .postalCode
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.srcCompany.address.city
                                              ) +
                                              " "
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "auto",
                                          "align-self": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "" },
                                            on: { click: _vm.swap }
                                          },
                                          [
                                            _c("v-icon", [_vm._v("swap_horiz")])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-alert",
                                          { attrs: { type: "info" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "_companies._join_companies_dialog.remaining_company_hint"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("validation-provider", {
                                          attrs: {
                                            slim: "",
                                            rules: "required",
                                            name: _vm.$t("company")
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("company-select", {
                                                      attrs: {
                                                        excluded: [
                                                          _vm.srcCompany.id
                                                        ],
                                                        label: _vm.$t(
                                                          "company"
                                                        ),
                                                        type: "all",
                                                        returnObject: "",
                                                        outlined: "",
                                                        dense: "",
                                                        "error-messages": errors,
                                                        "hide-details": "auto"
                                                      },
                                                      model: {
                                                        value: _vm.dstCompany,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.dstCompany = $$v
                                                        },
                                                        expression: "dstCompany"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        }),
                                        _vm.dstCompany && _vm.dstCompany.address
                                          ? [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.dstCompany.address
                                                      .addressLine
                                                  )
                                                )
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.dstCompany.address
                                                        .countryId
                                                    ) +
                                                    "-" +
                                                    _vm._s(
                                                      _vm.dstCompany.address
                                                        .postalCode
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.dstCompany.address
                                                        .city
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-7 pb-5" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text",
                            attrs: { text: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(_vm._s(_vm.$t("cancel")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: _vm.loading,
                              disabled: !valid || !_vm.dstCompany,
                              depressed: "",
                              color: "primary"
                            },
                            on: { click: _vm.confirm }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "_companies._join_companies_dialog.join"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: "pickerMenu",
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 0,
        "return-value": _vm.model,
        transition: "scale-transition",
        "offset-y": "",
        "max-width": "290px",
        "min-width": "290px"
      },
      on: {
        "update:returnValue": function($event) {
          _vm.model = $event
        },
        "update:return-value": function($event) {
          _vm.model = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      style: "width: " + _vm.width,
                      attrs: { value: _vm.model, readonly: "" }
                    },
                    "v-text-field",
                    _vm.$attrs,
                    false
                  ),
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _vm.model
        ? _c(
            "v-time-picker",
            _vm._b(
              {
                attrs: { format: "24hr", "full-width": "" },
                on: {
                  "click:minute": function($event) {
                    return _vm.$refs.pickerMenu.save(_vm.model)
                  }
                },
                model: {
                  value: _vm.model,
                  callback: function($$v) {
                    _vm.model = $$v
                  },
                  expression: "model"
                }
              },
              "v-time-picker",
              _vm.$attrs,
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
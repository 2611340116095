var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "min-height": "100%" } },
        [
          _c("v-card-title", { staticStyle: { height: "64px" } }, [
            _vm._v(" " + _vm._s(_vm.$t("import_project")) + " ")
          ]),
          _c(
            "v-card-text",
            { staticClass: "flex-grow-1" },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.systemsForImport,
                  outlined: "",
                  dense: "",
                  label: _vm.$t("_projects._project_import_dialog.import_from")
                },
                model: {
                  value: _vm.importFrom,
                  callback: function($$v) {
                    _vm.importFrom = $$v
                  },
                  expression: "importFrom"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("job_number"),
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  type: "number"
                },
                model: {
                  value: _vm.number,
                  callback: function($$v) {
                    _vm.number = _vm._n($$v)
                  },
                  expression: "number"
                }
              }),
              _vm.hasError
                ? _c("v-alert", { attrs: { type: "error" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "_projects._project_import_dialog.error_message"
                          )
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-7 pb-5" },
            [
              _c("v-spacer"),
              _c("v-btn", { attrs: { text: "" }, on: { click: _vm.close } }, [
                _vm._v(_vm._s(_vm.$t("cancel")))
              ]),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.number,
                    loading: _vm.isLoading,
                    text: "",
                    color: "primary"
                  },
                  on: { click: _vm.okAsync }
                },
                [_vm._v(" " + _vm._s(_vm.$t("import")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }











































































































import RegistrationRequest from '@/users/requests/registration-request'
import { Component, Vue, Ref } from 'vue-property-decorator'
import AddressInput from '@/locations/components/address-input/address-input.vue'

@Component
export default class RegisterForm extends Vue {
  @Ref()
  private validator!: ValidationObserver

  @Ref()
  private addressInput!: AddressInput

  private valid = true
  private request = new RegistrationRequest()
  private loading = false

  private roles = [
    { text: 'Admin', value: 'ADMIN' },
    { text: 'Mandanten-Admin', value: 'TENANT' },
    { text: 'Benutzer', value: 'USER' },
    { text: 'Eingeschränkter Benutzer', value: 'RESTRICTED' },
    { text: 'Externer Benutzer', value: 'EXTERNAL' },
  ]

  public async register() {
    this.loading = true
    if (await this.validator.validate()) {
      this.$emit('register', this.request)
    }
  }

  public reset() {
    this.loading = false
  }
}

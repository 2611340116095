var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "d-flex align-center" },
    [
      _c(
        "v-col",
        { staticClass: "py-0" },
        [
          _c("validation-provider", {
            attrs: { slim: "", rules: "required", name: _vm.$t("supplier") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("company-select", {
                      attrs: {
                        value: _vm.supplier.supplierId,
                        label: "* " + _vm.$t("supplier"),
                        excluded: _vm.supplierIds,
                        type: "supplier",
                        outlined: "",
                        dense: "",
                        "persistent-hint": "",
                        "error-messages": errors,
                        readonly: _vm.readonly,
                        ellipsis: 60
                      },
                      on: { "input:object": _vm.changeSupplier }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "py-0" },
        [
          _c("validation-provider", {
            attrs: {
              slim: "",
              rules: "required",
              name: _vm.$t("supplier_product_number")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("v-text-field", {
                      attrs: {
                        label: "* " + _vm.$t("supplier_product_number"),
                        outlined: "",
                        dense: "",
                        "persistent-hint": "",
                        "error-messages": errors,
                        readonly: _vm.readonly
                      },
                      model: {
                        value: _vm.supplier.supplierProductId,
                        callback: function($$v) {
                          _vm.$set(_vm.supplier, "supplierProductId", $$v)
                        },
                        expression: "supplier.supplierProductId"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
        ? _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "2" } },
            [
              _c("validation-provider", {
                attrs: {
                  rules: "decimal|min_value:0",
                  name: _vm.$t("purchase_price")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _c("x-money-input", {
                            attrs: {
                              label: _vm.$t("purchase_price"),
                              currency: "€/" + _vm.unitSuffix(_vm.product.unit),
                              outlined: "",
                              dense: "",
                              hint: _vm.dateTime(_vm.supplier.priceDate),
                              "persistent-hint": "",
                              "error-messages": errors,
                              readonly: _vm.readonly,
                              "fractional-digits": 4,
                              "append-icon": "query_stats"
                            },
                            on: {
                              "click:append": function($event) {
                                return _vm.priceHistoryDialog.open(
                                  _vm.product.id,
                                  _vm.supplier.supplierId
                                )
                              }
                            },
                            model: {
                              value: _vm.supplier.netPurchasePrice,
                              callback: function($$v) {
                                _vm.$set(_vm.supplier, "netPurchasePrice", $$v)
                              },
                              expression: "supplier.netPurchasePrice"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2682709213
                )
              }),
              _c("price-history-dialog", {
                ref: "priceHistoryDialog",
                attrs: { currency: "€ / " + _vm.unitSuffix(_vm.product.unit) }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
        ? _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "2" } },
            [
              _c("validation-provider", {
                attrs: {
                  rules: "decimal|min_value:0",
                  name: _vm.$t("net_sales_price_multiplier")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _c("x-number-input", {
                            attrs: {
                              label: _vm.$t("net_sales_price_multiplier"),
                              outlined: "",
                              dense: "",
                              "persistent-hint": "",
                              "error-messages": errors,
                              readonly: _vm.readonly,
                              "fractional-digits": 4,
                              "hide-details": "auto",
                              hint: _vm.$t("net_sales_price_multiplier_hint")
                            },
                            model: {
                              value: _vm.supplier.netSalesPriceMultiplier,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.supplier,
                                  "netSalesPriceMultiplier",
                                  $$v
                                )
                              },
                              expression: "supplier.netSalesPriceMultiplier"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2267931451
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "auto", "align-self": "start" } },
        [
          _c("v-checkbox", {
            staticClass: "mt-1",
            class: { hidden: _vm.supplier.isWarehouse },
            attrs: { readonly: _vm.readonly },
            on: {
              click: function($event) {
                return _vm.$emit("select:primary", _vm.supplier)
              }
            },
            model: {
              value: _vm.supplier.isPrimary,
              callback: function($$v) {
                _vm.$set(_vm.supplier, "isPrimary", $$v)
              },
              expression: "supplier.isPrimary"
            }
          })
        ],
        1
      ),
      !_vm.readonly
        ? _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "auto", "align-self": "start" }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("remove", _vm.index)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("delete")])],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./x-chart.vue?vue&type=template&id=d547a92a&"
import script from "./x-chart.vue?vue&type=script&lang=ts&"
export * from "./x-chart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d547a92a')) {
      api.createRecord('d547a92a', component.options)
    } else {
      api.reload('d547a92a', component.options)
    }
    module.hot.accept("./x-chart.vue?vue&type=template&id=d547a92a&", function () {
      api.rerender('d547a92a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/components/x-chart.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { tile: "" } },
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            staticClass: "elevation-0",
            attrs: {
              loading: _vm.loading,
              headers: _vm.headers,
              items: _vm.packs,
              options: _vm.filter.paging,
              "server-items-length": _vm.filter.paging.totalItems,
              "footer-props": { itemsPerPageText: _vm.$t("items_per_page") },
              "no-data-text": _vm.$t("_logwood._logwood-packages-table.empty")
            },
            on: {
              "update:options": function($event) {
                return _vm.$set(_vm.filter, "paging", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.type",
                  fn: function(ref) {
                    var item = ref.item
                    return [_c("logwood-package-id", { attrs: { pack: item } })]
                  }
                },
                {
                  key: "item.volume",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("div", [
                        _vm._v(_vm._s(_vm._f("num")(item.volume, 3)) + " m³")
                      ]),
                      _c(
                        "div",
                        { staticClass: "text--secondary font-size-75" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.length) +
                              " mm × " +
                              _vm._s(item.height) +
                              " mm "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.outerBearingOn",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("time")(item.outerBearingOn, true)) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "text-no-wrap" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "_logwood._logwood-packages-table.print_tag"
                                ),
                                icon: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("print", item.number)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("print")])],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { UnitType } from '@/products/entities/unit-type'
import ProductSupplier from '@/products/entities/product-supplier'
import Guid from '@/app/utils/guid'
import { ProductCategory } from '@/products/entities/product-category'
import StoreProduct from '@/stores/models/store-product'
import PriceHistory from './price-history'

export default class Product {
  public id = Guid.empty()
  public directoryId = Guid.empty()
  public category = ProductCategory.Sonstiges
  public number = ''
  public tags: string[] = []
  public name = ''
  public description = ''
  public netSalesPrice = 0
  public length = 0
  public width = 0
  public height = 0
  public weightPerUnit = 0
  public unit = UnitType.STK
  public purchaseUnit = UnitType.STK
  public isExcluded: boolean = false
  public manufacturerId: string | null = null
  public manufacturerProductId = ''
  public stores: StoreProduct[] = []
  public suppliers: ProductSupplier[] = []
  public priceHistory: PriceHistory[] = []
  public imagePath: string | null = null
}




























































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import axios from '@/app/axios'

@Component
export default class CorrectionListImportDialog extends Vue {
  @Ref()
  private validator!: ValidationObserver

  private dialog = false
  private loading = false

  private file: File | null = null
  private importStocks = false
  private warnings: string[] = []

  public open() {
    this.dialog = true
    this.file = null
    this.importStocks = false
    this.warnings = []
    this.loading = false
  }

  private async confirm() {
    if (await this.validator.validate()) {
      this.loading = true
      await this.importCsv()
      this.$emit('upload:complete')
      this.loading = false
      if (this.warnings.length === 0) {
        this.reset()
        this.cancel()
      }
    }
  }

  private async importCsv() {
    if (this.file) {
      const formData = new FormData()
      formData.append('file', this.file, this.file.name)
      const res = await axios.post<string[]>(
        `correctionlistfiles?importStocks=${this.importStocks}`,
        formData
      )
      this.warnings = res.data
    }
  }

  private reset() {
    if (this.validator) {
      this.validator.reset()
    }
  }

  private cancel() {
    this.dialog = false
  }
}

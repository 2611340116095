



































































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import EditDialogMixin from '@/common/dialogs/mixins/edit-dialog-mixin'
import Permission from '@/users/entities/permission'

@Component
export default class PermissionDialog extends Mixins<
  EditDialogMixin<Permission>
>(EditDialogMixin) {}

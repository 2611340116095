var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-grow-1" },
    [
      _c(
        "v-autocomplete",
        _vm._b(
          {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce:update:search-input",
                value: 500,
                expression: "500",
                arg: "update:search-input"
              }
            ],
            staticClass: "product-select__autocomplete",
            attrs: {
              items: _vm.items,
              "return-object": "",
              "hide-no-data": "",
              "hide-selected": "",
              "no-filter": "",
              "menu-props": { "min-width": 450 },
              "search-input": _vm.filter.text,
              "append-outer-icon": "search"
            },
            on: {
              focus: function($event) {
                return _vm.updateItems()
              },
              change: function($event) {
                return _vm.emitChange(_vm.product)
              },
              "update:searchInput": function($event) {
                return _vm.$set(_vm.filter, "text", $event)
              },
              "update:search-input": function($event) {
                return _vm.$set(_vm.filter, "text", $event)
              },
              "click:append-outer": function($event) {
                return _vm.picker.open(_vm.product ? _vm.product.number : null)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("product-avatar", {
                      staticClass: "mr-1",
                      attrs: {
                        "image-path": item.imagePath,
                        size: "24",
                        zoom: "24"
                      }
                    }),
                    _c("span", { staticClass: "text-no-wrap" }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-list-item-avatar",
                      [
                        _c("product-avatar", {
                          attrs: { "image-path": item.imagePath }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-list-item-subtitle",
                          { staticClass: "text-caption" },
                          [_vm._v(" " + _vm._s(item.number) + " ")]
                        ),
                        _c("v-list-item-title", [_vm._v(_vm._s(item.name))])
                      ],
                      1
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.product,
              callback: function($$v) {
                _vm.product = $$v
              },
              expression: "product"
            }
          },
          "v-autocomplete",
          _vm.$attrs,
          false
        )
      ),
      _c("product-picker", {
        ref: "picker",
        attrs: { categories: _vm.categories, pruned: _vm.pruned },
        on: { confirm: _vm.pickProduct }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var valid = ref.valid
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    attrs: { "min-height": "100%" }
                  },
                  [
                    _c("v-card-title", { staticStyle: { height: "64px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "_projects._partlist_upload_dialog.upload_imos_partlist"
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c(
                      "v-card-text",
                      { staticClass: "flex-grow-1" },
                      [
                        _c("v-file-input", {
                          attrs: {
                            label:
                              _vm.$t(
                                "_projects._partlist_upload_dialog.upload_imos_partlist"
                              ) + " (.xlsx)",
                            outlined: "",
                            dense: "",
                            accept: ".xlsx",
                            "show-size": ""
                          },
                          on: {
                            change: function($event) {
                              _vm.warnings = ""
                            }
                          },
                          model: {
                            value: _vm.file,
                            callback: function($$v) {
                              _vm.file = $$v
                            },
                            expression: "file"
                          }
                        }),
                        _vm.warnings && _vm.warnings.length > 0
                          ? _c(
                              "v-alert",
                              {
                                attrs: {
                                  border: "bottom",
                                  "colored-border": "",
                                  type: "warning",
                                  elevation: "2"
                                }
                              },
                              [_c("pre", [_vm._v(_vm._s(_vm.warnings))])]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-7 pb-5" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text",
                            attrs: { text: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(_vm._s(_vm.$t("close")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: _vm.loading,
                              disabled: !valid,
                              depressed: "",
                              color: "primary"
                            },
                            on: { click: _vm.confirm }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("upload")) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }












































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LogwoodShift from '@/logwood/models/logwood-history'
import LogwoodService from '@/logwood/services/logwood-service'
import LogwoodHistoryFilter from '@/logwood/models/logwood-history-filter'
import { HistoryPeriodType } from '@/logwood/models/history-period-type'
import { LogwoodPackageState } from '@/logwood/models/logwood-package-state'
import moment from 'moment'

@Component
export default class LogwoodHistory extends Vue {
  @Prop()
  private title!: string

  @Prop({ default: () => LogwoodPackageState.OuterBearing })
  private state!: LogwoodPackageState

  private shifts: LogwoodShift[] = []
  private filter = new LogwoodHistoryFilter()
  private loading = false
  private tab = 0

  private get headers() {
    return [
      {
        text: this.$tc('shift'),
        value: 'shift',
        sortable: false,
      },
      {
        text: this.$tc('volume'),
        value: 'volume',
        sortable: false,
        align: 'end',
      },
      {
        value: 'data-table-expand',
      },
    ]
  }

  private get isPeriodTypeDate() {
    return this.filter.periodType === HistoryPeriodType.Date
  }

  private get isPeriodTypeWeek() {
    return this.filter.periodType === HistoryPeriodType.Week
  }

  private get isPeriodTypeMonth() {
    return this.filter.periodType === HistoryPeriodType.Month
  }

  private get inputType() {
    return this.isPeriodTypeMonth ? 'month' : 'date'
  }

  private get type() {
    if (this.isPeriodTypeMonth) {
      return 'month'
    }
    if (this.isPeriodTypeWeek) {
      return 'week'
    }
    return 'day'
  }

  @Watch('filter.periodType', { deep: true })
  private async filterPeriodTypeChanged() {
    this.filter.start = moment(this.filter.end)
      .subtract(this.type === 'day' ? 3 : 7, this.type)
      .format('YYYY-MM-DD')
    await this.loadShiftsAsync()
  }

  private async pick() {
    this.filter.start = moment(this.filter.end)
      .subtract(this.type === 'day' ? 3 : 7, this.type)
      .format('YYYY-MM-DD')
    await this.loadShiftsAsync()
  }

  private async loadShiftsAsync() {
    this.loading = true
    this.shifts = await LogwoodService.loadShiftsFilteredAsync(this.filter)
    this.loading = false
  }

  private setPeriodTypeToDate() {
    this.filter.periodType = HistoryPeriodType.Date
  }

  private setPeriodTypeToWeek() {
    this.filter.periodType = HistoryPeriodType.Week
  }

  private setPeriodTypeToMonth() {
    this.filter.periodType = HistoryPeriodType.Month
  }

  private get chartLabels() {
    return this.shifts.map((s) => s.start)
  }

  private get chartData() {
    return [this.shifts.map((s) => s.volume)]
  }

  private get chartConfig() {
    return {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#363636',
            borderColor: '#363636',
            borderWidth: 1,
            xAxisID: 'x',
            yAxisID: 'y',
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        scales: {
          x: {
            type: 'time',
            time: {
              parser: 'YYYY-MM-DD[T]HH:mm:ss',
              unit: this.type,
              displayFormats: {
                day: 'DD. MMM YY',
                week: `[${this.$tc('calendar_week')}] WW`,
                month: 'MMM YY',
              },
            },
            ticks: {
              source: 'data',
              maxRotation: 90,
              minRotation: 90,
            },
          },
          y: {
            title: {
              display: true,
              text: 'm³',
              font: {
                size: 16,
                weight: 'bold',
              },
              color: '#777',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    }
  }

  private async mounted() {
    this.filter.state = this.state
    await this.loadShiftsAsync()
  }
}

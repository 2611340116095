




































































































































































import Company from '@/companies/entities/company'
import Project from '@/projects/entities/project'
import { Component, Vue, Ref } from 'vue-property-decorator'
import projectsService from '@/projects/services/projects-service'
import companiesService from '@/companies/services/companies-service'
import Order from '@/orders/entities/order'
import ordersService from '@/orders/services/orders-service'
import OrderDetailsDialog from '@/orders/components/order-details-dialog.vue'

@Component
export default class Home extends Vue {
  @Ref()
  private orderDetailsDialog!: OrderDetailsDialog

  private lateProjects: Project[] = []
  private lateOrders: Order[] = []
  private recentlyChangedProjects: Project[] = []
  private recentlyChangedCompanies: Company[] = []

  private gotoProject(projectId: string) {
    this.$router.push({
      name: 'project-details',
      params: { lang: this.$i18n.locale, projectId: projectId },
    })
  }

  private async gotoOrder(orderId: string) {
    await this.orderDetailsDialog.openAsync(orderId)
  }

  private gotoCompany(companyId: string) {
    this.$router.push({
      name: 'companies',
      params: { lang: this.$i18n.locale, companyId: companyId },
    })
  }

  private async loadLateOrdersAsync() {
    this.lateOrders = await ordersService.findAllLateAsync()
  }

  private async mounted() {
    this.lateProjects = await projectsService.findAllLateAsync()
    await this.loadLateOrdersAsync()
    this.recentlyChangedProjects =
      await projectsService.findAllLastChangedAsync()
    this.recentlyChangedCompanies =
      await companiesService.findAllLastChangedAsync()
  }
}

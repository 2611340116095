















































































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import Stock from '@/stores/models/stock'
import storesService from '@/stores/services/stores-service'
import OrderDetailsDialog from '@/orders/components/order-details-dialog.vue'
import StockFilter from '@/stores/models/stock-filter'
import moment from 'moment'
import Dates from '@/app/utils/dates'

@Component
export default class StockInput extends Vue {
  @Prop({ default: '' })
  private unit!: string

  @Prop()
  private productId!: string

  @Prop()
  private storeId!: string

  @Prop()
  private disabled!: boolean

  @Ref()
  private ordersDialog!: OrderDetailsDialog

  private dialog = false
  private tab = 0
  private filter = new StockFilter()
  private history: Stock[] = []

  private current = 0
  private change = 0
  private comment = ''

  private get headers() {
    return [
      {
        text: this.$tc('quantity'),
        align: 'end',
        value: 'current',
        sortable: false,
      },
      {
        text: this.$tc('_products._stock_input.delta'),
        align: 'end',
        value: 'change',
        sortable: false,
      },
      {
        text: this.$tc('date'),
        align: 'start',
        value: 'createdOn',
        cellClass: 'text-caption',
        sortable: false,
      },
      {
        text: this.$tc('user'),
        align: 'start',
        value: 'createdBy',
        cellClass: 'text-caption',
        sortable: false,
      },
      {
        text: this.$tc('order'),
        align: 'start',
        value: 'orderNumber',
        cellClass: 'text-caption',
        sortable: false,
      },
      {
        text: this.$tc('_products._stock_input.comment'),
        align: 'start',
        value: 'comment',
        cellClass: 'text-caption',
        sortable: false,
        width: '33%',
      },
    ]
  }

  private get formattedCurrent() {
    return this.$fmt.num(this.current, 2)
  }

  private get formattedCurrentUnit() {
    return this.$fmt.num(this.current, 2) + ' ' + this.unit
  }

  private get scale() {
    const diff = Dates.absPeriodDiffInDays(this.filter.period)
    if (diff <= 31) {
      return 'day'
    }
    if (diff <= 365) {
      return 'month'
    }
    return 'year'
  }

  private get chartLabels() {
    return this.history.map((h) => h.createdOn)
  }

  private get chartData() {
    return [this.history.map((h) => h.current)]
  }

  private get chartConfig() {
    return {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#363636',
            borderColor: '#363636',
            borderWidth: 1,
            xAxisID: 'x',
            yAxisID: 'y',
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        scales: {
          x: {
            type: 'time',
            time: {
              parser: 'YYYY-MM-DD[T]HH:mm:ss',
              unit: this.scale,
              displayFormats: {
                day: 'DD. MMM YY',
                month: 'MMM YY',
              },
            },
            ticks: {
              source: 'data',
              maxRotation: 90,
              minRotation: 90,
            },
          },
          y: {
            title: {
              display: true,
              text: this.unit,
              font: {
                size: 16,
                weight: 'bold',
              },
              color: '#777',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    }
  }

  @Watch('productId')
  private async productIdChanged() {
    this.filter.productId = this.productId
    await this.loadAsync()
  }

  @Watch('storeId')
  private async storeIdChanged() {
    this.filter.storeId = this.storeId
    await this.loadAsync()
  }

  private async editAsync() {
    if (this.disabled === false) {
      this.dialog = true
    }
  }

  private async loadAsync() {
    this.history = await storesService.findStocksAsync(this.filter)
    if (this.history.length > 0) {
      this.current = this.history[0].current
    } else {
      this.current = 0
    }
  }

  private async addAsync() {
    if (this.productId) {
      const stock = new Stock()
      stock.productId = this.productId
      stock.storeId = this.storeId
      stock.change = this.change
      stock.comment = this.comment

      await storesService.createStockAsync(stock)

      this.change = 0
      this.comment = ''

      await this.loadAsync()
    }
  }

  private async mounted() {
    this.filter.productId = this.productId
    this.filter.storeId = this.storeId
    this.filter.period.begin = moment()
      .subtract(6, 'month')
      .format('YYYY-MM-DD')
    await this.loadAsync()
  }
}

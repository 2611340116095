var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pb-0 px-0", attrs: { cols: "12" } },
                [
                  _c("logwood-select", {
                    attrs: {
                      placeholder: _vm.$t("_logwood.product"),
                      outlined: "",
                      dense: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.pack.productId,
                      callback: function($$v) {
                        _vm.$set(_vm.pack, "productId", $$v)
                      },
                      expression: "pack.productId"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pb-0 px-0", attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "required",
                      name: _vm.$t("_logwood.targetProduct")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("simple-product-select", {
                              attrs: {
                                categories: _vm.solidWoodCategory,
                                pruned: "",
                                placeholder: _vm.$t("_logwood.targetProduct"),
                                outlined: "",
                                dense: "",
                                "error-messages": errors,
                                "hide-details": "auto"
                              },
                              model: {
                                value: _vm.pack.targetProductId,
                                callback: function($$v) {
                                  _vm.$set(_vm.pack, "targetProductId", $$v)
                                },
                                expression: "pack.targetProductId"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pl-0" },
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "decimal|min_value:0",
                      name: _vm.$t("length")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("x-number-input", {
                              attrs: {
                                label: _vm.$t("length"),
                                suffix: "mm",
                                outlined: "",
                                dense: "",
                                "error-messages": errors,
                                "hide-details": "auto"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.computeVolume()
                                }
                              },
                              model: {
                                value: _vm.pack.length,
                                callback: function($$v) {
                                  _vm.$set(_vm.pack, "length", _vm._n($$v))
                                },
                                expression: "pack.length"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pr-0" },
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "decimal|min_value:0",
                      name: _vm.$t("height") + "/" + _vm.$t("thickness")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("x-number-input", {
                              attrs: {
                                label:
                                  _vm.$t("height") + "/" + _vm.$t("thickness"),
                                suffix: "mm",
                                outlined: "",
                                dense: "",
                                "error-messages": errors,
                                "hide-details": "auto"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.computeVolume()
                                }
                              },
                              model: {
                                value: _vm.pack.height,
                                callback: function($$v) {
                                  _vm.$set(_vm.pack, "height", _vm._n($$v))
                                },
                                expression: "pack.height"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-0" },
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "decimal|min_value:0",
                      name: _vm.$t("volume")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("x-number-input", {
                              attrs: {
                                label: _vm.$t("volume"),
                                suffix: "m³",
                                outlined: "",
                                dense: "",
                                "fractional-digits": 3,
                                "error-messages": errors,
                                "hide-details": "auto",
                                disabled: _vm.pack.productId !== null
                              },
                              model: {
                                value: _vm.pack.volume,
                                callback: function($$v) {
                                  _vm.$set(_vm.pack, "volume", $$v)
                                },
                                expression: "pack.volume"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.pack.productId !== null
            ? [
                _c(
                  "v-row",
                  _vm._l(_vm.diameters, function(width, idx) {
                    return _c(
                      "v-col",
                      { key: idx, attrs: { cols: "2" } },
                      [
                        _c("validation-provider", {
                          attrs: {
                            rules: "decimal|min_value:0",
                            name: _vm.$t("solid_wood_diameter")
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("x-number-input", {
                                      attrs: {
                                        label: _vm.$t("solid_wood_diameter"),
                                        suffix: "mm",
                                        outlined: "",
                                        dense: "",
                                        "error-messages": errors,
                                        "hide-details": "auto",
                                        autofocus:
                                          _vm.diameters.length === idx + 1
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.computeVolume()
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.addDiameter(idx)
                                        }
                                      },
                                      model: {
                                        value: _vm.diameters[idx],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.diameters,
                                            idx,
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "diameters[idx]"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }






























import { Component, Vue } from 'vue-property-decorator'
import authService from '@/users/services/auth-service'
import accountService from '@/users/services/account-service'
import tenantsService from '@/tenants/services/tenants-service'

@Component
export default class App extends Vue {
  private snacks: boolean = false
  private dialog: boolean = false
  private color: string = 'info'
  private msg: string | object = ''

  get layout() {
    return this.$route.meta.layout || 'empty-layout'
  }

  // Hier können vorab Daten in den Store geladen werden, die sich für die
  // Dauer der Session eher selten ändern.
  private async prefetch() {}

  private showSnacks(color: string, msg: string) {
    this.color = color
    this.msg = msg
    this.snacks = true
  }

  private showExceptionDialog(msg: string | object) {
    this.msg = msg
    this.dialog = true
  }

  private created() {
    this.$bus.$on('app.snack.show', this.showSnacks)
    this.$bus.$on('app.exception-dialog.show', this.showExceptionDialog)
    this.$bus.$on('auth.logged-in', this.prefetch)
    this.$bus.$on('auth.logged-in', accountService.getCurrent)
    this.$bus.$on('auth.logged-in', tenantsService.getCurrent)
  }

  private async mounted() {
    if (authService.authenticated()) {
      await this.prefetch()
      const data = await accountService.getCurrent()
      if (data == null) {
        await authService.logoutAsync()
      }
      await tenantsService.getCurrent()
    }
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "800px",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("validation-observer", {
            ref: "validator",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var invalid = ref.invalid
                  return [
                    _c(
                      "v-card",
                      {
                        staticClass: "d-flex flex-column",
                        attrs: { "min-height": "100%" }
                      },
                      [
                        _c("v-card-title", [
                          _vm.editMode
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "_tenants._tenant_dialog.edit_tenant"
                                    )
                                  )
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("_tenants._tenant_dialog.add_tenant")
                                  )
                                )
                              ])
                        ]),
                        _c(
                          "v-card-text",
                          { staticClass: "flex-grow-1" },
                          [
                            _c(
                              "v-form",
                              [
                                _c(
                                  "v-container",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c("validation-provider", {
                                                      attrs: {
                                                        rules: "required",
                                                        name: _vm.$t("company")
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      label: _vm.$t(
                                                                        "company"
                                                                      ),
                                                                      outlined:
                                                                        "",
                                                                      dense: "",
                                                                      "error-messages": errors,
                                                                      "hide-details":
                                                                        errors.length ===
                                                                        0
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .model
                                                                          .company,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.model,
                                                                          "company",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "model.company"
                                                                    }
                                                                  }
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c("validation-provider", {
                                                      attrs: {
                                                        rules: "required",
                                                        name: _vm.$t("vat")
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      label: _vm.$t(
                                                                        "vat"
                                                                      ),
                                                                      outlined:
                                                                        "",
                                                                      dense: "",
                                                                      "error-messages": errors,
                                                                      "hide-details":
                                                                        errors.length ===
                                                                        0
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .model
                                                                          .vat,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.model,
                                                                          "vat",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "model.vat"
                                                                    }
                                                                  }
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c("image-input", {
                                              ref: "imageUpload",
                                              attrs: {
                                                value: "logo.png",
                                                "base-path":
                                                  "tenants/" + _vm.model.id,
                                                height: "104",
                                                width: "360",
                                                "aspect-ratio": 5,
                                                outlined: "",
                                                "message-color": "#fff",
                                                "background-color": "#454545"
                                              },
                                              on: { change: _vm.uploadImage }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pb-0",
                                            attrs: { cols: "12", sm: "6" }
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                rules: "required",
                                                name: _vm.$t("firstName")
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "firstName"
                                                            ),
                                                            outlined: "",
                                                            dense: "",
                                                            "error-messages": errors,
                                                            "hide-details":
                                                              errors.length ===
                                                              0
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.model
                                                                .firstName,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.model,
                                                                "firstName",
                                                                typeof $$v ===
                                                                  "string"
                                                                  ? $$v.trim()
                                                                  : $$v
                                                              )
                                                            },
                                                            expression:
                                                              "model.firstName"
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pb-0",
                                            attrs: { cols: "12", sm: "6" }
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                rules: "required",
                                                name: _vm.$t("lastName")
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "lastName"
                                                            ),
                                                            outlined: "",
                                                            dense: "",
                                                            "error-messages": errors,
                                                            "hide-details":
                                                              errors.length ===
                                                              0
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.model
                                                                .lastName,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.model,
                                                                "lastName",
                                                                typeof $$v ===
                                                                  "string"
                                                                  ? $$v.trim()
                                                                  : $$v
                                                              )
                                                            },
                                                            expression:
                                                              "model.lastName"
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                rules: "required|email",
                                                name: _vm.$t("email")
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "email"
                                                            ),
                                                            outlined: "",
                                                            dense: "",
                                                            "error-messages": errors,
                                                            "hide-details":
                                                              errors.length ===
                                                              0
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.model.email,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.model,
                                                                "email",
                                                                typeof $$v ===
                                                                  "string"
                                                                  ? $$v.trim()
                                                                  : $$v
                                                              )
                                                            },
                                                            expression:
                                                              "model.email"
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                rules: "required",
                                                name: _vm.$t("phone")
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "phone"
                                                            ),
                                                            outlined: "",
                                                            dense: "",
                                                            "error-messages": errors,
                                                            "hide-details":
                                                              errors.length ===
                                                              0
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.model.phone,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.model,
                                                                "phone",
                                                                typeof $$v ===
                                                                  "string"
                                                                  ? $$v.trim()
                                                                  : $$v
                                                              )
                                                            },
                                                            expression:
                                                              "model.phone"
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("address-input", {
                                              ref: "addressInput",
                                              attrs: {
                                                outlined: "",
                                                dense: "",
                                                "hide-details": ""
                                              },
                                              model: {
                                                value: _vm.model.address,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.model,
                                                    "address",
                                                    $$v
                                                  )
                                                },
                                                expression: "model.address"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                label:
                                                  "Tomifa Massivholzabwicklung"
                                              },
                                              model: {
                                                value:
                                                  _vm.model
                                                    .enabledTomifaSolidWood,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.model,
                                                    "enabledTomifaSolidWood",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "model.enabledTomifaSolidWood"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                rules: "integer|min_value:1",
                                                name: _vm.$t(
                                                  "solid_wood_num_dryingchamber"
                                                )
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("x-number-input", {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "solid_wood_num_dryingchamber"
                                                            ),
                                                            outlined: "",
                                                            dense: "",
                                                            "error-messages": errors,
                                                            "hide-details":
                                                              errors.length ===
                                                              0,
                                                            disabled: !_vm.model
                                                              .enabledTomifaSolidWood
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.model
                                                                .numberOfDryingChambers,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.model,
                                                                "numberOfDryingChambers",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "model.numberOfDryingChambers"
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                rules: "decimal|min_value:0",
                                                name: _vm.$t(
                                                  "solid_wood_offcut_factor"
                                                )
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("x-number-input", {
                                                          attrs: {
                                                            "fractional-digits": 2,
                                                            label: _vm.$t(
                                                              "solid_wood_offcut_factor"
                                                            ),
                                                            outlined: "",
                                                            dense: "",
                                                            "error-messages": errors,
                                                            "hide-details":
                                                              errors.length ===
                                                              0,
                                                            disabled: !_vm.model
                                                              .enabledTomifaSolidWood
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.model
                                                                .solidWoodOffcutFactor,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.model,
                                                                "solidWoodOffcutFactor",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "model.solidWoodOffcutFactor"
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "px-7 pb-5" },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass: "primary--text",
                                attrs: { text: "" },
                                on: { click: _vm.cancel }
                              },
                              [_vm._v(_vm._s(_vm.$t("cancel")))]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: invalid,
                                  loading: _vm.loading,
                                  depressed: "",
                                  color: "primary"
                                },
                                on: { click: _vm.confirm }
                              },
                              [
                                _vm.editMode
                                  ? _c("span", [_vm._v(_vm._s(_vm.$t("save")))])
                                  : _c("span", [_vm._v(_vm._s(_vm.$t("add")))])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
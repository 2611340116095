var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        attrs: {
          items: _vm.items,
          "return-object": "",
          "hide-no-data": "",
          "hide-selected": "",
          filter: _vm.filter,
          clearable: "",
          "menu-props": { "min-width": 450 }
        },
        on: { change: _vm.emitChange },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", { staticClass: "text-no-wrap" }, [
                  _vm._v(_vm._s(item.name))
                ])
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [_vm._v(_vm._s(item.name))]),
                    _c("v-list-item-subtitle", [_vm._v(_vm._s(item.number))])
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.product,
          callback: function($$v) {
            _vm.product = $$v
          },
          expression: "product"
        }
      },
      "v-autocomplete",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
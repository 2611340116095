var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._b(
      {
        attrs: {
          label: _vm.$t("tenant"),
          items: _vm.tenants,
          loading: _vm.loading,
          "return-object": "",
          clearable: ""
        },
        on: {
          change: function($event) {
            return _vm.emitChange()
          },
          "click:clear": function($event) {
            return _vm.emitClear()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", { attrs: { title: item.company } }, [
                  _vm._v(_vm._s(_vm._f("ellipsis")(item.company, _vm.ellipsis)))
                ])
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(" " + _vm._s(item.company) + " ")
                    ]),
                    _c(
                      "v-list-item-subtitle",
                      [
                        _c("address-line", { attrs: { address: item.address } })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.tenant,
          callback: function($$v) {
            _vm.tenant = $$v
          },
          expression: "tenant"
        }
      },
      "v-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }





















































































































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import AuthModule from '@/users/store/auth-module'
import { getModule } from 'vuex-module-decorators'

@Component
export default class SideMenu extends Vue {
  @Prop()
  private value!: boolean

  @Prop({ type: Number, default: 300 })
  private width!: number

  private show = true
  private pValue: boolean = false

  private tenantId: string | null = null

  private togglePermanent: boolean = true
  private feedbackDialog: boolean = false

  get logoUrl() {
    const authModule = getModule(AuthModule, this.$store)
    const base = this.$env.VUE_APP_IMAGES_URL
    const tenantId = this.$user.data.tenantId || authModule.tenant
    return `${base}/tenants/${tenantId}/logo.png`
  }

  @Watch('value')
  private valueChanged(newVal: boolean, oldVal: boolean) {
    if (newVal !== oldVal) {
      this.pValue = newVal
    }
  }

  @Watch('pValue')
  private pValueChanged(newVal: boolean, oldVal: boolean) {
    if (newVal !== oldVal) {
      this.$emit('input', newVal)
    }
  }

  get widthValue() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return '100%'
    }
    if (this.show) {
      return this.width
    }
    return 0
  }

  private toggleSideMenu() {
    this.pValue = !this.pValue
    this.$bus.$emit('sidemenu:toggle')
  }

  private showFeedbackDialog() {
    this.feedbackDialog = true
  }

  private changeTenant(tenantId: string | null) {
    const authModule = getModule(AuthModule, this.$store)
    authModule.setTenantId(tenantId)
    // Force a page reload to effect the tenant change.
    this.$router.go(0)
  }

  private loadTenant() {
    const authModule = getModule(AuthModule, this.$store)
    this.tenantId = authModule.tenant
  }

  private async mounted() {
    this.pValue = this.value
    this.loadTenant()
  }
}

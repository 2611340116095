var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "700px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "min-height": "100%" } },
        [
          _c("v-card-title", { staticStyle: { height: "64px" } }, [
            _c("span", [_vm._v(_vm._s(_vm.title))])
          ]),
          _c(
            "v-card-text",
            { staticClass: "flex-grow-1" },
            [
              _c(
                "v-toolbar",
                { staticClass: "mt-1", attrs: { flat: "", dense: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "debounce",
                        rawName: "v-debounce:input",
                        value: 500,
                        expression: "500",
                        arg: "input"
                      }
                    ],
                    staticClass: "mx-2",
                    attrs: {
                      label: _vm.$t("search"),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      clearable: "",
                      "append-icon": "search"
                    },
                    on: { "click:clear": _vm.clearSearch },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
                                ? _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            title: _vm.$t("more") + "...",
                                            icon: "",
                                            small: ""
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("more_vert")])],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _vm.editDisabled === false
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.createRootFolderAsync()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", [
                                        _vm._v("create_new_folder")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("create_new_base_dir")) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.moveDirectoryDialog.open()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                [_c("v-icon", [_vm._v("format_line_spacing")])],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("move_elements")) + "... "
                                )
                              ])
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t("show_hidden_folders"),
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.filter.includeHidden,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "includeHidden",
                                          $$v
                                        )
                                      },
                                      expression: "filter.includeHidden"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-treeview", {
                staticClass: "fill-height",
                attrs: {
                  open: _vm.open,
                  active: _vm.active,
                  items: _vm.items,
                  search: _vm.search,
                  filter: _vm.customTreeFilter,
                  activatable: "",
                  selectable: _vm.multiselect,
                  "selection-type": "independent",
                  "item-key": "id",
                  dense: "",
                  transition: "",
                  "return-object": ""
                },
                on: {
                  "update:open": function($event) {
                    _vm.open = $event
                  },
                  "update:active": function($event) {
                    _vm.active = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function(ref) {
                      var item = ref.item
                      var open = ref.open
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "clickable",
                            class: { hidden: item.isHidden },
                            on: {
                              click: function($event) {
                                return _vm.selectFolder(item)
                              },
                              dblclick: function($event) {
                                return _vm.selectFolderAndConfirm(item)
                              },
                              contextmenu: function($event) {
                                return _vm.showContextMenu($event, item)
                              }
                            }
                          },
                          [
                            item.isFolder
                              ? _c("v-icon", [
                                  _vm._v(
                                    " " +
                                      _vm._s(open ? "folder_open" : "folder") +
                                      " "
                                  )
                                ])
                              : _c("v-icon", [_vm._v(" text_snippet ")])
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "label",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "clickable",
                            class: { hidden: item.isHidden },
                            on: {
                              click: function($event) {
                                return _vm.selectFolder(item)
                              },
                              dblclick: function($event) {
                                return _vm.selectFolderAndConfirm(item)
                              },
                              contextmenu: function($event) {
                                return _vm.showContextMenu($event, item)
                              }
                            }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.isEditing,
                                  expression: "item.isEditing"
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.name,
                                  expression: "item.name"
                                }
                              ],
                              staticClass: "folder-name-input",
                              attrs: {
                                id: item.id,
                                placeholder: _vm.$t("new_folder")
                              },
                              domProps: { value: item.name },
                              on: {
                                blur: function($event) {
                                  return _vm.updateFolderAsync(item)
                                },
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.updateFolderAsync(item)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(item, "name", $event.target.value)
                                }
                              }
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !item.isEditing,
                                    expression: "!item.isEditing"
                                  }
                                ]
                              },
                              [
                                _vm._v(" " + _vm._s(item.name) + " "),
                                item.number
                                  ? _c(
                                      "small",
                                      { staticClass: "text--disabled" },
                                      [_vm._v(" " + _vm._s(item.number) + " ")]
                                    )
                                  : _vm._e(),
                                _c("tenancy-chip", {
                                  attrs: { "tenant-id": item.tenantId }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selection,
                  callback: function($$v) {
                    _vm.selection = $$v
                  },
                  expression: "selection"
                }
              }),
              _c(
                "v-menu",
                {
                  attrs: {
                    "position-x": _vm.mouseX,
                    "position-y": _vm.mouseY,
                    absolute: "",
                    "offset-y": ""
                  },
                  model: {
                    value: _vm.menuVisible,
                    callback: function($$v) {
                      _vm.menuVisible = $$v
                    },
                    expression: "menuVisible"
                  }
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.createFolderAsync()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-avatar",
                            [_c("v-icon", [_vm._v("create_new_folder")])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(_vm.$t("add_subfolder")) + " ")
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.selectedFolder
                        ? [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.editFolder()
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("title")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("rename_folder")) + " "
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.importProducts()
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("cloud_upload")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("import_products")) +
                                      "... "
                                  )
                                ])
                              ],
                              1
                            ),
                            _vm.selectedFolder.isHidden
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.unhideFolderAsync()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      [_c("v-icon", [_vm._v("visibility")])],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(" " + _vm._s(_vm.$t("show")) + " ")
                                    ])
                                  ],
                                  1
                                )
                              : _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.hideFolderAsync()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      [
                                        _c("v-icon", [_vm._v("visibility_off")])
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(" " + _vm._s(_vm.$t("hide")) + " ")
                                    ])
                                  ],
                                  1
                                ),
                            _c("v-divider"),
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.removeFolderAsync()
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("delete")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" " + _vm._s(_vm.$t("delete")) + " ")
                                ])
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              ),
              _c("move-template-directory-dialog", {
                ref: "moveDirectoryDialog",
                on: {
                  confirm: function($event) {
                    return _vm.reloadAsync()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-7 pb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { text: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    depressed: "",
                    color: "primary"
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("select")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }







import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Project, { ProjectStatus } from "@/projects/entities/project"

@Component
export default class ProjectStatusDisplay extends Vue {
  @Prop({ default: ProjectStatus.Open })
  public value!: ProjectStatus

  get statusList() {
      return Project.statusList
  }

  statusColor(status: ProjectStatus) {
    return Project.statusColor(status)
  }
}

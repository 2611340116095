














































































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import EditDialogMixin from '@/common/dialogs/mixins/edit-dialog-mixin'
import Company from '@/companies/entities/company'

@Component
export default class CompanyDialog extends Mixins<EditDialogMixin<Company>>(
  EditDialogMixin
) {
  model = new Company()

  public isNameDisabled() {
    switch (this.model.importedFrom ?? '') {
      case '':
        return false
      default:
        return true
    }
  }

  public isReadOnly() {
    switch (this.model.importedFrom ?? '') {
      case '':
        return false
      case 'IMOS':
        return false
      case 'CSV':
        return false
      default:
        return true
    }
  }
}

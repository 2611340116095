var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "fill-width", attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "container-toolbar", attrs: { flat: "" } },
        [
          _c("product-category-select", {
            staticClass: "mr-2",
            staticStyle: { width: "200px" },
            attrs: { outlined: "", dense: "", "hide-details": "" },
            on: {
              input: function($event) {
                return _vm.loadAsync()
              }
            },
            model: {
              value: _vm.filter.category,
              callback: function($$v) {
                _vm.$set(_vm.filter, "category", $$v)
              },
              expression: "filter.category"
            }
          }),
          _c("company-select", {
            staticClass: "mr-2",
            staticStyle: { "max-width": "400px" },
            attrs: {
              label: _vm.$t("manufacturer"),
              type: "manufacturer",
              outlined: "",
              dense: "",
              "hide-details": "",
              ellipsis: 60
            },
            on: {
              input: function($event) {
                return _vm.loadAsync()
              }
            },
            model: {
              value: _vm.filter.manufacturerId,
              callback: function($$v) {
                _vm.$set(_vm.filter, "manufacturerId", $$v)
              },
              expression: "filter.manufacturerId"
            }
          }),
          _c("company-select", {
            staticClass: "mr-2",
            staticStyle: { "max-width": "400px" },
            attrs: {
              label: _vm.$t("supplier"),
              type: "supplier",
              outlined: "",
              dense: "",
              "hide-details": "",
              ellipsis: 60
            },
            on: {
              input: function($event) {
                return _vm.loadAsync()
              }
            },
            model: {
              value: _vm.filter.supplierId,
              callback: function($$v) {
                _vm.$set(_vm.filter, "supplierId", $$v)
              },
              expression: "filter.supplierId"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: {
              label: _vm.$t("_products._product-inventory.bulk_good_only"),
              "hide-details": ""
            },
            on: {
              click: function($event) {
                return _vm.loadAsync()
              }
            },
            model: {
              value: _vm.filter.bulkGoodsOnly,
              callback: function($$v) {
                _vm.$set(_vm.filter, "bulkGoodsOnly", $$v)
              },
              expression: "filter.bulkGoodsOnly"
            }
          }),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { depressed: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.print()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("print")) + " ")]
          )
        ],
        1
      ),
      _c("product-inventory-list", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce:changed:stock",
            value: 500,
            expression: "500",
            arg: "changed:stock"
          }
        ],
        attrs: {
          id: "print-container",
          inventory: _vm.inventory,
          editable: ""
        },
        on: { "changed:stock": _vm.updateStockAsync }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SideMenuItem extends Vue {

  @Prop({ default: '' })
  public icon!: string

  @Prop({ default: '' })
  public iconClass!: string

  @Prop({ required: true })
  public title!: string

  @Prop({ default: '' })
  public route!: string

  @Prop({ default: '' })
  public href!: string

  @Prop({ default: '#' })
  public target!: string

  @Prop({ default: 20 })
  public size!: number

  private clicked() {
    this.$emit('click')
  }
}

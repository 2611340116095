













































import { Component, Vue, Ref } from 'vue-property-decorator'
import downloadAsync from '@/common/files/download'
import Company from '@/companies/entities/company'

@Component
export default class CorrectionListExportDialog extends Vue {
  @Ref()
  private validator!: ValidationObserver

  private dialog = false
  private loading = false

  private company: Company = new Company()
  private type: 'manufacturer' | 'supplier' = 'manufacturer'

  public open(company: Company) {
    this.company = company
    this.type = company.isManufacturer ? 'manufacturer' : 'supplier'
    this.dialog = true
    this.loading = false
  }

  private async confirm() {
    if (await this.validator.validate()) {
      this.loading = true
      await this.exportCsv()
      this.loading = false
      this.reset()
      this.cancel()
    }
  }

  private async exportCsv() {
    const url = `correctionlistfiles/${this.company.id}/${this.type}`
    const filename = `Bereinigungsliste_${this.company.name || 'Firma'}_${this.type
      }.csv`
    await downloadAsync(url, filename)
  }

  private reset() {
    if (this.validator) {
      this.validator.reset()
    }
  }

  private cancel() {
    this.dialog = false
  }
}

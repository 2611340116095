

































import { Component, Prop, Vue } from 'vue-property-decorator'
import isString from 'lodash/isString'

@Component
export default class App extends Vue {
  @Prop({ default: false })
  public value!: boolean

  @Prop({ default: '' })
  public msg!: string | object

  get msgHtml() {
    if (isString(this.msg)) {
      return `<pre>${this.msg}</pre>`
    }
    return `<pre>${JSON.stringify(this.msg, null, 2)}</pre>`
  }

  public close() {
    this.$emit('input', false)
  }
}

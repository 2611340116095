




import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class CalendarWeek extends Vue {
  @Prop({ default: '' })
  public value!: string
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "container-toolbar", attrs: { flat: "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: {
                to: {
                  name: "project-orders",
                  params: { projectId: _vm.project.id, lang: _vm.$i18n.locale }
                },
                depressed: "",
                small: ""
              }
            },
            [_vm._v(_vm._s(_vm.$t("_projects._parts_list.to_orders")))]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                title: _vm.$t("_projects._parts_list.expand_all"),
                icon: ""
              },
              on: { click: _vm.expandAll }
            },
            [_c("v-icon", [_vm._v("expand")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                title: _vm.$t("_projects._parts_list.collapse_all"),
                icon: ""
              },
              on: { click: _vm.collapseAll }
            },
            [_c("v-icon", [_vm._v("vertical_align_center")])],
            1
          ),
          _c("product-category-select", {
            staticClass: "ml-2",
            attrs: {
              multiple: "",
              outlined: "",
              dense: "",
              "hide-details": "",
              close: "",
              limit: 5,
              clearable: ""
            },
            model: {
              value: _vm.categories,
              callback: function($$v) {
                _vm.categories = $$v
              },
              expression: "categories"
            }
          })
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        {
          staticClass: "mb-16",
          attrs: { multiple: "" },
          model: {
            value: _vm.panels,
            callback: function($$v) {
              _vm.panels = $$v
            },
            expression: "panels"
          }
        },
        _vm._l(_vm.groups, function(group, index) {
          return _c(
            "v-expansion-panel",
            { key: index },
            [
              _c(
                "v-expansion-panel-header",
                { staticClass: "pl-4" },
                [
                  _c("parts-list-group-header", {
                    attrs: { group: group, index: index },
                    on: {
                      "group:up": _vm.up,
                      "group:down": _vm.down,
                      "group:addfavorite": _vm.createTemplateDialog.open,
                      "group:edit": _vm.groupDialog.open,
                      "group:duplicate": _vm.duplicateGroup,
                      "group:remove": _vm.removeGroup
                    }
                  })
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                [
                  _c("parts-list-group-items", {
                    attrs: {
                      group: group,
                      categories: _vm.categories,
                      "order-states": _vm.orderStates,
                      "is-warehouse-project": _vm.project.storeIds.length > 0
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _vm.groups.length === 0
        ? _c(
            "div",
            { staticClass: "text-center font-italic text--secondary" },
            [_vm._v(" " + _vm._s(_vm.$t("_projects._parts_list.empty")) + " ")]
          )
        : _vm._e(),
      _c("parts-list-group-dialog", {
        ref: "groupDialog",
        attrs: { "order-states": _vm.orderStates },
        on: { confirm: _vm.saveAsync, cancel: _vm.cleanupGroup }
      }),
      _c("partlist-upload-dialog", {
        ref: "partlistUploadDialog",
        on: { imported: _vm.loadAsync }
      }),
      _c("project-items-dialog", {
        ref: "machineListDialog",
        attrs: {
          title: "Maschinen CSV erstellen",
          "empty-message": "Stückliste enthält keine Platten",
          "order-states": _vm.orderStates,
          "selected-categories": _vm.machineCategoryIds,
          "hide-categories": ""
        },
        on: { confirm: _vm.createMachineListAsync }
      }),
      _c("project-items-dialog", {
        ref: "printableListDialog",
        attrs: {
          title: "Druckansicht erstellen",
          "empty-message":
            "Stückliste enthält keine Positionen der ausgewählten Kategorien",
          "order-states": _vm.orderStates,
          "selected-categories": _vm.allCategoryIds
        },
        on: { confirm: _vm.createPrintableListAsync }
      }),
      _c("project-items-dialog", {
        ref: "printableSumsDialog",
        attrs: {
          title: "Druckansicht Massenermittlung erstellen",
          "empty-message":
            "Stückliste enthält keine Positionen der ausgewählten Kategorien",
          "order-states": _vm.orderStates,
          "selected-categories": _vm.allCategoryIds
        },
        on: { confirm: _vm.createPrintableSumsAsync }
      }),
      _c("project-items-dialog", {
        ref: "orderListDialog",
        attrs: {
          title: "Bestellung erstellen",
          "empty-message":
            "Stückliste enthält keine Positionen der ausgewählten Kategorien",
          "order-states": _vm.orderStates,
          "selected-categories": _vm.allCategoryIds
        },
        on: { confirm: _vm.createOrderListAsync }
      }),
      _c("project-group-templates-dialog", {
        ref: "projectGroupTemplatesDialog",
        on: { templateClicked: _vm.projectGroupTemplateSelected }
      }),
      _c("create-template-dialog", {
        ref: "createTemplateDialog",
        on: { confirm: _vm.createTemplateAsync }
      }),
      _c(
        "v-btn",
        {
          staticClass: "mb-8",
          staticStyle: { "margin-right": "122px" },
          attrs: {
            loading: _vm.loading,
            title: _vm.$t("_projects._parts_list.add_group_template"),
            fab: "",
            small: "",
            fixed: "",
            bottom: "",
            right: ""
          },
          on: {
            click: function($event) {
              return _vm.projectGroupTemplatesDialog.open()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "icon-group" },
            [
              _c("v-icon", [_vm._v("favorite")]),
              _c(
                "v-icon",
                { staticClass: "icon-group__badge", attrs: { small: "" } },
                [_vm._v("add")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-btn",
        {
          staticClass: "mb-8",
          staticStyle: { "margin-right": "72px" },
          attrs: {
            loading: _vm.loading,
            title: _vm.$t("_projects._parts_list.add_group"),
            fab: "",
            small: "",
            fixed: "",
            bottom: "",
            right: ""
          },
          on: {
            click: function($event) {
              return _vm.addGroup()
            }
          }
        },
        [_c("v-icon", [_vm._v("playlist_add")])],
        1
      ),
      _c(
        "v-speed-dial",
        {
          staticClass: "mb-8",
          attrs: {
            fixed: "",
            bottom: "",
            right: "",
            direction: "top",
            transition: "slide-y-reverse-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", fab: "", large: "" },
                      model: {
                        value: _vm.fab,
                        callback: function($$v) {
                          _vm.fab = $$v
                        },
                        expression: "fab"
                      }
                    },
                    [
                      _vm.fab
                        ? _c("v-icon", [_vm._v("close")])
                        : _c("v-icon", [_vm._v("build")])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.fab,
            callback: function($$v) {
              _vm.fab = $$v
            },
            expression: "fab"
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { fab: "", title: "IMOS Stückliste hochladen" },
              on: {
                click: function($event) {
                  return _vm.openPartlistUploadDialog()
                }
              }
            },
            [_c("v-icon", [_vm._v("cloud_upload")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { fab: "", title: "Maschinenliste erstellen" },
              on: {
                click: function($event) {
                  return _vm.openMachineListDialog()
                }
              }
            },
            [_c("v-icon", [_vm._v("api")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { fab: "", title: "Druckansicht erstellen" },
              on: {
                click: function($event) {
                  return _vm.openPrintableListDialog()
                }
              }
            },
            [_c("v-icon", [_vm._v("print")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                title: "Druckansicht Massenermittlung erstellen"
              },
              on: {
                click: function($event) {
                  return _vm.openPrintableSumsDialog()
                }
              }
            },
            [_c("v-icon", [_vm._v("note_add")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                disabled: !_vm.canOrder,
                title: "Bestellung erstellen"
              },
              on: {
                click: function($event) {
                  return _vm.openOrderListDialog()
                }
              }
            },
            [_c("v-icon", [_vm._v("shopping_cart")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }





















































import authService from '@/users/services/auth-service'
import { Component, Vue, Ref } from 'vue-property-decorator'

@Component
export default class ResetForm extends Vue {
  @Ref()
  private validator!: ValidationObserver

  private email = ''
  private loading = false

  private async sendResetLink() {
    if (await this.validator.validate()) {
      this.loading = true
      await authService.sendPasswordResetLinkAsync(this.email)
      this.loading = false
      this.$emit('reset')
    }
  }

  private cancel() {
    this.$emit('cancel')
  }
}






























































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import PriceHistory from '@/products/entities/price-history'
import priceHistoryService from '@/products/services/price-history-service'
import PriceHistoryFilter from '@/products/entities/price-history-filter'
import moment from 'moment'
import Dates from '@/app/utils/dates'

@Component
export default class PriceHistoryDialog extends Vue {
  @Prop()
  private currency!: string

  private dialog = false
  private loading = false
  private tab = 0
  private filter = new PriceHistoryFilter()
  private history: PriceHistory[] = []

  private get headers() {
    return [
      {
        text: this.$tc('date'),
        align: 'start',
        value: 'priceDate',
        cellClass: 'text-caption',
        sortable: false,
      },
      {
        text: this.$tc('purchase_price'),
        value: 'netPurchasePrice',
        sortable: false,
        align: 'end',
      },
      {
        text: this.$tc('price_change'),
        value: 'change',
        sortable: false,
        align: 'end',
        width: '100px',
      },
    ]
  }

  private get scale() {
    const diff = Dates.absPeriodDiffInDays(this.filter.period)
    if (diff <= 31) {
      return 'day'
    }
    if (diff <= 365) {
      return 'month'
    }
    return 'year'
  }

  public async open(productId: string, supplierId: string) {
    this.filter.productId = productId
    this.filter.supplierId = supplierId
    this.filter.period.begin = moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD')
    await this.fetchAllAsync()
    this.dialog = true
  }

  private async fetchAllAsync() {
    this.loading = true
    this.history = await priceHistoryService.findAsync(this.filter)
    this.loading = false
  }

  private cancel() {
    this.dialog = false
    this.$emit('cancel')
  }

  private get chartLabels() {
    return this.history.map((h) => h.priceDate)
  }

  private get chartData() {
    return [this.history.map((h) => h.netPurchasePrice)]
  }

  private get chartConfig() {
    return {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#363636',
            borderColor: '#363636',
            borderWidth: 1,
            xAxisID: 'x',
            yAxisID: 'y',
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        scales: {
          x: {
            type: 'time',
            time: {
              parser: 'YYYY-MM-DD[T]HH:mm:ss',
              unit: this.scale,
              displayFormats: {
                day: 'DD. MMM YY',
                month: 'MMM YY',
              },
            },
            ticks: {
              source: 'data',
              maxRotation: 90,
              minRotation: 90,
            },
          },
          y: {
            title: {
              display: true,
              text: this.currency,
              font: {
                size: 16,
                weight: 'bold',
              },
              color: '#777',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    }
  }
}

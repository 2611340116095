


































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import Project from '@/projects/entities/project'
import PaginationRequest from '@/common/pagination/entities/pagination-request'
import projectsService from '@/projects/services/projects-service'
import ProjectDialog from '@/projects/components/project-dialog.vue'
import ProjectImportDialog from '@/projects/components/project-import-dialog.vue'
import ServiceResult from '@/app/utils/service-result'
import snacks from '@/app/services/snacks'

@Component
export default class Projects extends Vue {
  @Ref()
  private projectDialog!: ProjectDialog

  @Ref()
  private projectImportDialog!: ProjectImportDialog

  private fab = false
  private loading = false
  private isOpen = true
  private isInPreparation = true
  private isInProduction = true
  private isInDelivery = true
  private isClosed = false
  private isCancelled = false
  private projects: Project[] = []
  private selectedProjectId = ''
  private customerId: string | null = null
  private leadId: string | null = null
  private search: string = ''
  private pagination = new PaginationRequest()

  private async fetchAll() {
    this.loading = true
    const response = await projectsService.findAllFilteredAsync({
      paging: this.pagination,
      isOpen: this.isOpen,
      isInPreparation: this.isInPreparation,
      isInProduction: this.isInProduction,
      isInDelivery: this.isInDelivery,
      customerId: this.customerId,
      leadId: this.leadId,
      search: this.search,
      isClosed: this.isClosed,
      isCancelled: this.isCancelled,
    })
    this.projects = response.items
    this.pagination.totalItems = response.totalItems
    this.loading = false
  }

  private openNew() {
    this.projectDialog.openNew(new Project())
  }

  private openEdit(project: Project) {
    this.projectDialog.openEdit(project)
  }

  private importProject() {
    this.projectImportDialog.open()
  }

  public async imported(result: ServiceResult<Project>) {
    if (result.success) {
      if (result.message) {
        snacks.info(result.message)
      }
      await this.fetchAll()
      this.projectImportDialog.close()
    } else {
      snacks.error(result.message)
    }
  }

  private async create(project: Project) {
    await projectsService.createAsync(project)
    await this.fetchAll()
  }

  private async update(project: Project) {
    await projectsService.updateAsync(project)
    await this.fetchAll()
  }

  private async remove(project: Project) {
    await projectsService.removeAsync(project.id)
    await this.fetchAll()
  }

  @Watch('pagination', { deep: true })
  private async paginationChanged(
    newVal: PaginationRequest,
    oldVal: PaginationRequest
  ) {
    if (newVal !== oldVal) {
      await this.fetchAll()
    }
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    {
      staticClass: "side-menu-item",
      attrs: { to: _vm.route, href: _vm.href, target: _vm.target },
      on: { click: _vm.clicked }
    },
    [
      _c(
        "v-list-item-action",
        { staticClass: "side-menu-item--icon" },
        [
          _vm._t("default", [
            _vm.icon ? _c("v-icon", [_vm._v(_vm._s(_vm.icon))]) : _vm._e(),
            _vm.iconClass
              ? _c("i", {
                  class: _vm.iconClass,
                  style: "font-size:" + _vm.size + "px;"
                })
              : _vm._e()
          ])
        ],
        2
      ),
      _c(
        "v-list-item-content",
        [_c("v-list-item-title", [_vm._v(_vm._s(_vm.title))])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex flex-row" },
            [
              _c(
                "div",
                { staticClass: "ma-2" },
                [
                  _c("date-input", {
                    attrs: {
                      label: _vm.$t("since"),
                      type: "date",
                      "display-format": "DD.MM.YYYY",
                      "model-format": "YYYY-MM-DD",
                      width: "200"
                    },
                    model: {
                      value: _vm.date,
                      callback: function($$v) {
                        _vm.date = $$v
                      },
                      expression: "date"
                    }
                  })
                ],
                1
              ),
              _c("v-text-field", {
                staticClass: "ma-3",
                attrs: { clearable: "", label: _vm.$t("search") },
                model: {
                  value: _vm.searchText,
                  callback: function($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-simple-table", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v(" " + _vm._s(_vm.$t("number")) + " ")
                    ]),
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v(" " + _vm._s(_vm.$t("name")) + " ")
                    ]),
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v(" " + _vm._s(_vm.$t("price_history")) + " ")
                    ])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.getFilteredProducts(), function(product) {
                    return _c("tr", [
                      _c("td", { staticStyle: { "vertical-align": "top" } }, [
                        _vm._v(_vm._s(product.number))
                      ]),
                      _c("td", { staticStyle: { "vertical-align": "top" } }, [
                        _vm._v(_vm._s(product.name))
                      ]),
                      _c("td", [
                        _c(
                          "table",
                          { staticStyle: { width: "250px" } },
                          _vm._l(product.priceHistory, function(price) {
                            return _c("tr", [
                              _c("td", { staticStyle: { padding: "3px" } }, [
                                _vm._v(
                                  _vm._s(_vm._f("dateTime")(price.priceDate))
                                )
                              ]),
                              _c(
                                "td",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { padding: "3px" }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("money")(price.netPurchasePrice)
                                      )
                                    )
                                  ])
                                ]
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    ])
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
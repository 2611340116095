




import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AcademicTitleSelect extends Vue {
  @Prop({ required: true })
  private value!: string

  private titles = [
    { text: 'Mag.', value: 'Mag' },
    { text: 'Dr.', value: 'Dr' },
  ]

  private title: string = this.value

  @Watch('value')
  private valueChanged(val: string, old: string) {
    if (val !== old) {
      this.title = val
    }
  }

  @Watch('title')
  private titleChanged(val: string, old: string) {
    this.$emit('input', val)
  }
}




































































































































import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import productsService from '@/products/services/products-service'
import Product from '@/products/entities/product'
import ProjectItem from '@/projects/entities/project-item'
import Profiles from '@/products/entities/profiles'
import { ProductCategory } from '@/products/entities/product-category'

@Component
export default class ProfilesPicker extends Vue {
  @Prop()
  private value!: ProjectItem

  private items = [
    { name: 'V', value: 'V' },
    { name: 'V=H', value: 'V=H' },
    { name: 'V=H=L=R', value: 'V=H=L=R' },
    { name: 'V/H/L/R', value: 'V/H/L/R' },
  ]

  private profileCategories = [
    ProductCategory.Anleimer,
    ProductCategory.MaterialKanten,
    ProductCategory.Furnierkante,
    // ProductCategory.FurnierBelegung
  ]

  private dialog = false
  private loading = false

  private selected = this.items[0]

  private front: Product | null = this.value?.frontEdgingProduct
  private back: Product | null = this.value?.backEdgingProduct
  private left: Product | null = this.value?.leftEdgingProduct
  private right: Product | null = this.value?.rightEdgingProduct
  private special: Product | null = this.value?.customEdgingProduct

  get showH() {
    const v = this.selected.value
    return v === 'V/H/L/R'
  }

  get showL() {
    const v = this.selected.value
    return v === 'V/H/L/R'
  }

  get showR() {
    const v = this.selected.value
    return v === 'V/H/L/R'
  }

  @Watch('value', { deep: true })
  private async valueChanged(val: ProjectItem) {
    await this.init()
  }

  @Watch('selected')
  private selectedChanged(val: any, old: any) {
    if (val.value !== old.value) {
      switch (val.value) {
        case 'V':
          this.back = null
          this.left = null
          this.right = null
          break
        case 'V=H':
          this.back = this.front
          this.left = null
          this.right = null
          break
        case 'V=H=L=R':
          this.back = this.front
          this.left = this.front
          this.right = this.front
          break
      }
    }
  }

  public open() {
    this.dialog = true
  }

  public confirm() {
    this.dialog = false

    const profiles = new Profiles()
    profiles.front = this.front
    profiles.back = this.back
    profiles.left = this.left
    profiles.right = this.right
    profiles.special = this.special
    this.$emit('confirm', profiles)
  }

  public cancel() {
    this.dialog = false
    this.$emit('cancel')
  }

  private frontSelected(val: Product | null) {
    switch (this.selected.value) {
      case 'V=H':
        this.back = this.front
        this.left = null
        this.right = null
        break
      case 'V=H=L=R':
        this.back = this.front
        this.left = this.front
        this.right = this.front
        break
    }
  }

  private async load(product: Product | null, id: string | null) {
    if (product !== null) {
      return product
    }
    if (!id) {
      return null
    }
    return await productsService.findOneAsync(id)
  }

  private async init() {
    this.front = await this.load(
      this.value.frontEdgingProduct,
      this.value.frontEdgingProductId
    )

    this.back = await this.load(
      this.value.backEdgingProduct,
      this.value.backEdgingProductId
    )

    this.left = await this.load(
      this.value.leftEdgingProduct,
      this.value.leftEdgingProductId
    )

    this.right = await this.load(
      this.value.rightEdgingProduct,
      this.value.rightEdgingProductId
    )

    this.special = await this.load(
      this.value.customEdgingProduct,
      this.value.customEdgingProductId
    )
  }

  private async mounted() {
    await this.init()
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tenantId == null
    ? _c("v-chip", { staticClass: "ma-2", attrs: { "x-small": "" } }, [
        _vm._v(" " + _vm._s(_vm.$t("_tenants.global")) + " ")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.delta !== 0 || !_vm.hideFlatChange
    ? _c(
        "div",
        { staticClass: "d-flex flex-column align-end", class: _vm.colorClass },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("div", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm._f("snum")(_vm.deltaPercent, 2)) + "%")
              ]),
              _vm.delta > 0
                ? _c(
                    "v-icon",
                    { class: _vm.colorClass, attrs: { size: "20px" } },
                    [_vm._v(" trending_up ")]
                  )
                : _vm.delta < 0
                ? _c(
                    "v-icon",
                    { class: _vm.colorClass, attrs: { size: "20px" } },
                    [_vm._v(" trending_down ")]
                  )
                : _c(
                    "v-icon",
                    { class: _vm.colorClass, attrs: { size: "20px" } },
                    [_vm._v(" trending_flat ")]
                  )
            ],
            1
          ),
          _c("div", { staticStyle: { "font-size": "80%", opacity: "75%" } }, [
            _vm._v(
              " (" +
                _vm._s(_vm._f("snum")(_vm.delta, _vm.numberOfFractionDigits)) +
                " " +
                _vm._s(_vm.unit) +
                ") "
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
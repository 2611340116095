var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._v(_vm._s(_vm._f("logwoodPackageNr")(_vm.pack)))]),
    _c(
      "div",
      {
        staticClass: "text--secondary font-size-75",
        staticStyle: { "line-height": "1.1" }
      },
      [
        _vm._v(
          " " +
            _vm._s(_vm.pack.productName || _vm.$t("additional_purchases")) +
            " → " +
            _vm._s(_vm.pack.targetProductName) +
            " "
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./logwood-history.vue?vue&type=template&id=102421b6&scoped=true&"
import script from "./logwood-history.vue?vue&type=script&lang=ts&"
export * from "./logwood-history.vue?vue&type=script&lang=ts&"
import style0 from "./logwood-history.vue?vue&type=style&index=0&id=102421b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "102421b6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardText,VCardTitle,VDataTable,VIcon,VSpacer,VToolbar,VWindow,VWindowItem})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('102421b6')) {
      api.createRecord('102421b6', component.options)
    } else {
      api.reload('102421b6', component.options)
    }
    module.hot.accept("./logwood-history.vue?vue&type=template&id=102421b6&scoped=true&", function () {
      api.rerender('102421b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/logwood/components/logwood-history.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users fill-width" },
    [
      _c(
        "v-toolbar",
        { staticClass: "container-toolbar", attrs: { flat: "" } },
        [
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("_users._roles.ADMIN"), "hide-details": "" },
            on: {
              click: function($event) {
                return _vm.fetchAll(true)
              }
            },
            model: {
              value: _vm.filter.isAdmin,
              callback: function($$v) {
                _vm.$set(_vm.filter, "isAdmin", $$v)
              },
              expression: "filter.isAdmin"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: {
              label: _vm.$t("_users._roles.TENANT"),
              "hide-details": ""
            },
            on: {
              click: function($event) {
                return _vm.fetchAll(true)
              }
            },
            model: {
              value: _vm.filter.isTenant,
              callback: function($$v) {
                _vm.$set(_vm.filter, "isTenant", $$v)
              },
              expression: "filter.isTenant"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("_users._roles.USER"), "hide-details": "" },
            on: {
              click: function($event) {
                return _vm.fetchAll(true)
              }
            },
            model: {
              value: _vm.filter.isUser,
              callback: function($$v) {
                _vm.$set(_vm.filter, "isUser", $$v)
              },
              expression: "filter.isUser"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: {
              label: _vm.$t("_users._roles.RESTRICTED"),
              "hide-details": ""
            },
            on: {
              click: function($event) {
                return _vm.fetchAll(true)
              }
            },
            model: {
              value: _vm.filter.isRestricted,
              callback: function($$v) {
                _vm.$set(_vm.filter, "isRestricted", $$v)
              },
              expression: "filter.isRestricted"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: {
              label: _vm.$t("_users._roles.EXTERNAL"),
              "hide-details": ""
            },
            on: {
              click: function($event) {
                return _vm.fetchAll(true)
              }
            },
            model: {
              value: _vm.filter.isExternal,
              callback: function($$v) {
                _vm.$set(_vm.filter, "isExternal", $$v)
              },
              expression: "filter.isExternal"
            }
          }),
          _c("v-text-field", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce:input",
                value: 500,
                expression: "500",
                arg: "input"
              }
            ],
            attrs: {
              label: _vm.$t("search"),
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: "",
              "append-icon": "search"
            },
            on: {
              input: function($event) {
                return _vm.fetchAll(true)
              }
            },
            model: {
              value: _vm.filter.search,
              callback: function($$v) {
                _vm.$set(_vm.filter, "search", $$v)
              },
              expression: "filter.search"
            }
          })
        ],
        1
      ),
      _c("user-dialog", {
        ref: "dialog",
        on: { create: _vm.create, update: _vm.update }
      }),
      _c("users-table", {
        attrs: {
          loading: _vm.loading,
          users: _vm.users,
          pagination: _vm.filter.paging
        },
        on: {
          "update:pagination": function($event) {
            return _vm.$set(_vm.filter, "paging", $event)
          },
          edit: _vm.openEdit,
          impersonate: _vm.impersonate,
          enable: _vm.enable,
          disable: _vm.disable,
          delete: _vm.remove
        }
      }),
      _c(
        "v-btn",
        {
          staticClass: "mb-8",
          attrs: {
            fixed: "",
            fab: "",
            bottom: "",
            right: "",
            color: "primary"
          },
          on: { click: _vm.openNew }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
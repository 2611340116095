export interface LogwoodPackageKey {
  state: LogwoodPackageState
  location: number
}

export enum LogwoodPackageState {
  OuterBearing = 1,
  DryingChamber = 2,
  DryBearing = 3,
  Consumed = 4,
}

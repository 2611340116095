var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "800px",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
            persistent: ""
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column",
              attrs: { "min-height": "100%" }
            },
            [
              _c("v-card-title", { staticStyle: { height: "64px" } }, [
                _vm._v(_vm._s(_vm.$t("contact_persons")))
              ]),
              _c(
                "v-card-text",
                { staticClass: "flex-grow-1" },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.contacts,
                      "item-key": "id",
                      loading: _vm.loading,
                      "loading-text": _vm.$t("please_wait") + "...",
                      "no-data-text": _vm.$t("no_data_available"),
                      "hide-default-footer": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.salutation",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(_vm._s(item.salutation))]
                          }
                        },
                        {
                          key: "item.firstName",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(_vm._s(item.firstName))]
                          }
                        },
                        {
                          key: "item.lastName",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(_vm._s(item.lastName))]
                          }
                        },
                        {
                          key: "item.email",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(_vm._s(item.email))]
                          }
                        },
                        {
                          key: "item.action",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                { staticClass: "text-no-wrap" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { icon: "", title: "Bearbeiten" },
                                      on: {
                                        click: function($event) {
                                          return _vm.editContact(item)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("edit")])],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { icon: "", title: "Löschen" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteContact(item)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("delete")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-5" },
                    [
                      _c("v-btn", { on: { click: _vm.add } }, [
                        _vm._v(_vm._s(_vm.$t("add")))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "px-7 pb-5" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text",
                      attrs: { text: "" },
                      on: { click: _vm.close }
                    },
                    [_vm._v(_vm._s(_vm.$t("close")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("contact-dialog", {
        ref: "contactDialog",
        on: { create: _vm.create, update: _vm.update }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.address
    ? _c("div", [
        _vm._v(" " + _vm._s(_vm.address.addressLine) + " "),
        _vm.address.addressLine ? _c("span", [_vm._v("·")]) : _vm._e(),
        _vm._v(" " + _vm._s(_vm.address.countryId) + " "),
        _vm._v(
          " " +
            _vm._s(_vm.address.postalCode) +
            " " +
            _vm._s(_vm.address.city) +
            " "
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }











import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class BusinessDivisionSelect extends Vue {
  @Prop({ required: true })
  private value!: string

  @Prop({ type: Boolean, default: false })
  private allowEmpty!: Boolean

  @Prop({ type: Boolean, default: false })
  private allowAll!: Boolean

  private division = this.value

  private get items() {
    const list: { value: string; text: string }[] = []

    if (this.allowEmpty) {
      list.push({ value: '', text: this.$tc('none') })
    }

    if (this.allowAll) {
      list.push({ value: 'all', text: this.$tc('all') })
    }

    list.push({ value: 'GB10', text: 'GB10' })
    list.push({ value: 'GB20', text: 'GB20' })
    list.push({ value: 'GB30', text: 'GB30' })
    list.push({ value: 'GB33', text: 'GB33' })
    list.push({ value: 'GB40', text: 'GB40' })
    list.push({ value: 'GB50', text: 'GB50' })
    list.push({ value: 'GB60', text: 'GB60' })
    list.push({ value: 'GB80', text: 'GB80' })

    return list
  }

  @Watch('value')
  private valueChanged(val: string, old: string) {
    if (val !== old) {
      this.division = val
    }
  }

  @Watch('division')
  private divisionChanged(val: string) {
    this.$emit('input', val)
  }
}

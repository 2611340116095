



















































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import projectsService from '@/projects/services/projects-service'
import axios from '@/app/axios'
import Project from '@/projects/entities/project'
import ProjectSumItem from '@/projects/entities/project-sum-item'
import moment from 'moment'

type OrderBy = {
  categoryName: string,
  column: string,
  descending: boolean
}

interface IIndexable {
  [key: string]: any;
}

@Component
export default class PrintSums extends Vue {
  @Prop()
  private projectId!: string

  @Prop()
  private itemIds!: string

  private project: Project = new Project()
  private items: ProjectSumItem[] = []
  private categoryOrderBy: OrderBy[] = []

  private getDateTime() {
    return moment().format('DD.MM.YYYY HH:mm')
  }

  private nameOrNumber(item: ProjectSumItem) {
    return item.productName == item.productNumber
      ? item.productNumber
      : item.productName
  }

  private get categories() {
    return this.items
      .map((i) => i.categoryName)
      .filter((v, i, a) => a.indexOf(v) == i)
  }

  private categoryItems(cat: string) {
    let result = this.items.filter((i) => i.categoryName == cat)
    const orderBy = this.categoryOrderBy.find((o) => o.categoryName == cat)
    if (orderBy) {
      result = orderBy.descending
        ? result.sort((a, b) => (b as IIndexable)[orderBy.column] - (a as IIndexable)[orderBy.column])
        : result.sort((a, b) => (a as IIndexable)[orderBy.column] - (b as IIndexable)[orderBy.column])
    }
    return result
  }

  private toggleCategorySort(cat: string, column: string) {
    const orderBy = this.categoryOrderBy.find((o) => o.categoryName == cat)
    if (orderBy) {
      orderBy.column = column
      orderBy.descending = !orderBy.descending
    } else {
      this.categoryOrderBy.push({ categoryName: cat, column, descending: false })
    }
  }

  private quantityCategorySum(cat: string) {
    return this.items
      .filter((i) => i.categoryName == cat)
      .map((i) => i.quantity)
      .reduce((acc, curr) => acc + curr, 0)
  }

  private purchaseQuantityCategorySum(cat: string) {
    return this.items
      .filter((i) => i.categoryName == cat)
      .map((i) => i.purchaseQuantity)
      .reduce((acc, curr) => acc + curr, 0)
  }

  private get quantitySum() {
    return this.items
      .map((i) => i.quantity)
      .reduce((acc, curr) => acc + curr, 0)
  }

  private get purchaseQuantitySum() {
    return this.items
      .map((i) => i.purchaseQuantity)
      .reduce((acc, curr) => acc + curr, 0)
  }

  private priceCategorySum(cat: string) {
    return this.items
      .filter((i) => i.categoryName == cat)
      .map((i) => i.price)
      .reduce((acc, curr) => acc + curr, 0)
  }

  private purchasePriceCategorySum(cat: string) {
    return this.items
      .filter((i) => i.categoryName == cat)
      .map((i) => i.purchasePrice)
      .reduce((acc, curr) => acc + curr, 0)
  }

  private get priceSum() {
    return this.items.map((i) => i.price).reduce((acc, curr) => acc + curr, 0)
  }

  private get purchasePriceSum() {
    return this.items
      .map((i) => i.purchasePrice)
      .reduce((acc, curr) => acc + curr, 0)
  }

  private async mounted() {
    this.project =
      (await projectsService.findOneAsync(this.projectId)) || new Project()

    const res = await axios.post<ProjectSumItem[]>(`print/sums`, {
      itemIds: JSON.parse(this.itemIds),
    })
    this.items = res.data
    this.items.forEach((i) => {
      i.purchaseSinglePrice = i.purchasePrice / i.purchaseQuantity
    })
  }
}

import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import PriceHistory from '@/products/entities/price-history'
import PriceHistoryFilter from '@/products/entities/price-history-filter'

class PriceHistoryService {

  public async findAsync(filter: PriceHistoryFilter) {
    const res = await axios.post<PriceHistory[]>(`pricehistory/find`, filter)
    return Responses.list(res)
  }
}

export default new PriceHistoryService()




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LogwoodInventory from '@/logwood/models/logwood-inventory'
import LogwoodService from '@/logwood/services/logwood-service'

@Component
export default class LogwoodTable extends Vue {
  private inventory: LogwoodInventory[] = []

  private async loadInventoryAsnyc() {
    this.inventory = await LogwoodService.loadInventoryAsync()
  }

  private async mounted() {
    await this.loadInventoryAsnyc()
  }
}

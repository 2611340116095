import Vue from 'vue'
import Router from 'vue-router'

import routing from '@/app/config/routing'
import authService from '@/users/services/auth-service'
import { loadLanguageAsync } from '@/app/i18n'

Vue.use(Router)

const router = new Router({
  routes: routing.routes,
})

// Handles redirect if the page requires authentication.
router.beforeEach((to, from, next) => {
  const lang = to.params.lang ?? 'de'
  const recordsWithRoles = to.matched.filter((record) => record.meta.roles)
  if (recordsWithRoles.length > 0) {
    const record = recordsWithRoles[0]
    if (authService.isInAnyRole(record.meta.roles)) {
      loadLanguageAsync(lang).then(() => next())
    } else {
      next(`/${lang}/login/${to.name}`)
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authService.authenticated()) {
      loadLanguageAsync(lang).then(() => next())
    } else {
      next(`/${lang}/login/${to.name}`)
    }
  } else {
    loadLanguageAsync(lang).then(() => next())
  }
})

export default router

import store from '@/app/store'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'

@Module({ name: 'AdminTenantModule', store: store })
export default class AdminTenantModule extends VuexModule {
  private pTenantId: string | null = null

  get tenantId(): string | null {
    return this.pTenantId
  }

  @Mutation
  public setTenantId(tenantId: string | null) {
    this.pTenantId = tenantId
  }
}

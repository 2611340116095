var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c(
        "v-dialog",
        {
          staticClass: "error-dialog",
          attrs: { fullscreen: "" },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column",
              attrs: { "min-height": "100%" }
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline red darken-3",
                  attrs: { "primary-title": "" }
                },
                [
                  _c("span", { staticClass: "white--text" }, [
                    _vm._v("Exception")
                  ])
                ]
              ),
              _c("v-card-text", { staticClass: "flex-grow-1 mt-5" }, [
                _c("iframe", {
                  staticClass: "error-dialog--contents",
                  attrs: { srcdoc: _vm.msgHtml }
                })
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", depressed: "" },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }









































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import LogwoodService from '@/logwood/services/logwood-service'
import LogwoodPackage from '@/logwood/models/logwood-package'
import snacks from '@/app/services/snacks'
import LogwoodShiftsTable from '@/logwood/components/logwood-shifts-table.vue'
import LogwoodShiftPackagesTable from '@/logwood/components/logwood-shift-packages-table.vue'

@Component
export default class LogwoodRegistration extends Vue {
  @Ref()
  private validator!: ValidationObserver

  @Ref()
  private shiftTable!: LogwoodShiftsTable

  @Ref()
  private shiftPackagesTable!: LogwoodShiftPackagesTable

  private loading = false
  private pack: LogwoodPackage = new LogwoodPackage()

  private async addAsync() {
    await this.saveAsync()
    this.pack = this.createNew()
    await this.shiftTable.refreshAsync()
    await this.shiftPackagesTable.refreshAsync()
  }

  private createNew() {
    const newPack = new LogwoodPackage()
    newPack.productId = this.pack.productId
    newPack.targetProductId = this.pack.targetProductId
    newPack.length = this.pack.length
    newPack.height = this.pack.height
    return newPack
  }

  private async saveAsync() {
    if (await this.validator.validate()) {
      const num = await LogwoodService.createPackageAsync(this.pack)
      snacks.success(this.$tc('_logwood._logwood-registration.created'))
      this.printAsync(num)
    }
  }

  private async printAsync(num: string | null) {
    if (num) {
      const route = this.$router.resolve({
        name: 'logwood-tag',
        params: { lang: this.$i18n.locale, num: num },
      })
      window.open(route.href)
    }
  }
}

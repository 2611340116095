



























































































import { Component, Mixins } from 'vue-property-decorator'
import EditDialogMixin from '@/common/dialogs/mixins/edit-dialog-mixin'
import Store from '@/stores/models/store'

@Component
export default class StoreDialog extends Mixins<EditDialogMixin<Store>>(
  EditDialogMixin
) {
  model = new Store()

  get primaryStoreDisabled() {
    return (
      this.model.businessDivision === '' ||
      this.model.businessDivision === 'all'
    )
  }
}

import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import ProjectGroupTemplate from '../entities/project-group-template'

class ProjectGroupTemplatesService {
  public async findAllAsync() {
    const res = await axios.post<ProjectGroupTemplate[]>(
      `projectgrouptemplates`
    )
    return Responses.list(res)
  }

  public async findOneAsync(id: string) {
    const res = await axios.get<ProjectGroupTemplate>(
      `projectgrouptemplates?id=${id}`
    )
    return Responses.obj(res)
  }

  public async createAsync(entity: ProjectGroupTemplate) {
    await axios.post(`projectgrouptemplates/create`, entity)
  }

  public async saveAsync(entity: ProjectGroupTemplate) {
    await axios.put(`projectgrouptemplates/${entity.id}/update`, entity)
  }

  public async deleteAsync(id: string) {
    await axios.post(`projectgrouptemplates/${id}/delete`)
  }
}

export default new ProjectGroupTemplatesService()
















































import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import accountService from '@/users/services/account-service'

@Component
export default class EmailConfirm extends Vue {
  @Prop(String)
  private user!: string

  @Prop(String)
  private token!: string

  private loading = false

  private async confirmEmailAsync() {
    this.loading = true
    await accountService.confirmEmailAsync(this.user, this.token)
    this.loading = false
  }

  private async mounted() {
    await this.confirmEmailAsync()
  }
}

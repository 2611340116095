var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-combobox",
    _vm._b(
      {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce:update:search-input",
            value: 500,
            expression: "500",
            arg: "update:search-input"
          }
        ],
        attrs: {
          items: _vm.items,
          "hide-selected": "",
          label: _vm.label,
          multiple: "",
          loading: _vm.loading,
          "search-input": _vm.text,
          readonly: _vm.readonly
        },
        on: {
          change: _vm.onChange,
          "update:searchInput": function($event) {
            _vm.text = $event
          },
          "update:search-input": [
            function($event) {
              _vm.text = $event
            },
            _vm.getKeywords
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var attrs = ref.attrs
              var item = ref.item
              var parent = ref.parent
              var selected = ref.selected
              return [
                _c(
                  "v-chip",
                  _vm._b(
                    {
                      attrs: {
                        "input-value": selected,
                        small: "",
                        pill: "",
                        close: "",
                        readonly: _vm.readonly
                      },
                      on: {
                        "click:close": function($event) {
                          return parent.selectItem(item)
                        }
                      }
                    },
                    "v-chip",
                    attrs,
                    false
                  ),
                  [_vm._v(_vm._s(item))]
                )
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.highlight(item)) }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.selection,
          callback: function($$v) {
            _vm.selection = $$v
          },
          expression: "selection"
        }
      },
      "v-combobox",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
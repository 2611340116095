























































































import AuthRequest from '@/users/requests/auth-request'
import AuthResponse from '@/users/responses/auth-response'
import authService from '@/users/services/auth-service'
import { Component, Vue, Ref, Watch } from 'vue-property-decorator'

@Component
export default class LoginForm extends Vue {
  @Ref()
  private validator!: ValidationObserver

  private loggingIn = false
  private request = new AuthRequest()
  private response: AuthResponse | null = null

  private loading = false

  public async login() {
    if (await this.validator.validate()) {
      this.loggingIn = true
      this.response = await authService.loginAsync(this.request)
      this.loggingIn = false
    }
  }
}

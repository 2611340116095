var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "8" } },
                      [
                        _c("logwood-package-input", {
                          model: {
                            value: _vm.pack,
                            callback: function($$v) {
                              _vm.pack = $$v
                            },
                            expression: "pack"
                          }
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mt-8",
                                attrs: {
                                  disabled: _vm.loading || invalid,
                                  title: _vm.$t("add"),
                                  block: "",
                                  "x-large": "",
                                  depressed: "",
                                  color: "primary"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.addAsync()
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("add")])],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("logwood-shifts-table", {
                          ref: "shiftTable",
                          staticClass: "mb-4",
                          attrs: { "num-of-shifts": 1 }
                        }),
                        _c("logwood-shift-packages-table", {
                          ref: "shiftPackagesTable",
                          attrs: {
                            title: _vm.$t(
                              "_logwood._logwood-packages-table.shift_title"
                            )
                          },
                          on: { print: _vm.printAsync }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var valid = ref.valid
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    attrs: { "min-height": "100%" }
                  },
                  [
                    _c("v-card-title", { staticStyle: { height: "64px" } }, [
                      _vm.editMode
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("_projects.edit_project")))
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(_vm.$t("_projects.add_project")))
                          ])
                    ]),
                    _c(
                      "v-card-text",
                      { staticClass: "flex-grow-1" },
                      [
                        _c(
                          "v-form",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "pb-0" },
                                      [
                                        _vm.editMode
                                          ? _c("validation-provider", {
                                              attrs: {
                                                rules:
                                                  "required|verify:projects/verify?id=" +
                                                  _vm.model.id,
                                                name: _vm.$t("project_number")
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "project_number"
                                                            ),
                                                            outlined: "",
                                                            dense: "",
                                                            "error-messages": errors,
                                                            "hide-details":
                                                              "auto",
                                                            hint: _vm.$t(
                                                              "required_field"
                                                            ),
                                                            "persistent-hint":
                                                              ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.model.number,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.model,
                                                                "number",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "model.number"
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "pb-0" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required",
                                            name: "name"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: _vm.$t("name"),
                                                        outlined: "",
                                                        dense: "",
                                                        "error-messages": errors,
                                                        "hide-details": "auto",
                                                        hint: _vm.$t(
                                                          "required_field"
                                                        ),
                                                        "persistent-hint": ""
                                                      },
                                                      model: {
                                                        value: _vm.model.name,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "model.name"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.$t("commission"),
                                            outlined: "",
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.model.commission,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "commission",
                                                $$v
                                              )
                                            },
                                            expression: "model.commission"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-textarea", {
                                          attrs: {
                                            label: _vm.$t("comments"),
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            rows: "4"
                                          },
                                          model: {
                                            value: _vm.model.remarks,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "remarks",
                                                $$v
                                              )
                                            },
                                            expression: "model.remarks"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "pb-0" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            rules: "required",
                                            name: "companyId"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("company-select", {
                                                      attrs: {
                                                        type: "customer",
                                                        label: _vm.$t(
                                                          "customer"
                                                        ),
                                                        outlined: "",
                                                        dense: "",
                                                        "error-messages": errors,
                                                        "hide-details": "auto",
                                                        hint: _vm.$t(
                                                          "required_field"
                                                        ),
                                                        "persistent-hint": "",
                                                        ellipsis: 60
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model.companyId,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "companyId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.companyId"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("user-autocomplete", {
                                          attrs: {
                                            label: _vm.$t("project_lead"),
                                            outlined: "",
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.model.projectLeadId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "projectLeadId",
                                                $$v
                                              )
                                            },
                                            expression: "model.projectLeadId"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      [
                                        _c("user-autocomplete", {
                                          attrs: {
                                            label: _vm.$t(
                                              "production_planning_lead"
                                            ),
                                            outlined: "",
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value:
                                              _vm.model
                                                .productionPlanningLeadId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "productionPlanningLeadId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "model.productionPlanningLeadId"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("date-input", {
                                          attrs: {
                                            label: _vm.$t("production_start"),
                                            outlined: "",
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.model.productionDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "productionDate",
                                                $$v
                                              )
                                            },
                                            expression: "model.productionDate"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      [
                                        _c("date-input", {
                                          attrs: {
                                            label: _vm.$t("delivery_date"),
                                            outlined: "",
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.model.deliveryDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "deliveryDate",
                                                $$v
                                              )
                                            },
                                            expression: "model.deliveryDate"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      [
                                        _c("date-input", {
                                          attrs: {
                                            label: _vm.$t("due_date"),
                                            outlined: "",
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.model.dueDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "dueDate",
                                                $$v
                                              )
                                            },
                                            expression: "model.dueDate"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("project-status-select", {
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.model.status,
                                            callback: function($$v) {
                                              _vm.$set(_vm.model, "status", $$v)
                                            },
                                            expression: "model.status"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("store-multi-select", {
                                          attrs: {
                                            label: _vm.$t("stores"),
                                            outlined: "",
                                            dense: "",
                                            chips: "",
                                            "small-chips": "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.model.storeIds,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "storeIds",
                                                $$v
                                              )
                                            },
                                            expression: "model.storeIds"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-7 pb-5" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text",
                            attrs: { text: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: !valid,
                              loading: _vm.loading,
                              depressed: "",
                              color: "primary"
                            },
                            on: { click: _vm.confirm }
                          },
                          [
                            _vm.editMode
                              ? _c("span", [_vm._v(_vm._s(_vm.$t("save")))])
                              : _c("span", [_vm._v(_vm._s(_vm.$t("add")))])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import AuthModule from '@/users/store/auth-module'
import AccountModule from '@/users/store/account-module'
import AdminTenantModule from '@/tenants/store/admin-tenant-module'
import TenantModule from '@/tenants/store/tenant-module'

export default {
  AuthModule,
  AccountModule,
  AdminTenantModule,
  TenantModule,
}

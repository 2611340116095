export enum UnitType {
  M = 0,
  M2 = 1,
  M3 = 2,
  KG = 3,
  STK = 4,
  GT = 5,
  L = 6,
  PKG = 7,
}

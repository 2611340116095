import Vue from 'vue'
import { PluginObject, VueConstructor } from 'vue'
import store from '@/app/store'
import TenantModule from '@/tenants/store/tenant-module'
import { getModule } from 'vuex-module-decorators'
import Tenant from '@/tenants/entities/tenant'

declare module 'vue/types/vue' {
  interface Vue {
    $tenant: TenantData
  }
}

class TenantData {
  get data(): Tenant {
    const tenantModule = getModule(TenantModule, store)
    return tenantModule.tenantData
  }
}

// tslint:disable-next-line: max-classes-per-file
class VueTenantPlugin implements PluginObject<any> {
  public install(vue: VueConstructor, options?: any): void {
    vue.prototype.$tenant = new TenantData()
  }
}

Vue.use(new VueTenantPlugin())








import moment from 'moment'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class OrderDeliveryDateDisplay extends Vue {
  @Prop()
  private value!: Date

  dateColor(date: Date) {
    return moment(date) < moment().startOf('day') ? "#FF7C80" : "#eeeeee"
  }
}

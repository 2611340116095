var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "d-flex flex-column align-center" }, [
        _c("div", { staticClass: "profile-graph", class: _vm.clazz }),
        _c("div", { staticClass: "font-size-75 font-weight-bold mt-1" }, [
          _vm._v("V")
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
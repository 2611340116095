var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "side-menu",
      attrs: {
        app: "",
        dark: "",
        width: _vm.widthValue,
        "hide-overlay": "",
        temporary: _vm.$vuetify.breakpoint.smAndDown
      },
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function() {
            return [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "#303030" } },
                [
                  _c("v-app-bar-nav-icon", {
                    on: { click: _vm.toggleSideMenu }
                  })
                ],
                1
              ),
              _c(
                "header",
                { staticClass: "d-flex justify-space-around" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("image-display", {
                        staticClass: "logo",
                        attrs: {
                          src: _vm.logoUrl,
                          "fallback-src": "/img/logo.png"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.pValue,
        callback: function($$v) {
          _vm.pValue = $$v
        },
        expression: "pValue"
      }
    },
    [
      _vm.$auth.isInRole("ADMIN")
        ? _c("tenant-select", {
            staticClass: "ma-4",
            attrs: { outlined: "", dense: "", "hide-details": "" },
            on: { input: _vm.changeTenant },
            model: {
              value: _vm.tenantId,
              callback: function($$v) {
                _vm.tenantId = $$v
              },
              expression: "tenantId"
            }
          })
        : _vm._e(),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c("side-menu-item", {
            attrs: {
              icon: "home",
              title: _vm.$t("home"),
              route: { name: "home", params: { lang: _vm.$i18n.locale } }
            }
          }),
          _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "ruler",
                  title: _vm.$t("projects"),
                  route: {
                    name: "projects",
                    params: { lang: _vm.$i18n.locale }
                  }
                }
              })
            : _vm._e(),
          _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER", "RESTRICTED"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "shopping_cart",
                  title: _vm.$t("orders"),
                  route: { name: "orders", params: { lang: _vm.$i18n.locale } }
                }
              })
            : _vm._e(),
          _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER", "RESTRICTED"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "text_snippet",
                  title: _vm.$t("products"),
                  route: {
                    name: "products",
                    params: { lang: _vm.$i18n.locale }
                  }
                }
              })
            : _vm._e(),
          _vm.$auth.isInAnyRole(["ADMIN", "TENANT"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "text_snippet",
                  title: _vm.$t("_products._price-histories.menu"),
                  route: {
                    name: "price-histories",
                    params: { lang: _vm.$i18n.locale }
                  }
                }
              })
            : _vm._e(),
          _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER", "RESTRICTED"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "task",
                  title: _vm.$t("_products._product-inventory.menu"),
                  route: {
                    name: "product-inventory",
                    params: { lang: _vm.$i18n.locale }
                  }
                }
              })
            : _vm._e(),
          _vm.$auth.isInAnyRole(["ADMIN"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "pallet",
                  title: _vm.$t("stores"),
                  route: { name: "stores", params: { lang: _vm.$i18n.locale } }
                }
              })
            : _vm._e(),
          _c("v-divider"),
          _vm.$tenant.data.enabledTomifaSolidWood
            ? [
                _c("side-menu-item", {
                  attrs: {
                    icon: "museum",
                    title: _vm.$t("_logwood._logwood-home.title"),
                    route: {
                      name: "logwood-home",
                      params: { lang: _vm.$i18n.locale }
                    }
                  }
                }),
                _c("side-menu-item", {
                  attrs: {
                    icon: "workspaces",
                    title: _vm.$t("_logwood._logwood-registration.title"),
                    route: {
                      name: "logwood-registration",
                      params: { lang: _vm.$i18n.locale }
                    }
                  }
                }),
                _c("side-menu-item", {
                  attrs: {
                    icon: "qr_code_scanner",
                    title: _vm.$t("_logwood._logwood-scan.title"),
                    route: {
                      name: "logwood-scan",
                      params: { lang: _vm.$i18n.locale }
                    }
                  }
                }),
                _c("v-divider")
              ]
            : _vm._e(),
          _vm.$auth.isInAnyRole(["ADMIN", "TENANT", "USER"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "domain",
                  title: _vm.$t("companies"),
                  route: {
                    name: "companies",
                    params: { lang: _vm.$i18n.locale }
                  }
                }
              })
            : _vm._e(),
          _vm.$auth.isInAnyRole(["ADMIN", "TENANT"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "people",
                  title: _vm.$t("users"),
                  route: { name: "users", params: { lang: _vm.$i18n.locale } }
                }
              })
            : _vm._e(),
          _vm.$auth.isInAnyRole(["ADMIN", "TENANT"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "storefront",
                  title: _vm.$t("tenants"),
                  route: { name: "tenants", params: { lang: _vm.$i18n.locale } }
                }
              })
            : _vm._e(),
          _vm.$auth.isInAnyRole(["ADMIN", "TENANT"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "factory",
                  title: _vm.$t("equipment"),
                  route: {
                    name: "equipment",
                    params: { lang: _vm.$i18n.locale }
                  }
                }
              })
            : _vm._e(),
          _vm.$auth.isInAnyRole(["ADMIN"])
            ? _c("side-menu-item", {
                attrs: {
                  icon: "admin_panel_settings",
                  title: _vm.$t("management"),
                  route: {
                    name: "management",
                    params: { lang: _vm.$i18n.locale }
                  }
                }
              })
            : _vm._e(),
          _c("v-divider"),
          _c("side-menu-item", {
            attrs: { icon: "feedback", title: _vm.$t("feedback") },
            on: { click: _vm.showFeedbackDialog }
          })
        ],
        2
      ),
      _c("feedback-dialog", {
        on: {
          close: function($event) {
            _vm.feedbackDialog = false
          }
        },
        model: {
          value: _vm.feedbackDialog,
          callback: function($$v) {
            _vm.feedbackDialog = $$v
          },
          expression: "feedbackDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
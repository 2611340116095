





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Tenant from '@/tenants/entities/tenant'
import tenantsService from '@/tenants/services/tenants-service'
import isString from 'lodash/isString'

@Component
export default class TenantSelect extends Vue {
  @Prop()
  private value!: Tenant | string | null

  @Prop({ type: Boolean, default: false })
  private returnObject!: boolean

  @Prop({ default: 20 })
  private ellipsis!: number

  private loading: boolean = false
  private tenant: Tenant | null = null
  private tenants: Tenant[] = []

  @Watch('value')
  private async valueChanged(val: Tenant | string | null) {
    await this.prefetchAsync(this.value)
  }

  private async prefetchAsync(val: Tenant | string | null) {
    if (val === null) {
      this.tenant = null
      return
    }

    const tenant = isString(val) ? await this.findOrFetchOneAsync(val) : val

    if (tenant) {
      const id = tenant.id
      if (this.tenants.findIndex((c) => c.id === id) === -1) {
        this.tenants.push(tenant)
      }
      this.tenant = tenant
    }
  }

  private async findOrFetchOneAsync(id: string) {
    const found = this.tenants.find((c) => c.id === id)
    if (found) {
      return found
    }
    return await tenantsService.findOneAsync(id)
  }

  private async loadAsync() {
    this.loading = true
    this.tenants = await tenantsService.loadAllAsync()
    this.loading = false
  }

  private async emitChange() {
    this.$emit('input', this.returnObject ? this.tenant : this.tenant?.id)
  }

  private emitClear() {
    this.$emit('input', null)
  }

  private async mounted() {
    await this.loadAsync()
    await this.prefetchAsync(this.value)
  }
}












































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LogwoodService from '@/logwood/services/logwood-service'
import LogwoodPackage from '@/logwood/models/logwood-package'
import jsbarcode from 'jsbarcode'
import moment from 'moment'

@Component
export default class LogwoodTag extends Vue {
  @Prop()
  private num!: string

  private loading = false
  private pack = new LogwoodPackage()

  private async loadPackAsync() {
    const pack = await LogwoodService.findPackageAsync(this.num)
    this.pack = pack || new LogwoodPackage()
  }

  private getNum(p: LogwoodPackage) {
    if (!p) {
      return '000'
    }
    const num = `${p.number}`.padStart(6, '0')
    const type = p.productNumber || '000'
    const date = moment(p.outerBearingOn).format('MMYY')
    return `${num} ${type} ${date}`
  }

  private async mounted() {
    await this.loadPackAsync()

    jsbarcode('#barcode', this.getNum(this.pack), {
      height: 63,
      width: 2,
      margin: 0,
      text: 'CODE 128',
    })

    window.print()
    window.close()
  }
}

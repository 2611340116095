import axios from "@/app/axios"

export default async function downloadAsync(url: string, filename: string) {
  const res = await axios.get<Blob>(url, { responseType: 'blob' })
  var a = document.createElement('a')
  a.href = URL.createObjectURL(res.data)
  a.download = filename
  // Firefox requires the element to be attached to the DOM.
  document.body.appendChild(a)
  a.click()
  a.remove()
  URL.revokeObjectURL(a.href)
}
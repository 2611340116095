var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("v-select", {
        staticStyle: { "min-width": "160px", "max-width": "160px" },
        attrs: {
          items: _vm.periodTypes,
          "item-text": "text",
          "item-value": "value",
          outlined: "",
          dense: "",
          "hide-details": ""
        },
        on: {
          change: function($event) {
            return _vm.emit()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-icon", [_vm._v(_vm._s(item.icon))]),
                _c("span", { staticClass: "ml-1" }, [
                  _vm._v(_vm._s(_vm.$t(item.text)))
                ])
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              var on = ref.on
              var attrs = ref.attrs
              return [
                _c(
                  "v-list-item",
                  _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                  [
                    _c(
                      "v-list-item-icon",
                      [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                      1
                    ),
                    _c("v-list-item-title", [_vm._v(_vm._s(_vm.$t(item.text)))])
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.filter.periodType,
          callback: function($$v) {
            _vm.$set(_vm.filter, "periodType", $$v)
          },
          expression: "filter.periodType"
        }
      }),
      !_vm.hideDateInputs
        ? [
            _c(
              "div",
              { staticClass: "ml-2", staticStyle: { width: "120px" } },
              [
                _c("date-input", {
                  attrs: {
                    label: _vm.$t("from"),
                    type: _vm.inputType,
                    outlined: "",
                    dense: "",
                    "hide-details": ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.emit()
                    }
                  },
                  model: {
                    value: _vm.filter.start,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "start", $$v)
                    },
                    expression: "filter.start"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ml-2", staticStyle: { width: "120px" } },
              [
                _c("date-input", {
                  attrs: {
                    label: _vm.$t("until"),
                    type: _vm.inputType,
                    outlined: "",
                    dense: "",
                    "hide-details": ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.emit()
                    }
                  },
                  model: {
                    value: _vm.filter.end,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "end", $$v)
                    },
                    expression: "filter.end"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
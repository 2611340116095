










































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import directoriesService from '@/projects/services/project-group-template-directories-service'
import TemplateDirectoryPicker from '@/projects/components/template-directory-picker.vue'
import ProjectItemGroup from '@/projects/entities/project-item-group'
import ProjectGroupTemplate from '@/projects/entities/project-group-template'

@Component
export default class CreateTemplateDialog extends Vue {
  @Ref()
  private dstPicker!: TemplateDirectoryPicker

  private dialog = false
  private loading = false

  private group!: ProjectItemGroup
  private name: string = ''
  private dstFolder: DirectoryTreeItem | null = null

  private get dstFolderName() {
    if (this.dstFolder) {
      return this.dstFolder.name
    }
    return this.$t('pick_target_folder') + '...'
  }

  private get valid() {
    return this.name && this.name.length > 0 && this.dstFolder
  }

  public open(group: ProjectItemGroup) {
    this.group = group
    this.dialog = true
  }

  private selectDstFolder(folder: DirectoryTreeItem) {
    this.dstFolder = folder
  }

  private async confirm() {
    if (this.valid) {
      const template = new ProjectGroupTemplate()
      template.name = this.name
      template.content = JSON.stringify(this.group.items)
      template.directoryId = this.dstFolder?.id || null
      this.dialog = false
      this.$emit('confirm', template)
    }
  }

  private cancel() {
    this.dialog = false
    this.$emit('cancel')
  }
}

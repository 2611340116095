import Guid from '@/app/utils/guid'
import Product from '@/products/entities/product'

export default class ProjectItem {
  public id: string = Guid.empty()
  public number: string = ''
  public posName: string = ''
  public groupName: string = ''
  public name: string = ''
  public productId: string | null = null
  public product: Product | null = null
  public quantity: number = 1
  public length: number = 0
  public width: number = 0
  public height: number = 0
  public length2: number | null = null
  public width2: number | null = null

  public remarks: string = ''
  public businessUnit: string = ''
  public sorting: number = 1

  public frontFupId: string | null = null
  public frontFup: Product | null = null
  public backFupId: string | null = null
  public backFup: Product | null = null

  public frontEdgingProductId: string | null = null
  public frontEdgingProduct: Product | null = null
  public backEdgingProductId: string | null = null
  public backEdgingProduct: Product | null = null
  public leftEdgingProductId: string | null = null
  public leftEdgingProduct: Product | null = null
  public rightEdgingProductId: string | null = null
  public rightEdgingProduct: Product | null = null
  public customEdgingProductId: string | null = null
  public customEdgingProduct: Product | null = null
}

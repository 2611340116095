import Guid from '@/app/utils/guid'

export default class Store {
  public id = Guid.empty()
  public projectId: string | null = null
  public projectNumber = ''
  public projectName = ''
  public name = ''
  public isPrimary = false
  public businessDivision = ''
}
import axios from '@/app/axios'
import CrudService from '@/common/services/crud-service'
import Responses from '@/app/utils/responses'
import Company from '@/companies/entities/company'
import PaginationRequest from '@/common/pagination/entities/pagination-request'
import PaginationResponse from '@/common/pagination/entities/pagination-response'
import Contact from '@/companies/entities/contact'
import Guid from '@/app/utils/guid'
import CompanyFilter from '../entities/company-filter'

class CompaniesService extends CrudService<Company> {
  public async findAllFilteredAsync(request: CompanyFilter) {
    const res = await axios.post<PaginationResponse<Company>>(
      `companies/filter`,
      request
    )
    return Responses.pagedList(res)
  }

  public async findAllSuppliersAsync(search: string) {
    const res = await axios.get<Company[]>(
      `companies/suppliers?search=${search}`
    )
    return Responses.list(res)
  }

  public async findAllManufacturersAsync(search: string) {
    const res = await axios.get<Company[]>(
      `companies/manufacturers?search=${search}`
    )
    return Responses.list(res)
  }

  public async findAllCustomersAsync(search: string) {
    const res = await axios.get<Company[]>(
      `companies/customers?search=${search}`
    )
    return Responses.list(res)
  }

  public async findAllNonCustomersAsync(search: string) {
    const res = await axios.get<Company[]>(
      `companies/noncustomers?search=${search}`
    )
    return Responses.list(res)
  }

  public async findAllCompaniesAsync(search: string) {
    const res = await axios.get<Company[]>(`companies/all?search=${search}`)
    return Responses.list(res)
  }

  public async findAllContactsAsync(companyId: Guid) {
    const res = await axios.post<Contact[]>(`companies/${companyId}/contacts`)
    return Responses.list(res)
  }

  public async findAllLastChangedAsync() {
    const res = await axios.get<Company[]>(`companies/lastChanged`)
    return Responses.list(res)
  }

  public async createContactAsync(entity: Contact) {
    const res = await axios.post<Contact>(`companies/createContact`, entity)
    return Responses.obj(res)
  }

  public async updateContactAsync(entity: Contact) {
    const res = await axios.put<Contact>(`companies/updateContact`, entity)
    return Responses.obj(res)
  }

  public async removeContactAsync(id: Guid) {
    await axios.delete(`companies/${id}/deleteContact`)
  }
}

export default new CompaniesService('companies')

import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import PaginationResponse from '@/common/pagination/entities/pagination-response'
import Stock from '@/stores/models/stock'
import Store from '@/stores/models/store'
import StockFilter from '@/stores/models/stock-filter'
import StoreFilter from '@/stores/models/store-filter'

class StoresService {
  public async findOneAsync(id: string) {
    const res = await axios.get<Store>(`stores/${id}`)
    return Responses.obj(res)
  }

  public async findAllFilteredAsync(filter: StoreFilter) {
    const res = await axios.post<PaginationResponse<Store>>(`stores/find/all/paged`, filter)
    return Responses.pagedList(res)
  }

  public async findAllAsync(search: string) {
    const res = await axios.post<Store[]>(`stores/find/all?search=${search}`)
    return Responses.list(res)
  }

  public async saveAsync(store: Store) {
    const res = await axios.post<string>(`stores`, store)
    return Responses.obj(res)
  }

  public async removeAsync(store: Store) {
    await axios.delete<string>(`stores/${store.id}`)
  }

  public async findStocksAsync(filter: StockFilter) {
    const url = `stores/${filter.storeId}/products/${filter.productId}/stocks/filtered`
    const res = await axios.post<Stock[]>(url, filter)
    return Responses.list(res)
  }

  public async createStockAsync(stock: Stock) {
    const url = `stores/${stock.storeId}/products/${stock.productId}/stocks`
    const res = await axios.post<Store>(url, stock)
    return Responses.obj(res)
  }

  public async setStockAsync(stock: Stock) {
    const url = `stores/${stock.storeId}/products/${stock.productId}/stocks`
    const res = await axios.put<Store>(url, stock)
    return Responses.obj(res)
  }
}

export default new StoresService()

import axios from 'axios'
import Vue from 'vue'
import Vuei18n from 'vue-i18n'
// @ts-ignore
import de from '@/lang/de.json'

Vue.use(Vuei18n)

const i18n = new Vuei18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    de,
  },
})

const loadedLanguages = ['de']

function setI18nLanguage(lang: string) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang: string) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`
  ).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

export default i18n

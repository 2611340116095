var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        attrs: {
          items: _vm.availabelStores,
          loading: _vm.loading,
          filter: _vm.filter,
          multiple: "",
          "return-object": "",
          "hide-no-data": "",
          "hide-selected": "",
          clearable: "",
          chips: "",
          "deletable-chips": "",
          "item-text": "name",
          "item-value": "id"
        },
        on: {
          change: _vm.emitChange,
          "click:clear": function($event) {
            return _vm.emitClear()
          }
        },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      },
      "v-autocomplete",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }






import { extractHeader } from '@/common/files/csv'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ExcelColumnSelect extends Vue {

  @Prop({ required: true })
  private value!: string

  @Prop({ default: null })
  private file!: File | null

  public columns = [
    { text: 'A', value: 0 },
    { text: 'B', value: 1 },
    { text: 'C', value: 2 },
    { text: 'D', value: 3 },
    { text: 'E', value: 4 },
    { text: 'F', value: 5 },
    { text: 'G', value: 6 },
    { text: 'H', value: 7 },
    { text: 'I', value: 8 },
    { text: 'J', value: 9 },
  ]

  public column: string = this.value
  public labels = []

  @Watch('value')
  private valueChanged(val: string, old: string) {
    if (val !== old) {
      this.column = val
    }
  }

  @Watch('file')
  private async fileChanged(val: File | null) {
    const csv = await val?.text()
    const cols = extractHeader(csv)
    this.columns = cols.map((c, i) => ({ text: c, value: i }))
  }

  @Watch('column')
  private columnChanged(val: string) {
    this.$emit('input', val)
  }
}

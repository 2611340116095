import axios from '@/app/axios'
import { extend } from 'vee-validate'
import FeedbackResponse from '@/common/models/feedback-response'

extend('verify', {
  params: ['url'],
  // @ts-ignore
  async validate(value, { url }) {
    var res = await axios.get<FeedbackResponse>(`${url}&value=${value}`)
    if (res.data.success) {
      return true
    }
    return res.data.messages[0]
  },
})

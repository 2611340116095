
















import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component
export default class PasswordInput extends Vue {
  private type = 'password'

  private toggleVisibility() {
    this.type = this.type === 'password' ? 'text' : 'password'
  }
}

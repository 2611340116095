var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { tile: "" } },
    [
      _c("v-card-title", [
        _vm._v(
          " " +
            _vm._s(_vm.$t("_logwood._logwood-shifts-table.title-sawmill")) +
            " "
        )
      ]),
      _c(
        "v-card-text",
        [
          _vm.shifts.length == 0
            ? _c("em", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("_logwood._logwood-shifts-table.empty")) +
                    " "
                )
              ])
            : _c("v-simple-table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("shift")))]),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.$t("volume")))
                    ])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.shifts, function(shift, idx) {
                    return _c("tr", { key: idx }, [
                      _c("td", [
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v(_vm._s(_vm._f("date")(shift.start)))
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("time")(shift.start)))
                        ]),
                        _c("span", { staticClass: "mx-1" }, [_vm._v("-")]),
                        _c("span", [_vm._v(_vm._s(_vm._f("time")(shift.end)))])
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm._f("num")(shift.volume, 3)) + " m³")
                      ])
                    ])
                  }),
                  0
                )
              ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
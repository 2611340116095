var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "" } },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-layout",
            {
              attrs: {
                "align-center": "",
                "justify-center": "",
                "fill-height": ""
              }
            },
            [
              _c(
                "v-flex",
                {
                  staticClass: "justify-center",
                  attrs: { xs11: "", md6: "", lg4: "" }
                },
                [
                  _vm.loading
                    ? [
                        _c("h1", [_vm._v(_vm._s(_vm.$env.VUE_APP_TITLE))]),
                        _c("h2", { staticClass: "mb-10" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "_users._email_confirm.awaiting_confirmation"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "_users._email_confirm.awaiting_confirmation_message"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("v-progress-circular", {
                          staticClass: "mt-5",
                          attrs: { size: 70, width: 7, indeterminate: "" }
                        })
                      ]
                    : [
                        _c("h1", [_vm._v(_vm._s(_vm.$env.VUE_APP_TITLE))]),
                        _c("h2", { staticClass: "mb-10" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("_users._email_confirm.email_confirmed")
                              ) +
                              " "
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "_users._email_confirm.email_confirmed_message"
                              )
                            )
                          )
                        ]),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mt-2",
                            attrs: {
                              to: {
                                name: "login",
                                params: { lang: _vm.$i18n.locale }
                              },
                              depressed: "",
                              color: "primary"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("_users.goto_login")) + " "
                            )
                          ]
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
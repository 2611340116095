var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "" } },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-layout",
            {
              attrs: {
                "align-center": "",
                "justify-center": "",
                "fill-height": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs11: "", md6: "", lg4: "" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.registered,
                          expression: "registered"
                        }
                      ]
                    },
                    [
                      _vm.success
                        ? [
                            _c("p", [
                              _vm._v(
                                " Die Registrierung war erfolgreich. Sie erhalten in Kürze eine E-Mail um die Registrierung abzuschließen. "
                              )
                            ]),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  to: {
                                    name: "login",
                                    params: { lang: _vm.$i18n.locale }
                                  },
                                  depressed: "",
                                  color: "primary"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("_users.goto_login")) +
                                    " "
                                )
                              ]
                            )
                          ]
                        : [
                            _c("v-alert", { attrs: { type: "error" } }, [
                              _vm._v("Bei der Registrierung ging etwas schief.")
                            ])
                          ]
                    ],
                    2
                  ),
                  _c("register-form", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.registered === false,
                        expression: "registered === false"
                      }
                    ],
                    ref: "registerForm",
                    staticClass: "elevation-10 pa-5",
                    on: { register: _vm.register }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
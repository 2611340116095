











































































































import authService from '@/users/services/auth-service'
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'

@Component
export default class SetPassword extends Vue {
  @Prop(String)
  private email!: string

  @Prop(String)
  private token!: string

  @Ref()
  private validator!: ValidationObserver

  private password = ''
  private passwordConfirmation = ''
  private serverError = ''
  private loggingIn = false

  public async setPassword() {
    this.loggingIn = true

    const res = await authService.resetPasswordAsync(
      this.email,
      this.token,
      this.password
    )

    this.loggingIn = false

    if (res[0]) {
      this.$router.push({
        name: 'login',
        params: { lang: this.$i18n.locale },
      })
    } else {
      this.serverError = res[1]
    }
  }
}

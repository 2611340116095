






















































































































































































import User from '@/users/entities/user'
import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import EditDialogMixin from '@/common/dialogs/mixins/edit-dialog-mixin'
import AddressInput from '@/locations/components/address-input/address-input.vue'

@Component
export default class UserDialog extends Mixins<EditDialogMixin<User>>(
  EditDialogMixin
) {
  @Ref()
  private addressInput!: AddressInput

  get roles() {
    const rs: Array<{ value: string; text: string }> = []
    if (this.$auth.isAdmin) {
      rs.push({ value: 'ADMIN', text: 'Admin' })
    }
    rs.push({ value: 'TENANT', text: 'Mandanten-Admin' })
    rs.push({ value: 'USER', text: 'Benutzer' })
    rs.push({ value: 'RESTRICTED', text: 'Eingeschränkter Benutzer' })
    rs.push({ value: 'EXTERNAL', text: 'Externer Benutzer' })
    return rs
  }

  protected async afterValidationSuccessAsync() {}
}

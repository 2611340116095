var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "700px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var valid = ref.valid
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    attrs: { "min-height": "100%" }
                  },
                  [
                    _c("v-card-title", {
                      staticStyle: { height: "64px" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "_products._apply_supplier_multiplier_dialog.title",
                            {
                              directory: _vm.directory.name
                            }
                          )
                        )
                      }
                    }),
                    _c(
                      "v-card-text",
                      { staticClass: "px-7 flex-grow-1" },
                      [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "_products._apply_supplier_multiplier_dialog.message",
                                {
                                  directory: _vm.directory.name
                                }
                              )
                            )
                          }
                        }),
                        _c("validation-provider", {
                          attrs: {
                            rules: "decimal|min_value:0",
                            name: _vm.$t("net_sales_price_multiplier")
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("x-number-input", {
                                      attrs: {
                                        label: _vm.$t(
                                          "net_sales_price_multiplier"
                                        ),
                                        outlined: "",
                                        dense: "",
                                        "persistent-hint": "",
                                        "error-messages": errors,
                                        "fractional-digits": 4,
                                        "hide-details": "auto",
                                        hint: _vm.$t(
                                          "net_sales_price_multiplier_hint"
                                        )
                                      },
                                      model: {
                                        value: _vm.netSalesPriceMultiplier,
                                        callback: function($$v) {
                                          _vm.netSalesPriceMultiplier = $$v
                                        },
                                        expression: "netSalesPriceMultiplier"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-7 pb-5" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text",
                            attrs: { text: "" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: _vm.loading,
                              disabled: !valid,
                              depressed: "",
                              color: "primary"
                            },
                            on: { click: _vm.confirm }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "_products._apply_supplier_multiplier_dialog.apply"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "container-toolbar", attrs: { flat: "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-3",
              attrs: {
                to: {
                  name: "parts-list",
                  params: { projectId: _vm.project.id, lang: _vm.$i18n.locale }
                },
                depressed: "",
                small: ""
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("_projects._partlist_edit.goto")) + " "
              )
            ]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                to: {
                  name: "project-orders",
                  params: { projectId: _vm.project.id, lang: _vm.$i18n.locale }
                },
                depressed: "",
                small: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("_orders._project_orders.goto")) + " ")]
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { md: "3", sm: "12" } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("status")))])
                  ]),
                  _c(
                    "v-col",
                    { attrs: { md: "9", sm: "12" } },
                    [
                      _c("project-status-display", {
                        model: {
                          value: _vm.project.status,
                          callback: function($$v) {
                            _vm.$set(_vm.project, "status", $$v)
                          },
                          expression: "project.status"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { md: "3", sm: "12" } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("customer")))])
                  ]),
                  _c("v-col", { attrs: { md: "9", sm: "12" } }, [
                    _vm._v(_vm._s(_vm.project.companyName))
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { md: "3", sm: "12" } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("due_date")))])
                  ]),
                  _c("v-col", { attrs: { md: "9", sm: "12" } }, [
                    _vm._v(_vm._s(_vm._f("date")(_vm.project.dueDate)))
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { md: "3", sm: "12" } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("production_start")))])
                  ]),
                  _c("v-col", { attrs: { md: "9", sm: "12" } }, [
                    _vm._v(_vm._s(_vm._f("date")(_vm.project.productionDate)))
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { md: "3", sm: "12" } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("delivery_date")))])
                  ]),
                  _c("v-col", { attrs: { md: "9", sm: "12" } }, [
                    _vm._v(_vm._s(_vm._f("date")(_vm.project.deliveryDate)))
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { md: "3", sm: "12" } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("project_lead")))])
                  ]),
                  _c("v-col", { attrs: { md: "9", sm: "12" } }, [
                    _vm._v(_vm._s(_vm.projectLeadName))
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { md: "3", sm: "12" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("production_planning_lead")))
                    ])
                  ]),
                  _c("v-col", { attrs: { md: "9", sm: "12" } }, [
                    _vm._v(_vm._s(_vm.productionPlanningLeadName))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("files-list", {
                attrs: { title: "Uploads", files: _vm.uploads },
                on: {
                  uploadFiles: _vm.filesSelectedAsync,
                  downloadFile: _vm.download,
                  deleteFile: _vm.deleteUploadAsync
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
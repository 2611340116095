

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SideMenuGroup extends Vue {

  @Prop({ default: '' })
  public icon!: string

  @Prop({ default: '' })
  public iconClass!: string

  @Prop({ required: true })
  public title!: string

  @Prop({ default: 20 })
  public size!: number
}

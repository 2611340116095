import Guid from '@/app/utils/guid'
import AuditedEntity from '@/common/models/audited-entity'
import OrderItem from './order-item'

export default class Order extends AuditedEntity {
  projectId: Guid | null = null
  projectNumber = ''
  buyerShortCode = ''
  number = ''
  businessUnit = ''
  groupName = ''
  supplierId: Guid = Guid.empty
  supplierNumber = ''
  supplierName = ''
  supplierContactPersonId: Guid = Guid.empty
  status: OrderStatus = OrderStatus.Open
  orderDate: Date = new Date()
  expectedDeliveryDate: Date | null = null
  text = ""
  items: OrderItem[] = []
  price = 0

  static get statusList() {
    return [
      { text: "open", value: OrderStatus.Open },
      { text: "ordered", value: OrderStatus.Ordered },
      { text: "ordered_online", value: OrderStatus.OrderedOnline },
      { text: "in_stock", value: OrderStatus.InStock },
      { text: "delivered", value: OrderStatus.Delivered },
      { text: "partially_delivered", value: OrderStatus.PartiallyDelivered },
      { text: "closed", value: OrderStatus.Closed },
      { text: "cancelled", value: OrderStatus.Cancelled },
    ]
  }

  static statusColor(status: OrderStatus) {
    switch (status) {
      case OrderStatus.Open: return "#eeeeee"
      case OrderStatus.Ordered: return "#FFD966"
      case OrderStatus.OrderedOnline: return "#FFD966"
      case OrderStatus.InStock: return "#92D050"
      case OrderStatus.Delivered: return "#92D050"
      case OrderStatus.PartiallyDelivered: return "#8EA9DB"
      case OrderStatus.Closed: return "#AEAAAA"
      case OrderStatus.Cancelled: return "#FF7C80"
    }
  }
}

export enum OrderStatus {
  Cancelled = -1,
  Open = 0,
  Ordered = 1,
  OrderedOnline = 2,
  InStock = 3,
  Delivered = 4,
  PartiallyDelivered = 5,
  Closed = 6,
}



































































































import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import ProjectItem from '@/projects/entities/project-item'
import Profiles from '@/products/entities/profiles'
import ProfilesPicker from '@/products/entities/profiles-picker.vue'
import ProductOrderItem from '@/orders/entities/product-order-item'

@Component
export default class ProfilesInput extends Vue {
  @Ref()
  private picker!: ProfilesPicker

  @Prop()
  private value!: ProjectItem

  @Prop({ default: () => [] })
  private orderStates!: ProductOrderItem[]

  @Prop({ type: Boolean, default: false })
  private readonly!: boolean

  private item: ProjectItem = this.value || new ProjectItem()

  @Watch('value', { deep: true })
  private async valueChanged(val: ProjectItem) {
    this.item = val
  }

  private confirm(profiles: Profiles) {
    this.item.frontEdgingProduct = profiles?.front
    this.item.frontEdgingProductId = profiles?.front?.id || null

    this.item.backEdgingProduct = profiles?.back
    this.item.backEdgingProductId = profiles?.back?.id || null

    this.item.leftEdgingProduct = profiles?.left
    this.item.leftEdgingProductId = profiles?.left?.id || null

    this.item.rightEdgingProduct = profiles?.right
    this.item.rightEdgingProductId = profiles?.right?.id || null

    this.item.customEdgingProduct = profiles?.special
    this.item.customEdgingProductId = profiles?.special?.id || null

    this.$emit('input', this.item)
  }
}

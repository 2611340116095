









































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import Product from '@/products/entities/product'
import ProductSupplier from '@/products/entities/product-supplier'
import { unitName } from '@/products/utils/units'
import { UnitType } from '@/products/entities/unit-type'
import axios from '@/app/axios'
import Guid from '@/app/utils/guid'
import Company from '@/companies/entities/company'
import productsService from '@/products/services/products-service'
import StoreProduct from '@/stores/models/store-product'
import Dates from '@/app/utils/dates'
import ImageInput from '@/common/components/image-input.vue'

@Component
export default class ProductInput extends Vue {
  @Ref()
  private imageInput!: ImageInput

  @Prop()
  private value!: Product

  @Prop({ type: Boolean, default: false })
  private readonly!: boolean

  private product = this.value

  get isNew() {
    return this.product.id === Guid.empty()
  }

  get storeIds() {
    return this.product.stores.map((s) => s.storeId)
  }

  // get supplierIds() {
  //   return this.product.suppliers.map((s) => s.supplierId)
  // }

  @Watch('value')
  private async valueUpdated(val: Product, old: Product) {
    this.product = val
    await this.updateSalesPriceAsync()
  }

  private addStore() {
    const sp = new StoreProduct()
    sp.productId = this.product.id
    this.product.stores.push(sp)
  }

  private removeStore(index: number) {
    this.product.stores.splice(index, 1)
  }

  private addSupplier() {
    const supplier = new ProductSupplier()
    supplier.productId = this.product.id
    this.product.suppliers.push(supplier)
  }

  private removeSupplier(index: number) {
    this.product.suppliers.splice(index, 1)
  }

  // private changeSupplier(supplier: ProductSupplier, company: Company) {
  //   supplier.supplierId = company.id
  //   supplier.isWarehouse = company.isWarehouse
  //   if (company.isWarehouse) {
  //     supplier.isPrimary = false
  //   }
  // }

  private async updateSalesPriceAsync() {
    let supplier = this.product.suppliers.find((p) => p.isPrimary)
    if (!supplier && this.product.suppliers.length > 0) {
      supplier = this.product.suppliers[0]
    }
    if (supplier) {
      let netSalesPriceMultiplier = supplier.netSalesPriceMultiplier
      if (!netSalesPriceMultiplier) {
        netSalesPriceMultiplier =
          await productsService.findCategoryMultiplierAsync(
            this.product.category
          )
      }
      if (netSalesPriceMultiplier) {
        this.product.netSalesPrice =
          supplier.netPurchasePrice * netSalesPriceMultiplier
      }
    }
  }

  private changePrimarySupplier(supplier: ProductSupplier) {
    for (const s of this.product.suppliers) {
      s.isPrimary = false
    }
    supplier.isPrimary = true
  }

  private unitSuffix(unit: UnitType) {
    return unitName(unit)
  }

  private weightSuffix(unit: UnitType) {
    if (unit == UnitType.KG) {
      return 'g'
    }
    return `g/${unitName(unit)}`
  }

  // private dateTime(v: string) {
  //   return v ? Dates.dateTime(v) : ''
  // }

  private async uploadImage(image: File) {
    const formData = new FormData()
    formData.append('image', image, image.name)
    const res = await axios.post<string>(
      `products/${this.product.id}/image`,
      formData
    )
    this.product.imagePath = res.data
    this.imageInput.reload()
  }

  private async mounted() {
    await this.updateSalesPriceAsync()
  }
}

import { render, staticRenderFns } from "./logwood-shifts-table.vue?vue&type=template&id=74843300&scoped=true&"
import script from "./logwood-shifts-table.vue?vue&type=script&lang=ts&"
export * from "./logwood-shifts-table.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74843300",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardText,VCardTitle,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74843300')) {
      api.createRecord('74843300', component.options)
    } else {
      api.reload('74843300', component.options)
    }
    module.hot.accept("./logwood-shifts-table.vue?vue&type=template&id=74843300&scoped=true&", function () {
      api.rerender('74843300', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/logwood/components/logwood-shifts-table.vue"
export default component.exports
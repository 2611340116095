import Vue from 'vue'
import LogwoodPackage from '@/logwood/models/logwood-package'
import moment from 'moment'

Vue.filter('logwoodPackageNr', (p: LogwoodPackage) => {
  if (!p) {
    return '000'
  }
  const num = `${p.number}`.padStart(6, '0')
  const type = p.productNumber || '000'
  const date = moment(p.outerBearingOn).format('MMYY')
  return `${num} ${type} ${date}`
})













































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class ImageInput extends Vue {
  @Prop({ type: String })
  private value!: string | null

  @Prop({ type: String, default: '' })
  private basePath!: string

  @Prop({ type: [Number, String], default: 1 })
  private aspectRatio!: number | string

  @Prop({ type: Boolean, default: false })
  private readonly!: boolean

  // @Prop({ type: String, default: 'Bild wählen ...' })
  // private uploadMessage!: string

  // @Prop({ type: String, default: 'Für Bildauswahl erst speichern' })
  // private readonlyMessage!: string

  @Prop({ type: String, default: '#222' })
  private messageColor!: string

  @Prop({ type: String, default: '#fff' })
  private overlayColor!: string

  @Prop({ type: String, default: '#fff' })
  private backgroundColor!: string

  @Ref()
  private overlay!: HTMLElement

  @Ref()
  private fileInput!: HTMLInputElement

  private showImage = true
  private showOverlay = false

  get imagePath() {
    let path = `${this.$env.VUE_APP_IMAGES_URL}/`
    if (this.basePath !== '') {
      path += `${this.basePath}/`
    }
    return path + this.value + `?${Math.round(Math.random() * 1000000)}`
  }

  get originalPath() {
    let path = `${this.$env.VUE_APP_IMAGES_URL}/`
    if (this.basePath !== '') {
      path += `${this.basePath}/`
    }
    const idx = this.value?.lastIndexOf('.')
    if (idx && idx >= 0) {
      const fileName = this.value?.substring(0, idx)
      const fileExt = this.value?.substring(idx + 1, this.value.length)
      const nonce = Math.round(Math.random() * 1000000)
      return `${path}${fileName}.original.${fileExt}?${nonce}`
    }
    return ''
  }

  // get message() {
  //   if (this.readonly) {
  //     return this.readonlyMessage
  //   }
  //   return this.uploadMessage
  // }

  private openDialog() {
    if (this.readonly === false) {
      this.fileInput.click()
    }
  }

  private openInFull() {
    this.showOverlay = true
    this.$nextTick(() => {
      this.overlay.focus()
    })
  }

  private upload(e: Event) {
    const elem = e.target as HTMLInputElement
    if (elem.files && elem.files.length === 1) {
      this.$emit('change', elem.files[0])
    }
  }

  public reload() {
    this.showImage = false
    this.$nextTick(() => {
      this.showImage = true
    })
  }
}

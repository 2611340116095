























































import Address from '@/locations/models/address'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import isEqual from 'lodash/isEqual'
import LatLng from '@/locations/models/lat-lng'

@Component
export default class AddressInput extends Vue {

  @Prop({ required: true })
  private value!: Address

  @Prop({ default: true })
  private withCountry!: boolean

  @Prop({ default: true })
  private withStreet!: boolean

  @Prop({ default: false })
  private showCountryCode!: boolean

  private address: Address = this.normalize(this.value)

  public async locateAsync() {
    const res = await axios.get<any>(`http://photon.komoot.de/api`, {
      params: {
        q: this.address.city + ',Austria',
      },
    })
    const geo = Responses.obj(res)
    if (geo) {
      if (geo.features.length > 0) {
        const coord = geo.features[0].geometry.coordinates
        this.address.location.latitude = coord[1]
        this.address.location.longitude = coord[0]
      }
    }
  }

  @Watch('value', { deep: true })
  private valueChanged(val: Address, old: Address) {
    if (isEqual(val, old) === false) {
      this.address = this.normalize(val)
    }
  }

  @Watch('address', { deep: true })
  private addressChanged(val: Address, old: Address) {
    this.$emit('input', val)
  }

  private normalize(val: Address) {
    val = val || new Address()
    val.location = val.location || new LatLng()
    return val
  }
}






































































import { Component, Vue, Ref } from 'vue-property-decorator'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import productDirectoriesService from '@/products/services/product-directories-service'

@Component
export default class ApplySupplierMultiplierDialog extends Vue {
  @Ref()
  private validator!: ValidationObserver

  private dialog = false
  private loading = false

  private directory: DirectoryTreeItem = new DirectoryTreeItem()
  private netSalesPriceMultiplier: number | null = null

  public open(directory: DirectoryTreeItem) {
    this.directory = directory
    this.dialog = true
  }

  private async confirm() {
    if (await this.validator.validate()) {
      this.loading = true
      await this.applyMultiplier()
      this.$emit('confirm')
      this.loading = false
      this.reset()
      this.cancel()
    }
  }

  private async applyMultiplier() {
    if (this.directory.id) {
      await productDirectoriesService.applySupplierMultiplierAsync(
        this.directory.id,
        this.netSalesPriceMultiplier
      )
    }
  }

  private reset() {
    if (this.validator) {
      this.validator.reset()
    }
  }

  private cancel() {
    this.dialog = false
  }
}

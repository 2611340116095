var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-text-field",
    _vm._g(
      _vm._b(
        {
          attrs: {
            label: _vm.$t("_common._password_input.label"),
            type: _vm.type,
            "append-icon":
              _vm.type === "password" ? "visibility" : "visibility_off",
            autocomplete: "off",
            autocorrect: "off",
            autocapitalize: "none",
            spellcheck: "false"
          },
          on: { "click:append": _vm.toggleVisibility }
        },
        "v-text-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
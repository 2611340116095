










































































































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import ordersService from '@/orders/services/orders-service'
import PaginationRequest from '@/common/pagination/entities/pagination-request'
import Order from '@/orders/entities/order'
import OrderDetailsDialog from '@/orders/components/order-details-dialog.vue'
import OrderFilter from '@/orders/entities/order-filter'
import Period from '@/common/models/periods/period'
import moment from 'moment'

@Component
export default class Orders extends Vue {
  @Ref()
  private orderDetailsDialog!: OrderDetailsDialog

  @Prop()
  private projectId!: string

  private loading = false
  private isOpen = true
  private isOrdered = true
  private isOrderedOnline = true
  private isInStock = true
  private isDelivered = false
  private isPartiallyDelivered = false
  private isClosed = false
  private isCancelled = false
  private groupMode = 0
  private search = ''
  private orders: Order[] = []
  private pagination = new PaginationRequest()
  private period: Period = new Period()
  private sum = 0

  private get headers() {
    const h = [
      {
        text: this.$tc('number'),
        align: 'start',
        value: 'number',
        class: '',
        sortable: false,
      },
      {
        text: this.$tc('project'),
        align: 'start',
        value: 'projectNumber',
        sortable: false,
      },
      {
        text: this.$tc('supplier'),
        align: 'start',
        value: 'supplierName',
        sortable: false,
      },
      {
        text: this.$tc('created'),
        value: 'orderDate',
        align: 'start',
        sortable: false,
      },
      {
        text: this.$tc('expected'),
        value: 'expectedDeliveryDate',
        align: 'start',
        sortable: false,
      },
      {
        text: this.$tc('group'),
        value: 'groupName',
        align: 'start',
        sortable: false,
      },
      {
        text: this.$tc('buyer'),
        value: 'buyerShortCode',
        align: 'start',
        sortable: false,
      },
      {
        text: this.$tc('status'),
        value: 'status',
        align: 'start',
        sortable: false,
      },
      {
        text: this.$tc('cost_center'),
        align: 'start',
        value: 'businessUnit',
        sortable: false,
      },
    ]

    if (this.$auth.isInAnyRole(['ADMIN', 'TENANT', 'USER'])) {
      h.push({
        text: this.$tc('price'),
        align: 'end',
        value: 'price',
        sortable: false,
      })
    }

    h.push({
      text: '',
      value: 'action',
      sortable: false,
      class: 'td-w-shrink',
      align: 'end',
    })

    return h
  }

  private async resetPageAndFetchAllAsync() {
    this.pagination.page = 1
    await this.fetchAllAsync()
  }

  private async fetchAllAsync() {
    this.loading = true

    const filter: OrderFilter = {
      search: this.search,
      paging: this.pagination,
      isOpen: this.isOpen,
      isOrdered: this.isOrdered,
      isOrderedOnline: this.isOrderedOnline,
      isInStock: this.isInStock,
      isDelivered: this.isDelivered,
      isPartiallyDelivered: this.isPartiallyDelivered,
      isClosed: this.isClosed,
      isCancelled: this.isCancelled,
      period: this.period,
    }

    const result = await ordersService.findAllFilteredAsync(filter)
    this.orders = result.items
    this.pagination.totalItems = result.totalItems

    const sum = await ordersService.findTotalAsync(filter)
    this.sum = sum || 0

    this.loading = false
  }

  private async detailsAsync(order: Order) {
    await this.orderDetailsDialog.openAsync(order.id)
  }

  private async deleteAsync(order: Order) {
    if (confirm(this.$t('are_you_sure').toString())) {
      await ordersService.removeAsync(order.id)
      await this.fetchAllAsync()
    }
  }

  private created() {
    this.pagination.sortBy = ['OrderDate']
    this.pagination.sortDesc = [true]

    var begin = moment().set({ month: 1, dayOfYear: 1 })

    if (moment().isSameOrBefore(begin)) {
      begin = begin.subtract(1, 'year')
    }

    this.period.begin = begin.format('YYYY-MM-DDTHH:mm')
  }
}

import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import ProjectGroupTemplateDirectoryFilter from '@/projects/entities/project-group-template-directory-filter'

class ProjectGroupTemplateDirectoriesService {
  public async createFolderAsync(dir: DirectoryTreeItem) {
    const res = await axios.post<any>(`projectgrouptemplatedirectories`, dir)
    return Responses.obj(res)
  }

  public async updateFolderAsync(dir: DirectoryTreeItem) {
    const res = await axios.put<string>(`projectgrouptemplatedirectories`, dir)
    return Responses.obj(res)
  }

  public async moveItemsAsync(srcItemIds: string[], dstFolderId: string) {
    await axios.post(`projectgrouptemplatedirectories/move`, {
      srcItemIds,
      dstFolderId,
    })
  }

  public async hideFolderAsync(folderId: string) {
    await axios.get(`projectgrouptemplatedirectories/${folderId}/hide`)
  }

  public async unhideFolderAsync(folderId: string) {
    await axios.get(`projectgrouptemplatedirectories/${folderId}/unhide`)
  }

  public async removeFolderAsync(folderId: string) {
    await axios.delete(`projectgrouptemplatedirectories/${folderId}`)
  }

  public async findAllAsync(request: ProjectGroupTemplateDirectoryFilter) {
    const res = await axios.post<DirectoryTreeItem[]>(
      'projectgrouptemplatedirectories/all',
      request
    )
    return Responses.list(res)
  }
}

export default new ProjectGroupTemplateDirectoriesService()

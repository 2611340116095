var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "products fill-width", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "auto" } },
            [
              _c("product-directory-tree", {
                ref: "productDirectoryTree",
                staticClass: "container-fixed hidden-scroll-container",
                staticStyle: { "min-width": "360px" },
                on: { create: _vm.createProduct, select: _vm.loadProductAsync }
              })
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _vm.product
            ? _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c("validation-observer", {
                    ref: "validator",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var invalid = ref.invalid
                            return [
                              _c("product-input", {
                                staticClass: "container-fixed",
                                attrs: {
                                  readonly:
                                    _vm.$auth.isInAnyRole([
                                      "ADMIN",
                                      "TENANT"
                                    ]) === false
                                },
                                model: {
                                  value: _vm.product,
                                  callback: function($$v) {
                                    _vm.product = $$v
                                  },
                                  expression: "product"
                                }
                              }),
                              _vm.$auth.isInAnyRole(["ADMIN", "TENANT"])
                                ? [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mb-8",
                                        staticStyle: { "margin-right": "72px" },
                                        attrs: {
                                          disabled: invalid,
                                          title: _vm.$t("save"),
                                          fab: "",
                                          fixed: "",
                                          bottom: "",
                                          right: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.saveAsync()
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v("save")])],
                                      1
                                    ),
                                    _c(
                                      "v-speed-dial",
                                      {
                                        staticClass: "mb-8",
                                        attrs: {
                                          fixed: "",
                                          bottom: "",
                                          right: "",
                                          direction: "top",
                                          transition:
                                            "slide-y-reverse-transition"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        dark: "",
                                                        fab: ""
                                                      },
                                                      model: {
                                                        value: _vm.fab,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.fab = $$v
                                                        },
                                                        expression: "fab"
                                                      }
                                                    },
                                                    [
                                                      _vm.fab
                                                        ? _c("v-icon", [
                                                            _vm._v("close")
                                                          ])
                                                        : _c("v-icon", [
                                                            _vm._v("build")
                                                          ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.fab,
                                          callback: function($$v) {
                                            _vm.fab = $$v
                                          },
                                          expression: "fab"
                                        }
                                      },
                                      [
                                        _vm.$auth.isInAnyRole(["ADMIN"])
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  title: _vm.$t("delete"),
                                                  fab: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteAsync()
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", [_vm._v("delete")])
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              title: _vm.$t("duplicate"),
                                              fab: "",
                                              disabled: invalid
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.duplicateAsync()
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("content_copy")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1190735746
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Period from '@/common/models/periods/period'
import moment from 'moment'

@Component
export default class PeriodInput extends Vue {
  @Prop({ required: true })
  private value!: Period

  @Prop({ type: Boolean, default: false })
  private sameDay!: boolean

  @Prop({ type: Boolean, default: false })
  private noTime!: boolean

  private beginDate: string = this.getBeginDate(this.value)
  private beginTime: string = this.getBeginTime(this.value)
  private endDate: string = this.getEndDate(this.value)
  private endTime: string = this.getEndTime(this.value)

  @Watch('value', { deep: true })
  private valueChanged(val: Period, old: Period) {
    this.beginDate = this.getBeginDate(val)
    this.beginTime = this.getBeginTime(val)
    this.endDate = this.getEndDate(val)
    this.endTime = this.getEndTime(val)
  }

  @Watch('beginDate')
  private beginDateChanged(val: string, old: string) {
    if (val !== old) {
      this.emit()
    }
  }

  @Watch('beginTime')
  private beginTimeChanged(val: string, old: string) {
    if (val !== old) {
      this.emit()
    }
  }

  @Watch('endDate')
  private endDateChanged(val: string, old: string) {
    if (val !== old) {
      this.emit()
    }
  }

  @Watch('endTime')
  private endTimeChanged(val: string, old: string) {
    if (val !== old) {
      this.emit()
    }
  }

  private emit() {
    const p = new Period()

    const beginDate = this.beginDate
    const beginTime = this.noTime ? '00:00' : this.beginTime
    const endDate = this.sameDay ? this.beginDate : this.endDate
    const endTime = this.noTime ? '23:59' : this.endTime

    p.begin = `${beginDate}T${beginTime}`
    p.end = `${endDate}T${endTime}`

    this.$emit('input', p)
  }

  private getBeginDate(p: Period) {
    return this.getDate(p.begin)
  }

  private getBeginTime(p: Period) {
    return this.noTime ? '00:00' : this.getTime(p.begin)
  }

  private getEndDate(p: Period) {
    return this.getDate(p.end)
  }

  private getEndTime(p: Period) {
    return this.noTime ? '23:59' : this.getTime(p.end)
  }

  private getDate(d: string) {
    return moment(d, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD')
  }

  private getTime(d: string) {
    return moment(d, 'YYYY-MM-DDTHH:mm').format('HH:mm')
  }
}

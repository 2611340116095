import { Icons } from 'vuetify/types/services/icons'

// import XIcon from '@/common/components/x-icon/x-icon.vue'

// https://vuetifyjs.com/en/customization/icons#component-icons
// https://stackoverflow.com/questions/51614557/how-to-add-custom-svg-icon-in-vuetify-vue
const icons: Partial<Icons> = {
  iconfont: 'md', // 'md' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  // values: {
    //   complete: '...',
    //   cancel: '...',
    //   close: '...',
    //   delete: '...', // delete (e.g. v-chip close)
    //   clear: '...',
    //   success: '...',
    //   info: '...',
    //   warning: '...',
    //   error: '...',
    //   prev: '...',
    //   next: '...',
    //   checkboxOn: '...',
    //   checkboxOff: '...',
    //   checkboxIndeterminate: '...',
    //   delimiter: '...', // for carousel
    //   sort: '...',
    //   expand: '...',
    //   menu: '...',
    //   subgroup: '...',
    //   dropdown: '...',
    //   radioOn: '...',
    //   radioOff: '...',
    //   edit: '...',
    //   ratingEmpty: '...',
    //   ratingFull: '...',
    //   ratingHalf: '...',
    //   loading: '...',
    //   first: '...',
    //   last: '...',
    //   unfold: '...',
    //   file: '...',

    // cube: {
    //   component: XIcon,
    //   props: {
    //     name: 'cube',
    //   },
    // },
    // info: {
    //   component: XIcon,
    //   props: {
    //     name: 'info',
    //   },
    // },
    // add: {
    //   component: XIcon,
    //   props: {
    //     name: 'add',
    //   },
    // },
    // arrangeLetter: {
    //   component: XIcon,
    //   props: {
    //     name: 'arrange-letter',
    //   },
    // },
    // bin: {
    //   component: XIcon,
    //   props: {
    //     name: 'bin-1',
    //   },
    // },
    // database: {
    //   component: XIcon,
    //   props: {
    //     name: 'database-2',
    //   },
    // },
    // pencil: {
    //   component: XIcon,
    //   props: {
    //     name: 'pencil-1',
    //   },
    // },
    // presentationProjectorScreenPieChart: {
    //   component: XIcon,
    //   props: {
    //     name: 'presentation-projector-screen-pie-chart',
    //   },
    // },
    // search: {
    //   component: XIcon,
    //   props: {
    //     name: 'search',
    //   },
    // },
    // singleNeutralActionsSetting: {
    //   component: XIcon,
    //   props: {
    //     name: 'single-neutral-actions-setting',
    //   },
    // },
    // viewSquare: {
    //   component: XIcon,
    //   props: {
    //     name: 'view-square',
    //   },
    // },
    // module: {
    //   component: XIcon,
    //   props: {
    //     name: 'module',
    //   },
    // },
    // zoomin: {
    //   component: XIcon,
    //   props: {
    //     name: 'zoomin',
    //   },
    // },
    // zoomout: {
    //   component: XIcon,
    //   props: {
    //     name: 'zoomout',
    //   },
    // },
    // resetzoom: {
    //   component: XIcon,
    //   props: {
    //     name: 'resetzoom',
    //   },
    // },
  // },
}

export default icons

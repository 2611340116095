
















































import axios from '@/app/axios'
import moment from 'moment'
import { version } from '@/../package.json'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import authService from '@/users/services/auth-service'

@Component
export default class SidebarLayout extends Vue {
  private serverVersion: string = ''

  @Prop()
  private title!: string

  @Prop({ default: 'simple-header' })
  private header!: string

  @Prop({ default: true })
  private showHeader!: boolean

  get coyrightYear() {
    return moment().year()
  }

  get clientVersion() {
    return `v${version}`
  }

  get environment() {
    return `${this.$env.VUE_APP_ENVIRONMENT}`
  }

  private async fetchServerVersion() {
    var res = await axios.get<string>('appversion')
    this.serverVersion = res.data
  }

  private async mounted() {
    if (authService.authenticated()) {
      await this.fetchServerVersion()
    }
  }

  private showSideMenu = false

  public toggleSideMenu() {
    this.showSideMenu = !this.showSideMenu
    this.$bus.$emit('sidemenu:toggle')
  }

  @Watch('$route', { immediate: true, deep: true })
  private async onUrlChangeAsync(newVal: any) {
    this.showSideMenu = this.$vuetify.breakpoint.lgAndUp
  }
}

import { render, staticRenderFns } from "./project-status-select.vue?vue&type=template&id=66facd36&"
import script from "./project-status-select.vue?vue&type=script&lang=ts&"
export * from "./project-status-select.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('66facd36')) {
      api.createRecord('66facd36', component.options)
    } else {
      api.reload('66facd36', component.options)
    }
    module.hot.accept("./project-status-select.vue?vue&type=template&id=66facd36&", function () {
      api.rerender('66facd36', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/projects/components/project-status-select.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c("h1", [_vm._v("Allgemeine Geschäftsbedingungen")]),
    _c("p", [_vm._v("...")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("feedback")))
              ]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("feedback_prompt", {
                        appName: _vm.$env.VUE_APP_TITLE
                      })
                    )
                  )
                ]),
                _c(
                  "div",
                  [
                    _c("v-textarea", {
                      attrs: { outlined: "", dense: "", "hide-details": "" },
                      model: {
                        value: _vm.text,
                        callback: function($$v) {
                          _vm.text = $$v
                        },
                        expression: "text"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "v-card-actions",
                { staticClass: "px-7 pb-5" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.close } },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", color: "primary" },
                      on: { click: _vm.send }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("send")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 1500 },
          model: {
            value: _vm.confirmation,
            callback: function($$v) {
              _vm.confirmation = $$v
            },
            expression: "confirmation"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("feedback_thank_you")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
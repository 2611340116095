








































import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator'

@Component
export default class OversizeMenu extends Vue {
  @Prop()
  private finishedSize!: number | null

  @Prop()
  private size!: number

  private oversize = this.getOversize(this.finishedSize, this.size)

  private menu = false

  @Watch('finishedSize')
  private finishedSizeUpdated(val: number | null, old: number | null) {
    if (val !== old) {
      this.emitSizeUpdate(this.oversize)
    }
  }

  @Watch('oversize')
  private oversizeUpdated(val: number | null, old: number | null) {
    if (val !== old) {
      this.emitSizeUpdate(val)
    }
  }

  private getOversize(fs: number | null, s: number) {
    return fs ? s - fs : 0
  }

  private emitSizeUpdate(os: number | null) {
    const fs = this.finishedSize //this.pFinishedSize
    this.$emit('update:size', fs ? fs + (os || 0) : null)
  }
}

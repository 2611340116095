var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$auth.authenticated
        ? _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                transition: "slide-y-transition",
                "offset-y": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c("v-btn", _vm._g({ attrs: { icon: "" } }, on), [
                          _c("span", { staticClass: "user-name" }, [
                            _vm._v(_vm._s(_vm.displayName))
                          ])
                        ])
                      ]
                    }
                  }
                ],
                null,
                false,
                415994278
              ),
              model: {
                value: _vm.menu,
                callback: function($$v) {
                  _vm.menu = $$v
                },
                expression: "menu"
              }
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    { on: { click: _vm.goToSettings } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("build")])],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("_users._user_widget.my_account")) +
                            " "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.logout } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("exit_to_app")])],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("_users._user_widget.log_out")) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : [
            _vm.$env.VUE_APP_ALLOW_REGISTRATION !== "no"
              ? _c(
                  "v-btn",
                  {
                    staticClass: "app-bar-btn",
                    attrs: {
                      to: {
                        name: "register",
                        params: { lang: _vm.$i18n.locale }
                      },
                      text: ""
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("_users._register_form.register")) +
                        " "
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "v-btn",
              {
                staticClass: "app-bar-btn",
                attrs: {
                  to: { name: "login", params: { lang: _vm.$i18n.locale } },
                  text: ""
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("_users._user_widget.log_in")) + " ")]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
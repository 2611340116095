






















































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import store from '@/app/store'
import User from '@/users/entities/user'
import accountService from '@/users/services/account-service'
import authService from '@/users/services/auth-service'
import AccountModule from '@/users/store/account-module'
import { getModule } from 'vuex-module-decorators'
import AddressInput from '@/locations/components/address-input/address-input.vue'
import snacks from '@/app/services/snacks'

@Component
export default class Settings extends Vue {
  @Ref()
  private validator!: ValidationObserver

  @Ref()
  private addressInput!: AddressInput

  private loading: boolean = false
  private sendingReset: boolean = false

  private data: User = new User()

  private async save() {
    if (await this.validator.validate()) {
      this.loading = true
      await accountService.updateCurrent(this.data)
      snacks.success('Änderungen gespeichert.')
      this.loading = false
    }
  }

  private async resetPassword() {
    const accountModule = getModule(AccountModule, store)
    this.loading = true
    await authService.sendPasswordResetLinkAsync(accountModule.userData.email)
    snacks.success(
      'Sie erhalten in Kürze eine Email um Ihr Passwort zu ändern.'
    )
    this.loading = false
  }

  private mounted() {
    const accountModule = getModule(AccountModule, store)
    this.data = accountModule.userData
  }
}









import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component
export default class SimpleHeader extends Vue {
  @Prop()
  private title!: string

  @Watch('title')
  private routeChanged() {
    this.pTitle = this.$tc(this.title)
  }

  private pTitle: string = ''

  mounted() {
    this.pTitle = this.$tc(this.title)
    this.$bus.$on('app.simple-header.title', (title: string) => {
      this.pTitle = title
    })
  }
}

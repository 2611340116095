var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var valid = ref.valid
                return [
                  _c(
                    "v-card",
                    {
                      staticClass: "d-flex flex-column",
                      attrs: { "min-height": "100%" }
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticStyle: { height: "64px" } },
                        [
                          _vm._t("title", [
                            _vm.editMode
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.title) + " ändern")
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.title) + " erstellen")
                                ])
                          ])
                        ],
                        2
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "flex-grow-1" },
                        [_vm._t("body")],
                        2
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        { staticClass: "px-7 pb-5 pt-3" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary--text",
                              attrs: { text: "" },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: !valid,
                                depressed: "",
                                color: "primary"
                              },
                              on: { click: _vm.save }
                            },
                            [
                              _vm.editMode
                                ? _c("span", [_vm._v(_vm._s(_vm.$t("save")))])
                                : _c("span", [_vm._v(_vm._s(_vm.$t("add")))])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
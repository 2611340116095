import Guid from '@/app/utils/guid'

export default class DirectoryTreeItem {
  public id: string = Guid.empty()
  public tenantId: Guid | null = null
  public number: string = ''
  public manufacturerProductId: string = ''
  public supplierIds: string[] = []
  public name: string = ''
  public parentId: string | null = null
  public imagePath: string | null = null
  public isHidden: boolean = false
  public isExcluded: boolean = false
  public isEditing: boolean = false
  public isFolder: boolean = false
  public isWarehouseProduct: boolean = false
  public children: DirectoryTreeItem[] = []
  public _isNew: boolean = false
}

















































































































import PagedMixin from '@/common/pagination/mixins/paged-mixin'
import Company from '@/companies/entities/company'
import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import CorrectionListExportDialog from '@/companies/components/correction-list-export-dialog.vue'
import JoinCompaniesDialog from '@/companies/components/join-companies-dialog.vue'
import downloadAsync from '@/common/files/download'

@Component
export default class CompaniesTable extends Mixins(PagedMixin) {
  @Ref()
  private correctionListExportDialog!: CorrectionListExportDialog

  @Ref()
  private joinCompaniesDialog!: JoinCompaniesDialog

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ default: () => [] })
  public companies!: Company[]

  get headers() {
    return [
      { text: this.$tc('number'), value: 'number', sortable: false },
      { text: this.$tc('name'), value: 'name', sortable: false },
      { text: this.$tc('address'), value: 'address', sortable: false },
      { text: this.$tc('type'), value: 'type', sortable: false },
      { text: this.$tc('status'), value: 'status', sortable: false },
      {
        text: '',
        value: 'action',
        sortable: false,
        class: 'td-w-shrink',
        align: 'end',
      },
    ]
  }

  private edit(company: Company) {
    this.$emit('edit', company)
  }

  private enable(company: Company) {
    this.$emit('enable', company)
  }

  private deleteCompany(company: Company) {
    if (confirm(this.$tc('_companies.confirm_company_delete'))) {
      this.$emit('delete', company)
    }
  }

  private editContacts(company: Company) {
    this.$emit('editContacts', company)
  }

  private async exportPriceList(company: Company) {
    const url = `pricelistfiles/${company.id}`
    const filename = `Preisliste_${company.name || 'Firma'}.csv`
    await downloadAsync(url, filename)
  }
}

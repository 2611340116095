var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-text-field",
    _vm._g(
      _vm._b(
        {
          ref: "input",
          on: { focus: _vm.onFocus, blur: _vm.onBlur },
          model: {
            value: _vm.display,
            callback: function($$v) {
              _vm.display = $$v
            },
            expression: "display"
          }
        },
        "v-text-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }




















import { Component, Vue, Prop } from 'vue-property-decorator'
import Product from '@/products/entities/product'
import ProductOrderItem from '@/orders/entities/product-order-item'

@Component
export default class ProductOrderIndicator extends Vue {
  @Prop({ default: () => [] })
  private orderStates!: ProductOrderItem[]

  @Prop()
  private product!: Product | null

  @Prop()
  private projectItemId!: string

  private get isOrdered() {
    return this.orderStates
      .filter(this.orderItemFilter)
      .some((i) => i.productNumber === this.product?.number)
  }

  private orderItemFilter(item: ProductOrderItem) {
    const ids = item.projectItemIds
    return ids == null || ids.includes(this.projectItemId)
  }
}

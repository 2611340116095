





















































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import DirectoryTreeItem from '@/directories/models/directory-tree-item'
import directoriesService from '@/projects/services/project-group-template-directories-service'
import TemplateDirectoryPicker from '@/projects/components/template-directory-picker.vue'

@Component
export default class MoveTemplateDirectoryDialog extends Vue {
  @Ref()
  private srcPicker!: TemplateDirectoryPicker
  @Ref()
  private dstPicker!: TemplateDirectoryPicker

  private dialog = false
  private loading = false

  private srcItems: DirectoryTreeItem[] = []
  private dstFolder: DirectoryTreeItem | null = null

  get scrItemsName() {
    if (this.srcItems.length === 0) {
      return this.$t('select_elements_to_move') + '...'
    } else if (this.srcItems.length === 1) {
      return this.srcItems[0].name
    }
    return `${this.srcItems.length} ${this.$t('elements_selected')}`
  }

  get dstFolderName() {
    if (this.dstFolder) {
      return this.dstFolder.name
    }
    return this.$t('pick_target_folder') + '...'
  }

  get excludedItems() {
    return [this.srcItems.map((i) => i.id)]
  }

  public open() {
    this.dialog = true
  }

  private selectSrcItems(items: DirectoryTreeItem[]) {
    this.srcItems = items
  }

  private selectDstFolder(folder: DirectoryTreeItem) {
    this.dstFolder = folder
  }

  private async confirm() {
    if (this.srcItems.length > 0 && this.dstFolder) {
      await directoriesService.moveItemsAsync(
        this.srcItems.map((i) => i.id),
        this.dstFolder.id
      )
      this.dialog = false
      this.$emit('confirm')
    }
  }

  private cancel() {
    this.dialog = false
    this.$emit('cancel')
  }
}






















import { Component, Prop, Vue } from 'vue-property-decorator'
import LogwoodShift from '@/logwood/models/logwood-history'
import { HistoryPeriodType } from '@/logwood/models/history-period-type'

@Component
export default class LogwoodHistoryDate extends Vue {
  @Prop()
  private type!: HistoryPeriodType

  @Prop()
  private shift!: LogwoodShift

  private get isPeriodTypeDate() {
    return this.type === HistoryPeriodType.Date
  }

  private get isPeriodTypeWeek() {
    return this.type === HistoryPeriodType.Week
  }

  private get isPeriodTypeMonth() {
    return this.type === HistoryPeriodType.Month
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-snackbar",
        {
          staticClass: "app-snacks",
          attrs: { top: "", color: _vm.color, timeout: 5000 },
          model: {
            value: _vm.snacks,
            callback: function($$v) {
              _vm.snacks = $$v
            },
            expression: "snacks"
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0", attrs: { align: "center" } },
            [
              _c("v-col", { staticClass: "grow pa-0" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t(_vm.msg)))])
              ]),
              _c(
                "v-col",
                { staticClass: "shrink py-0 pr-0" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.snacks = false
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("exception-dialog", {
        attrs: { msg: _vm.msg },
        model: {
          value: _vm.dialog,
          callback: function($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog"
        }
      }),
      _c(
        _vm.layout,
        _vm._b({ tag: "component" }, "component", _vm.$route.meta, false),
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view", { key: _vm.$route.path })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
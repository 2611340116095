var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPeriodTypeDate
    ? _c(
        "span",
        [
          _c("calendar-week", {
            staticClass: "mr-2",
            attrs: { value: _vm.shift.start }
          }),
          _c("span", { staticClass: "mr-2" }, [
            _vm._v(_vm._s(_vm._f("weekday")(_vm.shift.start)))
          ]),
          _c("span", { staticClass: "mr-2" }, [
            _vm._v(_vm._s(_vm._f("date")(_vm.shift.start)))
          ]),
          _c("span", [_vm._v(_vm._s(_vm._f("time")(_vm.shift.start)))]),
          _c("span", { staticClass: "mx-1" }, [_vm._v("-")]),
          _c("span", [_vm._v(_vm._s(_vm._f("time")(_vm.shift.end)))])
        ],
        1
      )
    : _vm.isPeriodTypeWeek
    ? _c(
        "span",
        [
          _c("calendar-week", {
            staticClass: "mr-2",
            attrs: { value: _vm.shift.start }
          }),
          _c("span", [_vm._v(_vm._s(_vm._f("year")(_vm.shift.start)))])
        ],
        1
      )
    : _c("span", [
        _c("span", [_vm._v(_vm._s(_vm._f("month")(_vm.shift.start)))])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LogwoodPackage from '@/logwood/models/logwood-package'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'
import { ProductCategory } from '@/products/entities/product-category'

@Component
export default class LogwoodPackageInput extends Vue {
  @Prop()
  private value!: LogwoodPackage

  private solidWoodCategory: ProductCategory[] = [ProductCategory.Massivholz]

  private pack = this.value
  private diameters: number[] = [0]
  private width = 0

  @Watch('value', { deep: true })
  private valueChanged(val: LogwoodPackage, old: LogwoodPackage) {
    if (isEqual(val, old) === false) {
      this.pack = val
      this.diameters = [0]
    }
  }

  @Watch('pack', { deep: true })
  private packChanged(val: LogwoodPackage, old: LogwoodPackage) {
    if (isEqual(val, old) === false) {
      this.$emit('input', val)
    }
  }

  private addDiameter(idx: number) {
    if (this.diameters.length === idx + 1) {
      this.diameters.push(0)
    }
  }

  private computeVolume() {
    if (this.pack.productId !== null) {
      const length = this.pack.length / 1000
      const factor = this.$tenant.data.solidWoodOffcutFactor || 0.75
      const area0 = reduce(
        this.diameters,
        (a, d) => a + (d * d * Math.PI) / 4,
        0
      )
      const area1 = (area0 * factor) / 1000 / 1000
      this.pack.volume = area1 * length
    }
  }
}















































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import companiesService from '@/companies/services/companies-service'
import Contact from '../entities/contact'
import Guid from '@/app/utils/guid'
import ContactDialog from '@/companies/components/contact-dialog.vue'

@Component
export default class ContactsDialog extends Vue {
  get headers() {
    return [
      { text: this.$tc('salutation'), value: 'salutation', sortable: false },
      { text: this.$tc('firstName'), value: 'firstName', sortable: false },
      { text: this.$tc('lastName'), value: 'lastName', sortable: false },
      { text: this.$tc('email'), value: 'email', sortable: false },
      {
        text: '',
        value: 'action',
        sortable: false,
        class: 'td-w-shrink',
        align: 'end',
      },
    ]
  }
  public loading = false
  public dialog = false
  public contacts: Contact[] = []

  @Prop({ type: String })
  public value!: string

  @Ref()
  private contactDialog!: ContactDialog

  public async fetchAll() {
    this.loading = true
    this.contacts = await companiesService.findAllContactsAsync(this.value)
    this.loading = false
  }

  public editContact(contact: Contact) {
    this.contactDialog.openEdit(contact)
  }

  public async deleteContact(contact: Contact) {
    if (confirm(this.$tc('_companies.confirm_contact_delete')) && contact.id) {
      await companiesService.removeContactAsync(contact.id)
      await this.fetchAll()
    }
  }

  public async create(contact: Contact) {
    contact.id = Guid.newGuid()
    contact.companyId = this.value
    await companiesService.createContactAsync(contact)
    await this.fetchAll()
  }

  public async update(contact: Contact) {
    await companiesService.updateContactAsync(contact)
    await this.fetchAll()
  }

  public add() {
    this.contactDialog.openNew(new Contact())
  }

  public open() {
    this.dialog = true
  }

  public close() {
    this.dialog = false
  }

  @Watch('value')
  private async valueChanged(newValue: boolean) {
    if (this.value) {
      this.fetchAll()
    }
  }
}



























































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import ProductSupplier from '@/products/entities/product-supplier'
import Company from '@/companies/entities/company'
import Product from '@/products/entities/product'
import Dates from '@/app/utils/dates'
import { unitName } from '@/products/utils/units'
import { UnitType } from '@/products/entities/unit-type'
import PriceHistoryDialog from '@/products/components/price-history-dialog.vue'

@Component
export default class ProductSupplierInput extends Vue {
  @Prop()
  private product!: Product

  @Prop()
  private value!: ProductSupplier

  @Prop()
  private readonly!: boolean

  @Ref()
  private priceHistoryDialog!: PriceHistoryDialog

  private supplier = this.value

  get supplierIds() {
    return this.product.suppliers.map((s) => s.supplierId)
  }

  @Watch('value')
  private valueChanged(val: ProductSupplier) {
    this.supplier = val
  }

  // @Watch('supplier.netSalesPriceMultiplier')
  // private multiplierChanged(val: number, old: number) {
  //   if (val > 0 && val !== old) {
  //     // Vue.set(this.supplier, 'netSalesPriceMultiplierChanged', true)
  //     this.supplier.netSalesPriceMultiplierChanged = true
  //   }
  // }

  private changeSupplier(company: Company) {
    this.supplier.supplierId = company.id
    this.supplier.isWarehouse = company.isWarehouse
    if (company.isWarehouse) {
      this.supplier.isPrimary = false
    }
  }

  private dateTime(v: string) {
    return v ? Dates.dateTime(v) : ''
  }

  private unitSuffix(unit: UnitType) {
    return unitName(unit)
  }
}

import Guid from '@/app/utils/guid'
import { LogwoodPackageState } from '@/logwood/models/logwood-package-state'
import moment from 'moment'

export default class LogwoodPackage {
  public id = Guid.empty()
  public number = ''
  public productId: string | null = null
  public productName = ''
  public productNumber = ''
  public targetProductId = ''
  public targetProductName = ''
  public targetProductNumber = ''
  public length = 0
  public height = 22
  public volume = 0
  public state = LogwoodPackageState.OuterBearing
  public location = 0
  public createdById = Guid.empty()
  public createdByName = ''
  public outerBearingOn = moment().format('YYYY-MM-DDTHH:mm:ss')
  public dryingChamberOn: string | null = null
  public dryingBearingOn: string | null = null
}
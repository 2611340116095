

































































import PagedMixin from '@/common/pagination/mixins/paged-mixin'
import Project from '@/projects/entities/project'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ProjectsTable extends Mixins(PagedMixin) {
  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ default: () => [] })
  public projects!: Project[]

  get headers() {
    return [
      { text: this.$tc('number'), value: 'number', sortable: false },
      { text: this.$tc('name'), value: 'name', sortable: false },
      { text: this.$tc('customer'), value: 'companyName', sortable: false },
      {
        text: this.$tc('project_lead'),
        value: 'projectLeadShortCode',
        sortable: false,
      },
      {
        text: this.$tc('production_planning_lead'),
        value: 'productionPlanningLeadShortCode',
        sortable: false,
      },
      { text: this.$tc('due_date'), value: 'dueDate', sortable: false },
      { text: this.$tc('status'), value: 'status', sortable: false },
      {
        text: '',
        value: 'action',
        sortable: false,
        class: 'td-w-shrink',
        align: 'end',
      },
    ]
  }

  private details(project: Project) {
    this.$router.push({
      name: 'project-details',
      params: { lang: this.$i18n.locale, projectId: project.id },
    })
  }

  private newPartsList(project: Project) {
    this.$router.push({
      name: 'parts-list',
      params: { lang: this.$i18n.locale, projectId: project.id },
    })
  }

  private orders(project: Project) {
    this.$router.push({
      name: 'project-orders',
      params: { lang: this.$i18n.locale, projectId: project.id },
    })
  }

  private edit(project: Project) {
    this.$emit('edit', project)
  }

  private deleteProject(project: Project) {
    if (confirm(this.$tc('_projects.confirm_project_delete'))) {
      this.$emit('delete', project)
    }
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "elevation-0",
    attrs: {
      loading: _vm.loading,
      headers: _vm.headers,
      items: _vm.tenants,
      options: _vm.pPagination,
      "server-items-length": _vm.pPagination.totalItems,
      "no-data-text": _vm.$t("no_data_available")
    },
    on: {
      "update:options": function($event) {
        _vm.pPagination = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item.name",
          fn: function(ref) {
            var item = ref.item
            return [
              _vm._v(
                " " + _vm._s(item.firstName) + " " + _vm._s(item.lastName) + " "
              )
            ]
          }
        },
        {
          key: "item.action",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", title: _vm.$t("edit") },
                  on: {
                    click: function($event) {
                      return _vm.edit(item)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("edit")])],
                1
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
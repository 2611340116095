var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "min-height": "100%" } },
        [
          _c("v-card-title", { staticStyle: { height: "64px" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("_products._product_picker.select_product")) +
                " "
            )
          ]),
          _c(
            "v-card-text",
            { staticClass: "flex-grow-1" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0 pl-0", attrs: { cols: "auto" } },
                        [
                          _c("product-directory-tree", {
                            staticClass: "fill-height",
                            attrs: {
                              "edit-disabled": "",
                              lastSelectedNumbers: _vm.lastSelectedNumbers,
                              categories: _vm.categories,
                              pruned: _vm.pruned
                            },
                            on: {
                              select: _vm.loadProduct,
                              "select:dblclick": _vm.quickConfirm
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _vm.product
                            ? [
                                _c("product-input", {
                                  staticClass: "fill-height",
                                  attrs: { readonly: "" },
                                  model: {
                                    value: _vm.product,
                                    callback: function($$v) {
                                      _vm.product = $$v
                                    },
                                    expression: "product"
                                  }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-7 pb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { text: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    depressed: "",
                    color: "primary"
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("select")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }














































import Product from '@/products/entities/product'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import productsService from '@/products/services/products-service'
import moment from 'moment'

@Component
export default class PriceHistories extends Vue {
  products: Product[] = []

  searchText: string = ''
  getFilteredProducts() {
    return this.products.filter((product) => {
      return !this.searchText || product.name.toLowerCase().includes(this.searchText.toLowerCase())
    })
  }

  date: string = moment().add(-1, 'months').format('YYYY-MM-DD')

  @Watch('date')
  async valueChanged(newVal: Date, oldVal: Date) {
    if (newVal !== oldVal) {
      console.log(this.date)
      await this.reload()
    }
  }

  async reload() {
    this.products =
      (await productsService.findPriceHistoriesAsync(this.date)) || []
  }

  async mounted() {
    await this.reload()
  }
}

import { ProductCategory } from '@/products/entities/product-category'

export const productCategories = [
  { value: ProductCategory.Anleimer, text: 'Anleimer' },
  { value: ProductCategory.Bekleidung, text: 'Bekleidung' },
  { value: ProductCategory.Beschlaege, text: 'Beschläge' },
  { value: ProductCategory.Buerobedarf, text: 'Bürobedarf' },
  { value: ProductCategory.FurnierBelegung, text: 'Furnier Belegung' },
  { value: ProductCategory.Furnierkante, text: 'Furnierkante' },
  { value: ProductCategory.InstandhaltungReparatur, text: 'Instandhaltung Reparatur' },
  { value: ProductCategory.Lagerartikel, text: 'Lagerartikel' },
  { value: ProductCategory.Lebensmittel, text: 'Lebensmittel' },
  { value: ProductCategory.Massivholz, text: 'Massivholz' },
  { value: ProductCategory.MaterialKanten, text: 'Material Kanten' },
  { value: ProductCategory.MaterialPlatten, text: 'Material Platten' },
  { value: ProductCategory.Montagematerial, text: 'Montagematerial' },
  { value: ProductCategory.Moebelfertigteile, text: 'Möbelfertigteile' },
  { value: ProductCategory.Oberflaeche, text: 'Oberfläche' },
  { value: ProductCategory.Rundholz, text: 'Rundholz' },
  { value: ProductCategory.Schaerfen, text: 'Schärfen' },
  { value: ProductCategory.Sonstiges, text: 'Sonstiges' },
  { value: ProductCategory.Verpackung, text: 'Verpackung' },
  { value: ProductCategory.WerkstattArtikel, text: 'Werkstatt Artikel' },
  { value: ProductCategory.Werkzeug, text: 'Werkzeug' },
  { value: ProductCategory.ZukaufHandel, text: 'Zukauf Handel' },
  { value: ProductCategory.ZukaufWerkstatt, text: 'Zukauf Werkstatt' },
]

export function productCategoryName(category: ProductCategory) {
  return productCategories.find((u) => u.value === category)?.text || ''
}

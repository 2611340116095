var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "container-toolbar", attrs: { flat: "" } },
        [
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("manufacturer"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAll },
            model: {
              value: _vm.showManufacturers,
              callback: function($$v) {
                _vm.showManufacturers = $$v
              },
              expression: "showManufacturers"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("suppliers"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAll },
            model: {
              value: _vm.showSuppliers,
              callback: function($$v) {
                _vm.showSuppliers = $$v
              },
              expression: "showSuppliers"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("warehouse"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAll },
            model: {
              value: _vm.showWarehouses,
              callback: function($$v) {
                _vm.showWarehouses = $$v
              },
              expression: "showWarehouses"
            }
          }),
          _c("v-checkbox", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("customers"), "hide-details": "" },
            on: { click: _vm.resetPageAndFetchAll },
            model: {
              value: _vm.showCustomers,
              callback: function($$v) {
                _vm.showCustomers = $$v
              },
              expression: "showCustomers"
            }
          }),
          _c("v-text-field", {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce:input",
                value: 500,
                expression: "500",
                arg: "input"
              }
            ],
            attrs: {
              label: _vm.$t("search") + "...",
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: "",
              "append-icon": "search"
            },
            on: { input: _vm.resetPageAndFetchAll },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("companies-table", {
        attrs: {
          loading: _vm.loading,
          companies: _vm.companies,
          pagination: _vm.pagination
        },
        on: {
          "update:pagination": function($event) {
            _vm.pagination = $event
          },
          edit: _vm.openEdit,
          delete: _vm.remove,
          editContacts: _vm.editContacts,
          refresh: _vm.fetchAll
        }
      }),
      _c(
        "v-btn",
        {
          staticClass: "mt-8",
          attrs: {
            title: _vm.$t("import_correction_list"),
            small: "",
            depressed: ""
          },
          on: {
            click: function($event) {
              return _vm.correctionListImportDialog.open()
            }
          }
        },
        [
          _c("v-icon", { attrs: { size: "16px" } }, [
            _vm._v("cleaning_services")
          ]),
          _c("span", { staticClass: "ml-2" }, [
            _vm._v(_vm._s(_vm.$t("import_correction_list")))
          ])
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "ml-2 mt-8",
          attrs: {
            title: _vm.$t("import_price_list"),
            small: "",
            depressed: ""
          },
          on: {
            click: function($event) {
              return _vm.priceListImportDialog.open()
            }
          }
        },
        [
          _c("v-icon", { attrs: { size: "18px" } }, [_vm._v("price_check")]),
          _c("span", { staticClass: "ml-2" }, [
            _vm._v(_vm._s(_vm.$t("import_price_list")))
          ])
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "ml-2 mt-8",
          attrs: { title: _vm.$t("sync_prices"), small: "", depressed: "" },
          on: {
            click: function($event) {
              return _vm.priceSyncDialog.open()
            }
          }
        },
        [
          _c("v-icon", { attrs: { size: "18px" } }, [_vm._v("request_quote")]),
          _c("span", { staticClass: "ml-2" }, [
            _vm._v(_vm._s(_vm.$t("sync_prices")))
          ])
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "mb-8",
          attrs: {
            fixed: "",
            fab: "",
            right: "",
            bottom: "",
            color: "primary"
          },
          on: { click: _vm.openNew }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
      _c("company-dialog", {
        ref: "companyDialog",
        on: { create: _vm.create, update: _vm.update }
      }),
      _c("contacts-dialog", {
        ref: "contactsDialog",
        model: {
          value: _vm.selectedCompanyId,
          callback: function($$v) {
            _vm.selectedCompanyId = $$v
          },
          expression: "selectedCompanyId"
        }
      }),
      _c("correction-list-import-dialog", {
        ref: "correctionListImportDialog"
      }),
      _c("price-list-import-dialog", { ref: "priceListImportDialog" }),
      _c("price-sync-dialog", { ref: "priceSyncDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "container-toolbar", attrs: { flat: "", dense: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column w-group-reorder" },
        [
          _c(
            "v-icon",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.$emit("group:up", _vm.index)
                }
              }
            },
            [_vm._v(" keyboard_arrow_up ")]
          ),
          _c(
            "v-icon",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.$emit("group:down", _vm.index)
                }
              }
            },
            [_vm._v(" keyboard_arrow_down ")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "ml-3" }, [
        _vm._v(" " + _vm._s(_vm.group.number) + " ")
      ]),
      _c("div", { staticClass: "font-weight-bold ml-3" }, [
        _vm._v(" " + _vm._s(_vm.group.name) + " ")
      ]),
      _c("v-spacer"),
      _c(
        "v-btn",
        {
          attrs: {
            icon: "",
            title: _vm.$t("_projects._parts_list.add_to_favorites"),
            tabindex: "-1"
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.$emit("group:addfavorite", _vm.group)
            }
          }
        },
        [_c("v-icon", [_vm._v("favorite")])],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: { icon: "", title: _vm.$t("duplicate"), tabindex: "-1" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.$emit("group:duplicate", _vm.group)
            }
          }
        },
        [_c("v-icon", [_vm._v("content_copy")])],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: { icon: "", title: _vm.$t("edit"), tabindex: "-1" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.$emit("group:edit", _vm.group)
            }
          }
        },
        [_c("v-icon", [_vm._v("edit")])],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "mr-3",
          attrs: { icon: "", title: _vm.$t("delete"), tabindex: "-1" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.$emit("group:remove", _vm.group)
            }
          }
        },
        [_c("v-icon", [_vm._v("delete")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import '@/app/plugins/moment'
import '@/app/plugins/numeral'

import '@/app/plugins/vue-formatter'
import '@/app/plugins/vee-validate'
import '@/app/plugins/vue-bus'
import '@/app/plugins/vue-env'

import '@/users/plugins/vue-permissions'
import '@/users/plugins/vue-user'

import '@/tenants/plugins/vue-tenant'
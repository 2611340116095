import { render, staticRenderFns } from "./product-supplier-input.vue?vue&type=template&id=625d186e&scoped=true&"
import script from "./product-supplier-input.vue?vue&type=script&lang=ts&"
export * from "./product-supplier-input.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "625d186e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCheckbox,VCol,VIcon,VRow,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('625d186e')) {
      api.createRecord('625d186e', component.options)
    } else {
      api.reload('625d186e', component.options)
    }
    module.hot.accept("./product-supplier-input.vue?vue&type=template&id=625d186e&scoped=true&", function () {
      api.rerender('625d186e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/products/components/product-supplier-input.vue"
export default component.exports
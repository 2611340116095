var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex fill-width" },
    [
      _vm.valueIsArray
        ? _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { outlined: "", height: "40px" },
              on: { click: _vm.selectAll }
            },
            [_vm._v(" " + _vm._s(_vm.$t("all")) + " ")]
          )
        : _vm._e(),
      _c(
        "v-select",
        _vm._b(
          {
            attrs: { label: _vm.$t("category"), items: _vm.items },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _vm.valueIsArray
                      ? [
                          _vm.limit
                            ? [
                                index < _vm.limit
                                  ? _c("v-chip", { attrs: { small: "" } }, [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t(item.text)))
                                      ])
                                    ])
                                  : _vm._e(),
                                index === _vm.limit
                                  ? _c(
                                      "span",
                                      { staticClass: "grey--text caption" },
                                      [
                                        _vm._v(
                                          " (+" +
                                            _vm._s(
                                              _vm.value.length - _vm.limit
                                            ) +
                                            " " +
                                            _vm._s(_vm.$t("more")) +
                                            ") "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            : _c("v-chip", { attrs: { small: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("_product_categories." + item.text)
                                    )
                                  )
                                ])
                              ])
                        ]
                      : _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("_product_categories." + item.text))
                          )
                        ])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.item,
              callback: function($$v) {
                _vm.item = $$v
              },
              expression: "item"
            }
          },
          "v-select",
          _vm.$attrs,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logwood-inventory" }, [
    _c("div", { staticClass: "logwood-inventory__header" }, [
      _c("img", {
        staticClass: "logwood-inventory__logo",
        attrs: { src: "/img/logo_print.png" }
      }),
      _c("h1", [_vm._v(_vm._s(_vm.title))])
    ]),
    _c("table", { staticClass: "logwood-inventory__table" }, [
      _c("thead", { staticClass: "logwood-inventory__table__header" }, [
        _c("tr", [
          _c("th", { staticClass: "text-left" }, [
            _vm._v(_vm._s(_vm.$t("product_number_short")))
          ]),
          _c("th", { staticClass: "text-left" }, [
            _vm._v(_vm._s(_vm.$t("name")))
          ]),
          _c("th", { staticClass: "text-right" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("_logwood._logwood-inventory.price_per_unit")) +
                " "
            )
          ]),
          _c("th", { staticClass: "text-right" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("_logwood._logwood-inventory.stock")) + " "
            )
          ]),
          _c("th", { staticClass: "text-right" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("_logwood._logwood-inventory.total_value_stock")
                ) +
                " "
            )
          ])
        ])
      ]),
      _c(
        "tbody",
        _vm._l(_vm.inventory, function(item, idx) {
          return _c("tr", { key: idx }, [
            _c("td", [_vm._v(_vm._s(item.number))]),
            _c("td", [_vm._v(_vm._s(item.name))]),
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm._f("num")(item.price, 2)) + " €/m³")
            ]),
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm._f("num")(item.stock, 3)) + " m³")
            ]),
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm._f("num")(item.price * item.stock, 2)) + " €")
            ])
          ])
        }),
        0
      ),
      _c("tfoot", { staticClass: "logwood-inventory__table__footer" }, [
        _c("tr", [
          _c("td", { attrs: { colspan: "3" } }, [
            _c("strong", [_vm._v(_vm._s(_vm.$t("total")))])
          ]),
          _c("td", { staticClass: "text-right" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm._f("num")(_vm.stockSum, 3)) + " m³")
            ])
          ]),
          _c("td", { staticClass: "text-right" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm._f("num")(_vm.priceSum, 2)) + " €")
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
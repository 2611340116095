












import Country from '@/locations/models/country'
import countriesService from '@/locations/services/countries-service'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class CountrySelect extends Vue {
  @Prop({ required: true })
  private value!: string

  private items: Country[] = []
  private country: string = this.value

  @Watch('value')
  private valueChanged(val: string, old: string) {
    if (val !== old) {
      this.country = val
    }
  }

  @Watch('country')
  private countryChanged(val: Country, old: Country) {
    this.$emit('input', val)
  }

  private created() {
    this.items = countriesService.findAllAsync()
  }

  private mounted() {
    this.country = this.value
  }
}

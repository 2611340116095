import { render, staticRenderFns } from "./equipment.vue?vue&type=template&id=d5c50dba&"
import script from "./equipment.vue?vue&type=script&lang=ts&"
export * from "./equipment.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VFileInput,VRow})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d5c50dba')) {
      api.createRecord('d5c50dba', component.options)
    } else {
      api.reload('d5c50dba', component.options)
    }
    module.hot.accept("./equipment.vue?vue&type=template&id=d5c50dba&", function () {
      api.rerender('d5c50dba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/equipment/views/equipment.vue"
export default component.exports
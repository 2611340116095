
































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import PagedMixin from '@/common/pagination/mixins/paged-mixin'
import Store from '@/stores/models/store'

@Component
export default class StoresTable extends Mixins(PagedMixin) {
  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ default: () => [] })
  public stores!: Store[]

  get headers() {
    return [
      { text: this.$tc('name'), value: 'name', sortable: false },
      { text: this.$tc('project'), value: 'project', sortable: false },
      {
        text: this.$tc('business_unit'),
        value: 'division',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$tc('primary_store'),
        value: 'primary',
        sortable: false,
        align: 'center',
      },
      {
        text: '',
        value: 'action',
        sortable: false,
        class: 'td-w-shrink',
        align: 'end',
      },
    ]
  }

  private newPartsList(store: Store) {
    this.$router.push({
      name: 'parts-list',
      params: { lang: this.$i18n.locale, projectId: store.projectId || '' },
    })
  }

  private orders(store: Store) {
    this.$router.push({
      name: 'project-orders',
      params: { lang: this.$i18n.locale, projectId: store.projectId || '' },
    })
  }

  private divisionName(store: Store) {
    if (store.businessDivision === '') {
      return this.$tc('none')
    }
    if (store.businessDivision === 'all') {
      return this.$tc('all')
    }
    return store.businessDivision
  }

  private divisionColor(store: Store) {
    if (store.businessDivision === '') {
      return 'red lighten-3'
    }
    if (store.businessDivision === 'all') {
      return 'green lighten-3'
    }
    return 'default'
  }
}

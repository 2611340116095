


















import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import productsService from '@/products/services/products-service'
import { ProductCategory } from '@/products/entities/product-category'

@Component
export default class Settings extends Vue {
  private categoryId: ProductCategory | null = null
  private isLoading = false

  async syncCategory() {
      this.isLoading = true
      if (this.categoryId) {
          await productsService.syncCategoryAsync(this.categoryId)
      }
      this.isLoading = false
  }
}













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ProductAvatar extends Vue {
  @Prop()
  private imagePath!: string

  @Prop({ default: '38' })
  private size!: string

  @Prop({ default: '38' })
  private zoom!: string

  private get fullIagePath() {
    return `${this.$env.VUE_APP_IMAGES_URL}/products/${this.imagePath}`
  }
}

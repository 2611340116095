










import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { UnitType } from '@/products/entities/unit-type'
import { units } from '@/products/utils/units'

@Component
export default class UnitSelect extends Vue {
  @Prop()
  private value!: UnitType

  private unit = this.value
  private items = units

  @Watch('value')
  private valueChanged(val: UnitType) {
    this.unit = val
  }

  @Watch('unit')
  private unitChanged(val: UnitType) {
    this.$emit('input', val)
  }
}

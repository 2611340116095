



















































































import Project from '@/projects/entities/project'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import ProjectItemGroup from '@/projects/entities/project-item-group'

@Component
export default class PrintView extends Vue {
  @Prop()
  private project!: Project

  @Prop()
  private groups!: ProjectItemGroup[]

  @Prop({ type: Boolean, default: false })
  public loading!: boolean
}

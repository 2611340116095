var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { outline: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.login($event)
        }
      }
    },
    [
      _c("h1", { staticClass: "mb-5" }, [_vm._v(_vm._s(_vm.$t("login")))]),
      _vm.response
        ? [
            _vm.response.isNotConfirmed
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _vm._v(_vm._s(_vm.$t("_account.please_activate")))
                ])
              : _vm.response.isLockedOut
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _vm._v(_vm._s(_vm.$t("_account.locked_out")))
                ])
              : _vm.response.isDisabled
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _vm._v(_vm._s(_vm.$t("_account.is_disabled")))
                ])
              : _vm.response.authenticated === false
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _vm._v(_vm._s(_vm.$t("_account.wrong_username_or_password")))
                ])
              : _vm._e()
          ]
        : _vm._e(),
      _c("validation-observer", {
        ref: "validator",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("validation-provider", {
                          attrs: {
                            rules: "required|email",
                            mode: "lazy",
                            name: "email"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("email"),
                                        placeholder: " ",
                                        outlined: "",
                                        "error-messages": errors,
                                        "hide-details": errors.length === 0
                                      },
                                      model: {
                                        value: _vm.request.email,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.request,
                                            "email",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "request.email"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("validation-provider", {
                          attrs: {
                            rules: "required",
                            mode: "lazy",
                            name: "password"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("password"),
                                        placeholder: " ",
                                        type: "password",
                                        outlined: "",
                                        autocorrect: "off",
                                        autocapitalize: "none",
                                        spellcheck: "false",
                                        "error-messages": errors,
                                        "hide-details": errors.length === 0
                                      },
                                      model: {
                                        value: _vm.request.password,
                                        callback: function($$v) {
                                          _vm.$set(_vm.request, "password", $$v)
                                        },
                                        expression: "request.password"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { "offset-sm": "6" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              type: "submit",
                              loading: _vm.loggingIn,
                              disabled: invalid,
                              depressed: "",
                              block: "",
                              "x-large": "",
                              color: "primary"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("login")) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
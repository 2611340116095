var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c(
        "date-input",
        _vm._b(
          {
            attrs: {
              label: _vm.$t("_common._period_input.date.begin"),
              width: "120px"
            },
            model: {
              value: _vm.beginDate,
              callback: function($$v) {
                _vm.beginDate = $$v
              },
              expression: "beginDate"
            }
          },
          "date-input",
          _vm.$attrs,
          false
        )
      ),
      _c("div", { staticClass: "mr-1" }),
      _vm.noTime === false
        ? [
            _c(
              "time-input",
              _vm._b(
                {
                  attrs: {
                    label: _vm.$t("_common._period_input.time.begin"),
                    width: "80px"
                  },
                  model: {
                    value: _vm.beginTime,
                    callback: function($$v) {
                      _vm.beginTime = $$v
                    },
                    expression: "beginTime"
                  }
                },
                "time-input",
                _vm.$attrs,
                false
              )
            ),
            _c("div", { staticClass: "mr-1" })
          ]
        : _vm._e(),
      _vm.sameDay === false
        ? [
            _c(
              "date-input",
              _vm._b(
                {
                  attrs: {
                    label: _vm.$t("_common._period_input.date.end"),
                    width: "120px"
                  },
                  model: {
                    value: _vm.endDate,
                    callback: function($$v) {
                      _vm.endDate = $$v
                    },
                    expression: "endDate"
                  }
                },
                "date-input",
                _vm.$attrs,
                false
              )
            ),
            _c("div", { staticClass: "mr-1" })
          ]
        : _vm._e(),
      _vm.noTime === false
        ? [
            _c(
              "time-input",
              _vm._b(
                {
                  attrs: {
                    label: _vm.$t("_common._period_input.time.end"),
                    width: "80px"
                  },
                  model: {
                    value: _vm.endTime,
                    callback: function($$v) {
                      _vm.endTime = $$v
                    },
                    expression: "endTime"
                  }
                },
                "time-input",
                _vm.$attrs,
                false
              )
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
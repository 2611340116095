var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        attrs: {
          items: _vm.users,
          filter: _vm.filter,
          loading: _vm.isLoading,
          "return-object": "",
          clearable: ""
        },
        on: {
          change: _vm.change,
          "click:clear": function($event) {
            return _vm.$emit("input", null)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", { staticClass: "text-no-wrap" }, [
                  _vm._v(
                    " " +
                      _vm._s(item.firstName) +
                      " " +
                      _vm._s(item.lastName) +
                      " "
                  )
                ])
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-list-item-content", [
                  _c("div", { staticClass: "d-flex text-no-wrap" }, [
                    _c(
                      "div",
                      {
                        staticClass: "font-weight-bold",
                        staticStyle: { width: "55px" }
                      },
                      [_vm._v(" " + _vm._s(item.shortCode) + " ")]
                    ),
                    _c("div", [
                      _vm._v(
                        _vm._s(item.firstName) + " " + _vm._s(item.lastName)
                      )
                    ])
                  ])
                ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.user,
          callback: function($$v) {
            _vm.user = $$v
          },
          expression: "user"
        }
      },
      "v-autocomplete",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
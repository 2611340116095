





































import { Component, Prop, Vue } from 'vue-property-decorator'
import FileEntry from '../models/fileEntry'

@Component
export default class FilesList extends Vue {
  file = new File([''], '')
  fileEntries: FileEntry[] = []

  @Prop({ required: true })
  title!: string

  @Prop()
  files!: FileEntry[]

  downloadFile(file: FileEntry) {
    this.$emit('downloadFile', file)
  }

  deleteFile(file: FileEntry) {
    this.$emit('deleteFile', file)
  }

  uploadFiles(files: File[]) {
    this.$emit('uploadFiles', files)
  }
}


































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'

import PaginationRequest from '@/common/pagination/entities/pagination-request'
import TenantDialog from '@/tenants/components/tenant-dialog.vue'
import Tenant from '@/tenants/entities/tenant'
import tenantsService from '@/tenants/services/tenants-service'

@Component
export default class Tenants extends Vue {
  @Ref()
  private dialog!: TenantDialog

  private loading: boolean = false
  private tenants: Tenant[] = []

  private pagination = new PaginationRequest()

  public async fetchAll() {
    this.loading = true
    const response = await tenantsService.findAllAsync(this.pagination)
    this.tenants = response.items
    this.pagination.totalItems = response.totalItems
    this.loading = false
  }

  public openNew() {
    this.dialog.openNew(new Tenant())
  }

  public edit(tenant: Tenant) {
    this.dialog.openEdit(tenant)
  }

  public async create(tenant: Tenant) {
    if (await tenantsService.createAsync(tenant)) {
      await this.fetchAll()
      this.dialog.close()
    }
  }

  public async update(tenant: Tenant) {
    if (await tenantsService.updateAsync(tenant)) {
      await this.fetchAll()
      this.dialog.close()
    }
  }

  @Watch('pagination', { deep: true })
  private async paginationChanged(
    newVal: PaginationRequest,
    oldVal: PaginationRequest
  ) {
    if (newVal !== oldVal) {
      await this.fetchAll()
    }
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.numOfLocations > 1
    ? _c(
        "div",
        [
          _c(
            "v-card-title",
            { staticClass: "d-flex justify-space-between elevation-2" },
            [
              _c("div", [_vm._v(_vm._s(_vm.title))]),
              _vm.showInventoryButton
                ? _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit(
                            "print:inventory",
                            _vm.state,
                            _vm.title
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "_logwood._logwood-packages-table.print_package_inventory"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-expansion-panels",
            { staticClass: "no-border-radius" },
            _vm._l(_vm.numOfLocations, function(location) {
              return _c(
                "v-expansion-panel",
                { key: location, staticClass: "no-border-radius" },
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(
                      " " + _vm._s(_vm.subTitle) + " " + _vm._s(location) + " "
                    )
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("logwood-packages-location-table", {
                        attrs: { state: _vm.state, location: location },
                        on: { print: _vm.print }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "d-flex justify-space-between" },
            [
              _c("div", [_vm._v(_vm._s(_vm.title))]),
              _vm.showInventoryButton
                ? _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit(
                            "print:inventory",
                            _vm.state,
                            _vm.title
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "_logwood._logwood-packages-table.print_package_inventory"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("logwood-packages-location-table", {
                attrs: { state: _vm.state, location: 1 },
                on: { print: _vm.print }
              })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    { attrs: { small: "", color: _vm.statusColor(_vm.value) } },
    [
      _vm._v(
        " " +
          _vm._s(
            _vm.$t(
              _vm.statusList.find(function(s) {
                return s.value == _vm.value
              }).text
            )
          ) +
          " "
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }






















import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator'
import Store from '@/stores/models/store'
import StoresService from '@/stores/services/stores-service'

@Component
export default class StoreMultiSelect extends Vue {
  @Prop()
  private value!: string[]

  @Ref()
  private validator!: ValidationObserver

  private loading: boolean = false
  private stores: Store[] = []
  private selected: Store[] = []

  private get availabelStores() {
    const res: Store[] = []
    const selectable = this.stores.filter((s) => s.projectId == null)
    res.push(...selectable)
    res.push(...this.selected)
    return res
  }

  @Watch('value')
  private async valueChanged(val: string[]) {
    await this.reloadAsync()
  }

  private async loadAllAsync() {
    this.loading = true
    this.stores = await StoresService.findAllAsync('')
    this.loading = false
  }

  private setSelected(ids: string[]) {
    this.selected = this.stores.filter((v) => ids.includes(v.id))
  }

  private filter(item: Store, queryText: string) {
    const itemName = item.name.toLocaleLowerCase()
    const search = queryText.toLocaleLowerCase()
    return itemName.indexOf(search) > -1
  }

  private async emitChange() {
    const ids = this.selected.map((s) => s.id)
    this.$emit('input', ids)
  }

  private emitClear() {
    this.$emit('input', [])
  }

  private async reloadAsync() {
    await this.loadAllAsync()
    this.setSelected(this.value)
  }

  private async mounted() {
    await this.reloadAsync()
  }
}

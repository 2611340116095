import Vue from 'vue'
import { PluginObject, VueConstructor } from 'vue'
import store from '@/app/store'
import AccountModule from '@/users/store/account-module'
import { getModule } from 'vuex-module-decorators'
import User from '@/users/entities/user'

declare module 'vue/types/vue' {
  interface Vue {
    $user: UserData
  }
}

class UserData {
  get data(): User {
    const accountModule = getModule(AccountModule, store)
    return accountModule.userData
  }
}

// tslint:disable-next-line: max-classes-per-file
class VueUserPlugin implements PluginObject<any> {
  public install(vue: VueConstructor, options?: any): void {
    vue.prototype.$user = new UserData()
  }
}

Vue.use(new VueUserPlugin())

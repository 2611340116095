import moment from 'moment'
import 'moment-duration-format'

import 'moment/locale/de'

console.log(`Installed moment.js locales are [${moment.locales()}]`)

moment.updateLocale('de', {
  calendar: {
    lastDay: '[gestern]',
    sameDay: '[heute]',
    nextDay: '[morgen]',
    lastWeek: '[letzten] ddd',
    nextWeek: '[diesen] ddd',
    sameElse: 'L',
  },
})

console.log(`Moment.js locale is [${moment.locale()}].`)

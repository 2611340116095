import cloneDeep from 'lodash/cloneDeep'
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'

@Component
export default class EditDialogMixin<T> extends Vue {

  /**
   * You need to add a validation observer to your dialog and add the attribute
   * `ref="valdiator"`.
   */
  @Ref()
  protected validator!: ValidationObserver

  protected dialog = false
  protected loading = false
  protected editMode = false
  protected model!: T

  public cancel() {
    this.$emit('cancel')
    this.dialog = false
  }

  public close() {
    this.dialog = false
  }

  public openNew(model: T) {
    this.openInternal(false, model)
  }

  public openEdit(model: T) {
    this.openInternal(true, model)
  }

  protected async confirm() {
    this.beforeConfirm()
    if (await this.validator.validate() && await this.customValidationAsync(this.model)) {
      this.loading = true
      await this.afterValidationSuccessAsync()
      this.emitConfirm()
      this.close()
    }
  }

  protected beforeConfirm() {}

  protected async customValidationAsync(model: T) {
    return true
  }

  protected async afterValidationSuccessAsync() {}

  protected emitConfirm() {
    this.$emit(this.editMode ? 'update' : 'create', this.model)
  }

  private openInternal(editMode: boolean, model: T) {
    this.loading = false
    this.editMode = editMode
    this.model = cloneDeep(model)
    this.dialog = true
    Vue.nextTick(() => {
      this.resetValidator()
    })
  }

  private resetValidator() {
    // For some odd reason, the validator is not ready upon first invocation
    // of the dialog.
    if (this.validator) {
      this.validator.reset()
    }
  }
}

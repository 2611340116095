import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import Product from '@/products/entities/product'
import ProductFilter from '@/products/entities/product-filter'
import ProductInventoryFilter from '@/products/entities/product-inventory-filter'
import ProductInventoryItem from '@/products/entities/product-inventory-item'
import { ProductCategory } from '@/products/entities/product-category'

class ProductsService {

  public async findOneAsync(id: string) {
    const res = await axios.get<Product>(`products?id=${id}`)
    return Responses.obj(res)
  }

  public async findAsync(filter: ProductFilter) {
    const res = await axios.post<Product[]>(`products/find`, filter)
    return Responses.list(res)
  }

  public async findInventoryAsync(filter: ProductInventoryFilter) {
    const res = await axios.post<ProductInventoryItem[]>(`products/inventory/find`, filter)
    return Responses.list(res)
  }

  public async createAsync(entity: Product) {
    const res = await axios.post<Product>(`products/create`, entity)
    return Responses.obj(res)
  }

  public async updateAsync(entity: Product) {
    const res = await axios.put<Product>(`products/${entity.id}/update`, entity)
    return Responses.obj(res)
  }

  public async deleteAsync(id: string) {
    const res = await axios.post<Product>(`products/${id}/delete`)
    return Responses.obj(res)
  }

  public async findCategoryMultiplierAsync(id: ProductCategory) {
    const res = await axios.get<number>(`products/categoryMultiplier/${id}`)
    return Responses.obj(res)
  }

  public async syncCategoryAsync(id: ProductCategory) {
    return axios.get(`productsSync?category=${id}`)
  }

  public async findPriceHistoriesAsync(from: string) {
    const res = await axios.get<Product[]>(`products/priceHistories?from=${from}`)
    return Responses.obj(res)
  }
}

export default new ProductsService()

import axios from '@/app/axios'
import CrudService from '@/common/services/crud-service'
import User from '@/users/entities/user'
import Responses from '@/app/utils/responses'
import PaginationResponse from '@/common/pagination/entities/pagination-response'
import UserFilter from '@/users/entities/user-filter'

class UsersService extends CrudService<User> {
  public async findAllForRolesAsync() {
    const res = await axios.post<User[]>(`${this.resource}/roles`)
    return Responses.list(res)
  }

  public async findAllFilteredAsync(request: UserFilter) {
    const res = await axios.post<PaginationResponse<User>>(
      `users/filter`,
      request
    )
    return Responses.pagedList(res)
  }
}

export default new UsersService('users')

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c("v-chip", { attrs: { small: "", color: _vm.dateColor(_vm.value) } }, [
        _vm._v(" " + _vm._s(_vm._f("date")(_vm.value)) + " ")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
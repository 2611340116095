import { render, staticRenderFns } from "./price-history-dialog.vue?vue&type=template&id=9222c4a8&scoped=true&"
import script from "./price-history-dialog.vue?vue&type=script&lang=ts&"
export * from "./price-history-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./price-history-dialog.vue?vue&type=style&index=0&id=9222c4a8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9222c4a8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardActions,VCardText,VCardTitle,VDataTable,VDialog,VIcon,VSpacer,VToolbar,VWindow,VWindowItem})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Sources\\nothegger.topwm\\Nothegger.TopWM.Warehouse.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9222c4a8')) {
      api.createRecord('9222c4a8', component.options)
    } else {
      api.reload('9222c4a8', component.options)
    }
    module.hot.accept("./price-history-dialog.vue?vue&type=template&id=9222c4a8&scoped=true&", function () {
      api.rerender('9222c4a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/products/components/price-history-dialog.vue"
export default component.exports
































import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import ProjectItem from '@/projects/entities/project-item'

@Component
export default class ProfileGraph extends Vue {
  @Prop({ required: true })
  private value!: ProjectItem | null

  private get visible() {
    return (
      this.value?.frontEdgingProduct ||
      this.value?.backEdgingProduct ||
      this.value?.leftEdgingProduct ||
      this.value?.rightEdgingProduct
    )
  }

  private get clazz() {
    return {
      'profile-graph__front': this.value?.frontEdgingProduct,
      'profile-graph__back': this.value?.backEdgingProduct,
      'profile-graph__left': this.value?.leftEdgingProduct,
      'profile-graph__right': this.value?.rightEdgingProduct,
    }
  }
}

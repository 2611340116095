var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "min-height": "100%" } },
        [
          _c("v-card-title", { staticStyle: { height: "64px" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("_products._profiles_picker.select_profiles")) +
                " "
            )
          ]),
          _c(
            "v-card-text",
            { staticClass: "flex-grow-1" },
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                staticClass: "profile-input__selected",
                                attrs: {
                                  items: _vm.items,
                                  "return-object": "",
                                  "item-text": "name",
                                  "item-value": "value",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.selected,
                                  callback: function($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("product-select", {
                                attrs: {
                                  group: "profiles",
                                  label: _vm.$t(
                                    "_products._profiles_picker.front_profile"
                                  ),
                                  placeholder: _vm.$t(
                                    "_products._profiles_picker.select_profiles"
                                  ),
                                  categories: _vm.profileCategories,
                                  pruned: "",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.front,
                                  callback: function($$v) {
                                    _vm.front = $$v
                                  },
                                  expression: "front"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("product-select", {
                                attrs: {
                                  group: "profiles",
                                  label: _vm.$t(
                                    "_products._profiles_picker.back_profile"
                                  ),
                                  placeholder: _vm.$t(
                                    "_products._profiles_picker.select_profiles"
                                  ),
                                  categories: _vm.profileCategories,
                                  pruned: "",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.back,
                                  callback: function($$v) {
                                    _vm.back = $$v
                                  },
                                  expression: "back"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("product-select", {
                                attrs: {
                                  group: "profiles",
                                  label: _vm.$t(
                                    "_products._profiles_picker.left_profile"
                                  ),
                                  placeholder: _vm.$t(
                                    "_products._profiles_picker.select_profiles"
                                  ),
                                  categories: _vm.profileCategories,
                                  pruned: "",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.left,
                                  callback: function($$v) {
                                    _vm.left = $$v
                                  },
                                  expression: "left"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("product-select", {
                                attrs: {
                                  group: "profiles",
                                  label: _vm.$t(
                                    "_products._profiles_picker.right_profile"
                                  ),
                                  placeholder: _vm.$t(
                                    "_products._profiles_picker.select_profiles"
                                  ),
                                  categories: _vm.profileCategories,
                                  pruned: "",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.right,
                                  callback: function($$v) {
                                    _vm.right = $$v
                                  },
                                  expression: "right"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("product-select", {
                                attrs: {
                                  group: "profiles",
                                  label: _vm.$t(
                                    "_products._profiles_picker.special_profile"
                                  ),
                                  placeholder: _vm.$t(
                                    "_products._profiles_picker.select_profiles"
                                  ),
                                  categories: _vm.profileCategories,
                                  pruned: "",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.special,
                                  callback: function($$v) {
                                    _vm.special = $$v
                                  },
                                  expression: "special"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-7 pb-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { text: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    depressed: "",
                    color: "primary"
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("select")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
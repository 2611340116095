var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        _vm._b(
          {
            staticClass: "image-input",
            style: { "background-color": _vm.backgroundColor }
          },
          "v-card",
          _vm.$attrs,
          false
        ),
        [
          _vm.value === null || _vm.value.length === 0
            ? _c(
                "v-card-text",
                {
                  staticStyle: { height: "100%" },
                  on: {
                    click: function($event) {
                      return _vm.openDialog()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-input--select" },
                    [
                      _c(
                        "v-icon",
                        {
                          style: { color: _vm.messageColor },
                          attrs: { size: "64px" }
                        },
                        [_vm._v(" photo_camera ")]
                      )
                    ],
                    1
                  )
                ]
              )
            : _c(
                "v-card-text",
                [
                  _c(
                    "div",
                    {
                      staticClass: "image-input--loupe",
                      on: {
                        click: function($event) {
                          return _vm.openInFull()
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          style: { color: _vm.overlayColor },
                          attrs: { size: "40px" }
                        },
                        [_vm._v(" zoom_in ")]
                      )
                    ],
                    1
                  ),
                  _vm.readonly === false
                    ? _c(
                        "div",
                        {
                          staticClass: "image-input--change",
                          on: {
                            click: function($event) {
                              return _vm.openDialog()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              style: { color: _vm.overlayColor },
                              attrs: { size: "24px" }
                            },
                            [_vm._v(" photo_camera ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showImage
                    ? _c("v-img", {
                        staticClass: "image-input--image",
                        class: { "image-input--image__disabled": _vm.readonly },
                        attrs: {
                          src: _vm.imagePath,
                          "aspect-ratio": _vm.aspectRatio
                        }
                      })
                    : _vm._e()
                ],
                1
              )
        ],
        1
      ),
      _c("input", {
        ref: "fileInput",
        staticClass: "image-input--input",
        attrs: { type: "file" },
        on: { change: _vm.upload }
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.showOverlay, "z-index": "200", opacity: 0.7 } },
        [
          _c(
            "v-btn",
            {
              staticClass: "image-input__overlay-btn",
              attrs: { icon: "", "x-large": "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.showOverlay = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
          _c("div", { staticClass: "image-input__overlay-info" }, [
            _vm._v(" " + _vm._s(_vm.$t("press_esc_to_close")) + " ")
          ]),
          _c(
            "div",
            {
              ref: "overlay",
              staticClass: "image-input__overlay elevation-12",
              attrs: { tabindex: "-1" },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape"
                    ])
                  ) {
                    return null
                  }
                  _vm.showOverlay = false
                }
              }
            },
            [
              _c("div", { staticClass: "image-input__overlay-wrapper" }, [
                _c("img", {
                  staticClass: "image-input__overlay-img",
                  attrs: { src: _vm.originalPath }
                })
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
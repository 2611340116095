




import { Component, Vue, Prop } from 'vue-property-decorator'
import productsService from '@/products/services/products-service'
import ProductInventoryItem from '@/products/entities/product-inventory-item'

@Component
export default class ProductInventoryPrint extends Vue {
  @Prop()
  private category!: string
  @Prop()
  private manufacturerId!: string
  @Prop()
  private supplierId!: string
  @Prop()
  private bulkGoodsOnly!: string

  private inventory: ProductInventoryItem[] = []

  private async loadAsync() {
    this.inventory = await productsService.findInventoryAsync({
      category: parseInt(this.category),
      manufacturerId: this.manufacturerId || '',
      supplierId: this.supplierId || '',
      bulkGoodsOnly: this.bulkGoodsOnly === 'true',
    })
  }

  private async mounted() {
    await this.loadAsync()
    window.print()
  }
}

































































































































































































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import EditDialogMixin from '@/common/dialogs/mixins/edit-dialog-mixin'
import Project from '../entities/project'

@Component
export default class ProjectDialog extends Mixins<EditDialogMixin<Project>>(
  EditDialogMixin
) {
  model = new Project()
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c(
        "v-container",
        { staticClass: "mb-8" },
        [
          _c("h3", { staticClass: "mb-4" }, [
            _vm._v(_vm._s(_vm.$t("general")))
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("product-category-select", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              readonly: _vm.readonly
                            },
                            model: {
                              value: _vm.product.category,
                              callback: function($$v) {
                                _vm.$set(_vm.product, "category", $$v)
                              },
                              expression: "product.category"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "7" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              rules: "required",
                              name: _vm.$t("manufacturer")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("company-select", {
                                      attrs: {
                                        label: "* " + _vm.$t("manufacturer"),
                                        type: "manufacturer",
                                        outlined: "",
                                        dense: "",
                                        "error-messages": errors,
                                        "persistent-hint": "",
                                        readonly: _vm.readonly
                                      },
                                      model: {
                                        value: _vm.product.manufacturerId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.product,
                                            "manufacturerId",
                                            $$v
                                          )
                                        },
                                        expression: "product.manufacturerId"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "5" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              rules: "required",
                              name: _vm.$t("manufacturer_product_number")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label:
                                          "* " +
                                          _vm.$t("manufacturer_product_number"),
                                        outlined: "",
                                        dense: "",
                                        "error-messages": errors,
                                        "persistent-hint": "",
                                        readonly: _vm.readonly
                                      },
                                      model: {
                                        value:
                                          _vm.product.manufacturerProductId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.product,
                                            "manufacturerProductId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "product.manufacturerProductId"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("product_number"),
                              required: "",
                              outlined: "",
                              dense: "",
                              hint: _vm.$t("product_number_hint"),
                              "persistent-hint": "",
                              readonly: _vm.readonly
                            },
                            model: {
                              value: _vm.product.number,
                              callback: function($$v) {
                                _vm.$set(_vm.product, "number", $$v)
                              },
                              expression: "product.number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              slim: "",
                              rules: "required",
                              name: _vm.$t("title")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "* " + _vm.$t("title"),
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        "error-messages": errors,
                                        "persistent-hint": "",
                                        readonly: _vm.readonly
                                      },
                                      model: {
                                        value: _vm.product.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.product, "name", $$v)
                                        },
                                        expression: "product.name"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("image-input", {
                    ref: "imageInput",
                    attrs: {
                      "base-path": "products",
                      height: "275",
                      width: "275",
                      outlined: "",
                      readonly: _vm.isNew || _vm.readonly
                    },
                    on: { change: _vm.uploadImage },
                    model: {
                      value: _vm.product.imagePath,
                      callback: function($$v) {
                        _vm.$set(_vm.product, "imagePath", $$v)
                      },
                      expression: "product.imagePath"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      label: _vm.$t("description"),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      readonly: _vm.readonly
                    },
                    model: {
                      value: _vm.product.description,
                      callback: function($$v) {
                        _vm.$set(_vm.product, "description", $$v)
                      },
                      expression: "product.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("ean_code"),
                      required: "",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      readonly: _vm.readonly
                    },
                    model: {
                      value: _vm.product.eanCode,
                      callback: function($$v) {
                        _vm.$set(_vm.product, "eanCode", $$v)
                      },
                      expression: "product.eanCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("unit-select", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      readonly: _vm.readonly
                    },
                    model: {
                      value: _vm.product.unit,
                      callback: function($$v) {
                        _vm.$set(_vm.product, "unit", $$v)
                      },
                      expression: "product.unit"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("purchase-unit-select", {
                    attrs: {
                      unit: _vm.product.unit,
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      readonly: _vm.readonly
                    },
                    model: {
                      value: _vm.product.purchaseUnit,
                      callback: function($$v) {
                        _vm.$set(_vm.product, "purchaseUnit", $$v)
                      },
                      expression: "product.purchaseUnit"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "required|decimal|min_value:0",
                      name: _vm.$t("sales_price")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("x-money-input", {
                              attrs: {
                                label: _vm.$t("sales_price"),
                                outlined: "",
                                dense: "",
                                hint: _vm.$t("product_sales_price_hint"),
                                "persistent-hint": "",
                                "error-messages": errors,
                                "hide-details": "auto",
                                disabled: ""
                              },
                              model: {
                                value: _vm.product.netSalesPrice,
                                callback: function($$v) {
                                  _vm.$set(_vm.product, "netSalesPrice", $$v)
                                },
                                expression: "product.netSalesPrice"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "required|decimal|min_value:0",
                      name: _vm.$t("length")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("x-number-input", {
                              attrs: {
                                label: _vm.$t("length"),
                                suffix: "mm",
                                outlined: "",
                                dense: "",
                                "fractional-digits": 1,
                                "error-messages": errors,
                                "hide-details": errors.length === 0,
                                readonly: _vm.readonly
                              },
                              model: {
                                value: _vm.product.length,
                                callback: function($$v) {
                                  _vm.$set(_vm.product, "length", $$v)
                                },
                                expression: "product.length"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "required|decimal|min_value:0",
                      name: _vm.$t("width")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("x-number-input", {
                              attrs: {
                                label: _vm.$t("width"),
                                suffix: "mm",
                                outlined: "",
                                dense: "",
                                "fractional-digits": 1,
                                "error-messages": errors,
                                "hide-details": errors.length === 0,
                                readonly: _vm.readonly
                              },
                              model: {
                                value: _vm.product.width,
                                callback: function($$v) {
                                  _vm.$set(_vm.product, "width", $$v)
                                },
                                expression: "product.width"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "required|decimal|min_value:0",
                      name: _vm.$t("height") + "/" + _vm.$t("thickness")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("x-number-input", {
                              attrs: {
                                label:
                                  _vm.$t("height") + "/" + _vm.$t("thickness"),
                                suffix: "mm",
                                outlined: "",
                                dense: "",
                                "fractional-digits": 1,
                                "error-messages": errors,
                                "hide-details": errors.length === 0,
                                readonly: _vm.readonly
                              },
                              model: {
                                value: _vm.product.height,
                                callback: function($$v) {
                                  _vm.$set(_vm.product, "height", $$v)
                                },
                                expression: "product.height"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: "required|integer|min_value:0",
                      name: _vm.$t("weight")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("x-number-input", {
                              attrs: {
                                label: _vm.$t("weight"),
                                suffix: _vm.weightSuffix(_vm.product.unit),
                                outlined: "",
                                dense: "",
                                "error-messages": errors,
                                "hide-details": errors.length === 0,
                                readonly: _vm.readonly
                              },
                              model: {
                                value: _vm.product.weightPerUnit,
                                callback: function($$v) {
                                  _vm.$set(_vm.product, "weightPerUnit", $$v)
                                },
                                expression: "product.weightPerUnit"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    staticClass: "mt-1",
                    attrs: {
                      label: _vm.$t("grain_direction"),
                      "hide-details": "",
                      readonly: _vm.readonly
                    },
                    model: {
                      value: _vm.product.grain,
                      callback: function($$v) {
                        _vm.$set(_vm.product, "grain", $$v)
                      },
                      expression: "product.grain"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("h3", { staticClass: "mt-8 mb-7" }, [
            _vm._v(_vm._s(_vm.$t("warehouse")))
          ]),
          _vm._l(_vm.product.stores, function(sp, index) {
            return _c(
              "v-row",
              { key: "store-" + index, staticClass: "d-flex align-center" },
              [
                _c(
                  "v-col",
                  { staticClass: "py-0" },
                  [
                    _c("validation-provider", {
                      attrs: {
                        slim: "",
                        rules: "required",
                        name: _vm.$t("stores")
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("store-select", {
                                  attrs: {
                                    excluded: _vm.storeIds,
                                    label: _vm.$t("stores"),
                                    outlined: "",
                                    dense: "",
                                    "error-messages": errors,
                                    readonly: _vm.readonly,
                                    ellipsis: 60
                                  },
                                  model: {
                                    value: sp.storeId,
                                    callback: function($$v) {
                                      _vm.$set(sp, "storeId", $$v)
                                    },
                                    expression: "sp.storeId"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "2" } },
                  [
                    _c("validation-provider", {
                      attrs: {
                        rules: "decimal|min_value:0",
                        name: _vm.$t("min_stock")
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("x-number-input", {
                                  attrs: {
                                    "fractional-digits": 2,
                                    label: _vm.$t("min_stock"),
                                    suffix: _vm.unitSuffix(_vm.product.unit),
                                    outlined: "",
                                    dense: "",
                                    "error-messages": errors,
                                    readonly: _vm.readonly
                                  },
                                  model: {
                                    value: sp.minStock,
                                    callback: function($$v) {
                                      _vm.$set(sp, "minStock", $$v)
                                    },
                                    expression: "sp.minStock"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "2" } },
                  [
                    _c("validation-provider", {
                      attrs: {
                        rules: "decimal|min_value:0",
                        name: _vm.$t("min_order_quantity")
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("x-number-input", {
                                  attrs: {
                                    "fractional-digits": 2,
                                    label: _vm.$t("min_order_quantity"),
                                    suffix: _vm.unitSuffix(_vm.product.unit),
                                    outlined: "",
                                    dense: "",
                                    "error-messages": errors,
                                    readonly: _vm.readonly
                                  },
                                  model: {
                                    value: sp.minOrderQuantity,
                                    callback: function($$v) {
                                      _vm.$set(sp, "minOrderQuantity", $$v)
                                    },
                                    expression: "sp.minOrderQuantity"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "auto" } },
                  [
                    _c("stock-input", {
                      attrs: {
                        "product-id": _vm.product.id,
                        "store-id": sp.storeId,
                        unit: _vm.unitSuffix(_vm.product.unit),
                        disabled: _vm.readonly
                      }
                    })
                  ],
                  1
                ),
                !_vm.readonly
                  ? _c(
                      "v-col",
                      {
                        staticClass: "py-0",
                        attrs: { cols: "auto", "align-self": "start" }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.removeStore(index)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("delete")])],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          !_vm.readonly
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-2",
                  attrs: { small: "", depressed: "" },
                  on: {
                    click: function($event) {
                      return _vm.addStore()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("add_store")) + " ")]
              )
            : _vm._e(),
          _c("h3", { staticClass: "mt-8 mb-7" }, [
            _vm._v(_vm._s(_vm.$t("supplier_s")))
          ]),
          _vm._l(_vm.product.suppliers, function(supplier, index) {
            return [
              _c("product-supplier-input", {
                key: "supplier-" + index,
                attrs: {
                  product: _vm.product,
                  value: supplier,
                  readonly: _vm.readonly
                },
                on: {
                  "select:primary": _vm.changePrimarySupplier,
                  remove: function($event) {
                    return _vm.removeSupplier(index)
                  }
                }
              })
            ]
          }),
          !_vm.readonly
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-2",
                  attrs: { small: "", depressed: "" },
                  on: {
                    click: function($event) {
                      return _vm.addSupplier()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("add_supplier")) + " ")]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }







































































































































































































































































































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import ProjectItemGroup from '@/projects/entities/project-item-group'
import cloneDeep from 'lodash/cloneDeep'
import ProjectItem from '@/projects/entities/project-item'
import filter from 'lodash/filter'
import Guid from '@/app/utils/guid'
import ProductOrderItem from '@/orders/entities/product-order-item'
import { makeId } from '@/common/utils/ids'
import { ProductCategory } from '@/products/entities/product-category'

@Component
export default class PartsListGroupDialog extends Vue {
  @Ref()
  private validator!: ValidationObserver

  @Prop({ default: () => [] })
  private orderStates!: ProductOrderItem[]

  private fupCategory = [ProductCategory.FurnierBelegung]

  private dialog = false
  private loading = false

  private group: ProjectItemGroup = new ProjectItemGroup()

  public open(group: ProjectItemGroup) {
    this.group = cloneDeep(group)
    this.group.items.sort((a, b) => a.sorting - b.sorting)
    this.dialog = true
  }

  private add() {
    const newPart = new ProjectItem()
    newPart.number = makeId(10)
    newPart.sorting = this.group.items.length + 1
    this.group.items.push(newPart)
  }

  private up(index: number) {
    if (index > 0) {
      const sel = this.group.items[index]
      const adj = this.group.items[index - 1]
      this.group.items.splice(index - 1, 2, sel, adj)
      this.renumber()
    }
  }

  private down(index: number) {
    if (index < this.group.items.length - 1) {
      const sel = this.group.items[index]
      const adj = this.group.items[index + 1]
      this.group.items.splice(index, 2, adj, sel)
      this.renumber()
    }
  }

  private duplicate(part: ProjectItem) {
    const copy = cloneDeep(part)
    copy.id = Guid.empty()
    copy.number = makeId(10)
    this.group.items.push(copy)
    this.renumber()
  }

  private remove(part: ProjectItem) {
    this.group.items = filter(
      this.group.items,
      (p) => p.sorting !== part.sorting
    )
    this.renumber()
  }

  private renumber() {
    this.group.items.forEach((item, index) => {
      item.sorting = index
    })
  }

  private async confirm() {
    if (await this.validator.validate()) {
      this.loading = true
      this.$emit('confirm', this.group)
      this.loading = false
      this.reset()
      this.cancel()
    }
  }

  private reset() {
    if (this.validator) {
      this.validator.reset()
    }
  }

  private cancel() {
    this.$emit('cancel', this.group)
    this.dialog = false
  }
}

import Entity from '@/common/models/entity'
import Guid from '@/app/utils/guid'

export default class Project extends Entity {
  public tenantId: Guid | null = null
  public number = ''
  public name = ''
  public status = ProjectStatus.Open
  public commission = ''
  public dueDate: string | null = null
  public productionDate: string | null = null
  public deliveryDate: string | null = null
  public companyId: string | null = null
  public companyName: string | null = null
  public projectLeadId: string | null = null
  public projectLeadFirstName: string | null = null
  public projectLeadLastName: string | null = null
  public projectLeadShortCode: string | null = null
  public productionPlanningLeadId: string | null = null
  public productionPlanningLeadFirstName: string | null = null
  public productionPlanningLeadLastName: string | null = null
  public productionPlanningLeadShortCode: string | null = null
  public remarks = ''
  public storeIds: string[] = []
  public isDeleted = false

  static get statusList() {
    return [
      { text: 'cancelled', value: ProjectStatus.Cancelled },
      { text: 'open', value: ProjectStatus.Open },
      { text: 'in_production_planning', value: ProjectStatus.InPreparation },
      { text: 'in_production', value: ProjectStatus.InProduction },
      { text: 'in_delivery', value: ProjectStatus.InDelivery },
      { text: 'closed', value: ProjectStatus.Closed },
    ]
  }

  static statusColor(status: ProjectStatus) {
    switch (status) {
      case ProjectStatus.Cancelled:
        return '#FF7C80'
      case ProjectStatus.Open:
        return '#eeeeee'
      case ProjectStatus.InPreparation:
        return '#ffecb3'
      case ProjectStatus.InProduction:
        return '#FFD966'
      case ProjectStatus.InDelivery:
        return '#92D050'
      case ProjectStatus.Closed:
        return '#AEAAAA'
    }
  }
}

export enum ProjectStatus {
  Cancelled = -1,
  Open = 0,
  InPreparation = 4,
  InProduction = 1,
  InDelivery = 2,
  Closed = 3,
}

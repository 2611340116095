import bus from '@/app/bus'

class Snacks {
  public success(msg: string) {
    this.show('success', msg)
  }

  public info(msg: string) {
    this.show('info', msg)
  }

  public warning(msg: string) {
    this.show('orange darken-3', msg)
  }

  public error(msg: string) {
    this.show('error', msg)
  }

  private show(color: string, msg: string) {
    bus.$emit('app.snack.show', color, msg)
  }
}

export default new Snacks()

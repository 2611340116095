
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LogwoodService from '@/logwood/services/logwood-service'
import LogwoodPackage from '@/logwood/models/logwood-package'
import LogwoodPackageFilter from '@/logwood/models/logwood-package-filter'
import { LogwoodPackageState } from '@/logwood/models/logwood-package-state'

@Component
export default class LogwoodPackagesLocationTable extends Vue {
  @Prop({ default: LogwoodPackageState.OuterBearing })
  private state!: LogwoodPackageState

  @Prop({ type: Number, default: 1 })
  private location!: number

  private packs: LogwoodPackage[] = []
  private filter = new LogwoodPackageFilter()
  private loading = false

  get headers() {
    return [
      {
        text: this.$tc('number_short'),
        value: 'type',
        sortable: false,
      },
      {
        text: this.$tc('volume'),
        value: 'volume',
        sortable: false,
      },
      {
        text: this.$tc('_logwood._logwood-packages-table.outerBearingOn'),
        value: 'outerBearingOn',
        sortable: false,
      },
      {
        text: this.$tc('_logwood._logwood-packages-table.dryingChamberOn'),
        value: 'dryingChamberOn',
        sortable: false,
      },
      {
        text: this.$tc('_logwood._logwood-packages-table.dryingBearingOn'),
        value: 'dryingBearingOn',
        sortable: false,
      },
      {
        text: '',
        value: 'action',
        sortable: false,
        class: 'td-w-shrink',
        align: 'end',
      },
    ]
  }

  @Watch('state', { deep: true })
  private async stateChanged(val: LogwoodPackageState) {
    this.filter.state = val
    await this.loadPackagesAsync()
  }

  @Watch('filter', { deep: true })
  private async filterChanged() {
    await this.loadPackagesAsync()
  }

  private async loadPackagesAsync() {
    this.loading = true
    const res = await LogwoodService.loadPackagesAsync(this.filter)
    this.packs = res.items
    this.filter.paging.totalItems = res.totalItems
    this.loading = false
  }

  private print(item: LogwoodPackage) {
    this.$emit('print', item)
  }

  private async mounted() {
    this.filter.state = this.state
    this.filter.location = this.location
    await this.loadPackagesAsync()
  }
}



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from '@/app/axios'

@Component
export default class FeedbackDialog extends Vue {
  @Prop()
  private value!: boolean

  private text = ''
  private confirmation = false

  @Watch('value')
  private valueChanged(newValue: boolean) {
    if (newValue) {
      this.confirmation = false
    }
  }

  private close() {
    this.$emit('close')
  }

  private async send() {
    await axios.post(`feedback`, { text: this.text })
    this.$emit('close')
    this.confirmation = true
    this.text = ''
  }
}













































































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import axios from '@/app/axios'

@Component
export default class PriceSyncDialog extends Vue {
  @Ref()
  private validator!: ValidationObserver



  private dialog = false
  private loading = false

  private articleColumnIndex = 0
  private priceColumnIndex = 0
  private priceDivisor = 100
  private file: File | null = null
  private warnings: string[] = []

  public open() {
    this.dialog = true
    this.articleColumnIndex = 0
    this.priceColumnIndex = 0
    this.priceDivisor = 100
    this.file = null
    this.warnings = []
    this.loading = false
  }

  private async confirm() {
    if (await this.validator.validate()) {
      this.loading = true
      await this.importCsv()
      this.$emit('upload:complete')
      this.loading = false
      if (this.warnings.length === 0) {
        this.reset()
        this.cancel()
      }
    }
  }

  private async importCsv() {
    if (this.file) {
      const formData = new FormData()
      formData.append('articleColumnIndex', this.articleColumnIndex.toString())
      formData.append('priceColumnIndex', this.priceColumnIndex.toString())
      formData.append('priceDivisor', this.priceDivisor.toString())
      formData.append('file', this.file, this.file.name)
      const res = await axios.post<string[]>(`pricesync`, formData)
      this.warnings = res.data
    }
  }

  private reset() {
    if (this.validator) {
      this.validator.reset()
    }
  }

  private cancel() {
    this.dialog = false
  }
}

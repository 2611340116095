






















import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class LanguageSelection extends Vue {
  private selectLanguage(lang: string) {
    this.$router.push({
      name: this.$route.name ?? undefined,
      params: { lang: lang },
    })
  }
}














































































































































































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import ordersService from '@/orders/services/orders-service'
import companiesService from '@/companies/services/companies-service'
import Order from '@/orders/entities/order'
import Contact from '@/companies/entities/contact'
import { unitName } from '@/products/utils/units'
import { UnitType } from '@/products/entities/unit-type'
import Guid from '@/app/utils/guid'
import FileEntry from '@/common/models/fileEntry'
import snacks from '@/app/services/snacks'
import store from '@/app/store'
import AccountModule from '@/users/store/account-module'
import { getModule } from 'vuex-module-decorators'
import accountService from '@/users/services/account-service'
import User from '@/users/entities/user'

@Component
export default class OrderDetailsDialog extends Vue {
  @Prop({ default: false })
  private readonly!: boolean

  private dialog = false
  private loading = false

  private orderId!: string
  private order: Order | null = new Order()

  private supplierContactPersons: Contact[] = []
  private attachments: FileEntry[] = []

  private currentUser: User = new User()

  get orderItemHeaders() {
    return [
      {
        text: this.$tc('position'),
        align: 'end',
        value: 'pos',
      },
      {
        text: this.$tc('product'),
        value: 'projectName',
        align: 'start',
      },
      {
        text: this.$tc('quantity'),
        align: 'end',
        value: 'purchaseQuantity',
      },
      {
        text: this.$tc('unit'),
        align: 'start',
        value: 'purchaseUnit',
      },
      {
        text: this.$tc('price'),
        align: 'end',
        value: 'price',
      },
      {
        text: '',
        value: 'action',
        sortable: false,
        class: 'td-w-shrink',
        align: 'end',
      },
    ]
  }

  private get statusList() {
    return Order.statusList.map((e) => ({
      text: this.$t(e.text),
      value: e.value,
    }))
  }

  private get enabledForCurrentUser() {
    return this.$auth.isInAnyRole(['ADMIN', 'TENANT', 'USER'])
  }

  private get disabledForCurrentUser() {
    return !this.enabledForCurrentUser
  }

  private get isSupplierContactPersonSelected() {
    return this.order && this.order.supplierContactPersonId != Guid.empty()
  }

  private get isValid() {
    return this.isSupplierContactPersonSelected && this.order?.businessUnit
  }

  public async openAsync(orderId: string) {
    this.orderId = orderId
    await this.loadOrderAsync()
    this.setCurrentUser()
    this.dialog = true
  }

  private async loadOrderAsync() {
    this.loading = true
    this.order = await ordersService.findOneAsync(this.orderId)
    if (this.order) {
      this.changeHeader()
      await this.loadCompanyContactsAsync()
      if (this.readonly === false) {
        await this.loadAttachmentsAsync()
      }
    }
    this.loading = false
  }

  private changeHeader() {
    if (this.order) {
      this.$bus.$emit(
        'app.simple-header.title',
        this.$t('_orders.details_header', {
          orderNum: this.order.number,
          projectNum: this.order.projectNumber,
        })
      )
    }
  }

  private async supplierChangedAsync() {
    await this.loadCompanyContactsAsync()
    if (this.order && this.supplierContactPersons.length == 1) {
      this.order.supplierContactPersonId =
        this.supplierContactPersons[0].id || Guid.empty()
    }
  }

  private async loadCompanyContactsAsync() {
    if (this.order) {
      if (this.order.supplierId) {
        this.supplierContactPersons =
          await companiesService.findAllContactsAsync(this.order.supplierId)
        if (this.supplierContactPersons.length == 0) {
          this.order.supplierContactPersonId = Guid.empty()
        }
      } else {
        this.supplierContactPersons = []
        this.order.supplierContactPersonId = Guid.empty()
      }
    }
  }

  private async loadAttachmentsAsync() {
    this.attachments = await ordersService.findAllAttachmentsAsync(this.orderId)
  }

  private async filesSelectedAsync(files: File[]) {
    for (const f of files) {
      if (f.size > 0 && this.order) {
        await ordersService.uploadAsync(this.orderId, f)
      }
    }
    await this.loadAttachmentsAsync()
  }

  private async deleteAttachmentAsync(upload: FileEntry) {
    if (this.order && confirm(this.$tc('file_delete_confirm'))) {
      await ordersService.deleteAttachmentAsync(this.orderId, upload.fileName)
      await this.loadAttachmentsAsync()
    }
  }

  private download(upload: FileEntry) {
    if (this.order) {
      window.open(
        `${process.env.VUE_APP_API_URL}/orders/${
          this.orderId
        }/download?fileName=${encodeURIComponent(upload.fileName)}`
      )
    }
  }

  private async sendAsync() {
    if (confirm(this.$tc('_orders.confirm_send_order'))) {
      if (this.order) {
        await this.saveInternalAsync()
        this.loading = true
        await ordersService.sendAsync(this.order)
        snacks.success(this.$tc('_orders.order_sent'))
        this.loading = false
        this.dialog = false
        this.$emit('confirm')
      }
    }
  }

  private async saveAsync() {
    if (await this.saveInternalAsync()) {
      snacks.success(this.$tc('_orders.order_saved'))
      this.dialog = false
      this.$emit('confirm')
    }
  }

  private async saveInternalAsync() {
    if (this.order) {
      this.loading = true
      await ordersService.updateAsync(this.order)
      this.loading = false
      return true
    }
    return false
  }

  private cancel() {
    this.dialog = false
  }

  private unitName(unit: UnitType) {
    return unitName(unit)
  }

  private allowedFractionalDigits(unit: UnitType) {
    switch (unit) {
      case UnitType.M2:
        return 2
      case UnitType.M3:
        return 2
      default:
        return 0
    }
  }

  private setCurrentUser() {
    const accountModule = getModule(AccountModule, store)
    this.currentUser = accountModule.userData
  }

  private async updateUserPreferences() {
    this.loading = true
    await accountService.updateCurrent(this.currentUser)
    this.loading = false
  }
}








































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import ProductInventoryItem from '@/products/entities/product-inventory-item'
import { UnitType } from '@/products/entities/unit-type'
import { unitName } from '@/products/utils/units'
import { sumBy, groupBy, forOwn } from 'lodash'

@Component
export default class ProductInventoryList extends Vue {
  @Prop({ default: () => [] })
  private inventory!: ProductInventoryItem[]

  @Prop({ type: Boolean, default: false })
  private editable!: boolean

  private get priceSum() {
    return sumBy(
      this.inventory,
      (i) => i.price * sumBy(i.stocks, (j) => j.current)
    )
  }

  private get stockSums() {
    const result = []
    const groups = groupBy(this.inventory, 'unit')
    for (const [key, items] of Object.entries(groups)) {
      result.push({
        amount: sumBy(items, (i) => sumBy(i.stocks, (j) => j.current)),
        unit: parseInt(key),
      })
    }
    return result
  }

  private unitName(unit: UnitType) {
    return unitName(unit)
  }

  private detailsLink(item: ProductInventoryItem) {
    return {
      path: `/products/${encodeURIComponent(item.number)}`,
    }
  }
}

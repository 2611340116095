



















































import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import projectsService from '@/projects/services/projects-service'
import ServiceResult from '@/app/utils/service-result'

@Component
export default class ProjectImportDialog extends Vue {
  number: number | null = null
  dialog = false
  isLoading = false
  hasError = false
  importFrom = 'TopCarpentry'
  systemsForImport = ['TopCarpentry', 'TopDrawer', 'TopInfittery']

  public open() {
    this.dialog = true
  }

  close() {
    this.dialog = false
  }

  async okAsync() {
    this.isLoading = true
    let result = new ServiceResult(true)
    if (this.number) {
      result = await projectsService.importAsync(this.number, this.importFrom)
    }
    this.$emit('imported', result)
    this.isLoading = false
  }
}


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ChangeDisplay extends Vue {
  @Prop()
  private currentValue!: number

  @Prop()
  private previousValue!: number

  @Prop({ default: '€' })
  private unit!: string

  @Prop({ default: 2 })
  private numberOfFractionDigits!: number

  @Prop({ type: Boolean, default: false })
  private hideFlatChange!: boolean

  private get delta() {
    return this.currentValue - this.previousValue
  }

  private get deltaPercent() {
    return (this.delta / this.previousValue) * 100
  }

  private get colorClass() {
    if (this.delta > 0) {
      return 'green--text'
    }
    if (this.delta < 0) {
      return 'red--text'
    }
    return 'gray--text'
  }
}




























































































import { Component, Ref, Vue, Watch, Prop } from 'vue-property-decorator'
import Project from '@/projects/entities/project'
import projectsService from '@/projects/services/projects-service'
import FileEntry from '@/common/models/fileEntry'
import User from '@/users/entities/user'

@Component
export default class ProjectDetails extends Vue {
  @Prop()
  projectId!: string

  private project: Project | null = new Project()
  private file = new File([''], '')
  private uploads: FileEntry[] = []
  private projectLead: User | null = null
  private productionPlanningLead: User | null = null

  private get projectLeadName() {
    return `${this.project?.projectLeadFirstName} ${this.project?.projectLeadLastName}`
  }

  private get productionPlanningLeadName() {
    return `${this.project?.productionPlanningLeadFirstName} ${this.project?.productionPlanningLeadLastName}`
  }

  public async filesSelectedAsync(files: File[]) {
    for (const f of files) {
      if (f.size > 0 && this.project) {
        await projectsService.uploadAsync(this.project.id, f)
      }
    }
    await this.loadUploadsAsync()
  }

  private async loadProjectAsync() {
    this.project = await projectsService.findOneAsync(this.projectId)
    if (this.project != null) {
      this.$bus.$emit(
        'app.simple-header.title',
        `${this.project.number} - ${this.project.name}`
      )
    }
  }

  private async loadUploadsAsync() {
    this.uploads = await projectsService.findAllUploadsAsync(this.projectId)
  }

  public async deleteUploadAsync(upload: FileEntry) {
    if (this.project && confirm(this.$tc('file_delete_confirm'))) {
      await projectsService.deleteUploadAsync(this.project.id, upload.fileName)
      await this.loadUploadsAsync()
    }
  }

  public download(upload: FileEntry) {
    if (this.project) {
      window.open(
        `${process.env.VUE_APP_API_URL}/projects/${
          this.project.id
        }/download?fileName=${encodeURIComponent(upload.fileName)}`
      )
    }
  }

  async mounted() {
    await this.loadProjectAsync()
    await this.loadUploadsAsync()
  }
}

import Address from '@/locations/models/address'
import Entity from '@/common/models/entity'
import Guid from '@/app/utils/guid'

export default class Company extends Entity {
  public tenantId: string | null = null
  public imosId = ''
  public name = ''
  public remarks = ''
  public address = new Address()
  public isSupplier = false
  public isManufacturer = false
  public isCustomer = false
  public isWarehouse = false
  public isEnabled = true
  public isDeleted = false
  public importedFrom: string | null = null
}

import axios from '@/app/axios'
import CrudService from '@/common/services/crud-service'
import Project from '@/projects/entities/project'
import Responses from '@/app/utils/responses'
import PaginationResponse from '@/common/pagination/entities/pagination-response'
import ProjectFilter from '@/projects/entities/project-filter'
import ProjectItemGroup from '@/projects/entities/project-item-group'
import FileEntry from '@/common/models/fileEntry'
import ApiResult from '@/app/utils/service-result'
import ServiceResult from '@/app/utils/service-result'

class ProjectsService extends CrudService<Project> {
  public async findAllLastChangedAsync() {
    const res = await axios.get<Project[]>(`projects/lastChanged`)
    return Responses.list(res)
  }

  public async findAllLateAsync() {
    const res = await axios.get<Project[]>(`projects/late`)
    return Responses.list(res)
  }

  public async findAllFilteredAsync(request: ProjectFilter) {
    const res = await axios.post<PaginationResponse<Project>>(
      `projects/filter`,
      request
    )
    return Responses.pagedList(res)
  }

  public async findItemsAsync(projectId: string) {
    const res = await axios.get<ProjectItemGroup[]>(
      `projects/${projectId}/findItems`
    )
    return Responses.list(res)
  }

  public async saveItemsAsync(projectId: string, items: ProjectItemGroup[]) {
    const res = await axios.put(`projects/${projectId}/saveItems`, items)
  }

  public async importAsync(number: number, from: string) {
    const res = await axios.get<ApiResult<Project>>(
      `projects/${number}/import?from=${encodeURIComponent(from)}`
    )
    return (
      res?.data ??
      new ServiceResult(false, 'Allgemeiner Fehler beim Importieren.')
    )
  }

  public async uploadAsync(projectId: string, file: File) {
    const formData = new FormData()
    formData.append('id', projectId)
    formData.append('file', file, file.name)
    await axios.post(`projects/${projectId}/upload`, formData)
  }

  public async findAllUploadsAsync(projectId: string) {
    const res = await axios.get<FileEntry[]>(`projects/${projectId}/uploads`)
    return Responses.list(res)
  }

  public async deleteUploadAsync(projectId: string, fileName: string) {
    const res = await axios.post(`projects/${projectId}/deleteUpload`, {
      id: projectId,
      fileName: fileName,
    })
  }

  public async importImosAsync(projectId: string, file: File): Promise<string> {
    const formData = new FormData()
    formData.append('id', projectId)
    formData.append('file', file, file.name)
    const res = await axios.post(`projects/${projectId}/importImos`, formData)
    return res.data
  }
}

export default new ProjectsService('projects')
